import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  List,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  fetchUserDetailsRequest,
  fetchCustomerProfileRequest,
  resetFetchCustomerProfile,
  resetFetchCustomerOnboarding,
  resetFetchCustomerIpaas,
  resetFetchCustomerGfnaccount,
  resetFetchCustomerPayCardReg,
  resetFetchCustomerAddress,
  resetFetchCustomerInvoiceDetails,
  resetFetchCustomerCards,
  resetFetchCustomerBusiness,
  resetUpdateCustomerVerify,
  resetDeleteCustomer,
  resetBlockCustomer,
  resetFetchCustomerOnfido,
  resetaccountCloser
} from '../store/user/actions';
import Spinner from './Spinner';
import { Alert } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  margin: {
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    fontFamily: 'Shell-Book',
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '.85rem',
    },
    '& label': {
      fontFamily: 'Shell-Book',
      fontSize: '.85rem',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
      fontSize: '.7rem',
    },
    '& span': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    alignItems: 'center',
  },
  SettingsBackupRestoreIcon: {
    alignItems: 'center',
    margin: theme.spacing(1, 2, 4, 2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  root: {
    padding: '8px 16px',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    height: 140,
    width: 100,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px 0px',
    fontSize: '1rem',
    textAlign: 'left',
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  failureMsg: {
    fontFamily: 'Shell-Book',
  },
}));

function UserDetailsSearch(props: any) {
  const { loading, userSearchResponse, user } = props;
  const [errorMessage, setErrorMessage] = useState(false);
  const classes = useStyles();

  const [userQuery, setUserQuery] = React.useState('');
  const [hasError, setError] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFetchCustomerProfile());
  }, []);

  const handleChange = (event: any) => {
    setUserQuery(event.target.value.trim());
  };

  function isPhoneContainsNumberAndHyphen(phoneNumber: string) {
    const pattern = /^[0-9-]+$/;
    const isNumber = pattern.test(phoneNumber);
    if (isNumber) {
      return false;
    }
    return true;
  }

  const handleButtonClick = () => {
    // const query: any = {
    //   query: userQuery,
    // };
    // dispatch(fetchUserDetailsRequest(query));

    if (!isPhoneContainsNumberAndHyphen(userQuery)) {
      setErrorMessage(true);
      return;
    }
    setErrorMessage(false);

    const query: any = {
      searchquery: userQuery,
    };
    dispatch(fetchCustomerProfileRequest(query));
    dispatch(resetFetchCustomerOnboarding());
    dispatch(resetFetchCustomerIpaas());
    dispatch(resetFetchCustomerGfnaccount());
    dispatch(resetFetchCustomerPayCardReg());
    dispatch(resetFetchCustomerAddress());
    dispatch(resetFetchCustomerInvoiceDetails());
    dispatch(resetFetchCustomerCards());
    dispatch(resetFetchCustomerBusiness());
    dispatch(resetUpdateCustomerVerify());
    dispatch(resetDeleteCustomer());
    dispatch(resetBlockCustomer());
    dispatch(resetFetchCustomerOnfido());
    dispatch(resetaccountCloser());
  };

  const validateQuery = (e: any) => {
    const query = e.target.value;
    if (!query) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              required
              error={hasError}
              variant="outlined"
              onBlur={validateQuery}
              value={userQuery}
              label="Search Customer"
              onChange={handleChange}
              name="query"
              className={classes.textField}
              size="small"
              helperText="Search with Email, Mobile Number, UserId, Customer ID or GFN Account Number"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && userQuery.length > 2) {
                  handleButtonClick();
                }
              }}
              fullWidth
              id="fullWidth"
            />
            {errorMessage && <Alert severity="info">
              Please enter the mobile number in the format: +(CountryCode)(MobileNumber)
            </Alert>}
          </Grid>

          <Grid item xs={2}>
            <Button
              type="submit"
              variant="contained"
              className={classes.margin}
              onClick={handleButtonClick}
              disabled={userQuery.length < 3}
              style={{ width: '100%' }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Card>

      {/* {props.loading ? (
        <CircularProgress size={42} />
      ) : (
        props.user.map((option: any) => (
          <Card className={classes.root}>
            <h4>
              Country : {_.get(option, 'countryCode', 'No Search availble')}
            </h4>
            <h4>Status : {_.get(option, 'status', 'No Search availble')}</h4>
            <h4>
              Sub Status : {_.get(option, 'subStatus', 'No Search availble')}
            </h4>
            <h4>Created : {_.get(option, 'created', 'No Search availble')}</h4>
            <h4>Modified: {_.get(option, 'modified', 'No Search availble')}</h4>
          </Card>
        ))
      )} */}

      <Grid item xs={12}>
        {loading ? (
          <Card className={classes.root}>
            <div className={classes.spinnerParent}>
              <Spinner custom />
            </div>
          </Card>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
          >
            {userSearchResponse.success ? (
              user.map((UserDetails: {}) => (
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {_.get(UserDetails, 'countryCode', 'NO')}
                      </Avatar>
                    }
                    title={_.get(UserDetails, 'status', 'No Search availble')}
                    subheader={_.get(
                      UserDetails,
                      'subStatus',
                      'No Search availble'
                    )}
                  />
                  <List>
                    Created Date:
                    {moment(
                      _.get(UserDetails, 'created', 'No Search availble')
                    ).format('DD MMM YYYY')}
                  </List>
                  <List>
                    Modified Date:{' '}
                    {moment(
                      _.get(UserDetails, 'modified', 'No Search availble')
                    ).format('DD MMM YYYY')}
                  </List>
                </Card>
              ))
            ) : (
              <div className={classes.failureMsg}>
                {userSearchResponse.message}
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.user.pending,
    user: state.user.user,
    userSearchResponse: state.user.response,
  };
};
export default connect(mapStateToProps)(UserDetailsSearch);
