import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function SkeletonComponent() {
  return (
    <>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </>
  );
}

export default SkeletonComponent;
