import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTimeDiff } from './helper';
import SOAPaymentRequest from './SOAPaymentRequest';
import SOAChargeback from './SOAChargeback';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { fetchChargebackSOARequest } from '../../store/user/actions';
import { connect, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '32ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    padding: 10,
  },
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  selected: {
    background: 'rgba(239, 239, 239, 0.93)',
  },
  row:{
    background:'red !important'
  },
}));

interface soaHeaderInterface {
  id: string;
  label: string;
}

interface Data {
  soaReferenceNumber: string;
  totalAmountDueToThisperiod: number;
  customerCurrencyCode: string;
  paymentReceivedDate: string;
  billingSummeryDate: string;
  chargebackDate: string;
  isChargeBack:boolean;
}

function Row(props: any) {
  const { row, i, payments, getChargeback } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={i}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          background: isOpen ? 'rgba(239, 239, 239, 0.93)' : '',
          cursor: 'pointer',
        }} 
      >
        <TableCell>{row.soaReferenceNumber || '-'} </TableCell>
        <TableCell>{row.totalAmountDueToThisperiod || '-'}</TableCell>
        <TableCell>{row.customerCurrencyCode || '-'}</TableCell>
        <TableCell>{row.paymentReceivedDate || '-'}</TableCell>
        <TableCell>{row.billingSummeryDate|| '-'}</TableCell>
        <TableCell>{row.chargebackDate || '-'}</TableCell>
        <TableCell>
        {row.isChargeBack === true ?
        (
          <Button
              size="small"
              onClick={getChargeback}
              sx={{ textTransform: 'none', fontSize: '0.75rem', color:'red', fontWeight: 'bold' }}
            >
            Yes
          </Button>
          ):
           'No'}
          </TableCell>
      </TableRow>

      <TableRow
        style={{
          background: 'rgba(239, 239, 239, 0.93)',
        }}
      >
        <TableCell style={{ padding: 0 }} colSpan={15}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {payments.length > 0 ? (
              <SOAPaymentRequest
                paymentRequests={payments}
              ></SOAPaymentRequest>
            ) : (
              <div style={{ padding: '8px' }}>
                No payment requests found
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SOATable(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    chargebacks,
    chargebacksLoading,
  } = props;
  const [open, setOpen] = React.useState(false);
  const {soaDetailDto, paymentRequestDto} = props.soaDetails;
  let updatedPaymentRequests: any = [];
  let soas = soaDetailDto.map((soa: any) => soa.soaReferenceNumber);

  const handleClose=()=>{
    setOpen(false);
  };

  const getChargeback = (e: any, soaNum: any) => {
    e.stopPropagation();

    dispatch(
      fetchChargebackSOARequest({
        soanumber: soaNum,
      })
    );

    setOpen(true);
  };

  for (let item of soas) {
    updatedPaymentRequests[item] = paymentRequestDto.filter(
      (pay: any) => pay.soaReferenceNumber === item
    );
  }
  function createData(
    soaReferenceNumber: string,
    totalAmountDueToThisperiod: number,
    customerCurrencyCode: string,
    paymentReceivedDate: string,
    billingSummeryDate: string,
    chargebackDate: string,
  ): Data {
    const isChargeBack = chargebackDate !== '-';
    return {
      soaReferenceNumber,
      totalAmountDueToThisperiod,
      customerCurrencyCode,
      paymentReceivedDate,
      billingSummeryDate,
      chargebackDate,
      isChargeBack
    };
  }

  const rows = soaDetailDto.map((soa: any) =>
    createData(
      soa.soaReferenceNumber,
      soa.totalAmountDueToThisperiod,
      soa.customerCurrencyCode,
      getTimeDiff(soa.paymentReceivedDate),
      getTimeDiff(soa.billingSummeryDate),
      getTimeDiff(soa.chargebackDate),
    )
  );

  const soaHeaderMap: readonly soaHeaderInterface[] = [
    {
      id: 'soaReferenceNumber',
      label: 'SOA Reference Number',
    },
    {
      id: 'totalAmountDueToThisperiod',
      label: 'Total Amount',
    },
    {
      id: 'customerCurrencyCode',
      label: 'Currency Code',
    },
    {
      id: 'paymentReceivedDate',
      label: 'Payment Received Date',
    },
    {
      id: 'billingSummeryDate',
      label: 'Billing Date',
    },
    {
      id: 'chargebackDate',
      label: 'Chargeback Date',
    },
    {
      id: 'isChargeBack',
      label: 'Chargeback',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }
 
  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FCD839' }}>
        <TableRow>
          {soaHeaderMap.map((header) => (
            <TableCell
              key={header.id}
              sx={{
                fontFamily: 'Shell-Book',
                color: '#484848',
                fontWeight: 600,
              }}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 1 },
          pr: { xs: 1, sm: 1 },
          textAlign: 'start',
          minHeight: '48px !important',
        }}
      >
        <Typography
          sx={{
            flex: '1 1 100%',
            fontWeight: 600,
            fontSize: '0.8rem',
            fontFamily: 'Shell-Book',
          }}
          id="tableTitle"
          component="div"
        >
          Statement of Account
        </Typography>
      </Toolbar>
    );
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  return (
                    <Row
                      row={row}
                      key={index}
                      i={index}
                      payments={updatedPaymentRequests[row.soaReferenceNumber]}  
                      getChargeback={(e: any) =>
                        getChargeback(e, row.soaReferenceNumber)
                      }
                    ></Row>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 30 * emptyRows,
                  }}
                >
                  <TableCell colSpan={14} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className={classes.tableFooter}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </Paper>
       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Chargeback Details</DialogTitle>
        <DialogContent>
          <ResponsiveContainer>
            <Grid container justifyContent="flex-start">
              {chargebacksLoading ? (
                <CircularProgress size="1rem" sx={{ color: 'grey.500' }} />
              ) : (
                chargebacks.length>0 ? (
                  <SOAChargeback chargebacks={chargebacks} />
                ) : (
                  'No Chargeback details found'
                )
              )}
            </Grid>
          </ResponsiveContainer>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
      </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    chargebacks: state.soaChargeback.soaChargebacks,
    chargebacksLoading: state.soaChargeback.pending,
    chargebacksError: state.soaChargeback.error,
  };
};

export default connect(mapStateToProps)(SOATable);
