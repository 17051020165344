import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchCountriesRequest } from '../../store/countries/actions';
import AdvancedUserSearch from './AdvancedUserSearch';
import CustomerTable from './CustomerTable';
import UserManagement from '../UserManagement';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
}));

function SearchCustomer(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchPayload, setSearchPayload] = useState({});
  const [displayCustDetails, setDisplayCustomerDetails] = useState(false);
  const {countries, countriesLoading, countriesError } = props;

  if (!countriesError && !countriesLoading && countries.length === 0) {
    dispatch(fetchCountriesRequest());
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={fixedHeightPaper}>
              <AdvancedUserSearch
                searchPayload={(e: any) => setSearchPayload(e)}
              />
            </Paper>
          </Grid>
          {!displayCustDetails ?
            <Grid item xs={12}>
              <Paper variant="outlined" className={fixedHeightPaper}>
                <CustomerTable
                  searchPayload={searchPayload}
                  data={props.customerDetails}
                  loading={props.pending}
                  error={props.customerDetailsError}
                  displayCustomerDetails={(val:any) => setDisplayCustomerDetails(val)}
                />
              </Paper>
            </Grid> 
            :
            <Grid item xs={12}>
              <Paper variant="outlined" className={fixedHeightPaper}>
               <UserManagement searchById={true} displayCustomerDetails={(val:any) => setDisplayCustomerDetails(val)}/>
              </Paper>
            </Grid>}
        </Grid>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    customerDetails: state.customerDetails.customerDetails,
    pending: state.customerDetails.pending,
    customerDetailsError: state.customerDetails.error,
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
  };
};
export default connect(mapStateToProps)(SearchCustomer);
