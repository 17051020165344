import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import Axios from '../../config/Interceptors';
import * as Session from '../session';

import {
  createTokenFailure,
  createTokenSuccess,
  fetchTokenDetailsFailure,
  fetchTokenDetailsSuccess,
  resetCreateTokenRequest,
} from './actions';
import {
  CREATE_TOKENS_REQUEST,
  FETCH_TOKEN_DETAILS_REQUEST,
  RESET_CREATE_TOKENS_REQUEST,
} from './actionTypes';
import {
  CreateTokenRequestPayload,
  FetchTokenDetailsRequestPayload,
  Token,
} from './types';

const getTokenDetails = async (param: FetchTokenDetailsRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return Axios.get<Token[]>(`/tokens`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const createTokenDetails = async (param: CreateTokenRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return Axios.post<Token[]>(
      '/tokens',
      { dealId: param.dealId, noOfTokens: Number(param.noOfTokens), validTo: param.validTo },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
  // return AxiosInstance.get<Token[]>('/search/tokens/' + param[0].token);
};

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* createTokenSaga(data: any) {
  try {
    const { payload }: { payload: CreateTokenRequestPayload } = data;
    const response: AxiosResponse = yield call(createTokenDetails, payload);
    if (response.status === 200) {
      yield put(
        createTokenSuccess({
          token: response.data.data,
          response: response.data,
        })
      );
    } else {
      yield put(
        createTokenFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createTokenFailure({
        error: e.message,
      })
    );
  }
}

function* fetchTokenDetailsSaga(data: any) {
  try {
    const { payload }: { payload: FetchTokenDetailsRequestPayload } = data;
    const response: AxiosResponse = yield call(getTokenDetails, payload);
    if (response.status === 200) {
      yield put(
        fetchTokenDetailsSuccess({
          token: response.data.data || [],
          response: response.data,
          page: response.data.page,
        })
      );
    } else {
      yield put(
        fetchTokenDetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchTokenDetailsFailure({
        error: e.message,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* tokenDetailsSaga() {
  yield all([
    takeLatest(FETCH_TOKEN_DETAILS_REQUEST, fetchTokenDetailsSaga),
    takeLatest(CREATE_TOKENS_REQUEST, createTokenSaga),
    takeLatest(RESET_CREATE_TOKENS_REQUEST, resetCreateTokenRequest),
  ]);
}

export default tokenDetailsSaga;
