import { CssBaseline, createTheme, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './components/Homepage';
import Layout from './components/Layout';
import TokenManagement from './components/TokenManagement';
import UserManagement from './components/UserManagement';
import SearchCustomer from './components/User/SearchCustomer';
import Logout from './components/Logout';
import store from './store';
import App from './App';
import DealManagement from './components/DealManagement';
import UnAuthorisedUser from './components/UnAuthorisedUser';
import DealMasterManagement from './components/DealMaster/DealMasterManagement';
import ConfigurationMaster from './components/Configuration/ConfigurationMaster';
import AppConfiguration from './components/Configuration/AppConfigurations/AppConfiguration';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000008a',
    },
  },
  typography: {
      fontFamily: 'Shell-Book !important',
  },
});

export default function HomeComponent() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <App>
            <CssBaseline>
              <Layout>
                <Switch>
                  <Route path={['/', '/admin']} exact component={Homepage} />
                  <Route
                    exact
                    path={'/admin/token-management'}
                    component={TokenManagement}
                  />
                  <Route
                    exact
                    path={'/admin/user-management'}
                    component={UserManagement}
                  />
                  <Route
                    exact
                    path={'/admin/deal-management'}
                    component={DealManagement}
                  />
                  <Route exact path={'/admin/logout'} component={Logout} />
                  <Route
                    exact
                    path={'/admin/un-authorised'}
                    component={UnAuthorisedUser}
                  />
                  <Route
                    exact
                    path={'/admin/fee-group'}
                    component={DealMasterManagement}
                  />
                  <Route
                    exact
                    path={'/admin/price-profile'}
                    component={DealMasterManagement}
                  />
                  <Route
                    exact
                    path={'/admin/token-type'}
                    component={DealMasterManagement}
                  />
                  <Route
                    exact
                    path={'/admin/card-type'}
                    component={DealMasterManagement}
                  />
                  <Route
                    exact
                    path={'/admin/legal-entity'}
                    component={DealMasterManagement}
                  />
                  <Route
                    exact
                    path={'/admin/configuration/mailjet'}
                    component={ConfigurationMaster}
                  />
                  <Route
                    exact
                    path={'/admin/configuration/appConfiguration'}
                    component={AppConfiguration}
                  />
                   <Route
                    exact
                    path={'/admin/user/search-user'}
                    component={SearchCustomer}
                  />
                </Switch>
              </Layout>
            </CssBaseline>
          </App>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
