import * as Yup from 'yup';
export const MailjetConfigurationValidation = Yup.object().shape({
  customerGroupType: Yup.string()
  .required('Please select Customer Group Type'),
  emailType: Yup.string()
  .required('Please enter Email Type'),
  mailjetId: Yup.number()
  .required('Please enter Mailjet Template Id'),
  languageCode: Yup.string()
  .required('Please select language code')
});
