import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { getTimeDiff } from './helper';

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  selected: {
    background: 'rgba(239, 239, 239, 0.93)',
  },
}));

interface cardOrdersHeaderInterface {
  id: string;
  label: string;
}

interface Data {
  replacementRequestIsComplete: boolean;
  orderReplacementReference: number;
  replacementCardReference: number;
  deliveryAddressLine1: string;
  deliveryAddressLine2: string;
  deliveryAddressTown: boolean;
  deliveryAddressCountry: boolean;
  deliveryAddressPostCode: string;
  created: string;
  modified: string;
}

function Row(props: any) {
  const { row, i } = props;

  return (
    <>
      <TableRow hover tabIndex={-1} key={i}>
        <TableCell>
          {row.replacementRequestIsComplete.toString() || '-'}
        </TableCell>
        <TableCell>{row.orderReplacementReference || '-'}</TableCell>
        <TableCell>{row.replacementCardReference || '-'}</TableCell>
        <TableCell>{row.deliveryAddressLine1 || '-'}</TableCell>
        <TableCell>{row.deliveryAddressLine2 || '-'}</TableCell>
        <TableCell>{row.deliveryAddressTown || '-'}</TableCell>
        <TableCell>{row.deliveryAddressCountry || '-'}</TableCell>
        <TableCell>{row.deliveryAddressPostCode || '-'}</TableCell>
        <TableCell>{row.created || '-'}</TableCell>
        <TableCell>{row.modified || '-'}</TableCell>
      </TableRow>
    </>
  );
}

function FuelCardReplacements(props: any) {
  const classes = useStyles();
  const { cardReplacementRequests } = props;

  function createData(
    replacementRequestIsComplete: boolean,
    orderReplacementReference: number,
    replacementCardReference: number,
    deliveryAddressLine1: string,
    deliveryAddressLine2: string,
    deliveryAddressTown: boolean,
    deliveryAddressCountry: boolean,
    deliveryAddressPostCode: string,
    created: string,
    modified: string
  ): Data {
    return {
      replacementRequestIsComplete,
      orderReplacementReference,
      replacementCardReference,
      deliveryAddressLine1,
      deliveryAddressLine2,
      deliveryAddressTown,
      deliveryAddressCountry,
      deliveryAddressPostCode,
      created,
      modified,
    };
  }

  const rows = cardReplacementRequests
    ? cardReplacementRequests.map((card: any) =>
        createData(
          card.replacementRequestIsComplete,
          card.orderReplacementReference,
          card.replacementCardReference,
          card.deliveryAddressLine1,
          card.deliveryAddressLine2,
          card.deliveryAddressTown,
          card.deliveryAddressCountry,
          card.deliveryAddressPostCode,
          getTimeDiff(card.created),
          getTimeDiff(card.modified)
        )
      )
    : [];

  const cardOrdersHeaderMap: readonly cardOrdersHeaderInterface[] = [
    {
      id: 'replacementRequestIsComplete',
      label: 'Is completed',
    },
    {
      id: 'orderReplacementReference',
      label: 'Order Reference',
    },
    {
      id: 'replacementCardReference',
      label: 'Card Reference',
    },
    {
      id: 'deliveryAddressLine1',
      label: 'Address Line 1',
    },
    {
      id: 'deliveryAddressLine2',
      label: 'Address Line 2',
    },
    {
      id: 'deliveryAddressTown',
      label: 'Town',
    },
    {
      id: 'deliveryAddressCountry',
      label: 'Country',
    },
    {
      id: 'deliveryAddressPostCode',
      label: 'Post Code',
    },
    {
      id: 'created',
      label: 'Created',
    },
    {
      id: 'modified',
      label: 'Modified',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FCD839' }}>
        <TableRow>
          {cardOrdersHeaderMap.map((header) => (
            <TableCell
              key={header.id}
              sx={{
                fontFamily: 'Shell-Book',
                color: '#484848',
                fontWeight: 600,
              }}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 1 },
          pr: { xs: 1, sm: 1 },
          textAlign: 'start',
          minHeight: '48px !important',
        }}
      >
        <Typography
          sx={{
            flex: '1 1 100%',
            fontWeight: 600,
            fontSize: '0.8rem',
            fontFamily: 'Shell-Book',
          }}
          id="tableTitle"
          component="div"
        >
          Card Replacement Requests
        </Typography>
      </Toolbar>
    );
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2, border: '1px solid #D9D9D9' }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    No card replacement request found for this customer
                  </TableCell>
                </TableRow>
              ) : null}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  return <Row row={row} key={index} i={index}></Row>;
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 30 * emptyRows,
                  }}
                >
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length > 0 ? (
            <div className={classes.tableFooter}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : null}
        </TableContainer>
      </Paper>
    </>
  );
}

export default FuelCardReplacements;
