import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '10px',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    border: '1px solid #f4f4f4',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  dnbRow: {
    marginTop: '6px',
  },
  contectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typography: {
    fontSize: '.85rem !important',
  },
}));

function BusinessCheckFailure(props: any) {
  const classes = useStyles();
  const { businessCheckFailure } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box sx={{ m: 2 }}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontSize: '0.9rem',
              borderBottom: '1px solid #D9D9D9',
              pb: 1,
            }}
          >
            Business Check Failure
          </Typography>
          {businessCheckFailure.map((bcf: any) => {
            return (
              <Box sx={{ textAlign: 'left' }}>
                <CardContent sx={{ pl: 0, pb: '0px !important', pt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    className={classes.typography}
                    sx={{ color: '#E44A4D' }}
                  >
                    {bcf.onBoardingRejection || '-'}
                  </Typography>
                </CardContent>
              </Box>
            );
          })}
        </Box>
      </Card>
    </div>
  );
}

export default BusinessCheckFailure;
