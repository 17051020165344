import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import React from 'react';

import { useDrawerContext } from '../contexts/drawer-context';

import MenuItemsList from './MenuItemsList';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    background: '#ffffff',
    position: 'static',
  },
  mobileDrawer: {
    background: '#ffffff',
    position: 'fixed',
    inset: '60px 0px 0px 0px !important',
    [theme.breakpoints.up('sm')]: {
      width: 210,
      flexShrink: 0,
    },
  },
  closed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6) + 1,
    overflowX: 'hidden',
  },
  opened: {
    width: '210px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const CustomDrawer = () => {
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Drawer
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      open={!isLargeScreen && isOpened ? true : false}
      onClose={() => toggleIsOpened(!isOpened)}
      classes={
        !isLargeScreen
          ? {
              paper: clsx(classes.mobileDrawer, {
                [classes.closed]: !isOpened,
                [classes.opened]: isOpened,
                position: 'fixed',
                inset: '60px 0px 0px 0px !important',
                [theme.breakpoints.up('sm')]: {
                  width: 210,
                  flexShrink: 0,
                },
              }),
            }
          : {
              paper: clsx(classes.drawer, {
                [classes.closed]: !isOpened,
                [classes.opened]: isOpened,
              }),
            }
      }
    >
      <MenuItemsList />
    </Drawer>
  );
};

export default CustomDrawer;
