import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import { fetchCustomerCardsRequest } from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';
import FuelCardsTable from './FuelCardsTable';
import FuelCardOrdersTable from './FuelCardOrdersTable';
import FuelCardReplacements from './FuelCardReplacements';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 8px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function UserFuelCards(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    customerProfile,
    customerCards,
    customerCardsLoading,
    customerCardsError,
  } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerCards).length === 0) {
      dispatch(
        fetchCustomerCardsRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box sx={{ m: 2 }}>
          <Box className={classes.boxTitle} onClick={handleExpandClick}>
            <Typography
              component="div"
              sx={{
                textAlign: 'left',
                fontFamily: 'Shell-Book',
                fontWeight: expanded ? '600 !important' : '',
                color: expanded ? '#595959' : '',
                fontSize: '.9rem',
              }}
            >
              Fuel Cards
            </Typography>
            {!expanded ? (
              <ControlPointIcon
                color="action"
                fontSize="small"
              ></ControlPointIcon>
            ) : (
              <RemoveCircleOutlineIcon
                color="action"
                fontSize="small"
              ></RemoveCircleOutlineIcon>
            )}
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <CardContent className={classes.cardContainer}>
            <Box sx={{ width: '100%' }}>
              {!customerCardsLoading ? (
                Object.keys(customerCards).length > 0 ? (
                  <>
                    <FuelCardsTable
                      cards={customerCards.cards}
                    ></FuelCardsTable>
                    <FuelCardOrdersTable
                      cardOrders={customerCards.cardOrders}
                    ></FuelCardOrdersTable>
                    <FuelCardReplacements
                      cardReplacementRequests={
                        customerCards.cardReplacementRequests
                      }
                    ></FuelCardReplacements>
                  </>
                ) : (
                  <>
                    {customerCardsError ? (
                      <Alert severity="error">
                        {`Error loading Fuel Cards - [${customerCardsError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No fuel cards found!</Alert>
                    )}
                  </>
                )
              ) : (
                <>
                  <SkeletonComponent></SkeletonComponent>
                </>
              )}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerCards: state.customerCards.customerCards,
    customerCardsLoading: state.customerCards.pending,
    customerCardsDetailsError: state.customerCards.error,
  };
};

export default connect(mapStateToProps)(UserFuelCards);
