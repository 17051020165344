import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container, Grid, Paper, Card, FormControl, MenuItem, Select, InputLabel, FormHelperText, Typography, } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../Spinner';
import _ from 'lodash';
import ButtonElement from '../../HtmlElement/Button';
import TableElement from '../../HtmlElement/Table';
import ConfirmDialog from '../../HtmlElement/Dialogbox';
import Alert from '@material-ui/lab/Alert';
import {
  fetchAppConfigurationRequest,
  fetchAppConfigurationDetailsRequest,
  deleteAppConfiguartionRequest,
  fetchAllmarketsRequest,
  resetDeleteAppConfiguartion,
  resetUpdateAppConfigurationRequest,
  resetCreateAppConfigurationRequest,
  resetFetchAppConfigurationDetailsRequest
} from '../../../store/configurations/action';
import {
  DeleteAppConfiguartionRequestPayload,
  FetchAppConfigurationDetailsRequestPayload,
} from '../../../store/configurations/types';
import { appConfigurationColumns } from './appConfigurationTableColumns';
import CreateAppConfig from './CreateAppConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root , .MuiTableCell-root': {
      fontFamily: 'Shell-Book',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 10.5px'
    },
    margin: '10px 0px',
    display: 'flex',
    '& .MuiAlert-root': {
      marginLeft: '34px'
    },
    '& .MuiPaper-root': {
      align: 'center'
    },
    '& .MuiPaper-root-MuiDialog-paper': {
      maxWidth: '800px !important'
    }
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
  // Select
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 1),
    minWidth: 250,
    width: 300,
  },
  formControlButton: {
    margin: 0,
  }
  ,
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  spinnerRoot: {
    padding: '8px 16px',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    align: 'center'
  },
}));

function AppConfiguration(this: any, props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    marketList, marketListError, marketListLoading,
    deleteConfigurationLoading, deleteAppConfigurationSuccess,
    appConfigurationLoading, appConfigurationError,appConfigDetailsList, 
    createAppConfiguration, createAppConfigurationErr, createAppConfigurationLoading,
    appConfigTypeList, appConfigTypeListLoading, appConfigTypeListError,
    updateAppConfiguration, updateAppConfiguartionLoading, updateAppConfigurationErr
  } = props;

  const dialogTitle = "Delete App Configuration";
  const dialogContentText =`Are you sure you want to delete App Configuration`
  let array: any[] = [];
  const [appConfigDetails, setAppConfigDetails] = useState(array);
  const [isEdit, setIsEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openAppConfigurationDialog, setOpenAppConfigurationDialog] = React.useState(false);
  const [deleteSuccess, setDeleteAppConfigurationSuccess] = useState(false);
  const [appConfigurationSuccess, setCreateAppConfigurationSuccess] = useState(false);
  const [createAppConfigurationError, setCreateAppConfigurationError] = useState(false);
  const [updateAppConfigurationSuccess, setUpdateAppConfigurationSuccess] = useState(false);
  const [updateAppConfigurationError, setUpdateAppConfigurationError] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);

  const [appConfigType, setAppConfigType] = useState('');
  const [customerGroupTypeValue, setcustomerGroupTypeValue] = useState('');
  const [offeringpackageIdValue, setOfferingpackageIdValue] = useState('');
  const [dealValue, setDealValue] = useState('');
  const [valueData, setValue] = useState('');
  const [addAppConfigType, setAddAppConfigType] = useState('');
  const [marketValue, setMarketValue] = useState('');
  const [configLevel, selectedConfigLevel] = useState('');
  const [configDataType, setConfigDataType] = useState('text');
  const [selectedConfigId, setSelectedConfigId] = useState('');
  const [selectedRow, setSelectedRow] = useState({});

  if (!marketListError && !marketListLoading && marketList.length === 0) {
    dispatch(fetchAllmarketsRequest());
  }

  useEffect(() => {
    dispatch(fetchAppConfigurationRequest());
    dispatch(resetFetchAppConfigurationDetailsRequest());
  }, []);

  const getAppConfigurationLevel = () => {
    setAppConfigDetails([]);
    let configurationLevel = 'Global';
    let newArray = {...appConfigDetailsList};
      newArray = appConfigDetailsList.map((data: any) => {
      if(data.customerGroupTypeId == null && data.dealId ==null && data.marketId == null && data.offeringPackageId == null){
        configurationLevel = 'Global'
      }
      if(data.customerGroupTypeId == null && data.dealId ==null && data.marketId !== null && data.offeringPackageId == null){
        configurationLevel = 'Market'
      }
      if(data.customerGroupTypeId !== null && data.dealId ==null && data.marketId == null && data.offeringPackageId == null){
        configurationLevel = 'Customer Group Type'
      }
      if(data.customerGroupTypeId !== null && data.dealId ==null && data.marketId !== null && data.offeringPackageId == null){
        configurationLevel = 'Customer Group Type & Market'
      }
      if(data.customerGroupTypeId == null && data.dealId !== null && data.marketId == null && data.offeringPackageId == null){
        configurationLevel = 'Deal'
      }
      if(data.customerGroupTypeId == null && data.dealId == null && data.marketId == null && data.offeringPackageId !== null){
        configurationLevel = 'Offering Package'
      }
      return {...data, configurationLevel: configurationLevel}
    })
    setAppConfigDetails(newArray);
  }

  useEffect(() => {
    if(appConfigDetailsList){
      getAppConfigurationLevel();
    }
  }, [appConfigDetailsList]);

  useEffect(() => {
  }, [appConfigDetails]);

  useEffect(() => {
    if (createAppConfiguration) {
      setOpenAppConfigurationDialog(false);
      setCreateAppConfigurationSuccess(true);
      searchAppConfiguration();
      setTimeout(() => {
        setCreateAppConfigurationSuccess(false);
      }, 4000);
      dispatch(resetCreateAppConfigurationRequest())
    }
    if (createAppConfigurationErr) {
      setCreateAppConfigurationError(true);
      setTimeout(() => {
        setCreateAppConfigurationError(false);
      }, 4000);
    }
    if (updateAppConfiguration) {
      setOpenAppConfigurationDialog(false);
      setUpdateAppConfigurationSuccess(true);
      searchAppConfiguration();
      setTimeout(() => {
        setUpdateAppConfigurationSuccess(false);
      }, 4000);
      dispatch(resetUpdateAppConfigurationRequest())
    }
    if (updateAppConfigurationErr) {
      setUpdateAppConfigurationError(true)
      setTimeout(() => {
        setUpdateAppConfigurationError(false);
      }, 4000);
    }
    if (deleteAppConfigurationSuccess) {
      setDialogOpen(false);
      setDeleteAppConfigurationSuccess(true);
      dispatch(resetDeleteAppConfiguartion());
      searchAppConfiguration();
      setTimeout(() => {
        setDeleteAppConfigurationSuccess(false);
      }, 4000);
    }

  }, [updateAppConfiguration, updateAppConfigurationErr, createAppConfigurationErr, createAppConfiguration, deleteAppConfigurationSuccess]);

  const handleConfigurationTypeChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setButtonDisable(false);
      setAppConfigType(value);
    }
  };

  const resetForm = () => {
    selectedConfigLevel('');
    setMarketValue('');
    setcustomerGroupTypeValue('');
    setDealValue('');
    setValue('');
    setOfferingpackageIdValue('');
    setSubmitButtonEnable(false);
  }

  const OpenAppConfigurationDialog = () => {
    setIsEdit(false);
    resetForm();
    setAddAppConfigType('');
    setCreateAppConfigurationSuccess(false);
    setOpenAppConfigurationDialog(true);
  };

  const handleEditAppConfiguration = (row: any) => {
    setSelectedRow(row);
    setIsEdit(true);
    selectedConfigLevel('');
    setOpenAppConfigurationDialog(true);
    setSubmitButtonEnable(true);
    selectedConfigLevel(row.configurationLevel);
    setValue(row.value);
    setMarketValue(row.marketName);
    setDealValue(row.dealname);
    setOfferingpackageIdValue(row.offeringPackageName);
    setcustomerGroupTypeValue(row.customerGroupTypeName);
    setSelectedConfigId(row.configId);
    setAddAppConfigType(row.configTypeId);
    const data = appConfigTypeList.find(
      (val: any) => val.configMasterId === row.configTypeId
    );
    setConfigDataType(data.configTypeName);
  }

  const handleDeleteAppConfiguration = (row: any) => {
    setSelectedConfigId(row.configId);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    const deleteRowRequest: DeleteAppConfiguartionRequestPayload = {
      configId: selectedConfigId
    };
    dispatch(deleteAppConfiguartionRequest(deleteRowRequest));
  };

  const searchAppConfiguration = () => {
    const appConfigTypePayload: FetchAppConfigurationDetailsRequestPayload = {
      configMasterId: appConfigType
    };
    dispatch(fetchAppConfigurationDetailsRequest(appConfigTypePayload));
  };

  return (
    <div className={classes.root}>
      <ResponsiveContainer>
        <>
          <Container className={classes.container} maxWidth="lg">
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                className={clsx(classes.paper, classes.fixedHeight)}
              >
                <Card className={classes.root} variant="elevation">
                  <Grid
                    key="configurationType-grid-1"
                    container
                    justifyContent="flex-start"
                  >
                    <FormControl
                      key="DM-configurationType-formcontrol"
                      variant="outlined"
                      className={classes.formControl} size="small">
                      <InputLabel
                        key="configurationType-input-label"
                        id="configurationType-select-label"
                      >
                        Configuration Type
                      </InputLabel>
                      <Select
                        key="configurationType"
                        label="App Configuration"
                        value={appConfigType || ''}
                        id="appConfiguration"
                        name="appConfiguration"
                        labelId="configurationType-select-label1"
                        onChange={handleConfigurationTypeChange}
                        autoWidth
                      >
                        {(appConfigTypeList || []).map((option: any) => (
                          <MenuItem
                            key={option.configMasterId}
                            value={option.configMasterId}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.configName}
                          </MenuItem>))}
                      </Select>
                      <FormHelperText key="DM-configurationType-FHT1">
                        {appConfigTypeListLoading
                          ? 'Loading app configuration type'
                          : appConfigTypeListError
                            ? `Error in loading configuration type - [${appConfigTypeListError}]`
                            : appConfigTypeList
                              ? ''
                              : 'Please select configuration type'}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlButton}
                      size="small"
                    >
                      <ButtonElement
                        buttonText="Search"
                        onClick={searchAppConfiguration}
                        disabled={buttonDisable}
                      /></FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlButton}
                      size="small"
                    >
                      <ButtonElement
                        buttonText="Configure"
                        onClick={OpenAppConfigurationDialog}
                        disabled={buttonDisable}
                      />
                    </FormControl>
                  </Grid></Card>
                {(((appConfigurationLoading) && (appConfigurationError == null)) ||
                  ((updateAppConfiguartionLoading) && (updateAppConfigurationError == null)) ||
                  ((createAppConfigurationLoading) && (createAppConfigurationErr == null))) &&
                  <Card className={classes.spinnerRoot}>
                    <div className={classes.spinnerParent}>
                      <Spinner custom />
                    </div>
                  </Card>
                }
                {deleteSuccess && <Alert severity="success"> App Configuration has been deleted .</Alert>}
                {appConfigurationSuccess && <Alert severity="success"> Configuration has been configured.</Alert>}
                {updateAppConfigurationSuccess && <Alert severity="success"> Configuration has been updated.</Alert>}
                {
                  (appConfigDetails.length > 0 && (!appConfigurationLoading)) &&
                  <TableElement
                    columns={appConfigurationColumns}
                    rows={ appConfigDetails}
                    isDeleteButtonEnable={true}
                    isEditButtonEnable={true}
                    onClick={(row: any) => handleDeleteAppConfiguration(row)}
                    onClickEdit={(row: any) => handleEditAppConfiguration(row)}
                  />
                }
                <Typography style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}>
                  {appConfigurationError ? (
                    <Alert severity="error">
                      {`Error loading app configuration - [${appConfigurationError}]`}
                    </Alert>
                  ) : (
                    ''
                  )}
                </Typography>
              </Paper>
            </Grid>
          </Container>
          <ConfirmDialog
            deleteConfiguartionLoading={deleteConfigurationLoading}
            open={dialogOpen}
            onClose={()=> setDialogOpen(false)}
            dialogContentText={dialogContentText}
            dialogTitle={dialogTitle}
            confirmDelete={confirmDelete}
            deleteSuccess={deleteSuccess} />

          <div>
            <CreateAppConfig isOpen={openAppConfigurationDialog}
              isEdit={isEdit}
              appConfigurationSuccess={appConfigurationSuccess}
              updateAppConfigurationSuccess={updateAppConfigurationSuccess}
              createAppConfigurationError={createAppConfigurationError}
              updateAppConfigurationError={updateAppConfigurationError}
              setOpenAppConfigurationDialog={setOpenAppConfigurationDialog}
              submitButtonEnable={submitButtonEnable}
              setSubmitButtonEnable={setSubmitButtonEnable}
              resetForm={resetForm}
              selectedConfigLevel={selectedConfigLevel}
              configLevel={configLevel}
              setMarketValue={setMarketValue}
              marketValue={marketValue}
              setcustomerGroupTypeValue={setcustomerGroupTypeValue}
              customerGroupTypeValue={customerGroupTypeValue}
              setDealValue={setDealValue}
              dealValue={dealValue}
              setValue={setValue}
              valueData={valueData}
              setOfferingpackageIdValue={setOfferingpackageIdValue}
              offeringpackageIdValue={offeringpackageIdValue}
              selectedConfigId={selectedConfigId}
              setAddAppConfigType={setAddAppConfigType}
              addAppConfigType={addAppConfigType}
              setIsEdit={setIsEdit}
              setConfigDataType={setConfigDataType}
              configDataType={configDataType}
              setCreateAppConfigurationSuccess={setCreateAppConfigurationSuccess}
              setCreateAppConfigurationError={setCreateAppConfigurationError}
              setUpdateAppConfigurationSuccess={setUpdateAppConfigurationSuccess}
              setUpdateAppConfigurationError={setUpdateAppConfigurationError}
              selectedRow={selectedRow}
            />
          </div>
        </>
      </ResponsiveContainer>
    </div>
  );

}
const mapStateToProps = (state: any) => {
  return {
    marketList: state.allMatrketsList.marketList,
    marketListLoading: state.allMatrketsList.pending,
    marketListError: state.allMatrketsList.error,

    createAppConfiguration: state.createAppConfiguration.CreateAppConfiguration,
    createAppConfigurationLoading: state.createAppConfiguration.pending,
    createAppConfigurationErr: state.createAppConfiguration.error,

    updateAppConfiguration: state.updateAppConfiguration.UpdateAppConfiguration,
    updateAppConfigurationLoading: state.updateAppConfiguration.pending,
    updateAppConfigurationErr: state.updateAppConfiguration.error,

    appConfigTypeList: state.appConfigTypeList.appConfigTypeList,
    appConfigTypeListLoading: state.appConfigTypeList.pending,
    appConfigTypeListError: state.appConfigTypeList.error,

    appConfigDetailsList: state.appConfigDetailsList.appConfigDetailsList,
    appConfigurationLoading: state.appConfigDetailsList.pending,
    appConfigurationError: state.appConfigDetailsList.error,

    deleteConfigurationLoading: state.deleteAppConfiguration.pending,
    deleteAppConfigurationSuccess: state.deleteAppConfiguration.success,
    deleteAppConfigrationError: state.deleteAppConfiguration.error,
  };
};

export default connect(mapStateToProps)(AppConfiguration);
