import { all, call, put, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '../../config/Interceptors';
import {
  createCardTypeFailure,
  createCardTypeSuccess,
  createFeeGroupFailure,
  createFeeGroupSuccess,
  createPriceProfileFailure,
  createPriceProfileSuccess,
  createTokenTypeFailure,
  createTokenTypeSuccess,
  fetchOfferingPackageFailure,
  fetchOfferingPackageSuccess,
  resetCreateCardTypeRequest,
  resetCreateFeeGroupRequest,
  resetCreatePriceProfileRequest,
  resetCreateTokenTypeRequest,
  fetchFeeGroupSuccess,
  fetchFeeGroupFailure,
  fetchPriceProfileSuccess,
  fetchPriceProfileFailure,
  updateFeeGroupSuccess,
  updateFeeGroupFailure,
  resetUpdateFeeGroupRequest,
  updatePriceProfileSuccess,
  updatePriceProfileFailure,
  resetUpdatePriceProfileRequest,
  fetchTokenTypeSuccess,
  fetchTokenTypeFailure,
  updateTokenTypeSuccess,
  updateTokenTypeFailure,
  resetUpdateTokenTypeRequest,
  fetchCardTypeSuccess,
  fetchCardTypeFailure,
  updateCardTypeSuccess,
  updateCardTypeFailure,
  resetUpdateCardTypeRequest,
  createLegalEntitySuccess,
  createLegalEntityFailure,
  resetCreateLegalEntityRequest,
  updateLegalEntitySuccess,
  updateLegalEntityFailure,
  resetUpdateLegalEntityRequest,
  fetchLegalEntitySuccess,
  fetchLegalEntityFailure,
} from './actions';
import {
  FETCH_OFFERINGPACKAGE_REQUEST,
  CREATE_FEEGROUP_REQUEST,
  CREATE_PRICEPROFILE_REQUEST,
  RESET_CREATE_FEEGROUP,
  RESET_CREATE_PRICEPROFILE,
  RESET_CREATE_TOKENTYPE,
  RESET_CREATE_CARDTYPE,
  CREATE_CARDTYPE_REQUEST,
  CREATE_TOKENTYPE_REQUEST,
  FETCH_FEE_GROUP_REQUEST,
  FETCH_PRICEPROFILE_REQUEST,
  UPDATE_FEEGROUP_REQUEST,
  RESET_UPDATE_FEEGROUP,
  UPDATE_PRICEPROFILE_REQUEST,
  RESET_UPDATE_PRICEPROFILE,
  FETCH_TOKENTYPE_REQUEST,
  UPDATE_TOKENTYPE_REQUEST,
  RESET_UPDATE_TOKENTYPE,
  FETCH_CARDTYPE_REQUEST,
  UPDATE_CARDTYPE_REQUEST,
  RESET_UPDATE_CARDTYPE,
  CREATE_LEGALENTITY_REQUEST,
  UPDATE_LEGALENTITY_REQUEST,
  FETCH_LEGALENTITY_REQUEST,
  RESET_CREATE_LEGALENTITY,
  RESET_UPDATE_LEGALENTITY,
} from './actionTypes';
import {
  CreateCardTypeRequest,
  CreateCardTypeTokenTypeRequestPayload,
  CreateFeeGroupRequest,
  CreateFeeGroupRequestPayload,
  CreatePriceProfileRequest,
  CreatePriceProfileRequestPayload,
  CreateTokenTypeRequest,
  FetchOfferingPackageRequestPayload,
  OfferingPackage,
  FetchFeeGroupRequest,
  FetchPriceProfileRequest,
  UpdateFeeGroupRequest,
  UpdatePriceProfileRequest,
  UpdateTokenTypeRequest,
  FetchTokenTypeRequest,
  UpdateCardTypeRequest,
  FetchCardTypeRequest,
  CreateLegalEntityRequestPayload,
  CreateLegalEntityRequest,
  UpdateLegalEntityRequest,
  FetchLegalEntityRequest,
} from './types';
import * as Session from '../session';
import { AxiosResponse } from 'axios';

const getOfferingPackage = async (
  param: FetchOfferingPackageRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<OfferingPackage[]>(
      '/offering-packages/getMaster',
      {
        params: { country: param.country },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchOfferingPackageSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getOfferingPackage, payload);
    if (response.status === 200) {
      yield put(
        fetchOfferingPackageSuccess({
          offeringPackage: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchOfferingPackageFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchOfferingPackageFailure({
        error: e.message,
      })
    );
  }
}

const createFeeGroup = async (param: CreateFeeGroupRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateFeeGroupRequestPayload = formatValues(
      param,
      'feeGroup'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/createFeeGroup',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* createFeeGroupSaga({ payload: payload }: CreateFeeGroupRequest) {
  try {
    const response: AxiosResponse = yield call(createFeeGroup, payload);
    if (response.status === 200) {
      yield put(
        createFeeGroupSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createFeeGroupFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createFeeGroupFailure({
        error: e.message,
      })
    );
  }
}

const createPriceProfile = async (param: CreatePriceProfileRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreatePriceProfileRequestPayload = formatValues(
      param,
      'priceProfile'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/createPriceProfile',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* createPriceProfileSaga({
  payload: payload,
}: CreatePriceProfileRequest) {
  try {
    const response: AxiosResponse = yield call(createPriceProfile, payload);
    if (response.status === 200) {
      yield put(
        createPriceProfileSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createPriceProfileFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createPriceProfileFailure({
        error: e.message,
      })
    );
  }
}

const createTokenType = async (
  param: CreateCardTypeTokenTypeRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateCardTypeTokenTypeRequestPayload = formatValues(
      param,
      'tokenType'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/createTokenType',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* createTokenTypeSaga({ payload: payload }: CreateTokenTypeRequest) {
  try {
    const response: AxiosResponse = yield call(createTokenType, payload);
    if (response.status === 200) {
      yield put(
        createTokenTypeSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createTokenTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createTokenTypeFailure({
        error: e.message,
      })
    );
  }
}

const createCardType = async (param: CreateCardTypeTokenTypeRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateCardTypeTokenTypeRequestPayload = formatValues(
      param,
      'cardType'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/createCardType',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* createCardTypeSaga({ payload: payload }: CreateCardTypeRequest) {
  try {
    const response: AxiosResponse = yield call(createCardType, payload);
    if (response.status === 200) {
      yield put(
        createCardTypeSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createCardTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createCardTypeFailure({
        error: e.message,
      })
    );
  }
}

function formatValues(values: any, type: string) {
  switch (type) {
    case 'feeGroup':
      values.name = `${values.countryCode} ${values.name}`;
      values.id = parseInt(values.id);
      values.annualFee = parseFloat(values.annualFee);
      values.setUpFee = parseFloat(values.setUpFee);
      values.monthlyFee = parseFloat(values.monthlyFee);
      values.cardMonthlyFee = parseFloat(values.cardMonthlyFee);
      values.transactionFee = parseFloat(values.transactionFee);
      values.cardReplacementFee = parseFloat(values.cardReplacementFee);
      values.oneOffFee = parseFloat(values.oneOffFee);
      delete values.countryCode;
      return values;
    case 'priceProfile':
      values.name = `${values.countryCode} ${values.name}`;
      values.id = parseInt(values.id);
      values.vPowerDiscount = parseFloat(values.vPowerDiscount);
      values.mainGrade = parseFloat(values.mainGrade);
      values.dieselAGO = parseFloat(values.dieselAGO);
      values.petrolMOGAS = parseFloat(values.petrolMOGAS);
      values.lubricants = parseFloat(values.lubricants);
      values.carWash = parseFloat(values.carWash);
      values.mainGradeTPN = parseFloat(values.mainGradeTPN);
      values.dieselAGOTPN = parseFloat(values.dieselAGOTPN);
      values.fuelSaveDiesel = parseFloat(values.fuelSaveDiesel);
      values.fuelSaveMOGAS = parseFloat(values.fuelSaveMOGAS);
      values.vPowerMOGAS = parseFloat(values.vPowerMOGAS);
      values.lpg = parseFloat(values.lpg);
      values.evShellNetwork = parseFloat(values.evShellNetwork);
      values.evOnRoam = parseFloat(values.evOnRoam);
      delete values.countryCode;
      return values;
    case 'tokenType':
    case 'cardType':
    case 'legalEntity':
      values.name = `${values.countryCode} ${values.name}`;
      values.id = parseInt(values.id);
      delete values.countryCode;
      return values;
    default:
      return values;
  }
}

const getFeeGroup = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getFeeGroups', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchFeeGroupSaga({ payload: payload }: FetchFeeGroupRequest) {
  try {
    const response: AxiosResponse = yield call(getFeeGroup, payload);
    if (response.status === 200) {
      yield put(
        fetchFeeGroupSuccess({
          feeGroups: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchFeeGroupFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchFeeGroupFailure({
        error: e.message,
      })
    );
  }
}

const getPriceProfile = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getPriceProfile', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchPriceProfileSaga({
  payload: payload,
}: FetchPriceProfileRequest) {
  try {
    const response: AxiosResponse = yield call(getPriceProfile, payload);
    if (response.status === 200) {
      yield put(
        fetchPriceProfileSuccess({
          priceProfiles: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchPriceProfileFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchPriceProfileFailure({
        error: e.message,
      })
    );
  }
}

const updateFeeGroup = async (param: CreateFeeGroupRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateFeeGroupRequestPayload = formatValues(
      param,
      'feeGroup'
    );
    return AxiosInstance.post<any>('/offering-packages/editFeeGroup', params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateFeeGroupSaga({ payload: payload }: UpdateFeeGroupRequest) {
  try {
    const response: AxiosResponse = yield call(updateFeeGroup, payload);
    if (response.status === 200) {
      yield put(
        updateFeeGroupSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateFeeGroupFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createFeeGroupFailure({
        error: e.message,
      })
    );
  }
}

const updatePriceProfile = async (param: CreatePriceProfileRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreatePriceProfileRequestPayload = formatValues(
      param,
      'priceProfile'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/editPriceProfile',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* updatePriceProfileSaga({
  payload: payload,
}: UpdatePriceProfileRequest) {
  try {
    const response: AxiosResponse = yield call(updatePriceProfile, payload);
    if (response.status === 200) {
      yield put(
        updatePriceProfileSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updatePriceProfileFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updatePriceProfileFailure({
        error: e.message,
      })
    );
  }
}

const getTokenType = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getTokenType', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchTokenTypeSaga({ payload: payload }: FetchTokenTypeRequest) {
  try {
    const response: AxiosResponse = yield call(getTokenType, payload);
    if (response.status === 200) {
      yield put(
        fetchTokenTypeSuccess({
          tokenTypes: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchTokenTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchTokenTypeFailure({
        error: e.message,
      })
    );
  }
}

const updateTokenType = async (
  param: CreateCardTypeTokenTypeRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateCardTypeTokenTypeRequestPayload = formatValues(
      param,
      'tokenType'
    );
    return AxiosInstance.post<any>('/offering-packages/editTokenType', params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateTokenTypeSaga({ payload: payload }: UpdateTokenTypeRequest) {
  try {
    const response: AxiosResponse = yield call(updateTokenType, payload);
    if (response.status === 200) {
      yield put(
        updateTokenTypeSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateTokenTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateTokenTypeFailure({
        error: e.message,
      })
    );
  }
}

const getCardType = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getCardType', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCardTypeSaga({ payload: payload }: FetchCardTypeRequest) {
  try {
    const response: AxiosResponse = yield call(getCardType, payload);
    if (response.status === 200) {
      yield put(
        fetchCardTypeSuccess({
          cardTypes: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchCardTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCardTypeFailure({
        error: e.message,
      })
    );
  }
}

const updateCardType = async (param: CreateCardTypeTokenTypeRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateCardTypeTokenTypeRequestPayload = formatValues(
      param,
      'cardType'
    );
    return AxiosInstance.post<any>('/offering-packages/editCardType', params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateCardTypeSaga({ payload: payload }: UpdateCardTypeRequest) {
  try {
    const response: AxiosResponse = yield call(updateCardType, payload);
    if (response.status === 200) {
      yield put(
        updateCardTypeSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateCardTypeFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateCardTypeFailure({
        error: e.message,
      })
    );
  }
}

const createLegalEntity = async (param: CreateLegalEntityRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateLegalEntityRequestPayload = formatValues(
      param,
      'legalEntity'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/createLegalEntity',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* createLegalEntitySaga({
  payload: payload,
}: CreateLegalEntityRequest) {
  try {
    const response: AxiosResponse = yield call(createLegalEntity, payload);
    if (response.status === 200) {
      yield put(
        createLegalEntitySuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createLegalEntityFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createLegalEntityFailure({
        error: e.message,
      })
    );
  }
}

const updateLegalEntity = async (param: CreateLegalEntityRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    const params: CreateLegalEntityRequestPayload = formatValues(
      param,
      'legalEntity'
    );
    return AxiosInstance.post<any>(
      '/offering-packages/editLegalEntity',
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* updateLegalEntitySaga({
  payload: payload,
}: UpdateLegalEntityRequest) {
  try {
    const response: AxiosResponse = yield call(updateLegalEntity, payload);
    if (response.status === 200) {
      yield put(
        updateLegalEntitySuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateLegalEntityFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateLegalEntityFailure({
        error: e.message,
      })
    );
  }
}

const getLegalEntity = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getLegalEntity', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchLegalEntitySaga({ payload: payload }: FetchLegalEntityRequest) {
  try {
    const response: AxiosResponse = yield call(getLegalEntity, payload);
    if (response.status === 200) {
      yield put(
        fetchLegalEntitySuccess({
          legalEntities: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchLegalEntityFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchLegalEntityFailure({
        error: e.message,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* offeringPackageSaga() {
  yield all([
    takeLatest(FETCH_OFFERINGPACKAGE_REQUEST, fetchOfferingPackageSaga),
    takeLatest(CREATE_FEEGROUP_REQUEST, createFeeGroupSaga),
    takeLatest(CREATE_PRICEPROFILE_REQUEST, createPriceProfileSaga),
    takeLatest(CREATE_TOKENTYPE_REQUEST, createTokenTypeSaga),
    takeLatest(CREATE_CARDTYPE_REQUEST, createCardTypeSaga),
    takeLatest(RESET_CREATE_FEEGROUP, resetCreateFeeGroupRequest),
    takeLatest(RESET_CREATE_PRICEPROFILE, resetCreatePriceProfileRequest),
    takeLatest(RESET_CREATE_TOKENTYPE, resetCreateTokenTypeRequest),
    takeLatest(RESET_CREATE_CARDTYPE, resetCreateCardTypeRequest),
    takeLatest(FETCH_FEE_GROUP_REQUEST, fetchFeeGroupSaga),
    takeLatest(FETCH_PRICEPROFILE_REQUEST, fetchPriceProfileSaga),
    takeLatest(FETCH_TOKENTYPE_REQUEST, fetchTokenTypeSaga),
    takeLatest(UPDATE_FEEGROUP_REQUEST, updateFeeGroupSaga),
    takeLatest(RESET_UPDATE_FEEGROUP, resetUpdateFeeGroupRequest),
    takeLatest(UPDATE_PRICEPROFILE_REQUEST, updatePriceProfileSaga),
    takeLatest(RESET_UPDATE_PRICEPROFILE, resetUpdatePriceProfileRequest),
    takeLatest(UPDATE_TOKENTYPE_REQUEST, updateTokenTypeSaga),
    takeLatest(RESET_UPDATE_TOKENTYPE, resetUpdateTokenTypeRequest),
    takeLatest(UPDATE_CARDTYPE_REQUEST, updateCardTypeSaga),
    takeLatest(RESET_UPDATE_CARDTYPE, resetUpdateCardTypeRequest),
    takeLatest(FETCH_CARDTYPE_REQUEST, fetchCardTypeSaga),
    takeLatest(CREATE_LEGALENTITY_REQUEST, createLegalEntitySaga),
    takeLatest(UPDATE_LEGALENTITY_REQUEST, updateLegalEntitySaga),
    takeLatest(FETCH_LEGALENTITY_REQUEST, fetchLegalEntitySaga),
    takeLatest(RESET_CREATE_LEGALENTITY, resetCreateLegalEntityRequest),
    takeLatest(RESET_UPDATE_LEGALENTITY, resetUpdateLegalEntityRequest),
  ]);
}

export default offeringPackageSaga;
