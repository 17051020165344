import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

const useStyles = makeStyles(() => ({
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
    },
  },
  td: {
    '& .MuiTableCell-root': {
      fontSize: '0.75rem !important',
    },
  },
}));

interface SOAChargebackHeaderInterface {
  id: string;
  label: string;
}

interface Data {
  amount: string;
  currency: string;
  pspReference: string;
  originalReference: string;
  reason: string;
  eventDate:Date;
  hasRecovered:boolean;
}

function SOAChargeback(props: any) {
  const classes = useStyles();
  const { chargebacks } = props;

  function createData(
    amount: string,
    currency: string,
    pspReference: string,
    originalReference: string,
    reason: string,
    eventDate:Date,
    hasRecovered:boolean,
  ): Data {
    return {
      amount,
      currency,
      pspReference,
      originalReference,
      reason,
      eventDate,
      hasRecovered,
    };
  }

  const rows = chargebacks.map((cb: any) =>
    createData(
      cb.amount,
      cb.currency,
      cb.pspReference,
      cb.originalReference,
      cb.reason,
      cb.eventDate,
      cb.hasRecovered,
    )
  );

  const SOAchargebackHeaderMap: readonly SOAChargebackHeaderInterface[] = [
    {
      id: 'amount',
      label: 'Amount',
    },
    {
      id: 'currency',
      label: 'Currency',
    },
    {
      id: 'pspReference',
      label: 'Psp Reference',
    },
    {
      id: 'originalReference',
      label: 'Original Reference',
    },
    {
      id: 'reason',
      label: 'Reason',
    },
    {
      id: 'eventDate',
      label: 'Event Date',
    },
    {
      id: 'hasRecovered',
      label: 'Is Recovered',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FDEB9C' }}>
        <TableRow>
          {SOAchargebackHeaderMap.map((header) => (
            <TableCell
              key={header.id}
              sx={{
                fontFamily: 'Shell-Medium',
                color: '#484848',
                fontSize: '12px',
              }}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows.map((row: any, index: any) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    className={classes.td}
                  >
                    <TableCell>{row.amount || '-'}</TableCell>
                    <TableCell>{row.currency.toString() || '-'}</TableCell>
                    <TableCell>{row.pspReference || '-'}</TableCell>
                    <TableCell>{row.originalReference || '-'}</TableCell>
                    <TableCell>{row.reason || '-'}</TableCell>
                    <TableCell>{row.eventDate || '-'}</TableCell>
                    <TableCell>{row.hasRecovered===true?'Yes':'No'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default SOAChargeback;
