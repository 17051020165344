import {
  Paper,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Card,
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../Spinner';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';
import {
  fetchFeeGroupRequest,
  resetCreateFeeGroupRequest,
  resetUpdateFeeGroupRequest,
} from '../../store/offeringPackages/actions';
import { FetchFeeGroupRequestPayload } from '../../store/offeringPackages/types';
import CreateUpdateFeeGroup from './CreateUpdateFeeGroup';
import { DealMasterTable } from './DealMasterTable';
import { handleNumberValidation } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
  },
  helperText: {
    marginLeft: 10,
  },
  chips: {
    margin: 1,
    background: '#aaaaaa50',
  },
  searchIcon: {
    '&:hover': { background: '#fbcf04d1' },
    '&.MuiIconButton-root.Mui-disabled': { background: '#ddddddcf' },
    background: '#fbcf04',
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
  },
}));

let feeGroupInitialValues: any = {
  category: '',
  subCategory: 'feeGroup',
  feeGroup: {
    countryCode: '',
    marketId: '',
    name: '',
    id: '',
    setUpFee: 0.0,
    monthlyFee: 0.0,
    cardMonthlyFee: 0.0,
    transactionFee: 0.0,
    annualFee: 0.0,
    cardReplacementFee: 0.0,
    oneOffFee: 0.0,
    ipaasCode: '',
  },
};

const headers = [
  { label: 'Id', key: 'id' },
  { label: 'Fee Group Name', key: 'name' },
  { label: 'Action', key: 'id' },
];

function ViewFeeGroup(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const {
    feeGroups,
    countryValue,
    feeGroupsLoading,
    feeGroupPage,
    feeGroupsError,
    feeGroupCreated,
    feeGroupCreatedError,
    feeGroupUpdated,
    feeGroupUpdatedError,
    editDealMaster,
    setButtonDisable,
    setEditDealMaster,
    buttonDisable,
    setBtnClick,
    createUpdateStatus,
    setCreateUpdateStatus,
  } = props;

  if (feeGroupsLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleSearch = () => {
    dispatch(
      fetchFeeGroupRequest({
        country: countryValue,
        feeGroupId: searchText,
      })
    );
  };

  const handleChangePage = (_event: any, page: any) => {
    const payload: FetchFeeGroupRequestPayload = {
      country: countryValue,
      pageNumber: page + 1,
      feeGroupId: searchText,
    };
    dispatch(fetchFeeGroupRequest(payload));
  };

  const handleEdit = (event: any) => {
    const feeGrp = feeGroups.filter(
      (feeId: any) => feeId.id == event.currentTarget.id
    );
    feeGroupInitialValues.feeGroup = feeGrp[0];
    resetFeeGroup();
    setButtonDisable({ ...buttonDisable, create: false });
    setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
    setEditDealMaster(true);
  };

  function resetFeeGroup() {
    if (
      Object.keys(feeGroupCreated.response).length > 0 ||
      feeGroupCreated.error ||
      Object.keys(feeGroupUpdated.response).length > 0 ||
      feeGroupUpdated.error
    ) {
      dispatch(resetCreateFeeGroupRequest());
      dispatch(resetUpdateFeeGroupRequest());
    }
  }

  function HandleAlert() {
    if (
      (!feeGroupUpdated.pending && feeGroupUpdated.response.success) ||
      (!feeGroupCreated.pending && feeGroupCreated.response.success)
    ) {
      return (
        <Alert key={'DMS-handleAlert-success'} severity="success">
          {createUpdateStatus.screen === 'update'
            ? ` Fee Group updated Successfully.`
            : ` Fee Group created Successfully.  ${JSON.stringify(
                feeGroupCreated.response.data,
                null,
                4
              )}.`}
        </Alert>
      );
    } else {
      return (
        <Alert key={'DMS-handleAlert-error'} severity="error">
          {`Error creating fee groups - [${
            feeGroupCreatedError ||
            feeGroupCreated.response.message ||
            feeGroupUpdatedError ||
            feeGroupUpdated.response.message
          }]`}
        </Alert>
      );
    }
  }

  return (
    <>
      {editDealMaster ? (
        <>
          <CreateUpdateFeeGroup
            isDealMasterEdit={editDealMaster}
            feeGroupInitialValues={feeGroupInitialValues}
            countryValue={countryValue}
            setEditDealMaster={setEditDealMaster}
            setButtonDisable={setButtonDisable}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            createUpdateStatus={createUpdateStatus}
            setCreateUpdateStatus={setCreateUpdateStatus}
          />
        </>
      ) : (
        <>
          {createUpdateStatus.flag ? (
            <Card className={classes.root} variant="elevation">
              <HandleAlert />
            </Card>
          ) : (
            <></>
          )}
          <div className="search-result">
            <div className="search-result-text">Fee Group Result</div>
            <div>
              <FormControl
                variant="outlined"
                className={classes.inputFormControl}
                error={false}
                size="small"
              >
                <TextField
                  name="searchFeeGroupText"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label="Search Fee Group Id"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  onKeyPress={handleNumberValidation}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleSearch}
                        className={classes.searchIcon}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
          <ResponsiveContainer>
            {!feeGroupsLoading && feeGroups.length > 0 ? (
              <Paper className={classes.root}>
                <DealMasterTable
                  headers={headers}
                  data={feeGroups}
                  page={feeGroupPage}
                  handleEdit={handleEdit}
                  handleChangePage={handleChangePage}
                />
              </Paper>
            ) : (
              <Typography
                style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}
              >
                {feeGroupsError ? (
                  <Alert severity="error">
                    {`Error loading Fee Groups - [${feeGroupsError}]`}
                  </Alert>
                ) : (
                  'No Fee Group found'
                )}
              </Typography>
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    feeGroups: state.feeGroups.feeGroups,
    feeGroupsLoading: state.feeGroups.pending,
    feeGroupsError: state.feeGroups.error,
    feeGroupPage: state.feeGroups.page,
    feeGroupCreated: state.feeGroupCreated,
    feeGroupCreatedError: state.feeGroupCreated.error,
    feeGroupUpdated: state.feeGroupUpdated,
    feeGroupUpdatedError: state.feeGroupUpdated.error,
  };
};
export default connect(mapStateToProps)(ViewFeeGroup);
