import { all, call, put, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '../../config/Interceptors';
import {
  fetchDealsFailure,
  fetchDealsSuccess,
  createDealsFailure,
  createDealsSuccess,
  resetCreateDealsRequest,
  fetchDealDetailsSuccess,
  fetchDealDetailsFailure,
  updateDealsSuccess,
  updateDealsFailure,
  resetUpdateDealsRequest,
  fetchAllDealsFailure,
  fetchAllDealsSuccess,
  createOffersSuccess,
  createOffersFailure,
  updateOffersSuccess,
  updateOffersFailure,
  resetCreateOffersRequest,
  resetUpdateOffersRequest,
} from './actions';
import {
  FETCH_DEALS_REQUEST,
  CREATE_DEALS_REQUEST,
  RESET_CREATE_DEALS_REQUEST,
  FETCH_DEAL_DETAILS_REQUEST,
  UPDATE_DEALS_REQUEST,
  RESET_UPDATE_DEALS_REQUEST,
  FETCH_ALL_DEALS_REQUEST,
  CREATE_OFFERS_REQUEST,
  UPDATE_OFFERS_REQUEST,
  RESET_CREATE_OFFERS_REQUEST,
  RESET_UPDATE_OFFERS_REQUEST
} from './actionTypes';
import { CreateDealsRequest, Deals, FetchDealDetailsRequest, CreateOffersRequest, UpdateOffersRequest } from './types';
import * as Session from '../session';
import { AxiosResponse } from 'axios';

const getDeals = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<Deals[]>('/deals', {
      params: { countryCode: param.country },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const getDealDetails = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>('/offering-packages/getDealDetails', {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const getAllDeals = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<Deals[]>('/deals/all', {
      params: { countryCode: param.country },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchDealsSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getDeals, payload);
    if (response.status === 200) {
      yield put(
        fetchDealsSuccess({
          deals: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchDealsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchDealsFailure({
        error: e.message,
      })
    );
  }
}

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchDealDetailsSaga({ payload: payload }: FetchDealDetailsRequest) {
  try {
    const response: AxiosResponse = yield call(getDealDetails, payload);
    if (response.status === 200) {
      yield put(
        fetchDealDetailsSuccess({
          dealDetails: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchDealDetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchDealDetailsFailure({
        error: e.message,
      })
    );
  }
}

const createDeals = async (param: any) => {
  param.productGroup = param.productGroup.join(',');
  param.creditLimit = parseFloat(param.creditLimit);
  param.weeklyCardLimit = parseFloat(param.weeklyCardLimit);
  param.minWeeklyCardLimit = parseFloat(param.minWeeklyCardLimit);
  param.maxWeeklyCardLimit = parseFloat(param.maxWeeklyCardLimit);
  param.dailyAccountLimit = parseFloat(param.dailyAccountLimit);
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/offering-packages/createDeal', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const updateDeals = async (param: any) => {
  param.productGroup = param.productGroup.join(',');
  param.creditLimit = parseFloat(param.creditLimit);
  param.weeklyCardLimit = parseFloat(param.weeklyCardLimit);
  param.minWeeklyCardLimit = parseFloat(param.minWeeklyCardLimit);
  param.maxWeeklyCardLimit = parseFloat(param.maxWeeklyCardLimit);
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/offering-packages/editDeal', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

/*
  Worker Saga: Fired on CREATE_TODO_REQUEST action
*/
function* createDealsSaga({ payload: payload }: CreateDealsRequest) {
  try {
    const response: AxiosResponse = yield call(createDeals, payload);
    if (response.status === 200) {
      yield put(
        createDealsSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createDealsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createDealsFailure({
        error: e.message,
      })
    );
  }
}

function* updateDealsSaga({ payload: payload }: CreateDealsRequest) {
  try {
    const response: AxiosResponse = yield call(updateDeals, payload);
    if (response.status === 200) {
      yield put(
        updateDealsSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateDealsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateDealsFailure({
        error: e.message,
      })
    );
  }
}

function* fetchAllDealsSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getAllDeals, payload);
    if (response.status === 200) {
      yield put(
        fetchAllDealsSuccess({
          allDeals: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchAllDealsFailure({
          allDealsError: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchAllDealsFailure({
        allDealsError: e.message,
      })
    );
  }
}

const createOffers = async (param: any) => {
  param.productGroup = param.productGroup.join(',');
  param.creditLimit = parseFloat(param.creditLimit);
  param.weeklyCardLimit = parseFloat(param.weeklyCardLimit);
  param.minWeeklyCardLimit = parseFloat(param.minWeeklyCardLimit);
  param.maxWeeklyCardLimit = parseFloat(param.maxWeeklyCardLimit);
  param.dailyAccountLimit = parseFloat(param.dailyAccountLimit);
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/offering-packages/createofferingpackage', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* createOffersSaga({ payload: payload }: CreateOffersRequest) {
  try {
    const response: AxiosResponse = yield call(createOffers, payload);
    if (response.status === 200) {
      yield put(
        createOffersSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        createOffersFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      createOffersFailure({
        error: e.message,
      })
    );
  }
}


const updateOffers = async (param: any) => {
  param.productGroup = param.productGroup.join(',');
  param.creditLimit = parseFloat(param.creditLimit);
  param.weeklyCardLimit = parseFloat(param.weeklyCardLimit);
  param.minWeeklyCardLimit = parseFloat(param.minWeeklyCardLimit);
  param.maxWeeklyCardLimit = parseFloat(param.maxWeeklyCardLimit);
  param.dailyAccountLimit = parseFloat(param.dailyAccountLimit);
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/offering-packages/updateofferingpackage', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateOffersSaga({ payload: payload }: UpdateOffersRequest) {
  try {
    const response: AxiosResponse = yield call(updateOffers, payload);
    if (response.status === 200) {
      yield put(
        updateOffersSuccess({
          response: response.data,
        })
      );
    } else {
      yield put(
        updateOffersFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateOffersFailure({
        error: e.message,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* dealDetailsSaga() {
  yield all([
    takeLatest(FETCH_DEALS_REQUEST, fetchDealsSaga),
    takeLatest(FETCH_ALL_DEALS_REQUEST, fetchAllDealsSaga),
    takeLatest(FETCH_DEAL_DETAILS_REQUEST, fetchDealDetailsSaga),
    takeLatest(CREATE_DEALS_REQUEST, createDealsSaga),
    takeLatest(UPDATE_DEALS_REQUEST, updateDealsSaga),
    takeLatest(RESET_CREATE_DEALS_REQUEST, resetCreateDealsRequest),
    takeLatest(RESET_UPDATE_DEALS_REQUEST, resetUpdateDealsRequest),
    takeLatest(CREATE_OFFERS_REQUEST, createOffersSaga),
    takeLatest(UPDATE_OFFERS_REQUEST, updateOffersSaga),
    takeLatest(RESET_CREATE_OFFERS_REQUEST, resetCreateOffersRequest),
    takeLatest(RESET_UPDATE_OFFERS_REQUEST, resetUpdateOffersRequest),
  ]);
}

export default dealDetailsSaga;
