export const FETCH_CUSTOMER_GROUP_TYPE_REQUEST = 'FETCH_CUSTOMER_GROUP_TYPE_REQUEST';
export const FETCH_CUSTOMER_GROUP_TYPE_SUCCESS = 'FETCH_CUSTOMER_GROUP_TYPE_SUCCESS';
export const FETCH_CUSTOMER_GROUP_TYPE_FAILURE = 'FETCH_CUSTOMER_GROUP_TYPE_FAILURE';

export const FETCH_MAILJET_CONFIGURATION_REQUEST = 'FETCH_MAILJET_CONFIGURATION_REQUEST';
export const FETCH_MAILJET_CONFIGURATION_FAILURE = 'FETCH_MAILJET_CONFIGURATION_FAILURE';
export const FETCH_MAILJET_CONFIGURATION_SUCCESS = 'FETCH_MAILJET_CONFIGURATION_SUCCESS';
export const RESET_FETCH_MAILJET_CONFIGURATION_DETAILS = 'RESET_FETCH_MAILJET_CONFIGURATION_DETAILS';

export const DELETE_MAILJET_CONFIGURATION_REQUEST = 'DELETE_MAILJET_CONFIGURATION_REQUEST';
export const DELETE_MAILJET_CONFIGURATION_FAILURE = 'DELETE_MAILJET_CONFIGURATION_FAILURE';
export const DELETE_MAILJET_CONFIGURATION_SUCCESS = 'DELETE_MAILJET_CONFIGURATION_SUCCESS';
export const RESET_DELETE_MAILJET_CONFIGURATION_REQUEST = 'RESET_DELETE_MAILJET_CONFIGURATION_REQUEST';


export const CREATE_MAILJET_CONFIGURATION_REQUEST = 'CREATE_MAILJET_CONFIGURATION_REQUEST';
export const CREATE_MAILJET_CONFIGURATION_FAILURE = 'CREATE_MAILJET_CONFIGURATION_FAILURE';
export const CREATE_MAILJET_CONFIGURATION_SUCCESS = 'CREATE_MAILJET_CONFIGURATION_SUCCESS';

export const FETCH_APP_CONFIGURATION_REQUEST = 'FETCH_APP_CONFIGURATION_REQUEST';
export const FETCH_APP_CONFIGURATION_SUCCESS = 'FETCH_APP_CONFIGURATION_SUCCESS';
export const FETCH_APP_CONFIGURATION_FAILURE = 'FETCH_APP_CONFIGURATION_FAILURE';

export const FETCH_APP_CONFIGURATION_DETAILS_REQUEST = 'FETCH_APP_CONFIGURATION_DETAILS_REQUEST';
export const FETCH_APP_CONFIGURATION_DETAILS_SUCCESS = 'FETCH_APP_CONFIGURATION_DETAILS_SUCCESS';
export const FETCH_APP_CONFIGURATION_DETAILS_FAILURE = 'FETCH_APP_CONFIGURATION_DETAILS_FAILURE';
export const RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST = 'RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST';

export const CREATE_APP_CONFIGURATION_REQUEST = 'CREATE_APP_CONFIGURATION_REQUEST';
export const CREATE_APP_CONFIGURATION_SUCCESS = 'CREATE_APP_CONFIGURATION_SUCCESS';
export const CREATE_APP_CONFIGURATION_FAILURE = 'CREATE_APP_CONFIGURATION_FAILURE';
export const RESET_CREATE_APP_CONFIGURATION_REQUEST = 'RESET_CREATE_APP_CONFIGURATION_REQUEST';

export const UPDATE_APP_CONFIGURATION_REQUEST = 'UPDATE_APP_CONFIGURATION_REQUEST';
export const UPDATE_APP_CONFIGURATION_SUCCESS = 'UPDATE_APP_CONFIGURATION_SUCCESS';
export const UPDATE_APP_CONFIGURATION_FAILURE = 'UPDATE_APP_CONFIGURATION_FAILURE';
export const RESET_UPDATE_APP_CONFIGURATION_REQUEST = 'RESET_UPDATE_APP_CONFIGURATION_REQUEST';


export const DELETE_APP_CONFIGURATION_REQUEST = 'DELETE_APP_CONFIGURATION_REQUEST';
export const DELETE_APP_CONFIGURATION_FAILURE = 'DELETE_APP_CONFIGURATION_FAILURE';
export const DELETE_APP_CONFIGURATION_SUCCESS = 'DELETE_APP_CONFIGURATION_SUCCESS';
export const RESET_DELETE_APP_CONFIGURATION_REQUEST = 'RESET_DELETE_APP_CONFIGURATION_REQUEST';

export const FETCH_ALL_OFFERINGPACKAGES_REQUEST = 'FETCH_ALL_OFFERINGPACKAGES_REQUEST';
export const FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE = 'FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE';
export const FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS = 'FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS';
export const RESET_ALL_OFFERINGPACKAGES_DETAILS ='RESET_ALL_OFFERINGPACKAGES_DETAILS';

export const FETCH_ALL_MARKETS_REQUEST = 'FETCH_ALL_MARKETS_REQUEST';
export const FETCH_ALL_MARKETS_FAILURE = 'FETCH_ALL_MARKETS_FAILURE';
export const FETCH_ALL_MARKETS_SUCCESS = 'FETCH_ALL_MARKETS_SUCCESS';
