import {
    Button,
    FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
        fontFamily: 'Shell-Book',
      },
      textAlign: 'left',
      margin: '10px 0px',
    },
    formControl: {
      '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
      '& .MuiSelect-select:focus': {
        background: 'transparent',
      },
      margin: theme.spacing(1, 5),
      minWidth: 250,
      width: 325,
    },
    formControlButton: {
      margin: 0,
    },
    menuItems: {
      fontFamily: 'Shell-Book',
    },
    searchButton: {
      margin: theme.spacing(1, 2, 1, 2),
      color: '#000000cf',
      backgroundColor: '#fbcf04',
      height: 40,
      fontFamily: 'Shell-Book',
      '&:hover': { background: '#fbcf04d1' },
    },
    input: {
      margin: '0px',
    },
    customInput: {
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      paddingRight: 39,
      //zIndex: 99999,
    },
    inputFormControl: {
      margin: theme.spacing(1, 5),
      width: 325,
    },
    helperText: {
      marginLeft: 10,
    },
    titleMsg: {
      fontFamily: 'Shell-Medium',
      padding: '10px',
      fontSize: '1rem',
      textAlign: 'left',
    },
    divider: {
      margin: '20px 0px 10px 0px',
    },
    textOR: {
      display: 'inline-flex',
      alignItems: 'center',
      margin: '20px 0px 20px 0px',
      fontFamily: 'Shell-Book',
      border: 0,
      position: 'relative',
      minWidth: 0,
      flexDirection: 'column',
      verticalAlign: 'top',
    },
  }));

export default function ButtonElement(props:any) {
    const classes = useStyles();

  return <div>
<FormControl
variant="outlined"
className={classes.formControlButton}
size="small"
>
<Button
  variant="contained"
  size="large"
  className={classes.searchButton}
  onClick={props.onClick}
  disabled={props.disabled}
>
  {props.buttonText}
</Button>
</FormControl>
</div>
};
