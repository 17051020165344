import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  Box,
  Chip,
  FormControl,
  TextField,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';

import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from './Spinner';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';
import { fetchDealDetailsRequest } from '../store/deals/actions';
import {
  CreateDealsPayload,
  FetchDealDetailsRequestPayload,
} from '../store/deals/types';
import OfferingPackageForm from './OfferingPackageForm';
import ExpandableCard from './ExpandableCard';
import ViewOffers from './ViewOffers';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit'; 

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeader: {
    background: '#fed700d1',
    fontFamily: 'Shell-Book',
    fontWeight: 900,
    border: '1px solid #aaaaaa',
    color: '#484848',
  },
  tableBodyCell: {
    border: '1px solid #dddddd',
    fontFamily: 'Shell-Book',
  },
  detailsTableHeader: {
    background: '#fed70075',
    fontFamily: 'Shell-Medium',
    fontSize: 12,
    border: '1px solid #aaaaaa',
    padding: 5,
  },
  detailsTableBodyCell: {
    border: '1px solid #aaaaaa',
    fontFamily: 'Shell-Book',
    fontSize: 12,
    padding: 5,
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  actionBtn: {
    '&:hover': { background: '#fbcf04d1' },
    cursor: 'pointer',
    background: '#fbcf04',
    padding: 5,
    margin: 2,
    width: 25,
    height: 25,
    borderRadius: 4,
    fontSize: 14,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
  },
  helperText: {
    marginLeft: 10,
  },
  chips: {
    margin: 1,
    background: '#aaaaaa50',
  },
  searchIcon: {
    '&:hover': { background: '#fbcf04d1' },
    '&.MuiIconButton-root.Mui-disabled': { background: '#ddddddcf' },
    background: '#fbcf04',
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
  },
  selected: { '&> td': { fontWeight: 900, background: '#f0f0f0' } },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f0f0f0d1',
    },
  },
}));

const headers = [
  { label: 'Deal Name [Deal ID]', key: 'dealName' },
  { label: 'Deal Description', key: 'aggregator.name' },
  { label: 'Created On', key: 'createdOn' },
  { label: 'Is Market Default', key: 'isMarketDefault' },
  { label: 'Action', key: 'dealId' },
];

const detailHeaders = [
  { label: 'Customer Group Type', key: 'customerGroup.name' },
  { label: 'Fee Group', key: 'offeringPackages.feeGroup.name' },
  { label: 'Profile Group', key: 'offeringPackages.priceProfile.name' },
  { label: 'Purchase Category', key: 'offeringPackages.purchaseCategory.name' },
  { label: 'Token Type', key: 'offeringPackages.tokenType.name' },
  { label: 'Legal Entity', key: 'offeringPackages.legalEntity.name' },
  { label: 'Payment Term', key: 'offeringPackages.paymentTerm.name' },
  { label: 'Billing Frequency', key: 'offeringPackages.billingFrequency.name' },

  { label: 'Account Clerk ID', key: 'offeringPackages.accountClerks.name' },
  { label: 'Account Manager ID', key: 'offeringPackages.accountManagerId' },
  { label: 'Card Type', key: 'offeringPackages.cardType.name' },
  { label: 'Max No of Cards', key: 'offeringPackages.maxNoCards' },
  { label: 'Default Weekly Card Limit', key: 'offeringPackages.weeklyCardLimit' },
  { label: 'Min Weekly Card Limit', key: 'offeringPackages.minWeeklyCardLimit' },
  { label: 'Max Weekly Card Limit', key: 'offeringPackages.maxWeeklyCardLimit' },
  { label: 'Daily Card Limit', key: 'offeringPackages.dailyCardLimit' },
  { label: 'Daily Account Limit', key: 'offeringPackages.dailyAccountLimit' },
  { label: 'Weekly Account Limit', key: 'offeringPackages.weeklyAccountLimit' },
  { label: 'Account Credit Limit', key: 'offeringPackages.creditLimit' },
  { label: 'Co2 Offset', key: 'offeringPackages.co2Offset' },
  { label: 'Product Groups', key: 'offeringPackages.productGroups' },
  { label: 'Marketing Segmentation', key: 'offeringPackages.marketsegmantation' },
  { label: 'Plan Header', key: 'offeringPackages.planHeader' },
  { label: 'Plan Offer Points', key: 'offeringPackages.planOfferPoints' },
  { label: 'Plan FAQ', key: 'offeringPackages.planFAQ' },
];

const dealInitialValues: Partial<CreateDealsPayload> = {
  dealId: '',
  countryCode: '',
  marketId: '',
  customerGroupType: '',
  dealName: '',
  title: '',
  feeGroup: null,
  priceProfile: null,
  productGroup: [],
  purchaseCategory: null,
  tokenType: null,
  legalEntity: null,
  paymentTerm: null,
  billingFrequency: null,
  accountManagerId: '',
  accountClerks: '',
  cardType: null,
  maxNoCards: 0,
  creditLimit: 0,
  weeklyCardLimit: 0,
  minWeeklyCardLimit: 0,
  maxWeeklyCardLimit: 0,
  co2Offset: false,
  isMarketDefault: false,
  dailyAccountLimit: null,

  weeklyAccountLimit: null,
  dailyCardLimit: null,
  packageName: '',
  marketSegmantations: '',
  marketingSegmentationId: '',
  id:''
};

function ViewDeals(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedDeal, setSelectedDeal] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    dealDetails,
    dealDetailsLoading,
    dealDetailsError,
    dealDetailPage,
    countryValue,
    setButtonDisable,
    setEditDeal,
    editDeals,
    addOffers,
    setAddOffers,
    marketSegmantations,
    setEditOffer,
    isEditOffer
  } = props;

  if (!dealDetailsLoading && dealDetails.length <= 0) {
    setButtonDisable({
      create: false,
      view: false,
    });
  }
  if (dealDetailsLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }
  const handleChangePage = (_event: any, page: any) => {
    const payload: FetchDealDetailsRequestPayload = {
      country: countryValue,
      pageNumber: page + 1,
      deal: searchText,
    };
    dispatch(fetchDealDetailsRequest(payload));
  };
  const handleSearch = () => {
    dispatch(
      fetchDealDetailsRequest({
        country: countryValue,
        deal: searchText,
      })
    );
  };
  
  const handleAddOfferingPackage = (event: any) => {
    const deal = dealDetails.find(
      (dId: any) => dId.dealId === event.currentTarget.id
    );
    delete dealInitialValues['id'];
    dealInitialValues.dealId = event.currentTarget.id;
    dealInitialValues.aggregatorId = deal.aggregator?.id;
    dealInitialValues.countryCode = countryValue;
    dealInitialValues.marketId = deal.market.id;
    dealInitialValues.customerGroupType = deal.customerGroup.id;
    dealInitialValues.title = deal.aggregator?.name;
    dealInitialValues.dealName = deal.dealName.substring(
      deal.dealName.indexOf(' ') + 1
    );
     dealInitialValues.feeGroup = null;
    dealInitialValues.priceProfile = null;
    dealInitialValues.productGroup = [];
    dealInitialValues.purchaseCategory = null;
    dealInitialValues.tokenType = null;
    dealInitialValues.legalEntity = null;
    dealInitialValues.paymentTerm = null;
    dealInitialValues.billingFrequency = null;
    dealInitialValues.accountManagerId = '';
    dealInitialValues.accountClerks = '';
    dealInitialValues.cardType = null;
    dealInitialValues.maxNoCards = 0;
    dealInitialValues.creditLimit = 0
    dealInitialValues.weeklyCardLimit = 0;
    dealInitialValues.minWeeklyCardLimit = 0;
    dealInitialValues.maxWeeklyCardLimit = 0;
    dealInitialValues.co2Offset =false;
    dealInitialValues.isMarketDefault = false;
    dealInitialValues.dailyAccountLimit = null;
    dealInitialValues.weeklyAccountLimit = null;
    dealInitialValues.dailyCardLimit = null;
    dealInitialValues.packageName = '';
    dealInitialValues.marketSegmantations = '';
    setTimeout(setAddOffers(true), 1000);
  };

  const handleEdit = (event: any) => {
    const deal = dealDetails.find(
      (dId: any) => dId.dealId === event.currentTarget.id
    );
    setSelectedDeal(event.currentTarget.id);
    dealInitialValues.dealId = event.currentTarget.id;
    dealInitialValues.aggregatorId = deal.aggregator?.id;
    dealInitialValues.countryCode = countryValue;
    dealInitialValues.marketId = deal.market.id;
    dealInitialValues.customerGroupType = deal.customerGroup.id;
    dealInitialValues.title = deal.aggregator?.name;
    dealInitialValues.isMarketDefault = deal.isMarketDefault || false;
    dealInitialValues.dealName = deal.dealName.substring(
      deal.dealName.indexOf(' ') + 1
    );
    setTimeout(setEditDeal(true), 1000);
  };

  if (isEditOffer) {
    const deal = dealDetails.find(
      (dId: any) => dId.dealId == selectedDeal
    );
    const selectedDealDetails = deal?.offeringPackages[selectedIndex];
    dealInitialValues.dealName = deal?.dealName.substring(
      deal.dealName.indexOf(' ') + 1
    );
    dealInitialValues.dealId = selectedDeal;
    dealInitialValues.aggregatorId = deal.aggregator?.id;
    dealInitialValues.countryCode = countryValue;
    dealInitialValues.marketId = deal.market.id;
    dealInitialValues.customerGroupType = deal.customerGroup.id;
    dealInitialValues.title = deal.aggregator?.name;
    dealInitialValues.feeGroup = selectedDealDetails.feeGroup.id;
    dealInitialValues.priceProfile = selectedDealDetails.priceProfile.id;
    dealInitialValues.productGroup =
    selectedDealDetails.productGroups.map((ech: any) => ech.id) || [];
    dealInitialValues.purchaseCategory =
    selectedDealDetails.purchaseCategory.id;
          dealInitialValues.id =
          selectedDealDetails.offeringPackageId;
    dealInitialValues.tokenType = selectedDealDetails.tokenType.id;
    dealInitialValues.legalEntity = selectedDealDetails.legalEntity.id;
    dealInitialValues.paymentTerm = selectedDealDetails.paymentTerm.id;
    dealInitialValues.billingFrequency =
    selectedDealDetails.billingFrequency.id;
    dealInitialValues.accountManagerId = selectedDealDetails.accountManagerId;
    dealInitialValues.accountClerks = selectedDealDetails.accountClerks.id;
    dealInitialValues.cardType = selectedDealDetails.cardType.id;
    dealInitialValues.maxNoCards = selectedDealDetails.maxNoCards;
    dealInitialValues.creditLimit = selectedDealDetails.creditLimit;
    dealInitialValues.weeklyCardLimit = selectedDealDetails.weeklyCardLimit;
    dealInitialValues.minWeeklyCardLimit =
    selectedDealDetails.minWeeklyCardLimit;
    dealInitialValues.maxWeeklyCardLimit =
    selectedDealDetails.maxWeeklyCardLimit;
    dealInitialValues.co2Offset = selectedDealDetails.co2Offset;
    dealInitialValues.dailyAccountLimit = selectedDealDetails.dailyAccountLimit == null ? '' : selectedDealDetails.dailyAccountLimit;
    dealInitialValues.weeklyAccountLimit = selectedDealDetails.weeklyAccountLimit == null ? '' : selectedDealDetails.weeklyAccountLimit;
    dealInitialValues.dailyCardLimit = selectedDealDetails.dailyCardLimit == null ? '' : selectedDealDetails.dailyCardLimit;
    dealInitialValues.packageName = selectedDealDetails.packageName == null ? '' : selectedDealDetails.packageName;
    dealInitialValues.marketSegmantations = selectedDealDetails.marketsegmantation == null ? '' : selectedDealDetails.marketsegmantation;
  }
  
  return (
    <>
      {(editDeals || addOffers || isEditOffer) ? (
        <>
          <OfferingPackageForm
            isDealEdit={editDeals}
            dealInitialValues={dealInitialValues}
            countryValue={countryValue}
            setEditDeal={setEditDeal}
            setButtonDisable={setButtonDisable}
            isAddOffers={addOffers}
            setAddOffers={setAddOffers}
            isEditOffer={isEditOffer}
            setEditOffer={setEditOffer}
          />
        </>
      ) : (
        <>
          <div className="search-result">
            <div className="search-result-text">Deals Result</div>
            <div>
              <FormControl
                variant="outlined"
                className={classes.inputFormControl}
                error={false}
                size="small"
              >
                <TextField
                  name="searchDealText"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label="Search Deals"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleSearch}
                        className={classes.searchIcon}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
                <FormHelperText className={classes.helperText}>
                  Please enter deal name to search specific Deals
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <ResponsiveContainer>
            {!dealDetailsLoading && dealDetails.length > 0 ? (
              <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table aria-label="collapsible table" key="table">
                    <TableHead
                      className={classes.tableHeader}
                      key={'table-head'}
                    >
                      <TableRow key="DM-table-head-row">
                        <TableCell
                          key="DM-No"
                          className={classes.tableHeader}
                          align="center"
                        >
                          No
                        </TableCell>
                        {headers.map((header: any) => {
                          return (
                            <TableCell
                              key={`DM-${header.label}`}
                              className={classes.tableHeader}
                              align="center"
                            >
                              {header.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody key={'DM-table-body-main'}>
                      {dealDetails.map((row: any, i: any) => (
                        <React.Fragment key={`DM-react-fragment-${i + 1}`}>
                          <TableRow
                            key={`DM-table-${i + 1}-row-main`}
                            className={classes.tableRow}
                            classes={{
                              selected: classes.selected,
                            }}
                            selected={open === i}
                            onClick={() => {
                              open === i ? setOpen('') : setOpen(i),
                              setSelectedDeal(row.dealId)
                            }}
                          >
                            <TableCell
                              key={`DM-table-${i + 1}-cell-main`}
                              className={classes.tableBodyCell}
                              align="center"
                            >
                              {dealDetailPage.pageNumber === 1
                                ? i + 1
                                : dealDetailPage.pageNumber *
                                dealDetailPage.pageSize -
                                dealDetailPage.pageSize +
                                i +
                                1}
                            </TableCell>
                            {headers.map((header: any) => (
                              <TableCell
                                key={`DM-table-${i + 1}-${header.label}-cell`}
                                className={classes.tableBodyCell}
                                align="center"
                                style={
                                  header.key === 'customer.maskedEmail'
                                    ? { wordBreak: 'break-all' }
                                    : {}
                                }
                              >
                                {header.key === 'dealName' ? (
                                  <>
                                    <span>{`${_.get(row, header.key)}`}</span>{' '}
                                    <div style={{ fontSize: 12 }}>{`[ ${_.get(
                                      row,
                                      'dealId'
                                    )} ]`}</div>
                                  </>
                                ) : header.key === 'dealId' ? (
                                  <>
                                    <Tooltip title="Edit Deal" arrow>
                                      <IconButton
                                        key={`DM-icon-${header.key}-${i + 1
                                          }-edit`}
                                        size="small"
                                        onClick={handleEdit}
                                        className={classes.actionBtn}
                                        id={_.get(row, header.key)}
                                      >
                                        <EditIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Add Offers" arrow>
                                      <IconButton
                                        key={`DM-icon-${header.key}-${i + 1
                                          }-add-offers`}
                                        size="small"
                                        onClick={handleAddOfferingPackage}
                                        className={classes.actionBtn}
                                        id={_.get(row, header.key)}
                                      >
                                        <AddIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                ) : header.key === 'createdOn' &&
                                  _.get(row, header.key) !== null ? (
                                  new Date(
                                    _.get(row, header.key)
                                  ).toLocaleString()
                                ) : header.key === 'isMarketDefault' ? (
                                  _.get(row, header.key) ? (
                                    'True'
                                  ) : (
                                    'False'
                                  )
                                ) : header.key ===
                                  'offeringPackage.creditLimit' ? (
                                  <>
                                    <span key={`DM-${i + 1}-span`}>
                                      {_.get(row, header.key, '-')}
                                    </span>
                                    <span
                                      style={{ float: 'right' }}
                                      key={`DM-${i + 1}-span-2`}
                                    >
                                      {_.get(row, 'market.currencyId', '-')}
                                    </span>
                                  </>
                                ) : (
                                  _.get(row, header.key, '-') || '-'
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow
                            key={`DM-${i + 1}-collapsble-row`}
                            style={{ border: '1px solid #dddddd' }}
                          >
                            <TableCell
                              key={`DM-${i + 1}-collapsble-cell`}
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                background: '#efefefef',
                              }}
                              colSpan={8}
                            >
                              <Collapse
                                in={open === i}
                                timeout="auto"
                                unmountOnExit
                                style={{ margin: '10px 0px' }}
                                key={`DM-${i + 1}-collapse`}
                              >
                                <Box sx={{ margin: 1 }} key={`DM-${i + 1}-box`}>
                                  <Typography
                                    key={`DM-${i + 1}-typography`}
                                    variant="inherit"
                                    gutterBottom
                                    component="div"
                                    style={{
                                      fontWeight: 'bold',
                                      color: '#484848',
                                      fontFamily: 'Shell-Light',
                                    }}
                                  >
                               Offers details
                                    {/* <span
                                      key={`DM-${i + 1}-span-DealName`}
                                      style={{
                                        color: '#e1251b',
                                        fontFamily: 'Shell-Book',
                                        fontWeight: 600,
                                      }}
                                    >
                                      {row.dealName}
                                    </span> */}
                                  </Typography>
                                  {row?.offeringPackages.map((data: any, index: any) => 
                                  <div onClick={() => setSelectedIndex(index)}>
                                  <ExpandableCard
                                    title={`Offering Package-${data.packageName ? data.packageName : "Default"}`}
                                    classes={classes}
                                    isEditOffer={isEditOffer}
                                    setEditOffer={setEditOffer}
                                    content={
                                        <ViewOffers
                                          i={index}
                                          detailHeaders={detailHeaders}
                                          row={row}
                                          classes={classes}
                                          marketSegmantations={marketSegmantations}
                                        />
                                    }
                                />
                               <div style={{marginTop: "6px"}}></div>
                                </div>
                                  )}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {dealDetailPage && Object.keys(dealDetailPage).length > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[dealDetailPage.pageSize]}
                    component="div"
                    count={
                      dealDetailPage.totalNoOfRecords
                        ? dealDetailPage.totalNoOfRecords
                        : dealDetailPage.totalPages * dealDetailPage.pageSize
                    }
                    rowsPerPage={dealDetailPage.pageSize}
                    page={dealDetailPage.pageNumber - 1}
                    backIconButtonProps={{
                      color: 'secondary',
                    }}
                    nextIconButtonProps={{ color: 'secondary' }}
                    onPageChange={handleChangePage}
                  />
                ) : (
                  <></>
                )}
              </Paper>
            ) : (
              <Typography
                style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}
              >
                {dealDetailsError ? (
                  <Alert severity="error">
                    {`Error loading Deals - [${dealDetailsError}]`}
                  </Alert>
                ) : (
                  'No Deals found'
                )}
              </Typography>
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    dealDetails: state.dealDetails.dealDetails,
    dealDetailsLoading: state.dealDetails.pending,
    dealDetailsError: state.dealDetails.error,
    dealDetailPage: state.dealDetails.page,
    offeringPackage: state.offeringPackage.offeringPackage,
    offeringPackageResp: state.offeringPackage,
    offeringPackageLoading: state.offeringPackage.pending,
    offeringPackageError: state.offeringPackage.error,
    marketSegmantations: state.offeringPackage.offeringPackage.marketSegmantations,
  };
};
export default connect(mapStateToProps)(ViewDeals);
