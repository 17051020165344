import React, { useState } from 'react';
import {
  MenuItem,
  Button,
  Menu,
  Avatar,
  ListItemIcon,
  Tooltip,
  Divider,
  ListItem,
} from '@material-ui/core';
import Logout from '@material-ui/icons/ExitToAppRounded';
import Person from '@material-ui/icons/PersonRounded';
import * as Session from '../store/session';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { fName, lName } = localStorage;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    if (Boolean(anchorEl)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const userName = (avatar?: boolean | false) => {
    return avatar
      ? `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`
      : ` ${fName} ${lName} `;
  };

  return (
    <>
      <Tooltip title="Account settings" arrow>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar
            style={{
              width: 32,
              height: 32,
              background: '#484848',
              fontFamily: 'Shell-Medium',
              fontSize: 16,
              letterSpacing: 2,
              fontWeight: 600,
            }}
          >
            {userName(true)}
          </Avatar>
        </Button>
      </Tooltip>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        style={{ transform: 'translate(10px, 40px)' }}
        transitionDuration={500}
      >
        <ListItem>
          <ListItemIcon style={{ alignItems: 'center' }}>
            <Person />{' '}
            <span style={{ color: '#000000cf', textTransform: 'capitalize' }}>
              {userName()}
            </span>
          </ListItemIcon>
        </ListItem>
        <Divider />
        <MenuItem
          style={{ fontFamily: 'Shell-Book' }}
          onClick={() => {
            Session.clearSession();
            window.location.href = '/admin/logout';
          }}
        >
          <ListItemIcon
            style={{ minWidth: 25, fontFamily: 'Shell-Book', color: '#e1251b' }}
          >
            <Logout fontSize="small" />
          </ListItemIcon>
          <span style={{ color: '#e1251b' }}>Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
};
export default AccountMenu;
