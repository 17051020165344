import {
  FETCH_OFFERINGPACKAGE_FAILURE,
  FETCH_OFFERINGPACKAGE_REQUEST,
  FETCH_OFFERINGPACKAGE_SUCCESS,
  CREATE_FEEGROUP_FAILURE,
  CREATE_FEEGROUP_REQUEST,
  CREATE_FEEGROUP_SUCCESS,
  FETCH_FEE_GROUP_REQUEST,
  FETCH_FEE_GROUP_SUCCESS,
  FETCH_FEE_GROUP_FAILURE,
  RESET_FETCH_FEE_GROUP,
  CREATE_PRICEPROFILE_FAILURE,
  CREATE_PRICEPROFILE_REQUEST,
  CREATE_PRICEPROFILE_SUCCESS,
  FETCH_PRICEPROFILE_REQUEST,
  FETCH_PRICEPROFILE_SUCCESS,
  FETCH_PRICEPROFILE_FAILURE,
  RESET_FETCH_PRICEPROFILE,
  RESET_CREATE_FEEGROUP,
  RESET_CREATE_PRICEPROFILE,
  RESET_CREATE_CARDTYPE,
  RESET_CREATE_TOKENTYPE,
  CREATE_CARDTYPE_FAILURE,
  CREATE_CARDTYPE_REQUEST,
  CREATE_CARDTYPE_SUCCESS,
  CREATE_TOKENTYPE_FAILURE,
  CREATE_TOKENTYPE_REQUEST,
  CREATE_TOKENTYPE_SUCCESS,
  UPDATE_FEEGROUP_REQUEST,
  UPDATE_FEEGROUP_SUCCESS,
  UPDATE_FEEGROUP_FAILURE,
  RESET_UPDATE_FEEGROUP,
  UPDATE_PRICEPROFILE_REQUEST,
  UPDATE_PRICEPROFILE_SUCCESS,
  UPDATE_PRICEPROFILE_FAILURE,
  RESET_UPDATE_PRICEPROFILE,
  FETCH_TOKENTYPE_REQUEST,
  FETCH_TOKENTYPE_SUCCESS,
  FETCH_TOKENTYPE_FAILURE,
  RESET_FETCH_TOKENTYPE,
  UPDATE_TOKENTYPE_REQUEST,
  UPDATE_TOKENTYPE_SUCCESS,
  UPDATE_TOKENTYPE_FAILURE,
  RESET_UPDATE_TOKENTYPE,
  FETCH_CARDTYPE_REQUEST,
  FETCH_CARDTYPE_SUCCESS,
  FETCH_CARDTYPE_FAILURE,
  RESET_FETCH_CARDTYPE,
  UPDATE_CARDTYPE_REQUEST,
  UPDATE_CARDTYPE_SUCCESS,
  UPDATE_CARDTYPE_FAILURE,
  RESET_UPDATE_CARDTYPE,
  CREATE_LEGALENTITY_REQUEST,
  CREATE_LEGALENTITY_SUCCESS,
  CREATE_LEGALENTITY_FAILURE,
  RESET_CREATE_LEGALENTITY,
  UPDATE_LEGALENTITY_REQUEST,
  UPDATE_LEGALENTITY_SUCCESS,
  UPDATE_LEGALENTITY_FAILURE,
  RESET_UPDATE_LEGALENTITY,
  FETCH_LEGALENTITY_REQUEST,
  FETCH_LEGALENTITY_SUCCESS,
  FETCH_LEGALENTITY_FAILURE,
  RESET_FETCH_LEGALENTITY
} from './actionTypes';
import {
  FetchOfferingPackageFailure,
  FetchOfferingPackageFailurePayload,
  FetchOfferingPackageRequest,
  FetchOfferingPackageSuccess,
  FetchOfferingPackageSuccessPayload,
  CreateFeeGroupRequestPayload,
  CreateFeeGroupRequest,
  CreateFeeGroupSuccess,
  CreateFeeGroupFailure,
  CreateSuccessPayload,
  CreatePriceProfileFailure,
  CreatePriceProfileRequest,
  CreatePriceProfileRequestPayload,
  CreatePriceProfileSuccess,
  CreateCardTypeTokenTypeRequestPayload,
  CreateCardTypeFailure,
  CreateCardTypeRequest,
  CreateCardTypeSuccess,
  CreateTokenTypeFailure,
  CreateTokenTypeRequest,
  CreateTokenTypeSuccess,
  FetchFeeGroupRequest,
  FetchFeeGroupSuccess,
  FetchFeeGroupFailure,
  FetchFeeGroupRequestPayload,
  FetchFeeGroupSuccessPayload,
  FetchFeeGroupFailurePayload,
  FetchPriceProfileRequest,
  FetchPriceProfileSuccess,
  FetchPriceProfileFailure,
  FetchPriceProfileRequestPayload,
  FetchPriceProfileSuccessPayload,
  FetchPriceProfileFailurePayload,
  UpdateFeeGroupRequest,
  UpdateFeeGroupSuccess,
  UpdateFeeGroupFailure,
  FetchTokenTypeRequestPayload,
  FetchTokenTypeSuccessPayload,
  UpdatePriceProfileRequest,
  UpdatePriceProfileSuccess,
  UpdatePriceProfileFailure,
  UpdateTokenTypeRequest,
  UpdateTokenTypeSuccess,
  UpdateTokenTypeFailure,
  FetchTokenTypeRequest,
  FetchTokenTypeSuccess,
  FetchTokenTypeFailure,
  FetchCardTypeRequestPayload,
  FetchCardTypeSuccessPayload,
  UpdateCardTypeRequest,
  UpdateCardTypeSuccess,
  UpdateCardTypeFailure,
  FetchCardTypeRequest,
  FetchCardTypeSuccess,
  FetchCardTypeFailure,
  CreateLegalEntityRequestPayload,
  FetchLegalEntitySuccessPayload,
  FetchLegalEntityRequestPayload,
  CreateLegalEntityRequest,
  CreateLegalEntitySuccess,
  CreateLegalEntityFailure,
  UpdateLegalEntityRequest,
  UpdateLegalEntitySuccess,
  UpdateLegalEntityFailure,
  FetchLegalEntityRequest,
  FetchLegalEntitySuccess,
  FetchLegalEntityFailure

} from './types';

export const fetchOfferingPackageRequest = (
  country: string
): FetchOfferingPackageRequest => ({
  type: FETCH_OFFERINGPACKAGE_REQUEST,
  country,
});

export const fetchOfferingPackageSuccess = (
  payload: FetchOfferingPackageSuccessPayload
): FetchOfferingPackageSuccess => ({
  type: FETCH_OFFERINGPACKAGE_SUCCESS,
  payload,
});

export const fetchOfferingPackageFailure = (
  payload: FetchOfferingPackageFailurePayload
): FetchOfferingPackageFailure => ({
  type: FETCH_OFFERINGPACKAGE_FAILURE,
  payload,
});

export const createFeeGroupRequest = (
  payload: CreateFeeGroupRequestPayload
): CreateFeeGroupRequest => ({
  type: CREATE_FEEGROUP_REQUEST,
  payload,
});

export const createFeeGroupSuccess = (
  payload: CreateSuccessPayload
): CreateFeeGroupSuccess => ({
  type: CREATE_FEEGROUP_SUCCESS,
  payload,
});

export const createFeeGroupFailure = (
  payload: FetchOfferingPackageFailurePayload
): CreateFeeGroupFailure => ({
  type: CREATE_FEEGROUP_FAILURE,
  payload,
});

export const createPriceProfileRequest = (
  payload: CreatePriceProfileRequestPayload
): CreatePriceProfileRequest => ({
  type: CREATE_PRICEPROFILE_REQUEST,
  payload,
});

export const createPriceProfileSuccess = (
  payload: CreateSuccessPayload
): CreatePriceProfileSuccess => ({
  type: CREATE_PRICEPROFILE_SUCCESS,
  payload,
});

export const createPriceProfileFailure = (
  payload: FetchOfferingPackageFailurePayload
): CreatePriceProfileFailure => ({
  type: CREATE_PRICEPROFILE_FAILURE,
  payload,
});

export const createCardTypeRequest = (
  payload: CreateCardTypeTokenTypeRequestPayload
): CreateCardTypeRequest => ({
  type: CREATE_CARDTYPE_REQUEST,
  payload,
});

export const createCardTypeSuccess = (
  payload: CreateSuccessPayload
): CreateCardTypeSuccess => ({
  type: CREATE_CARDTYPE_SUCCESS,
  payload,
});

export const createCardTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): CreateCardTypeFailure => ({
  type: CREATE_CARDTYPE_FAILURE,
  payload,
});

export const createTokenTypeRequest = (
  payload: CreateCardTypeTokenTypeRequestPayload
): CreateTokenTypeRequest => ({
  type: CREATE_TOKENTYPE_REQUEST,
  payload,
});

export const createTokenTypeSuccess = (
  payload: CreateSuccessPayload
): CreateTokenTypeSuccess => ({
  type: CREATE_TOKENTYPE_SUCCESS,
  payload,
});

export const createTokenTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): CreateTokenTypeFailure => ({
  type: CREATE_TOKENTYPE_FAILURE,
  payload,
});

export const resetCreateFeeGroupRequest = () => ({
  type: RESET_CREATE_FEEGROUP,
});

export const resetCreatePriceProfileRequest = () => ({
  type: RESET_CREATE_PRICEPROFILE,
});

export const resetCreateCardTypeRequest = () => ({
  type: RESET_CREATE_CARDTYPE,
});

export const resetCreateTokenTypeRequest = () => ({
  type: RESET_CREATE_TOKENTYPE,
});

export const fetchFeeGroupRequest = (
  payload: FetchFeeGroupRequestPayload
): FetchFeeGroupRequest => ({
  type: FETCH_FEE_GROUP_REQUEST,
  payload,
});

export const fetchFeeGroupSuccess = (
  payload: FetchFeeGroupSuccessPayload
): FetchFeeGroupSuccess => ({
  type: FETCH_FEE_GROUP_SUCCESS,
  payload,
});

export const fetchFeeGroupFailure = (
  payload: FetchFeeGroupFailurePayload
): FetchFeeGroupFailure => ({
  type: FETCH_FEE_GROUP_FAILURE,
  payload,
});

export const resetFetchFeeGroup = () => ({
  type: RESET_FETCH_FEE_GROUP,
});

export const fetchPriceProfileRequest = (
  payload: FetchPriceProfileRequestPayload
): FetchPriceProfileRequest => ({
  type: FETCH_PRICEPROFILE_REQUEST,
  payload,
});

export const fetchPriceProfileSuccess = (
  payload: FetchPriceProfileSuccessPayload
): FetchPriceProfileSuccess => ({
  type: FETCH_PRICEPROFILE_SUCCESS,
  payload,
});

export const fetchPriceProfileFailure = (
  payload: FetchPriceProfileFailurePayload
): FetchPriceProfileFailure => ({
  type: FETCH_PRICEPROFILE_FAILURE,
  payload,
});

export const resetFetchPriceProfile = () => ({
  type: RESET_FETCH_PRICEPROFILE,
});

export const updateFeeGroupRequest = (
  payload: CreateFeeGroupRequestPayload
): UpdateFeeGroupRequest => ({
  type: UPDATE_FEEGROUP_REQUEST,
  payload,
});

export const updateFeeGroupSuccess = (
  payload: CreateSuccessPayload
): UpdateFeeGroupSuccess => ({
  type: UPDATE_FEEGROUP_SUCCESS,
  payload,
});

export const updateFeeGroupFailure = (
  payload: FetchOfferingPackageFailurePayload
): UpdateFeeGroupFailure => ({
  type: UPDATE_FEEGROUP_FAILURE,
  payload,
});

export const resetUpdateFeeGroupRequest = () => ({
  type: RESET_UPDATE_FEEGROUP,
});



export const updatePriceProfileRequest = (
  payload: CreatePriceProfileRequestPayload
): UpdatePriceProfileRequest => ({
  type: UPDATE_PRICEPROFILE_REQUEST,
  payload,
});

export const updatePriceProfileSuccess = (
  payload: CreateSuccessPayload
): UpdatePriceProfileSuccess => ({
  type: UPDATE_PRICEPROFILE_SUCCESS,
  payload,
});

export const updatePriceProfileFailure = (
  payload: FetchOfferingPackageFailurePayload
): UpdatePriceProfileFailure => ({
  type: UPDATE_PRICEPROFILE_FAILURE,
  payload,
});

export const resetUpdatePriceProfileRequest = () => ({
  type: RESET_UPDATE_PRICEPROFILE,
});

export const updateTokenTypeRequest = (
  payload: CreateCardTypeTokenTypeRequestPayload
): UpdateTokenTypeRequest => ({
  type: UPDATE_TOKENTYPE_REQUEST,
  payload,
});

export const updateTokenTypeSuccess = (
  payload: CreateSuccessPayload
): UpdateTokenTypeSuccess => ({
  type: UPDATE_TOKENTYPE_SUCCESS,
  payload,
});

export const updateTokenTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): UpdateTokenTypeFailure => ({
  type: UPDATE_TOKENTYPE_FAILURE,
  payload,
});

export const resetUpdateTokenTypeRequest = () => ({
  type: RESET_UPDATE_TOKENTYPE,
});

export const fetchTokenTypeRequest = (
  payload: FetchTokenTypeRequestPayload
): FetchTokenTypeRequest => ({
  type: FETCH_TOKENTYPE_REQUEST,
  payload,
});

export const fetchTokenTypeSuccess = (
  payload: FetchTokenTypeSuccessPayload
): FetchTokenTypeSuccess => ({
  type: FETCH_TOKENTYPE_SUCCESS,
  payload,
});

export const fetchTokenTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): FetchTokenTypeFailure => ({
  type: FETCH_TOKENTYPE_FAILURE,
  payload,
});

export const resetFetchTokenType = () => ({
  type: RESET_FETCH_TOKENTYPE,
});


export const updateCardTypeRequest = (
  payload: CreateCardTypeTokenTypeRequestPayload
): UpdateCardTypeRequest => ({
  type: UPDATE_CARDTYPE_REQUEST,
  payload,
});

export const updateCardTypeSuccess = (
  payload: CreateSuccessPayload
): UpdateCardTypeSuccess => ({
  type: UPDATE_CARDTYPE_SUCCESS,
  payload,
});

export const updateCardTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): UpdateCardTypeFailure => ({
  type: UPDATE_CARDTYPE_FAILURE,
  payload,
});

export const resetUpdateCardTypeRequest = () => ({
  type: RESET_UPDATE_CARDTYPE,
});

export const fetchCardTypeRequest = (
  payload: FetchCardTypeRequestPayload
): FetchCardTypeRequest => ({
  type: FETCH_CARDTYPE_REQUEST,
  payload,
});

export const fetchCardTypeSuccess = (
  payload: FetchCardTypeSuccessPayload
): FetchCardTypeSuccess => ({
  type: FETCH_CARDTYPE_SUCCESS,
  payload,
});

export const fetchCardTypeFailure = (
  payload: FetchOfferingPackageFailurePayload
): FetchCardTypeFailure => ({
  type: FETCH_CARDTYPE_FAILURE,
  payload,
});

export const resetFetchCardType = () => ({
  type: RESET_FETCH_CARDTYPE,
});

export const createLegalEntityRequest = (
  payload: CreateLegalEntityRequestPayload
): CreateLegalEntityRequest => ({
  type: CREATE_LEGALENTITY_REQUEST,
  payload,
});

export const createLegalEntitySuccess = (
  payload: CreateSuccessPayload
): CreateLegalEntitySuccess => ({
  type: CREATE_LEGALENTITY_SUCCESS,
  payload,
});

export const createLegalEntityFailure = (
  payload: FetchOfferingPackageFailurePayload
): CreateLegalEntityFailure => ({
  type: CREATE_LEGALENTITY_FAILURE,
  payload,
});

export const resetCreateLegalEntityRequest = () => ({
  type: RESET_CREATE_LEGALENTITY,
});

export const updateLegalEntityRequest = (
  payload: CreateLegalEntityRequestPayload
): UpdateLegalEntityRequest => ({
  type: UPDATE_LEGALENTITY_REQUEST,
  payload,
});

export const updateLegalEntitySuccess = (
  payload: CreateSuccessPayload
): UpdateLegalEntitySuccess => ({
  type: UPDATE_LEGALENTITY_SUCCESS,
  payload,
});

export const updateLegalEntityFailure = (
  payload: FetchOfferingPackageFailurePayload
): UpdateLegalEntityFailure => ({
  type: UPDATE_LEGALENTITY_FAILURE,
  payload,
});

export const resetUpdateLegalEntityRequest = () => ({
  type: RESET_UPDATE_LEGALENTITY,
});

export const fetchLegalEntityRequest = (
  payload: FetchLegalEntityRequestPayload
): FetchLegalEntityRequest => ({
  type: FETCH_LEGALENTITY_REQUEST,
  payload,
});

export const fetchLegalEntitySuccess = (
  payload: FetchLegalEntitySuccessPayload
): FetchLegalEntitySuccess => ({
  type: FETCH_LEGALENTITY_SUCCESS,
  payload,
});

export const fetchLegalEntityFailure = (
  payload: FetchOfferingPackageFailurePayload
): FetchLegalEntityFailure => ({
  type: FETCH_LEGALENTITY_FAILURE,
  payload,
});

export const resetFetchLegalEntity = () => ({
  type: RESET_FETCH_LEGALENTITY,
});



