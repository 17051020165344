export const configLevelDropdownList = [
    {
        value: '0',
        label: 'Global',
      },
      {
        value: '1',
        label: 'Market',
      },
      {
        value: '3',
        label: 'Market and customer group Type Level',
      },
      {
        value: '2',
        label: 'Customer group type',
      },
      {
        value: '4',
        label: 'Deal',
      },
      {
        value: '5',
        label: 'Offering package',
      }];
    
      interface AppConfigurationColumn {
        id: 'no' |'configurationLevel' | 'marketName' | 'customerGroupTypeName' | 'dealname' | 'offeringPackageName' | 'value' | 'action';
        label: string;
        minWidth?: number;
        align?: string;
      }
    
      export const appConfigurationColumns: readonly AppConfigurationColumn[] = [
        { id: 'no', label: 'No', minWidth: 20, align: "center" },
        { id: 'configurationLevel', label: 'Configuration Level', minWidth: 20, align: "center" },
        { id: 'marketName', label: 'Market', minWidth: 40, align: "left" },
        { id: 'customerGroupTypeName', label: 'Customer Group Type', minWidth: 20, align: "left" },
        { id: 'dealname', label: 'Deal', minWidth: 10, align: "center" },
        { id: 'offeringPackageName', label: 'Offering Package Name', minWidth: 20, align: "left" },
        { id: 'value', label: 'Value', minWidth: 10, align: "center" },
        { id: 'action', label: 'Action', minWidth: 20, align: "center" },
      ];