import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchCountriesRequest } from '../../store/countries/actions';
import CountryAggregator from './CountryAggregator';
import { useLocation } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '80vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
  createTokenBtn: {
    '& button': {
      color: '#000000cf',
      backgroundColor: '#fbcf04',
      fontFamily: 'Shell-Book',
      '&:hover': { background: '#fbcf04d1' },
    },
    textAlign: 'right',
  },
}));

function DealMasterManagement(props: any) {
  const { countries, countriesLoading, countriesError } = props;
  const classes = useStyles();
  const { pathname } = useLocation();

  let splitPath = pathname.split('/');
  const dispatch = useDispatch();

  if (!countriesError && !countriesLoading && countries.length === 0) {
    dispatch(fetchCountriesRequest());
  }
  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            className={clsx(classes.paper, classes.fixedHeight)}
          >
            <CountryAggregator name={splitPath[splitPath.length-1]}/>
          </Paper>
        </Grid>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
  };
};

export default connect(mapStateToProps)(DealMasterManagement);
