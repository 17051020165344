import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import {
  fetchCustomerProfileRequest,
  resetFetchCustomerOnboarding,
  resetFetchCustomerIpaas,
  resetFetchCustomerGfnaccount,
  resetFetchCustomerPayCardReg,
  resetFetchCustomerAddress,
  resetFetchCustomerInvoiceDetails,
  resetFetchCustomerCards,
  resetFetchCustomerBusiness,
  resetUpdateCustomerVerify,
  resetDeleteCustomer,
  resetBlockCustomer,
  resetFetchCustomerOnfido,
  resetaccountCloser
} from '../../store/user/actions';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchCustomerDetailsRequest
} from '../../store/user/actions';
import { FetchCustomerDetails } from '../../store/user/types';
import Spinner from '../Spinner';
import { ResponsiveContainer } from 'recharts';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeader: {
    background: '#fed700d1',
    fontFamily: 'Shell-Medium',
    border: '1px solid #aaaaaa',
  },
  tableBodyCell: {
    border: '1px solid #dddddd',
    fontFamily: 'Shell-Book',
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  actionBtn: {
    '&:hover': { background: '#fbcf04d1' },
    cursor: 'pointer',
    background: '#fbcf04',
    padding: 5,
    margin: 2,
    width: 25,
    height: 25,
    borderRadius: 4,
    fontSize: 14,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
}));

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Deal Name', key: 'dealName' },
  { label: 'Status', key: 'status' },
  { label: 'Sub Status', key: 'substatus' },
  { label: 'Created On', key: 'created' },
];

function CustomerTable(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    loading,
    page,
    data,
    error
  }: {
    loading: boolean;
    page: any;
    data: FetchCustomerDetails[];
    error: string | null;
  } = props;

  if (loading || props.isLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleChangePage = (_event: any, pageNo: any) => {
    props.searchPayload.pageNumber = pageNo + 1;
    props.searchPayload.pageSize = 10;
    dispatch(fetchCustomerDetailsRequest(props.searchPayload));
  };

  const handleButtonClick = (data: any) => {
    const query: any = {
      searchquery: data,
    };
    props.displayCustomerDetails(true);
    dispatch(fetchCustomerProfileRequest(query));
    dispatch(resetFetchCustomerOnboarding());
    dispatch(resetFetchCustomerIpaas());
    dispatch(resetFetchCustomerGfnaccount());
    dispatch(resetFetchCustomerPayCardReg());
    dispatch(resetFetchCustomerAddress());
    dispatch(resetFetchCustomerInvoiceDetails());
    dispatch(resetFetchCustomerCards());
    dispatch(resetFetchCustomerBusiness());
    dispatch(resetUpdateCustomerVerify());
    dispatch(resetDeleteCustomer());
    dispatch(resetBlockCustomer());
    dispatch(resetFetchCustomerOnfido());
    dispatch(resetaccountCloser());
  };
  return (
    <>
      <div className="search-result">
        {!loading && data.length > 0 && (
          <div className="search-result-text">Search Result</div>
        )}
      </div>
      <ResponsiveContainer>
        {!loading && data.length > 0 ? (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table" key="table">
                <TableHead className={classes.tableHeader} key={'table-head'}>
                  <TableRow key="table-head-row">
                    <TableCell
                      key="No"
                      className={classes.tableHeader}
                      align="center"
                    >
                      No
                    </TableCell>
                    {headers.map((header: any) => {
                      return (
                        <TableCell
                          key={header.label}
                          className={classes.tableHeader}
                          align="center"
                        >
                          {header.label}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      key="Action"
                      className={classes.tableHeader}
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((val: any, i: any) => {
                    return (
                      <>
                        <TableRow key={`table-${i + 1}-row`}>
                          <TableCell
                            key={`table-${i + 1}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            key={`table-${i + 1}-${val.name}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {val.name}
                          </TableCell>
                          <TableCell
                            key={`table-${i + 1}-${val.dealName}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {val.dealName}
                          </TableCell>
                          <TableCell
                              key={`table-${i + 1}-${val.status}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {val.status}
                          </TableCell>
                          <TableCell
                              key={`table-${i + 1}-${val.subStatus}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {val.subStatus}
                          </TableCell>
                          <TableCell
                            key={`table-${i + 1}-${val.createdAt}-cell`}
                            className={classes.tableBodyCell}
                            align="center"
                          >
                            {val.created}
                          </TableCell>
                          <TableCell
                             key={`table-${i + 1}-${val.customerId}-cell`}
                            className={classes.tableBodyCell}
                            align="center" >
                            <IconButton
                              key={`DM-icon-${val.customerId
                                }-edit`}
                              size="small"
                              onClick={() => handleButtonClick(val.customerId)}
                              className={classes.actionBtn}
                            ><ViewHeadlineIcon
                                style={{ fontSize: 20 }}
                              /></IconButton>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[page.pageSize]}
              component="div"
              count={
                page.totalNoOfRecords
                  ? page.totalNoOfRecords
                  : page.totalPages * page.pageSize
              }
              rowsPerPage={page.pageSize}
              page={page.pageNumber - 1}
              backIconButtonProps={{
                color: 'secondary',
              }}
              nextIconButtonProps={{ color: 'secondary' }}
              onPageChange={handleChangePage}
            />
          </Paper>
        ) : (
          <Typography style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}>
            {error ? (
              <Alert severity="error">
                {`Error loading Customer - [${error}]`}
              </Alert>
            ) : (
              'No Customer found'
            )}
          </Typography>
        )}
      </ResponsiveContainer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerDetails: state.customerDetails,
    page: state.customerDetails.page,
    loading: state.customerDetails.pending,
    isLoading: state.customerProfile.pending,
  };
};
export default connect(mapStateToProps)(CustomerTable);
