import * as Yup from 'yup';
export const editdealManagementValidation = Yup.object().shape({
  customerGroupType: Yup.string().required('Please select Customer Group Type'),
  dealName: Yup.string()
    .required('Please enter Deal Name')
    .matches(
      /^[a-z\d\-_\s]+$/i,
      'Deal name should have alphabets and numbers only'
    )
    .test('len', 'Deal Name cannot be more than 50 characters', (val) => {
      if (!val) {
        return true;
      }
      return val.length <= 50;
    }),
  title: Yup.string().test(
    'len',
    'Deal Name cannot be more than 150 characters',
    (val) => {
      if (!val) {
        return true;
      }
      return val.length <= 150;
    }
  ),
});
export const dealManagementValidation = Yup.object().shape({
  customerGroupType: Yup.string().required('Please select Customer Group Type'),
  dealName: Yup.string()
    .required('Please enter Deal Name')
    .matches(
      /^[a-z\d\-_\s]+$/i,
      'Deal name should have alphabets and numbers only'
    )
    .test('len', 'Deal Name cannot be more than 50 characters', (val) => {
      if (!val) {
        return true;
      }
      return val.length <= 50;
    }),
  title: Yup.string().test(
    'len',
    'Deal Name cannot be more than 150 characters',
    (val) => {
      if (!val) {
        return true;
      }
      return val.length <= 150;
    }
  ),

  feeGroup: Yup.number().required('Please select Fee Group').nullable(),
  priceProfile: Yup.number().required('Please select Price Profile').nullable(),
  productGroup: Yup.array()
    .min(1, 'Please select atleast one option')
    .required('Please select Product Group'),
  purchaseCategory: Yup.number()
    .required('Please select Purchase Category')
    .nullable(),
  tokenType: Yup.number().required('Please select Token Type').nullable(),
  legalEntity: Yup.number().required('Please select Legal Entity').nullable(),
  paymentTerm: Yup.number().required('Please select Payment Term').nullable(),
  billingFrequency: Yup.number()
    .required('Please select Billing Frequency')
    .nullable(),
  accountManagerId: Yup.string()
    .trim()
    .required('Please enter Account Manager ID')
    .matches(/^[0-9]+/, 'Account Manager ID should be only numeric values'),
  accountClerks: Yup.string().required('Please select Account Clerk ID'),
  cardType: Yup.number()
    .required('Please select Customer Group Type')
    .nullable(),
  maxNoCards: Yup.number()
    .required('Please enter Max number of cards between 1 and 30')
    .min(1, ' Please enter Max number of cards between 1 and 30')
    .max(30, 'Max number of cards cannot be more than 30')
    .positive('Max number of cards cannot be empty, zero or negative')
    .test(
      'customerGroupType',
      'Max number of cards cannot be more than 1, when Customer Group Type is Aggregator',
      (value: any, ctx: any) => {
        if (
          ctx.parent.customerGroupType ===
            '62bb062d-792f-4dae-8d58-4fce255d17cd' &&
          value > 1
        ) {
          return false;
        }
        return true;
      }
    ),
  creditLimit: Yup.number()
    .required('Please enter Account Credit Limit')
    .min(1, 'Account Credit limit cannot be empty or zero')
    .positive('Account credit limit cannot be empty, zero or negative')
    .test(
      'accountCreditLimit',
      'Default Weekly Card Limit cannot be more than Account Credit Limit',
      function (value: any) {
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Account Credit Limit should have 2 digits after decimal or less',
          });
        }
        return true;
      }
    ),
    weeklyAccountLimit: Yup.number()
    .nullable(true)
    .min(1, 'Weekly Account Limit cannot be zero')
    .positive('Default Weekly Account Limit cannot be zero or negative')
    .test(
      'weeklyAccountLimit', function (value: any, ctx:any) {
        if(value>=0 && value !==null){
          if (value < ctx.parent.dailyAccountLimit) {
            return this.createError({
              message:
                'Weekly Account Limit cannot be less than Daily Account Limit',
            });
          }
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Default Weekly Card Limit should have 2 digits after decimal or less',
          });
        }
      }
        return true;
    }
    ),
    dailyCardLimit: Yup.number()
    .nullable(true)
    .min(1, 'Daily Card Limit cannot be zero')
    .positive('Default Daily Card Limit cannot be zero or negative')
    .test(
      'dailyCardLimit',
      'Default Daily Card Limit cannot be more than Weekly Account Limit',
      function (value: any, ctx: any) {
        if (value > ctx.parent.weeklyAccountLimit) {
          return false;
        }
        if(value>=0 && value !==null){
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Default Daily Card Limit should have 2 digits after decimal or less',
          });
        }
      }
        return true;
      }
    ),
  weeklyCardLimit: Yup.number()
    .required('Please enter Default Weekly Card Limit')
    .min(1, 'Weekly Credit limit cannot be zero')
    .positive('Default Weekly Card limit cannot be empty, zero or negative')
    .test(
      'weeklyCardLimit',
      'Default Weekly Card Limit cannot be more than Account Credit Limit',
      function (value: any, ctx: any) {
        if (value > ctx.parent.creditLimit) {
          return false;
        }
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Default Weekly Card Limit should have 2 digits after decimal or less',
          });
        }
        return true;
      }
    ),
  minWeeklyCardLimit: Yup.number()
    .required('Please enter Min Weekly Card Limit')
    .min(1, 'Min Weekly Card limit cannot be 0')
    .positive('Min Weekly Card limit cannot be empty, zero or negative')
    .test(
      'minWeeklyCardLimit',
      'Min Weekly Card Limit cannot be more than Account Credit Limit',
      function (value: any, ctx: any) {
        if (value > ctx.parent.creditLimit) {
          return false;
        }
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Min Weekly Card Limit should have 2 digits after decimal or less',
          });
        }
        return true;
      }
    ),
  maxWeeklyCardLimit: Yup.number()
    .required('Please enter Max Weekly Card Limit')
    .min(1, 'Max Weekly Card limit cannot be 0')
    .positive('Max Weekly Card limit cannot be empty, zero or negative')
    .test(
      'maxWeeklyCardLimit',
      'Max Weekly Card Limit cannot be less than Min Weekly Card Limit',
      function (value: any, ctx: any) {
        if (value > ctx.parent.creditLimit) {
          return this.createError({
            message:
              'Max Weekly Card Limit cannot be more than Account Credit Limit',
          });
        }
        if (value < ctx.parent.minWeeklyCardLimit) {
          return false;
        }
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          return this.createError({
            message:
              'Max Weekly Card Limit should have 2 digits after decimal or less',
          });
        }
        return true;
      }
    ),
  dailyAccountLimit: Yup.number()
  .nullable(true)
  .min(1, 'Daily Account Limit cannot be zero')
  .positive('Default Daily Account Limit cannot be zero or negative')
    .test('dailyAccountLimit', function (value: any, ctx: any) {
      if(value>=0 && value !==null){
        if (value > ctx.parent.weeklyAccountLimit) {
          return this.createError({
            message:
              'Daily Account Limit cannot be more than Weekly Account Limit',
          });
        }
      if (!/^\d+(\.\d{0,2})?$/.test(value)) {
        return this.createError({
          message:
            'Daily Account Limit should have 2 digits after decimal or less',
        });
      }
    }
      return true;
    }),
    packageName: Yup.string()
    .required('Please enter Package Name')
    .matches(
      /^[a-z\d\-_\s]+$/i,
      'Package name should have alphabets and numbers only'
    )
    .test('len', 'Package Name cannot be more than 50 characters', (val) => {
      if (!val) {
        return true;
      }
      return val.length <= 50;
    }),
});
