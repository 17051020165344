import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UserPermission from '../../store/UserPermission';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeader: {
    background: '#fed700d1 !important',
    fontFamily: 'Shell-Medium',
    border: '1px solid #aaaaaa',
    align: 'center !important',
    fontSize: 14,
  },

  tableBodyCell: {
    border: '1px solid #dddddd',
    fontFamily: 'Shell-Book',
    align: 'center'
  },

  detailsTableHeader: {
    background: '#fed70075',
    fontFamily: 'Shell-Medium',
    fontSize: 12,
    border: '1px solid #aaaaaa',
    padding: 5,
  },
  detailsTableBodyCell: {
    border: '1px solid #aaaaaa',
    fontFamily: 'Shell-Book',
    fontSize: 12,
    padding: 5,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f0f0f0d1',
    }
  },
  tableCellCenter: {
    border: '1px solid #dddddd',
    fontFamily: 'Shell-Book',
    align: 'center !important'
  },

}));

export default function TableElement(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const classes = useStyles();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSelectedRow = (row: any) => {
    props.onClick(row);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" key="table">
          <TableHead
            className={classes.tableHeader}
            key={'table-head'}>
            <TableRow key="DM-table-head-row">
              {props.columns.map((column: any) => (
                <TableCell
                  key={`DM-${column.label}`}
                  className={classes.tableHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key={'DM-table-body-main'}>
            {(props.rows || [])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, i:any) => {
                return (
                  <TableRow
                    hover role="checkbox"
                    tabIndex={-1} key={row.code}
                    className={classes.tableRow}>
                    {props.columns.map((column: any) => {
                      const value = row[column.id];
                      if (column.id == "no") {
                        return (<TableCell
                          key={`table-${i + 1}-cell`}
                          className={classes.tableBodyCell}>
                         {i + 1}
                        </TableCell>)
                      }
                      if (column.id == "action") {
                        return (<TableCell
                          className={classes.tableBodyCell}><Tooltip title="Delete">
                            {props.isDeleteButtonEnable && <IconButton onClick={() => handleSelectedRow(row)}>
                              <DeleteIcon />
                            </IconButton>}
                          </Tooltip>
                          {props?.isEditButtonEnable && <Tooltip title="Edit">
                             <IconButton onClick={() => props.onClickEdit(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>}
                          </TableCell>)
                      }
                      if (column.id == "offeringPackageName") {
                        return (<TableCell
                          className={classes.tableBodyCell}>
                          {row['offeringPackageId'] == null ? '-' : value}
                        </TableCell>)
                      }
                      if (column.id == "mailjetTemplateId") {
                        return (<TableCell
                          className={classes.tableCellCenter}>
                          {value || '-'}
                        </TableCell>)
                      }
                      
                      return (
                        <TableCell
                          key={column.id}
                          className={classes.tableBodyCell}
                          >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value || '-'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={(props.rows || []).length}
        rowsPerPage={50}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}