import React, { useState } from 'react';
import { Box, Card, CardContent, Collapse, Divider, Typography, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

const ExpandableCard = (props: any) => {
  const { classes, title, content } = props;
  const [expanded, setExpanded] = useState(false);
  const [isEditOffer, setEditOffer] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = () => {
    setEditOffer(!isEditOffer);
    props.setEditOffer(!isEditOffer);
  };

  return (
    <Card className={classes.card}>
      <Box sx={{ m: 2 }}>
        <Box
          className={classes.boxTitle}
          onClick={handleExpandClick}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            {title}
          </Typography>
          <Box>
            <Tooltip title="Edit Offers" arrow>
              <EditIcon color="action" fontSize="small" style={{ marginRight: '10px' }} onClick={handleEditClick} />
            </Tooltip>
            {expanded ? (
              <KeyboardArrowUpIcon color="action" fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon color="action" fontSize="small" />
            )}
          </Box>
        </Box>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
        <CardContent className={classes.cardContainer}>{content}</CardContent>
      </Collapse>
    </Card>
  );
};

export default ExpandableCard;