import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getTimeDiff } from './helper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
    },
  },
  td: {
    '& .MuiTableCell-root': {
      fontSize: '0.75rem !important',
    },
  },
}));

interface PaymentHeaderInterface {
  id: string;
  label: string;
}

interface Data {
  amount: string;
  isPending: boolean;
  wasDeclined: boolean;
  timeOfChargeConfirmation: string;
  invoiceIssueDate: string;
  authorisationCode: string;
  pspReference: string;
}

function InvoicePaymentRequest(props: any) {
  const classes = useStyles();
  const { paymentRequests } = props;

  function createData(
    amount: string,
    isPending: boolean,
    wasDeclined: boolean,
    timeOfChargeConfirmation: string,
    invoiceIssueDate: string,
    authorisationCode: string,
    pspReference: string
  ): Data {
    return {
      amount,
      isPending,
      wasDeclined,
      timeOfChargeConfirmation,
      invoiceIssueDate,
      authorisationCode,
      pspReference,
    };
  }

  const rows = paymentRequests.map((pay: any) =>
    createData(
      pay.amount,
      pay.isPending,
      pay.wasDeclined,
      getTimeDiff(pay.timeOfChargeConfirmation),
      getTimeDiff(pay.invoiceIssueDate),
      pay.authorisationCode,
      pay.pspReference
    )
  );

  const paymentHeaderMap: readonly PaymentHeaderInterface[] = [
    {
      id: 'amount',
      label: 'Amount',
    },
    {
      id: 'isPending',
      label: 'Pending',
    },
    {
      id: 'wasDeclined',
      label: 'Declined',
    },
    {
      id: 'timeOfChargeConfirmation',
      label: 'Charge Confirmation',
    },
    {
      id: 'invoiceIssueDate',
      label: 'Invoice Issue Date',
    },
    {
      id: 'authorisationCode',
      label: 'Authorisation Code',
    },
    {
      id: 'pspReference',
      label: 'PSP Reference',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FDEB9C' }}>
        <TableRow>
          {paymentHeaderMap.map((header) => (
            <TableCell
              key={header.id}
              sx={{
                fontFamily: 'Shell-Medium',
                color: '#484848',
                fontSize: '12px',
              }}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography
          sx={{
            flex: '1 1 100%',
            fontSize: '0.8rem',
            p: 0,
            textAlign: 'start',
            minHeight: '32px !important',
            fontWeight: 600,
            fontFamily: 'Shell-Light',
          }}
          id="tableTitle"
          component="div"
        >
          Payment Requests -{' '}
          <span style={{ color: '#EF9F37', fontSize: '0.8rem' }}>
            {paymentRequests[0].invoiceNumber}
          </span>
        </Typography>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows.map((row: any, index: any) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    className={classes.td}
                  >
                    <TableCell>{row.amount || '-'}</TableCell>
                    <TableCell>{row.isPending.toString() || '-'}</TableCell>
                    <TableCell>{row.wasDeclined.toString() || '-'}</TableCell>
                    <TableCell>{row.timeOfChargeConfirmation || '-'}</TableCell>
                    <TableCell>{row.invoiceIssueDate || '-'}</TableCell>
                    <TableCell>{row.authorisationCode || '-'}</TableCell>
                    <TableCell>{row.pspReference || '-'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default InvoicePaymentRequest;
