import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import React from 'react';
import Image from '../../public/assets/images/logo.svg';
import { useDrawerContext } from '../contexts/drawer-context';
import AccountMenu from './AccountMenu';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: '#FACE00',
    color: '#484848',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  },
  icon: {
    padding: theme.spacing(1),
  },
  title: {
    width: '100%',
    margin: '0px 5px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
  },
  toolbar: { paddingLeft: '16px', paddingRight: '16px' },
  menuButton: {},
}));

const Header = () => {
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const env =
    process.env.NODE_ENV === 'production'
      ? ''
      : process.env.NODE_ENV === 'development'
      ? '- QA'
      : '- UAT';

  return (
    <AppBar className={clsx(classes.appBar)} position="absolute">
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          className={clsx(classes.menuButton)}
          color="inherit"
          edge="start"
          onClick={() => toggleIsOpened(!isOpened)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.title}
          color="inherit"
          component="div"
          noWrap
          variant="h6"
        >
          <div className="header-shell-logo">
            <img src={Image}></img>
            <div className="header-logo-text">
              <span className="text1">Shell </span>
              <span className="text2">Fleet App</span>
            </div>
          </div>
          <div className="header-text">Operations Portal {env}</div>
          <div style={{ width: 255 }}></div>
        </Typography>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
