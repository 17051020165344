import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import AxiosInstance from '../../config/Interceptors';

import {
	accountCloserSuccess,
	accountCloserFailure,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  fetchCustomerProfileSuccess,
  fetchCustomerProfileFailure,
  fetchCustomerOnboardingSuccess,
  fetchCustomerOnboardingFailure,
  fetchCustomerGfnaccountSuccess,
  fetchCustomerGfnaccountFailure,
  fetchCustomerIpaasSuccess,
  fetchCustomerIpaasFailure,
  fetchCustomerPayCardRegSuccess,
  fetchCustomerPayCardRegFailure,
  fetchCustomerAddressSuccess,
  fetchCustomerAddressFailure,
  fetchCustomerInvoiceDetailsSuccess,
  fetchCustomerInvoiceDetailsFailure,
  fetchChargebackInvoiceSuccess,
  fetchChargebackInvoiceFailure,
  fetchSOADetailsSuccess,
  fetchSOADetailsFailure,
  fetchChargebackSOASuccess,
  fetchChargebackSOAFailure,
  updateCustomerVerifySuccess,
  updateCustomerVerifyFailure,
  fetchCustomerCardsSuccess,
  fetchCustomerCardsFailure,
  fetchCustomerBusinessSuccess,
  fetchCustomerBusinessFailure,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  blockCustomerSuccess,
  blockCustomerFailure,
  fetchCustomerH3InvoiceSuccess,
  fetchCustomerH3InvoiceFailure,
  fetchCustomerOnfidoSuccess,
  fetchCustomerOnfidoFailure,
  fetchCustomerDetailsSuccess,
  fetchCustomerDetailsFailure,
  updateUserNameSuccess,
  updateUserNameFailure
} from './actions';
import {
  CUSTOMER_ACCOUNT_CLOSER_REQUEST,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_ONBOARDING_REQUEST,
  FETCH_CUSTOMER_GFNACCOUNT_REQUEST,
  FETCH_CUSTOMER_IPAAS_REQUEST,
  FETCH_CUSTOMER_PAYCARDREG_REQUEST,
  FETCH_CUSTOMER_ADDRESS_REQUEST,
  FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST,
  FETCH_SOA_DETAILS_REQUEST,
  UPDATE_CUSTOMER_VERIFY_REQUEST,
  FETCH_CUSTOMER_CARDS_REQUEST,
  FETCH_CUSTOMER_BUSINESS_REQUEST,
  DELETE_CUSTOMER_ACCOUNT_REQUEST,
  BLOCK_CUSTOMER_ACCOUNT_REQUEST,
  FETCH_CUSTOMER_H3INVOICE_REQUEST,
  FETCH_CUSTOMER_ONFIDO_REQUEST,
  FETCH_CUSTOMER_CHARGEBACK_REQUEST,
  FETCH_SOA_CHARGEBACK_REQUEST,
  FETCH_CUSTOMER_DETAILS,
  UPDATE_USER_NAME_REQUEST
} from './actionTypes';
import {
  AccountCloserRequestPayload,
  FetchUserDetailsRequestPayload,
  User,
  FetchCustomerProfileRequestPayload,
  FetchCustomerOnboardingRequestPayload,
  FetchCustomerGfnaccountRequestPayload,
  FetchCustomerIpaasRequestPayload,
  FetchCustomerPayCardRegRequestPayload,
  FetchCustomerAddressRequestPayload,
  FetchCustomerInvoiceDetailsRequestPayload,
  FetchSOADetailsRequestPayload,
  UpdateCustomerVerifyRequestPayload,
  FetchCustomerCardsRequestPayload,
  FetchCustomerBusinessRequestPayload,
  DeleteCustomerRequestPayload,
  BlockCustomerRequestPayload,
  FetchCustomerH3InvoiceRequestPayload,
  FetchCustomerOnfidoRequestPayload,
  FetchChargebackInvoiceRequestPayload,
  FetchChargebackSOARequestPayload,
  FetchCustomerDetailsRequestPayload,
  UpdateUserNameRequestPayload
} from './types';
import * as Session from '../session';

const getUserDetails = async (param: FetchUserDetailsRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<User[]>('/users', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchUserDetailsSaga(data: any) {
  try {
    const { payload }: { payload: FetchUserDetailsRequestPayload } = data;
    const response: AxiosResponse = yield call(getUserDetails, payload);
    if (response.status === 200) {
      yield put(
        fetchUserDetailsSuccess({
          user: [response.data.data],
          response: response.data,
        })
      );
    } else {
      yield put(
        fetchUserDetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchUserDetailsFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerProfile = async (
  param: FetchCustomerProfileRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/customer/profile`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerProfileSaga(data: any) {
  try {
    const { payload }: { payload: FetchCustomerProfileRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerProfile, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerProfileSuccess({
          customerProfile: response.data.data || [],
          success: response.data.success,
        })
      );
    } else {
      yield put(
        fetchCustomerProfileFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerProfileFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerOnboarding = async (
  param: FetchCustomerOnboardingRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/onboarding/${param.customerId}`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerOnboardingSaga(data: any) {
  try {
    const { payload }: { payload: FetchCustomerOnboardingRequestPayload } =
      data;
    const response: AxiosResponse = yield call(getCustomerOnboarding, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerOnboardingSuccess({
          customerOnboarding: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerOnboardingFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerOnboardingFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerGfnaccount = async (
  param: FetchCustomerGfnaccountRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/gfnAccount/${param.customerId}`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerGfnaccountSaga(data: any) {
  try {
    const { payload }: { payload: FetchCustomerGfnaccountRequestPayload } =
      data;
    const response: AxiosResponse = yield call(getCustomerGfnaccount, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerGfnaccountSuccess({
          customerGfnaccount: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerGfnaccountFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerGfnaccountFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerIpaas = async (param: FetchCustomerIpaasRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/ipaas/${param.customerId}`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerIpaas(data: any) {
  try {
    const { payload }: { payload: FetchCustomerIpaasRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerIpaas, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerIpaasSuccess({
          customerIpaas: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerIpaasFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerIpaasFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerPayCardReg = async (
  param: FetchCustomerPayCardRegRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(
      `/paymentcardregistrationdetails/${param.customerId}`,
      {
        params: param,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerPayCardReg(data: any) {
  try {
    const { payload }: { payload: FetchCustomerPayCardRegRequestPayload } =
      data;
    const response: AxiosResponse = yield call(getCustomerPayCardReg, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerPayCardRegSuccess({
          customerPayCardReg: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerPayCardRegFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerPayCardRegFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerAddress = async (
  param: FetchCustomerPayCardRegRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/address/${param.customerId}`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerAddress(data: any) {
  try {
    const { payload }: { payload: FetchCustomerAddressRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerAddress, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerAddressSuccess({
          customerAddress: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerAddressFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerAddressFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerInvoiceDetails = async (
  param: FetchCustomerInvoiceDetailsRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/invoicedetails`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const getSOADetails = async (
  param: FetchSOADetailsRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    
    return AxiosInstance.get<string[]>(`/SOADetails`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const getSOAChargebackDetails = async (
  param: FetchChargebackSOARequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/SOADetails/soachargeback`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};
const getChargebackDetails = async (
  param: FetchChargebackInvoiceRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/invoicedetails/chargeback`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerInvoiceDetails(data: any) {
  try {
    const { payload }: { payload: FetchCustomerInvoiceDetailsRequestPayload } =
      data;
    const response: AxiosResponse = yield call(
      getCustomerInvoiceDetails,
      payload
    );
    if (response.status === 200) {
      yield put(
        fetchCustomerInvoiceDetailsSuccess({
          customerInvoiceDetails: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerInvoiceDetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerInvoiceDetailsFailure({
        error: e.message,
      })
    );
  }
}

function* fetchSOADetails(data: any) {
  try {
    const { payload }: { payload: FetchSOADetailsRequestPayload } =
      data;
    const response: AxiosResponse = yield call(
      getSOADetails,
      payload
    );
    if (response.status === 200) {
      yield put(
        fetchSOADetailsSuccess({
          soaDetails: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchSOADetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchSOADetailsFailure({
        error: e.message,
      })
    );
  }
}

function* fetchSOAChargebackDetails(data: any) {
  try {
    const { payload }: { payload: FetchChargebackSOARequestPayload } = data;
    const response: AxiosResponse = yield call(
      getSOAChargebackDetails,
      payload
    );
    if (response.status === 200) {
      yield put(
        fetchChargebackSOASuccess({
          soaChargebacks: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchChargebackSOAFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchChargebackSOAFailure({
        error: e.message,
      })
    );
  }
}

function* fetchChargebackDetails(data: any) {
  try {
    const { payload }: { payload: FetchChargebackInvoiceRequestPayload } = data;
    const response: AxiosResponse = yield call(
      getChargebackDetails,
      payload
    );
    if (response.status === 200) {
      yield put(
        fetchChargebackInvoiceSuccess({
          chargebacks: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchChargebackInvoiceFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchChargebackInvoiceFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerVerify = async (param: UpdateCustomerVerifyRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<string[]>('/onboarding/VerifyUser', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateCustomerVerifySaga(data: any) {
  try {
    const { payload }: { payload: UpdateCustomerVerifyRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerVerify, payload);
    if (response.status === 200) {
      yield put(
        updateCustomerVerifySuccess({
          customerVerify: response.data,
        })
      );
    } else {
      yield put(
        updateCustomerVerifyFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateCustomerVerifyFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerCards = async (param: FetchCustomerCardsRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/cards`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerCards(data: any) {
  try {
    const { payload }: { payload: FetchCustomerCardsRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerCards, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerCardsSuccess({
          customerCards: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerCardsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerCardsFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerBusiness = async (
  param: FetchCustomerBusinessRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/business/${param.customerId}`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerBusiness(data: any) {
  try {
    const { payload }: { payload: FetchCustomerBusinessRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerBusiness, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerBusinessSuccess({
          customerBusiness: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerBusinessFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerBusinessFailure({
        error: e.message,
      })
    );
  }
}

const deleteCustomer = async (param: DeleteCustomerRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.delete<string[]>(`/users`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* deleteCustomerAccount(data: any) {
  try {
    const { payload }: { payload: DeleteCustomerRequestPayload } = data;
    const response: AxiosResponse = yield call(deleteCustomer, payload);
    if (response.status === 200) {
      yield put(
        deleteCustomerSuccess({
          customerDelete: response.data,
        })
      );
    } else {
      yield put(
        deleteCustomerFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      deleteCustomerFailure({
        error: e.message,
      })
    );
  }
}

const blockCustomer = async (param: BlockCustomerRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<string[]>(
      '/customer/changecustomeraccountstate',
      param,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* blockCustomerAccount(data: any) {
  try {
    const { payload }: { payload: BlockCustomerRequestPayload } = data;
    const response: AxiosResponse = yield call(blockCustomer, payload);
    if (response.status === 200) {
      yield put(
        blockCustomerSuccess({
          customerBlock: true,
        })
      );
    } else {
      yield put(
        blockCustomerFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      blockCustomerFailure({
        error: e.message,
      })
    );
  }
}

const accountClose = async (param: AccountCloserRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<string[]>(
      '/users/closeuseraccount',
      param,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    window.location.reload();
    return false;
  }
};

function* closeuseraccount(data: any) {
  try {
    const { payload }: { payload: AccountCloserRequestPayload } = data;
    const response: AxiosResponse = yield call(accountClose, payload);
    if (response.status === 200) {
      yield put(
        accountCloserSuccess({
          accountClose: true,
        })
      );
    } else {
      yield put(
        accountCloserFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      blockCustomerFailure({
        error: e.message,
      })
    );
  }
}


const getCustomerH3Invoice = async (
  param: FetchCustomerH3InvoiceRequestPayload
) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/invoicedetails/h3invoices`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerH3Invoice(data: any) {
  try {
    const { payload }: { payload: FetchCustomerH3InvoiceRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerH3Invoice, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerH3InvoiceSuccess({
          customerH3Invoice: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerH3InvoiceFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerH3InvoiceFailure({
        error: e.message,
      })
    );
  }
}

const getCustomerOnfido = async (param: FetchCustomerOnfidoRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<string[]>(`/onfido`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchCustomerOnfido(data: any) {
  try {
    const { payload }: { payload: FetchCustomerOnfidoRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomerOnfido, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerOnfidoSuccess({
          customerOnfido: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerOnfidoFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerOnfidoFailure({
        error: e.message,
      })
    );
  }
}

function* fetchCustomerDetails(data: any) {
  try {
    const { payload }: { payload: FetchCustomerDetailsRequestPayload } = data;
    const response: AxiosResponse = yield call(getCustomer, payload);
    if (response.status === 200) {
      yield put(
        fetchCustomerDetailsSuccess({
          customerDetails: response.data.data || [],
          page: response.data.page || {},
        })
      );
    } else {
      yield put(
        fetchCustomerDetailsFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerDetailsFailure({
        error: e.message,
      })
    );
  }
}

const getCustomer = async (param: FetchCustomerDetailsRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken(); 
    return AxiosInstance.get<string[]>(`/users/UserByDeal`, {
      params: param,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateUserNameSaga(data: any) {
  try {
    const { payload }: { payload: UpdateUserNameRequestPayload } = data;
    const response: AxiosResponse = yield call(setUserName, payload);
    if (response.status === 200) {
      yield put(
        updateUserNameSuccess({
          userNameDetails: response.data,
        })
      );
    } else {
      yield put(
        updateUserNameFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateUserNameFailure({
        error: e.message,
      })
    );
  }
}

const setUserName = async (param: UpdateUserNameRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<string[]>('/customer/editcustomer', param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* userDetailsSaga() {
  yield all([takeLatest(FETCH_USER_DETAILS_REQUEST, fetchUserDetailsSaga)]),
    yield all([
      takeLatest(FETCH_CUSTOMER_PROFILE_REQUEST, fetchCustomerProfileSaga),
    ]),
    yield all([
      takeLatest(
        FETCH_CUSTOMER_ONBOARDING_REQUEST,
        fetchCustomerOnboardingSaga
      ),
    ]),
    yield all([
      takeLatest(
        FETCH_CUSTOMER_GFNACCOUNT_REQUEST,
        fetchCustomerGfnaccountSaga
      ),
    ]),
    yield all([takeLatest(FETCH_CUSTOMER_IPAAS_REQUEST, fetchCustomerIpaas)]),
    yield all([
      takeLatest(FETCH_CUSTOMER_PAYCARDREG_REQUEST, fetchCustomerPayCardReg),
    ]),
    yield all([
      takeLatest(FETCH_CUSTOMER_ADDRESS_REQUEST, fetchCustomerAddress),
    ]),
    yield all([
      takeLatest(
        FETCH_SOA_DETAILS_REQUEST,
        fetchSOADetails
      ),
    ]),
    yield all([
      takeLatest(
        FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST,
        fetchCustomerInvoiceDetails
      ),
    ]),
    yield all([
      takeLatest(UPDATE_CUSTOMER_VERIFY_REQUEST, updateCustomerVerifySaga),
    ]),
    yield all([takeLatest(FETCH_CUSTOMER_CARDS_REQUEST, fetchCustomerCards)]),
    yield all([
      takeLatest(FETCH_CUSTOMER_BUSINESS_REQUEST, fetchCustomerBusiness),
    ]),
    yield all([
      takeLatest(DELETE_CUSTOMER_ACCOUNT_REQUEST, deleteCustomerAccount),
    ]),
    yield all([
      takeLatest(BLOCK_CUSTOMER_ACCOUNT_REQUEST, blockCustomerAccount),
    ]),
    yield all([
      takeLatest(CUSTOMER_ACCOUNT_CLOSER_REQUEST, closeuseraccount),
    ]),
    yield all([
      takeLatest(FETCH_CUSTOMER_H3INVOICE_REQUEST, fetchCustomerH3Invoice),
    ]),
    yield all([takeLatest(FETCH_CUSTOMER_ONFIDO_REQUEST, fetchCustomerOnfido)]);
    
    yield all([takeLatest(FETCH_CUSTOMER_CHARGEBACK_REQUEST, fetchChargebackDetails)]);
    yield all([takeLatest(FETCH_SOA_CHARGEBACK_REQUEST, fetchSOAChargebackDetails)]);
    yield all([takeLatest(FETCH_CUSTOMER_DETAILS, fetchCustomerDetails)]);
    yield all([takeLatest(UPDATE_USER_NAME_REQUEST, updateUserNameSaga)]);
}

export default userDetailsSaga;
