import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import { getTimeDiff } from './helper';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    textAlign: 'left',
    padding: '8px 0 8px 8px',
    background: '#F7F7F7',
  },
  gridClass: {
    paddingBottom: '8px',
    '& .MuiTypography-root': {
      fontSize: '0.8rem',
    },
  },
}));

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 1 },
        pr: { xs: 1, sm: 1 },
        textAlign: 'start',
        minHeight: '48px !important',
      }}
    >
      <Typography
        sx={{
          flex: '1 1 100%',
          fontWeight: 600,
          fontSize: '0.8rem',
          fontFamily: 'Shell-Book',
        }}
        id="tableTitle"
        component="div"
      >
        Payment Type/Card
      </Typography>
    </Toolbar>
  );
};

function PaymentCard(props: any) {
  const classes = useStyles();
  const paymentCards = props?.paymentCards;
  const paymentIdeal = props?.paymentIdeal;
  const paymentSofort = props?.paymentSofort;
  let paymentType = paymentIdeal ? paymentIdeal : paymentSofort;
  let data = paymentCards && paymentCards.filter((pay: any) => pay.isValid);
  return (
    <>
      <Paper sx={{ width: '100%', mb: 2, border: '1px solid #D9D9D9' }}>
        <EnhancedTableToolbar />
        <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
        {paymentIdeal === null && paymentSofort === null ? <Box className={classes.box}>
          {data?.length > 0 ? (
            <>
              <Grid container className={classes.gridClass}>
                <Grid container>
                  <Grid item xs={3}>
                    Card Type
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      component="div"
                    >
                      {data[0].type}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    Created
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      component="div"
                    >
                      {getTimeDiff(data[0].created)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.gridClass}>
                <Grid item xs={3}>
                  Last Four Digit
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    component="div"
                  >
                    {data[0].lastFourDigits}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  Modified
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    component="div"
                  >
                    {getTimeDiff(data[0].modified)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.gridClass}>
                <Grid item xs={3}>
                  Recurring Reference
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ fontSize: 14, wordBreak: 'break-word' }}
                    color="text.secondary"
                    component="div"
                  >
                    {data[0].recurringDetailReference}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>No payment card found for this customer</>
          )}
        </Box> : 
        <Box className={classes.box}>
          {paymentType?.length > 0 ? (
            <>
              <Grid container className={classes.gridClass}>
                <Grid container>
                  <Grid item xs={3}>
                  Payment Type
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      component="div"
                    >
                      {paymentIdeal !== null ? 'Ideal' : 'Sofort'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                  Created
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      component="div"
                    >
                       {getTimeDiff(paymentType[0].created)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.gridClass}>
                <Grid item xs={3}>
                Last Four Digit
                </Grid>
                <Grid item xs={3}>
                <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    component="div"
                  >
                      {paymentType[0].ibanLast4Digits ? paymentType[0].ibanLast4Digits : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  Modified
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      component="div"
                    >
                        {getTimeDiff(paymentType[0].modified)}
                    </Typography>
                  </Grid>
              </Grid>
            </>
          ) : (
            <>No payment Type found for this customer</>
          )}
        </Box>}
      </Paper>
    </>
  );
}

export default PaymentCard;
