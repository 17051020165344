import {
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import UserPermission from '../../store/UserPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  formControlEditButton: {
    '&:hover': {
      background: '#fbcf04d1',
    },
    margin: 0,
    background: '#fbcf04',
    borderRadius: 5,
    width: 25,
    height: 25,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  backButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  header: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1.2rem',
    textAlign: 'center',
    float: 'left',
    width: '100%',
    background: '#fbd002ae',
    borderBottom: '1px solid #aaa',
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
    float: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  rootDiv: {
    margin: '10px 0px',
  },
}));

export const DealMasterForm = (props: any) => {
  const classes = useStyles();
  const {
    countryValue,
    setButtonDisable,
    isDealMasterEdit,
    setEditDealMaster,
    setBtnClick,
    buttonDisable,
    formik,
    screenName,
    screenType,
    currencyId,
    offerDetailsTextItems,
  } = props;

  function displayErrors() {
    let keys = Object.keys(formik.errors);
    let errs = Object.values(formik.errors[keys[0]]).join(', ');
    return errs;
  }

  return (
    <>
      <>
        <form onSubmit={formik.handleSubmit}>
          <Card className={classes.root} variant="elevation">
            <div className={classes.header}>
              {isDealMasterEdit ? `Edit ${screenName}` : `Create ${screenName}`}
            </div>
            <Grid key="offerDetails-grid" container justifyContent="flex-start">
              <div key="manageOffer-grid-card" className={classes.rootDiv}>
                <TextField
                  type="hidden"
                  key={`${screenType}-textfield`}
                  name="category"
                  variant="outlined"
                  size="small"
                  value={_.set(formik.values, 'category', 'offerDetails')}
                  style={{ display: 'none' }}
                />
                <FormDisplay
                  countryCode={countryValue}
                  currencyId={currencyId}
                  classes={classes}
                  formik={formik}
                  screenType={screenType}
                  offerDetailsTextItems={offerDetailsTextItems}
                />
              </div>
            </Grid>
          </Card>

          <FormControl
            variant="outlined"
            className={classes.formControlButton}
            size="small"
          >
            <Button
              type="button"
              variant="contained"
              size="large"
              className={classes.backButton}
              onClick={() => {
                setEditDealMaster(false);
                setBtnClick({ createBtn: false });
                setButtonDisable({ ...buttonDisable, create: false });
              }}
            >
              Back
            </Button>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlButton}
            size="small"
          >
            <UserPermission
              component={screenName.split(' ').join('')}
              permissionFor={isDealMasterEdit ? 'update' : 'create'}
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                className={classes.searchButton}
                disabled={
                  isDealMasterEdit
                    ? !formik.isValid || !formik.dirty
                    : !(formik.isValid && formik.dirty)
                }
              >
                Continue
              </Button>
            </UserPermission>
          </FormControl>
          {!formik.isValid ? (
            <div>
              <Alert severity="error">{displayErrors()}</Alert>
            </div>
          ) : isDealMasterEdit && !formik.dirty ? (
            <div>
              <Alert severity="error">
                Please update atleast one field to enable Continue button.
              </Alert>
            </div>
          ) : (
            <></>
          )}
        </form>
      </>
    </>
  );
};

function FormDisplay(props: any) {
  const {
    classes,
    formik,
    countryCode,
    currencyId,
    screenType,
    offerDetailsTextItems,
  } = props;
  return (
    <>
      <div className={classes.offerDetailsDiv}>
        <TextItems
          classes={classes}
          countryCode={countryCode}
          currencyId={currencyId}
          formik={formik}
          screenType={screenType}
          offerDetailsTextItems={offerDetailsTextItems}
        />
      </div>
    </>
  );
}

function TextItems(props: any) {
  const {
    classes,
    formik,
    countryCode,
    currencyId,
    screenType,
    offerDetailsTextItems,
  }: {
    classes: any;
    formik: any;
    countryCode: string;
    currencyId: string;
    screenType: string;
    offerDetailsTextItems: any;
  } = props;

  const handleKeyDown = (e: any) => {
    if (e.target.type === 'number') {
      if (e.keyCode === 69) {
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <TextField
        type="hidden"
        key={`${screenType}-textfield`}
        name="subCategory"
        variant="outlined"
        size="small"
        value={screenType}
        style={{ display: 'none' }}
      />
      <Grid key="feeGroup-grid">
        {offerDetailsTextItems[screenType].map((each: any, i: number) => (
          <FormControl
            variant="outlined"
            className={classes.inputFormControl}
            error={Boolean(
              _.get(formik.touched, each.key) && _.get(formik.errors, each.key)
            )}
            size="small"
            key={`${screenType}-${each.key}-${i + 1}-formControl`}
          >
            <TextField
              aria-label={each.key}
              type={each.type}
              key={`${screenType}-${each.key}-textfield`}
              name={each.key}
              variant="outlined"
              size="small"
              value={
                each.key === `${screenType}.name` &&
                _.get(formik.values, each.key).startsWith(`${countryCode} `)
                  ? _.get(formik.values, each.key).replace(
                      `${countryCode} `,
                      ''
                    )
                  : _.get(formik.values, each.key)
              }
              onChange={formik.handleChange}
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              className={classes.input}
              label={each.label}
              aria-invalid="false"
              InputProps={
                each.key === `${screenType}.name`
                  ? {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          children={countryCode}
                          className={classes.inputAdornment}
                        />
                      ),
                    }
                  : each.cur
                  ? {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          children={currencyId}
                          className={classes.inputAdornment}
                        />
                      ),
                    }
                  : {}
              }
            />
            <FormHelperText key={`feeGroup-${each.key}-${i + 1}-FHT`}>
              {_.get(formik.touched, each.key) &&
                _.get(formik.errors, each.key)}
            </FormHelperText>
          </FormControl>
        ))}
      </Grid>
    </>
  );
}
