import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import { fetchCustomerIpaasRequest } from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkeletonComponent from './SkeletonComponent';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  ipaasRow: {
    marginTop: '6px',
  },
  collapse: {
    padding: '16px',
    paddingTop: '8px',
    backgroundColor: '#F7F7F7',
  },
}));

interface ipaas {
  id: string;
  value: any;
  label: string;
}

function UserIpaas(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const {
    customerProfile,
    customerIpaas,
    customerIpaasLoading,
    customerIpaasError,
  } = props;
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [ipaasMap, setIpaasMap] = React.useState([
    { id: '', value: '', label: '' },
  ]);

  if (customerIpaasLoading) {
    if (isDataFetched) {
      setIsDataFetched(false);
    }
  } else if (!customerIpaasLoading && !isDataFetched) {
    if (Object.keys(customerIpaas).length > 0) {
      loadMap();
    } else {
      setIpaasMap([]);
    }
    setIsDataFetched(true);
  }

  function loadMap() {
    const ipaasDataMap: ipaas[] = [
      {
        id: 'createCustomerError',
        value: customerIpaas.createCustomerError || '-',
        label: 'Create Customer Error',
      },
      {
        id: 'createUserError',
        value: customerIpaas.createUserError || '-',
        label: 'Create User Error',
      },
      {
        id: 'isCreateCustomerComplete',
        value: customerIpaas.isCreateCustomerComplete.toString(),
        label: 'Create Customer Complete',
      },
      {
        id: 'isCreateUserComplete',
        value: customerIpaas.isCreateUserComplete.toString(),
        label: 'Create User Complete',
      },
      {
        id: 'customerNotificationSent',
        value: customerIpaas.customerNotificationSent.toString(),
        label: 'Customer Notification Sent',
      },
    ];
    setIpaasMap(ipaasDataMap);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerIpaas).length === 0) {
      dispatch(
        fetchCustomerIpaasRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.boxTitle} onClick={handleExpandClick}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            iPaaS Customer Creation
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {isDataFetched ? (
              <>
                {ipaasMap.length > 0 ? (
                  ipaasMap.map(({ label, value, id }) => {
                    return (
                      <Box className={classes.box} key={id}>
                        <Grid container className={classes.ipaasRow}>
                          <Grid item xs={8}>
                            {label}
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              sx={{
                                color:
                                  [
                                    'Create Customer Error',
                                    'Create User Error',
                                  ].indexOf(label) === -1
                                    ? 'text.secondary'
                                    : '#E44A4D',
                              }}
                            >
                              {value}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                ) : (
                  <>
                    {customerIpaasError ? (
                      <Alert severity="error">
                        {`Error loading ipaas details - [${customerIpaasError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No iPaaS details found!</Alert>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box className={classes.box}>
                <SkeletonComponent></SkeletonComponent>
              </Box>
            )}
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerIpaas: state.customerIpaas.customerIpaas,
    customerIpaasLoading: state.customerIpaas.pending,
    customerIpaasError: state.customerIpaas.error,
  };
};

export default connect(mapStateToProps)(UserIpaas);
