import {
  FETCH_DEALS_FAILURE,
  FETCH_DEALS_REQUEST,
  FETCH_DEALS_SUCCESS,
  CREATE_DEALS_SUCCESS,
  CREATE_DEALS_REQUEST,
  CREATE_DEALS_FAILURE,
  RESET_CREATE_DEALS_REQUEST,
  FETCH_DEAL_DETAILS_REQUEST,
  FETCH_DEAL_DETAILS_SUCCESS,
  FETCH_DEAL_DETAILS_FAILURE,
  RESET_FETCH_DEAL_DETAILS,
  UPDATE_DEALS_REQUEST,
  UPDATE_DEALS_FAILURE,
  UPDATE_DEALS_SUCCESS,
  RESET_UPDATE_DEALS_REQUEST,
  FETCH_ALL_DEALS_REQUEST,
  FETCH_ALL_DEALS_SUCCESS,
  FETCH_ALL_DEALS_FAILURE,
  CREATE_OFFERS_SUCCESS,
  CREATE_OFFERS_REQUEST,
  CREATE_OFFERS_FAILURE,
  UPDATE_OFFERS_REQUEST,
  UPDATE_OFFERS_SUCCESS,
  UPDATE_OFFERS_FAILURE,
  RESET_CREATE_OFFERS_REQUEST,
  RESET_UPDATE_OFFERS_REQUEST
} from './actionTypes';
import { DealsActions, DealsState, AllDealsState } from './types';

const initialState: DealsState = {
  pending: false,
  deals: [],
  error: null,
  response: {},
  dealDetails: [],
  page: {},
};

const initialStateForAllDeals: AllDealsState = {
  pendingDeals: false,
  allDeals: [],
  allDealsError: null,
  allDealsResponse: {},
  allDealDetails: [],
  page: {},
};

const fetchDealsReducer = (state = initialState, action: DealsActions) => {
  switch (action.type) {
    case FETCH_DEALS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DEALS_SUCCESS:
      return {
        ...state,
        pending: false,
        deals: action.payload.deals,
        error: null,
      };
    case FETCH_DEALS_FAILURE:
      return {
        ...state,
        pending: false,
        deals: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

const fetchDealDetailsReducer = (
  state = initialState,
  action: DealsActions
) => {
  switch (action.type) {
    case FETCH_DEAL_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        dealDetails: action.payload.dealDetails,
        error: null,
        page: action.payload.page,
      };
    case FETCH_DEAL_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        dealDetails: [],
        error: action.payload.error,
      };
    case RESET_FETCH_DEAL_DETAILS:
      return {
        ...state,
        pending: false,
        dealDetails: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialCreateState: DealsState = {
  pending: false,
  error: null,
  response: {},
};
const createDealsReducer = (
  state = initialCreateState,
  action: DealsActions
) => {
  switch (action.type) {
    case CREATE_DEALS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_DEALS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_DEALS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_DEALS_REQUEST:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const updateDealsReducer = (
  state = initialCreateState,
  action: DealsActions
) => {
  switch (action.type) {
    case UPDATE_DEALS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_DEALS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_DEALS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_DEALS_REQUEST:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const fetchAllDealsReducer = (state = initialStateForAllDeals, action: DealsActions) => {
  switch (action.type) {

    case FETCH_ALL_DEALS_REQUEST:
      return {
        ...state,
        pendingDeals: true,
      };
    case FETCH_ALL_DEALS_SUCCESS:
      return {
        ...state,
        pendingDeals: false,
        allDeals: action.payload?.allDeals,
        allDealsError: null,
      };
    case FETCH_ALL_DEALS_FAILURE:
      return {
        ...state,
        pendingDeals: false,
        allDeals: [],
        allDealsError: action.payload.allDealsError,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialCreateOffersState: DealsState = {
  pending: false,
  error: null,
  response: {},
};
const createOffersReducer = (
  state = initialCreateOffersState,
  action: DealsActions
) => {
  switch (action.type) {
    case CREATE_OFFERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_OFFERS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_OFFERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_OFFERS_REQUEST:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const updateOffersReducer = (
  state = initialCreateOffersState,
  action: DealsActions
) => {
  switch (action.type) {
    case UPDATE_OFFERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_OFFERS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_OFFERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_OFFERS_REQUEST:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export {
  fetchDealsReducer,
  createDealsReducer,
  fetchDealDetailsReducer,
  updateDealsReducer,
  fetchAllDealsReducer,
  createOffersReducer,
  updateOffersReducer
};
