import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import { fetchDealsRequest } from '../store/deals/actions';
import {
  fetchTokenDetailsRequest,
  resetFetchTokenDetails,
} from '../store/token/actions';
import { FetchTokenDetailsRequestPayload } from '../store/token/types';

const tokenStatus = [
  {
    value: '1',
    label: 'Used',
  },
  {
    value: '0',
    label: 'Unused',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '32ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    margin: theme.spacing(1),
    minWidth: 250,
  },
  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 5, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  SettingsBackupRestoreIcon: {
    alignItems: 'center',
    margin: theme.spacing(1, 2, 4, 2),
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
    width: 250,
  },
  iconButton: {
    padding: 5,
    margin: 5,
    color: '#fed700',
    '&:hover': { color: '#fbcf04', background: '#adadad2e' },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  helperText: {
    marginLeft: 10,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px 0px',
    fontSize: '1rem',
    textAlign: 'left',
  },
}));

const advancedSearchInit = {
  dealId: '',
  status: 'all',
  country: 'all',
  token: '',
};
const searchErrors = {
  country: false,
  dealId: false,
  status: false,
  token: false,
};

function countriesHelperText(
  countriesLoading: boolean,
  countriesError: string
) {
  if (countriesLoading) {
    return 'Loading Countries list';
  } else if (countriesError) {
    return `Error loading countries - [${countriesError}]`;
  } else {
    return 'Please select country';
  }
}

function dealsHelperText(
  dealsLoading: boolean,
  dealsError: string,
  advancedSearch: any,
  dealLength: number
) {
  if (advancedSearch.country === 'all') {
    return 'Please select country to enable deals';
  } else if (dealsLoading) {
    return 'Loading Deals for the country';
  } else if (dealsError) {
    return `Error Loading deals - [${dealsError}]`;
  } else if (dealLength === 0) {
    return 'No deals for the country';
  } else {
    return 'Please select deals';
  }
}

function AdvancedSearch(props: any) {
  const classes = useStyles();
  const {
    deals,
    dealsLoading,
    countriesLoading,
    countries,
    searchPayload,
    showSearchResult,
    countriesError,
    dealsError,
    isModalOpen,
    token,
    loading,
    handleTokenDownload,
  } = props;
  const dispatch = useDispatch();
  const onlyAlphanumericRegex = /[^a-z0-9]/gi;
  const [advancedSearch, setAdvancedSearch] = useState(advancedSearchInit);
  const [hasErrors, setErrors] = useState(searchErrors);
  const [dataFetched, setDataFetched] = useState(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'country':
        if (value === 'all') {
          setAdvancedSearch(advancedSearchInit);
          setErrors(searchErrors);
        } else {
          setAdvancedSearch({
            ...advancedSearch,
            ['country']: value,
            ['token']: '',
            ['dealId']: '',
          });
          setErrors(searchErrors);
          dispatch(fetchDealsRequest(value));
        }
        break;
      case 'token':
        if (event.target.value !== '') {
          event.target.value = value.replace(onlyAlphanumericRegex, '');
          setErrors(searchErrors);
          setAdvancedSearch({
            ...advancedSearch,
            ['country']: 'all',
            ['dealId']: '',
            ['token']: event.target.value,
          });
        } else {
          setErrors({ ...searchErrors, ['dealId']: true, ['token']: true });
          setAdvancedSearch({ ...advancedSearch, [name]: value });
        }
        break;
      default:
        setAdvancedSearch({ ...advancedSearch, [name]: value });
        break;
    }
  };

  if (!loading && dataFetched) {
    const data: FetchTokenDetailsRequestPayload = {
      dealId: advancedSearch.dealId,
      status: advancedSearch.status === 'all' ? '' : advancedSearch.status,
      code: advancedSearch.token,
    };
    handleTokenDownload(token);
    setDataFetched(false);
    if (token.length > 0) {
      dispatch(fetchTokenDetailsRequest(data));
    }
  }

  const handleButtonClick = () => {
    dispatch(resetFetchTokenDetails());
    const data: FetchTokenDetailsRequestPayload = {
      dealId: advancedSearch.dealId,
      status: advancedSearch.status === 'all' ? '' : advancedSearch.status,
      code: advancedSearch.token,
    };
    //fetch all token data for download
    dispatch(fetchTokenDetailsRequest({ ...data, pageSize: 9999 }));
    setDataFetched(true);
    searchPayload(data);
    setErrors(searchErrors);
    showSearchResult(true);
  };

  const validateErrors = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'country':
        if (value === 'all') {
          setErrors({ ...searchErrors, ['dealId']: true, ['token']: true });
        } else {
          setErrors(searchErrors);
        }
        break;
      case 'dealId':
        if (advancedSearch.country !== 'all' && value === '') {
          setErrors({ ...searchErrors, ['dealId']: true });
        } else {
          setErrors(searchErrors);
        }
        break;
      case 'token':
        if (advancedSearch.country === 'all' && value === '') {
          setErrors({ ...searchErrors, ['token']: true, ['dealId']: true });
        } else {
          if (advancedSearch.dealId === '' && value === '') {
            setErrors({ ...searchErrors, ['dealId']: true, ['token']: true });
          } else {
            setErrors(searchErrors);
          }
        }
        break;
      default:
        setErrors(searchErrors);
        break;
    }
  };

  const handleSubmitDisable = () => {
    if (advancedSearch.token !== '' || advancedSearch.dealId !== '') {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className={classes.titleMsg}>Search Token</div>

      <ResponsiveContainer>
        <Card className={classes.root} variant="elevation">
          <Grid container justifyContent="flex-start">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={!isModalOpen && (hasErrors.country || !!countriesError)}
              size="small"
            >
              <InputLabel id="TM-country-select-label">Country</InputLabel>
              <Select
                name="country"
                labelId="TM-country-select-label"
                id="TM-country-select"
                value={advancedSearch.country || ''}
                defaultValue=""
                onChange={handleChange}
                label="Country"
                onBlur={validateErrors}
              >
                <MenuItem
                  key="all"
                  value="all"
                  aria-selected={true}
                  selected={true}
                  className={classes.menuItems}
                >
                  All
                </MenuItem>
                {!countriesLoading &&
                  countries.map((option: any) => (
                    <MenuItem
                      key={option.code}
                      value={option.code || ''}
                      aria-selected={true}
                      className={classes.menuItems}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {countriesHelperText(countriesLoading, countriesError)}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={!isModalOpen && (hasErrors.dealId || !!dealsError)}
              size="small"
            >
              <InputLabel id="TM-deal-select-label">Deal</InputLabel>
              {
                <Select
                  name="dealId"
                  labelId="TM-deal-select-label"
                  id="TM-deal-select"
                  value={advancedSearch.dealId || ''}
                  onChange={handleChange}
                  label="Deal"
                  defaultValue=""
                  onBlur={validateErrors}
                  disabled={
                    advancedSearch.country === 'all' || deals.length === 0
                      ? true
                      : false
                  }
                >
                  {deals.map((option: any) => {
                    return (
                      <MenuItem
                        key={_.get(option, 'id')}
                        value={_.get(option, 'id', '')}
                        aria-selected={false}
                        className={classes.menuItems}
                      >
                        {_.get(option, 'description')}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              <FormHelperText>
                {dealsHelperText(
                  dealsLoading,
                  dealsError,
                  advancedSearch,
                  deals.length
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={hasErrors.status}
              size="small"
            >
              <InputLabel id="TM-status-select-label">Status</InputLabel>
              <Select
                name="status"
                labelId="TM-status-select-label"
                id="TM-status-select"
                value={advancedSearch.status}
                onChange={handleChange}
                label="Status"
                onBlur={validateErrors}
              >
                <MenuItem
                  key="all"
                  value="all"
                  aria-selected={true}
                  selected={true}
                  className={classes.menuItems}
                >
                  All
                </MenuItem>
                {tokenStatus.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    aria-selected={true}
                    className={classes.menuItems}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Please select token status</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
              error={hasErrors.token}
              size="small"
            >
              <TextField
                name="token"
                variant="outlined"
                size="small"
                className={classes.input}
                label="Token"
                value={advancedSearch.token}
                onChange={handleChange}
                onBlur={validateErrors}
              />
              <FormHelperText className={classes.helperText}>
                Tokens are case sensitive.
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              className={classes.formControlButton}
              size="small"
            >
              <Button
                variant="contained"
                size="large"
                className={classes.searchButton}
                onClick={handleButtonClick}
                disabled={handleSubmitDisable()}
              >
                Search
              </Button>
            </FormControl>
          </Grid>
        </Card>
      </ResponsiveContainer>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    deals: state.deals.deals,
    dealsLoading: state.deals.pending,
    dealsError: state.deals.error,
    token: state.token.token,
    loading: state.token.pending,
  };
};

export default connect(mapStateToProps)(AdvancedSearch);
