import { Card, Typography } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import React from 'react';
  import { connect, useDispatch } from 'react-redux';
  import { useFormik } from 'formik';
  import { createPriceProfileRequest, updatePriceProfileRequest } from '../../store/offeringPackages/actions';
  import Spinner from '../Spinner';
  import Alert from '@material-ui/lab/Alert';
  import _ from 'lodash';
  import { manageOfferSchema } from '../../schemas/manageOfferSchema';
  import { DealMasterForm } from './DealMasterForm';
  
  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
        fontFamily: 'Shell-Book',
      },
      textAlign: 'left',
      margin: '10px 0px',
    }
  }));
  
  const offerDetailsTextItems: {
    priceProfile: { key: string; label: string; type: string; cur?: boolean }[];
  } = {
    priceProfile: [
        { key: 'priceProfile.name', label: 'Name', type: 'text' },
        { key: 'priceProfile.id', label: 'ID', type: 'number' },
        { key: 'priceProfile.vPowerDiscount', label: 'V Power Discount', type: 'number'},
        { key: 'priceProfile.mainGrade', label: 'Main Grade', type: 'number' },
        { key: 'priceProfile.dieselAGO', label: 'Diesel AGO', type: 'number' },
        { key: 'priceProfile.petrolMOGAS', label: 'Petrol MOGAS', type: 'number' },
        { key: 'priceProfile.lubricants', label: 'Lubricants', type: 'number' },
        { key: 'priceProfile.carWash', label: 'Car Wash', type: 'number' },
        { key: 'priceProfile.mainGradeTPN', label: 'Main Grade TPN', type: 'number' },
        { key: 'priceProfile.dieselAGOTPN', label: 'Diesel AGOTPN', type: 'number' },
        { key: 'priceProfile.fuelSaveDiesel', label: 'Fuel Save Diesel', type: 'number' },
        { key: 'priceProfile.fuelSaveMOGAS', label: 'Fuel Save MOGAS', type: 'number' },
        { key: 'priceProfile.vPowerMOGAS', label: 'V Power MOGAS', type: 'number' },
        { key: 'priceProfile.lpg', label: 'LPG', type: 'number' },
        { key: 'priceProfile.evShellNetwork', label: 'EV Shell Network', type: 'number' },
        { key: 'priceProfile.evOnRoam', label: 'EV on Roam', type: 'number' },
      ]
  };
  
  const initialValues: any = {
    category: '',
    subCategory: 'priceProfile',
    priceProfile: {
        countryCode: '',
        name: '',
        id: '',
        vPowerDiscount: 0.0,
        mainGrade: 0.0,
        dieselAGO: 0.0,
        petrolMOGAS: 0.0,
        lubricants: 0.0,
        carWash: 0.0,
        mainGradeTPN: 0.0,
        dieselAGOTPN: 0.0,
        fuelSaveDiesel: 0.0,
        fuelSaveMOGAS: 0.0,
        vPowerMOGAS: 0.0,
        lpg: 0.0,
        evShellNetwork: 0.0,
        evOnRoam: 0.0,
    }
  };
  
  const CreateUpdatePriceProfile = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
      offeringPackage,
      countryValue,
      priceProfileCreated,
      priceProfileUpdated,
      offeringPackageError,
      setButtonDisable,
      isDealMasterEdit,
      setEditDealMaster,
      priceProfileInitialValues,
      setBtnClick,
      buttonDisable,
      setCreateUpdateStatus,
      createUpdateStatus
    } = props;

    const currencyId = offeringPackage.currencyId;
    
    const formik = useFormik({
      initialValues: isDealMasterEdit ? priceProfileInitialValues : initialValues,
      validationSchema: manageOfferSchema,
      onSubmit: (values , { setSubmitting, resetForm }) => {
        formatValues(values, offeringPackage.marketId, countryValue, dispatch,
           setButtonDisable, isDealMasterEdit, buttonDisable);
        setSubmitting(false);
        resetForm();
      },
    });

    _.set(formik.values.priceProfile, 'name', _.get(formik.values.priceProfile, 'name').replace(
      `${countryValue} `,
      ''
    ));

    if (
      !formik.isSubmitting &&
      countryValue !== '' &&
      (Object.keys(priceProfileCreated.response).length > 0 ||
      priceProfileCreated.error ||
        Object.keys(priceProfileUpdated.response).length > 0 ||
        priceProfileUpdated.error)
    ) {
      setCreateUpdateStatus({...createUpdateStatus, flag:true, screen: isDealMasterEdit? 'update' : 'create'});
      setBtnClick({ createBtn: false });
      setEditDealMaster(false);
      setButtonDisable({ ...buttonDisable, create:false});
    }
  
    if (countryValue === '') {
      return <></>;
    } else if (
      countryValue !== '' &&
      (priceProfileCreated.pending || priceProfileUpdated.pending)
    ) {
      return (
        <Card
          className={classes.root}
          style={{ textAlign: 'center' }}
          variant="elevation"
        >
          <Spinner
            custom
            title={isDealMasterEdit ? 'Updating the Price Profile...' : 'Creating the Price Profile...'}
          />
        </Card>
      );
    } 
    else if (
      countryValue !== '' &&
      Object.keys(offeringPackage).length > 0 &&
      !offeringPackageError
    ) {
      return (
        <>
          <DealMasterForm
            countryValue={countryValue}
            setButtonDisable={setButtonDisable}
            isDealMasterEdit={isDealMasterEdit}
            setEditDealMaster={setEditDealMaster}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            formik={formik}
            screenName='Price Profile'
            screenType='priceProfile'
            currencyId={currencyId}
            offerDetailsTextItems={offerDetailsTextItems}
          />
        </>
      );
    } else {
      return (
        <Typography>
          <Alert severity="error">{`Error fetching Master Data for the Country - [${offeringPackageError}]`}</Alert>
        </Typography>
      );
    }
  };

  const mapStateToProps = (state: any) => {
    return {
      countries: state.countries.countries,
      countriesLoading: state.countries.pending,
      countriesError: state.countries.error,
      offeringPackage: state.offeringPackage.offeringPackage,
      offeringPackageResp: state.offeringPackage,
      offeringPackageLoading: state.offeringPackage.pending,
      OfferingPackageError: state.offeringPackage.error,
      priceProfileCreated: state.priceProfileCreated,
      priceProfileUpdated: state.priceProfileUpdated
    };
  };
  
  export default connect(mapStateToProps)(CreateUpdatePriceProfile);
  
  function formatValues(
    values: any,
    marketId: string,
    countryCode: string,
    dispatch: any,
    setButtonDisable: any,
    isDealMasterEdit: any,
    buttonDisable: any
  ) {
      values.priceProfile.countryCode = countryCode;
      values.priceProfile.marketId = marketId;
      setButtonDisable({ ...buttonDisable, create: true });
      if (isDealMasterEdit) {
        dispatch(updatePriceProfileRequest(values.priceProfile));
      } else {
        dispatch(createPriceProfileRequest(values.priceProfile));
      }
    };
  
  