import React, { ReactNode, useEffect, useState } from 'react';
import Spinner from './components/Spinner';
import * as Session from './store/session';
import Logout from './components/Logout';
import UnAuthorised from './components/UnAuthorisedUser';
import ServerError from './components/ServerError';

export type PortalState =
  | 'loading'
  | 'logout'
  | 'loggedin'
  | 'unauthorised'
  | 'servererror';

async function handleSession(setPortalState: any) {
  const refreshToken = Session.getRefreshToken();
  const tokenExpiry = Number(Session.getTokenExpiry());
  if (refreshToken && tokenExpiry < Math.round(new Date().getTime() / 1000)) {
    await Session.setAccessToken('refToken', refreshToken, setPortalState);
  } else if (!refreshToken || !tokenExpiry) {
    const { data } = await Session.getAccessURI();
    window.location.href = data as string;
  } else {
    setPortalState('loggedin');
  }
}

function AppComponent(props: { children: ReactNode }) {
  const [portalState, setPortalState] = useState<PortalState>('loading');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      Session.setAccessToken('code', code, setPortalState);
      window.history.pushState({}, '', '/admin');
    } else if (window.location.pathname === '/admin/logout') {
      setPortalState('logout');
    } else if (window.location.pathname === '/admin/un-authorised') {
      setPortalState('unauthorised');
    } else {
      handleSession(setPortalState);
    }
    window.addEventListener('storage', (event) => {
      const { key, newValue } = event;
      if (!key || !newValue) {
        Session.clearSession();
        setPortalState('logout');
        window.history.pushState({}, '', '/admin/logout');
      }
    });
  }, []);

  switch (portalState) {
    case 'servererror':
      return (
        <>
          <ServerError />
        </>
      );
    case 'logout':
      return (
        <>
          <Logout />
        </>
      );
    case 'loggedin':
      return <>{props.children}</>;
    case 'unauthorised':
      return (
        <>
          <UnAuthorised />
        </>
      );
    default:
      return (
        <>
          <Spinner overlay title="Authorising user..." />
        </>
      );
  }
}

export default AppComponent;
