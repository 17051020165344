import { all, call, put, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '../../config/Interceptors';
import { fetchCustomerGroupTypesFailure,
  fetchCustomerGroupTypesSuccess,
  fetchMailjetConfiguartionSuccess,
  fetchMailjetConfiguartionFailure,
  deleteMailjetConfiguartionSuccess,
  deleteMailjetConfiguartionFailure,
  createMailjetConfiguartionSuccess,
  createMailjetConfiguartionFailure,
  fetchAppConfigurationSuccess,
  fetchAppConfigurationFailure,
  fetchAppConfigurationDetailsSuccess,
  fetchAppConfigurationDetailsFailure,
  createAppConfigurationSuccess,
  createAppConfigurationFailure,
  updateAppConfigurationSuccess,
  updateAppConfigurationFailure,
  deleteAppConfiguartionSuccess,
  deleteAppConfiguartionFailure,
  fetchAllOfferingPackagesSuccess,
  fetchAllOfferingPackagesFailure,
  fetchAllMarketSuccess,
  fetchAllMarketFailure,
} from './action';
import {
  DELETE_MAILJET_CONFIGURATION_REQUEST,
  FETCH_CUSTOMER_GROUP_TYPE_REQUEST,
  FETCH_MAILJET_CONFIGURATION_REQUEST,
  CREATE_MAILJET_CONFIGURATION_REQUEST,
  FETCH_APP_CONFIGURATION_REQUEST,
  FETCH_APP_CONFIGURATION_DETAILS_REQUEST,
  CREATE_APP_CONFIGURATION_REQUEST,
  DELETE_APP_CONFIGURATION_REQUEST,
  UPDATE_APP_CONFIGURATION_REQUEST,
  FETCH_ALL_OFFERINGPACKAGES_REQUEST,
  FETCH_ALL_MARKETS_REQUEST
} from './actionTypes';
import { CustomerGroupTypes, DeleteMailjetResponse, DeleteAppConfiguartionRequestPayload } from './types';
import * as Session from '../session';
import { AxiosResponse } from 'axios';

const getCustomerGroupTypes = async () => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('/mailjet/getCustomerGroupTypes', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const getMailjetConfiguartionRequest = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('/mailjet', {
      params: {Country:param.payload.Country, CustomerGroupType: param.payload.CustomerGroupType },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const deleteMailjetConfiguartionRequest = async (param: any) => {

  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/mailjet/remove', param.payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

const createMailjetConfiguartionRequest = async (param: any) => {

  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/mailjet', param.payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

/*
Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchCustomerGroupTypesSaga() {
  try {
    const response: AxiosResponse = yield call(getCustomerGroupTypes);
    if (response.status === 200) {
      yield put(
        fetchCustomerGroupTypesSuccess({
          customerGroupTypeList: response.data || [],
        })
      );
    } else {
      yield put(
        fetchCustomerGroupTypesFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerGroupTypesFailure({
        error: e.message,
      })
    );
  }
}

function* fetchMailjetConfiguartionSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(getMailjetConfiguartionRequest,payload);
    if (response.status === 200) {
      yield put(
        fetchMailjetConfiguartionSuccess({
          mailjetConfiguartionList: response.data || [],
        })
      );
    } else {
      yield put(
        fetchMailjetConfiguartionFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCustomerGroupTypesFailure({
        error: e.message,
      })
    );
  }
}

function* deleteMailjetConfiguartionSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(deleteMailjetConfiguartionRequest,payload);
    if (response.status === 200) {
      yield put(
        deleteMailjetConfiguartionSuccess({
          deleteMailjetConfiguartionSuccess: response.data.success
        })
      );
    } else {
      yield put(
        deleteMailjetConfiguartionFailure({
          error: response.statusText
        })
      );
    }
  } catch (e: any) {
    yield put(
      deleteMailjetConfiguartionFailure({
        error: e.message
      })
    );
  }
}

function* createMailjetConfiguartionSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(createMailjetConfiguartionRequest,payload);
    if (response.status === 200) {
      yield put(
        createMailjetConfiguartionSuccess({
          createMailjetConfiguartionSuccess: response.data.success
        })
      );
    } else {
      yield put(
        createMailjetConfiguartionFailure({
          error: response.data.exceptionDetails
        })
      );
    }
  } catch (e: any) {
    yield put(
      createMailjetConfiguartionFailure({
        error: e.message
      })
    );
  }
}

const getAllMarketsSaga = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('api/AppConfiguration/GetMarketList', {
      params: {ConfigMasterId: param?.payload?.configMasterId},
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchAllMarketsSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getAllMarketsSaga, payload);
    if (response.status === 200) {
      yield put(
        fetchAllMarketSuccess({
          marketList: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchAllMarketFailure({
          error: response.data.exceptionDetails
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchAllMarketFailure({
        error: e.message
      })
    );
  }
}

const getAllOfferingpackagesSaga = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('api/AppConfiguration/GetConfigurationDropDownList', {
      params: {marketid: param?.payload?.marketId},
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchAllOfferingPackagesSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getAllOfferingpackagesSaga, payload);
    if (response.status === 200) {
      yield put(
        fetchAllOfferingPackagesSuccess({
          offeringPackagesList: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchAllOfferingPackagesFailure({
          error: response.data.exceptionDetails
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchAllOfferingPackagesFailure({
        error: e.message
      })
    );
  }
}

const updateAppConfigurationRequest = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/api/AppConfiguration/update', param.payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* updateAppConfigurationSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(updateAppConfigurationRequest,payload);
    if (response.status === 200) {
      yield put(
        updateAppConfigurationSuccess({
          updateAppConfigurationSuccess: response.data.success
        })
      );
    } else {
      yield put(
        updateAppConfigurationFailure({
          error: response.data.title
        })
      );
    }
  } catch (e: any) {
    yield put(
      updateAppConfigurationFailure({
        error: e.message
      })
    );
  }
}

const createAppConfiguartionRequest = async (param: any) => {

  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.post<any>('/api/AppConfiguration/AddAppconfigurationByMasterId', param.payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* createAppConfiguartionSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(createAppConfiguartionRequest,payload);
    if (response.status === 200) {
      yield put(
        createAppConfigurationSuccess({
          createAppConfigurationSuccess: response.data.success
        })
      );
    } else {
      yield put(
        createAppConfigurationFailure({
          error: response.data.title
        })
      );
    }
  } catch (e: any) {
    yield put(
      createAppConfigurationFailure({
        error: e.message
      })
    );
  }
}

const getAppConfigTypeTypesSaga = async () => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('api/AppConfiguration/GetAppConfigMasterAsync', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchAppConfigTypeTypesSaga() {
  try {
    const response: AxiosResponse = yield call(getAppConfigTypeTypesSaga);
    if (response.status === 200) {
      yield put(
        fetchAppConfigurationSuccess({
          appConfigTypeList: response.data.data.appConfigMasterDto || [],
        })
      );
    } else {
      yield put(
        fetchAppConfigurationFailure({
          error: response.data.exceptionDetails
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchAppConfigurationFailure({
        error: e.message
      })
    );
  }
}

const getAppConfigDetailsTypesSaga = async (param: any) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<CustomerGroupTypes[]>('api/AppConfiguration/GetAppconfigurationByMasterId', {
      params: {ConfigMasterId: param?.payload?.configMasterId},
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* fetchAppConfigDetailsTypesSaga(payload: any) {
  try {
    const response: AxiosResponse = yield call(getAppConfigDetailsTypesSaga, payload);
    if (response.status === 200) {
      yield put(
        fetchAppConfigurationDetailsSuccess({
          appConfigDetailsList: response.data?.data?._AppConfigdetailsDtos || [],
        })
      );
    } else {
      yield put(
        fetchAppConfigurationDetailsFailure({
          error: response.data.exceptionDetails
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchAppConfigurationDetailsFailure({
        error: e.message
      })
    );
  }
}

const deleteAppConfiguartionRequest = async (param: DeleteAppConfiguartionRequestPayload) => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.delete<string[]>(`/api/AppConfiguration/DeleteConfig/${param.configId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

function* deleteAppConfiguartionSaga(payload: any) {

  try {
    const response: AxiosResponse = yield call(deleteAppConfiguartionRequest,payload.payload);
    if (response.status === 200) {
      yield put(
        deleteAppConfiguartionSuccess({
          deleteAppConfiguartionSuccess: response.data.success
        })
      );
    } else {
      yield put(
        deleteAppConfiguartionFailure({
          error: response.statusText
        })
      );
    }
  } catch (e: any) {
    yield put(
      deleteAppConfiguartionFailure({
        error: e.message
      })
    );
  }
}
/*
Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
Allows concurrent increments.
*/
function* customerGroupTypesSaga() {
  yield all([takeLatest(FETCH_CUSTOMER_GROUP_TYPE_REQUEST, fetchCustomerGroupTypesSaga)]);
  yield all([takeLatest(FETCH_MAILJET_CONFIGURATION_REQUEST, fetchMailjetConfiguartionSaga)]);
  yield all([takeLatest(DELETE_MAILJET_CONFIGURATION_REQUEST, deleteMailjetConfiguartionSaga)]);
  yield all([takeLatest(CREATE_MAILJET_CONFIGURATION_REQUEST, createMailjetConfiguartionSaga)]);
  yield all([takeLatest(FETCH_APP_CONFIGURATION_REQUEST, fetchAppConfigTypeTypesSaga)]);
  yield all([takeLatest(FETCH_APP_CONFIGURATION_DETAILS_REQUEST, fetchAppConfigDetailsTypesSaga)]);
  yield all([takeLatest(CREATE_APP_CONFIGURATION_REQUEST, createAppConfiguartionSaga)]);
  yield all([takeLatest(UPDATE_APP_CONFIGURATION_REQUEST, updateAppConfigurationSaga)]);
  yield all([takeLatest(DELETE_APP_CONFIGURATION_REQUEST, deleteAppConfiguartionSaga)]);
  yield all([takeLatest(FETCH_ALL_OFFERINGPACKAGES_REQUEST, fetchAllOfferingPackagesSaga)]);
  yield all([takeLatest(FETCH_ALL_MARKETS_REQUEST, fetchAllMarketsSaga)]);

}

export default customerGroupTypesSaga;
