import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  createLegalEntityRequest,
  updateLegalEntityRequest,
} from '../../store/offeringPackages/actions';
import Spinner from '../Spinner';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { manageOfferSchema } from '../../schemas/manageOfferSchema';
import { DealMasterForm } from './DealMasterForm';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
}));

const offerDetailsTextItems: {
  legalEntity: { key: string; label: string; type: string; cur?: boolean }[];
} = {
  legalEntity: [
    { key: 'legalEntity.name', label: 'Name', type: 'text' },
    { key: 'legalEntity.id', label: 'Legal Entity ID', type: 'number' },
    { key: 'legalEntity.ipaasCode', label: 'IPaas Code', type: 'text' },
  ],
};

const initialValues: any = {
  category: '',
  subCategory: 'legalEntity',
  legalEntity: {
    countryCode: '',
    marketId: '',
    name: '',
    id: '',
    ipaasCode: '',
  },
};

const CreateUpdateLegalEntity = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    offeringPackage,
    countryValue,
    legalEntityCreated,
    legalEntityUpdated,
    offeringPackageError,
    setButtonDisable,
    isDealMasterEdit,
    setEditDealMaster,
    legalEntityInitialValues,
    setBtnClick,
    buttonDisable,
    setCreateUpdateStatus,
    createUpdateStatus,
  } = props;

  const currencyId = offeringPackage.currencyId;

  const formik = useFormik({
    initialValues: isDealMasterEdit ? legalEntityInitialValues : initialValues,
    validationSchema: manageOfferSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      formatValues(
        values,
        offeringPackage.marketId,
        countryValue,
        dispatch,
        setButtonDisable,
        isDealMasterEdit,
        buttonDisable
      );
      setSubmitting(false);
      resetForm();
    },
  });

  _.set(
    formik.values.legalEntity,
    'name',
    _.get(formik.values.legalEntity, 'name').replace(`${countryValue} `, '')
  );

  if (
    !formik.isSubmitting &&
    countryValue !== '' &&
    (Object.keys(legalEntityCreated.response).length > 0 ||
      legalEntityCreated.error ||
      Object.keys(legalEntityUpdated.response).length > 0 ||
      legalEntityUpdated.error)
  ) {
    setCreateUpdateStatus({
      ...createUpdateStatus,
      flag: true,
      screen: isDealMasterEdit ? 'update' : 'create',
    });
    setBtnClick({ createBtn: false });
    setEditDealMaster(false);
    setButtonDisable({ ...buttonDisable, create: false });
  }

  if (countryValue === '') {
    return <></>;
  } else if (
    countryValue !== '' &&
    (legalEntityCreated.pending || legalEntityUpdated.pending)
  ) {
    return (
      <Card
        className={classes.root}
        style={{ textAlign: 'center' }}
        variant="elevation"
      >
        <Spinner
          custom
          title={
            isDealMasterEdit
              ? 'Updating the Legal Entity...'
              : 'Creating the Legal Entity...'
          }
        />
      </Card>
    );
  } else if (
    countryValue !== '' &&
    Object.keys(offeringPackage).length > 0 &&
    !offeringPackageError
  ) {
    return (
      <>
        <DealMasterForm
          countryValue={countryValue}
          setButtonDisable={setButtonDisable}
          isDealMasterEdit={isDealMasterEdit}
          setEditDealMaster={setEditDealMaster}
          setBtnClick={setBtnClick}
          buttonDisable={buttonDisable}
          formik={formik}
          screenName="Legal Entity"
          screenType="legalEntity"
          currencyId={currencyId}
          offerDetailsTextItems={offerDetailsTextItems}
        />
      </>
    );
  } else {
    return (
      <Typography>
        <Alert severity="error">{`Error fetching Master Data for the Country - [${offeringPackageError}]`}</Alert>
      </Typography>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    offeringPackage: state.offeringPackage.offeringPackage,
    offeringPackageResp: state.offeringPackage,
    offeringPackageLoading: state.offeringPackage.pending,
    OfferingPackageError: state.offeringPackage.error,
    legalEntityCreated: state.legalEntityCreated,
    legalEntityUpdated: state.legalEntityUpdated,
  };
};

export default connect(mapStateToProps)(CreateUpdateLegalEntity);

function formatValues(
  values: any,
  marketId: string,
  countryCode: string,
  dispatch: any,
  setButtonDisable: any,
  isDealMasterEdit: any,
  buttonDisable: any
) {
  values.legalEntity.countryCode = countryCode;
  values.legalEntity.marketId = marketId;
  setButtonDisable({ ...buttonDisable, create: true });
  if (isDealMasterEdit) {
    dispatch(updateLegalEntityRequest(values.legalEntity));
  } else {
    dispatch(createLegalEntityRequest(values.legalEntity));
  }
}
