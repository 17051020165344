import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TextField, Grid, FormControl, MenuItem, Select, InputLabel, CircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  createAppConfigurationRequest,
  updateAppConfigurationRequest,
  fetchAllOfferingPackagesRequest,
  resetAllOfferingPackagesDetails
} from '../../../store/configurations/action';
import {
  FetchAllOfferingPackagesRequestPayload
} from '../../../store/configurations/types';
import { configLevelDropdownList } from '../AppConfigurations/appConfigurationTableColumns';

const options = [
  {
    lable: 'True',
    value: 'true'
  },
  {
    lable: 'False',
    value: 'false'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root , .MuiTableCell-root': {
      fontFamily: 'Shell-Book',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 10.5px'
    },
    margin: '10px 0px',
    display: 'flex',
    '& .MuiAlert-root': {
      marginLeft: '34px'
    },
    '& .MuiPaper-root': {
      align: 'center'
    },
    '& .MuiPaper-root-MuiDialog-paper': {
      maxWidth: '800px !important'
    }
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  circularProgress: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#fbcf04',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  // Select
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 1),
    minWidth: 250,
    width: 300,
  },
  formControlButton: {
    margin: 0,
  },
  buttonIcon: {
    '&:hover': {
      background: '#fbcf04d1 !important',
    },
    background: '#fed700d1 !important',
    color: '#000000 !important',
    margin: theme.spacing(1),
    width: 110,
    height: 40,
    fontFamily: 'Shell-Book',
  },
  disabledButton: {
    width: 110,
    height: 40,
  }
}));

function CreateAppConfig(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isOpen, resetForm, createAppConfigurationError, updateAppConfigurationError,
    marketList, marketListLoading, customerGroupTypeList, createConfiguartionLoading, dealList, offeringPackagesList,
    createAppConfigurationErr, appConfigTypeList, updateAppConfigurationErr,
    submitButtonEnable, setSubmitButtonEnable,
    selectedConfigLevel, configLevel,
    setMarketValue, marketValue,
    setcustomerGroupTypeValue, customerGroupTypeValue,
    setDealValue, dealValue,
    setValue, valueData,
    setOfferingpackageIdValue, offeringpackageIdValue,
    setAddAppConfigType, addAppConfigType,
    setOpenAppConfigurationDialog, selectedConfigId, setIsEdit, isEdit, setConfigDataType, configDataType, selectedRow
  } = props;

  const [marketEnable, setMarketEnable] = useState(false);
  const [dealEnable, setDealEnable] = useState(false);
  const [customerGroupTypeEnable, setCustomerGroupTypeEnable] = useState(false);
  const [offeringPackageEnable, setOfferingPackageEnable] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (!isEdit) {
      if (configLevel == '0' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
      else if (configLevel == '1' && marketValue !== '' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
      else if (configLevel == '3' && marketValue !== '' && customerGroupTypeValue !== '' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
      else if (configLevel == '2' && customerGroupTypeValue !== '' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
      else if (configLevel == '4' && marketValue !== '' && dealValue !== '' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
      else if (configLevel == '5' && marketValue !== '' && dealValue !== '' && offeringpackageIdValue !== '' && valueData !== '') {
        setSubmitButtonEnable(true);
      }
    }
  }, [customerGroupTypeValue, offeringpackageIdValue, dealValue, valueData, addAppConfigType, marketValue, configLevel]);

  const handleAddConfigurationTypeChange = (event: any) => {
    resetForm();
    const { value } = event.target;
    const data = appConfigTypeList.find(
      (val: any) => val.configMasterId === value
    );
    setConfigDataType(data.configTypeName);
    if (value !== '') {
      setAddAppConfigType(value);
    }
  };

  const handleConfigurationLevelChange = (event: any) => {
    dispatch(resetAllOfferingPackagesDetails());
    resetForm();

    const { value } = event.target;
    if (value !== '') {
      selectedConfigLevel(value);
    }

    let enableFields = [false, false, false, false]
    if (value == '0')
      enableFields = [false, false, false, false];
    if (value == '1')
      enableFields = [true, false, false, false];
    if (value == '2')
      enableFields = [false, true, false, false]
    const payload: FetchAllOfferingPackagesRequestPayload = {
      marketId: ''
    };
    dispatch(fetchAllOfferingPackagesRequest(payload));
    if (value == '3')
      enableFields = [true, true, false, false];
    if (value == '4')
      enableFields = [true, false, true, false];
    if (value == '5')
      enableFields = [true, false, true, true];
    setMarketEnable(enableFields[0]);
    setCustomerGroupTypeEnable(enableFields[1]);
    setDealEnable(enableFields[2]);
    setOfferingPackageEnable(enableFields[3]);
  }

  const handleMarketChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setMarketValue(value);
      const payload: FetchAllOfferingPackagesRequestPayload = {
        marketId: value
      };
      dispatch(fetchAllOfferingPackagesRequest(payload));
    }
  };

  const handleCustomerGroupTypeChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setcustomerGroupTypeValue(value);
    }
  }

  const handleOfferingPackageChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setOfferingpackageIdValue(value);
    }
  }

  const handleDealChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setDealValue(value);
    }
  }

  const handleSubmit = () => {
    props.setCreateAppConfigurationSuccess(false);
    props.setCreateAppConfigurationError(false);
    let requestData = {
      configId: selectedConfigId == '' ? null : selectedConfigId,
      configMasterId: addAppConfigType == '' ? null : addAppConfigType,
      marketId: marketValue == '' ? null : marketValue,
      customerGroupTypeId:customerGroupTypeValue == '' ? null : customerGroupTypeValue,
      dealId: dealValue == '' ? null : dealValue,
      offeringPackageId: offeringpackageIdValue == '' || offeringpackageIdValue == 'Default' ? null : offeringpackageIdValue,
      value: valueData == '' ? null : valueData,
      configOptions: isEdit ? 0 : parseInt(configLevel)
    };
    let requestDataForUpdateAppConfig = {
      configId: selectedRow.configId,
      value: valueData == '' ? null : valueData,
    };
    isEdit ? dispatch(updateAppConfigurationRequest(requestDataForUpdateAppConfig)) : dispatch(createAppConfigurationRequest(requestData));
    resetForm();
  }

  const handleEmailChange = (e: any) => {
      setValue(e.target.value);
      if (e.target.value !== "" && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(e.target.value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
  };

  const handlekeydown = (evt: any) => {
    if ((evt.which >= 48 && evt.which <= 57) || (evt.which == 8 || evt.which == 46)) {
      // setValue(evt.target.value);
    }
    else {
      evt.preventDefault();
    }
  }

  const handleCancel = () => {
    resetForm();
    setIsEdit(false);
    setOpenAppConfigurationDialog(false);
    setMarketEnable(false);
    setDealEnable(false);
    setOfferingPackageEnable(false);
    setCustomerGroupTypeEnable(false);
    props.setCreateAppConfigurationSuccess(false);
    props.setCreateAppConfigurationError(false);
    props.setUpdateAppConfigurationSuccess(false);
    props.setCreateAppConfigurationError(false);
  }
  return (
    <div>
      <Dialog open={isOpen} maxWidth="sm">
        <div>
          <DialogTitle textAlign="center">{isEdit ? 'Update application Configuration' : 'Create application Configuration'}</DialogTitle>
          {createAppConfigurationError && <Alert severity="error"> {`An error occurred - ${createAppConfigurationErr}`}</Alert>}
          {updateAppConfigurationError && <Alert severity="error"> {`An error occurred - ${updateAppConfigurationErr}`}</Alert>}
          <DialogContent>
            <Grid container justifyContent="center" item xs={11}>
              <Grid alignItems="center">

                <FormControl
                  key="DM-ConfigurationType1-formcontrol"
                  variant="outlined"
                  className={classes.formControl} size="small">
                  <InputLabel
                    key="ConfigurationType1-input-label"
                    id="ConfigurationType1-select-label"
                  >
                    Configuration Type
                  </InputLabel>
                  <Select
                    key="ConfigurationType1"
                    label="App Configuration"
                    value={addAppConfigType || ''}
                    id="appConfiguration"
                    name="appConfiguration"
                    labelId="ConfigurationType1-select-label1"
                    onChange={val => handleAddConfigurationTypeChange(val)}
                    autoWidth
                    disabled={isEdit}
                  >
                    {(appConfigTypeList || []).map((option: any) => (
                      <MenuItem
                        key={option.configMasterId}
                        value={option.configMasterId}
                        aria-selected={true}
                        className={classes.menuItems}
                      >
                        {option.configName}
                      </MenuItem>))}
                  </Select>
                </FormControl>

                <FormControl
                  key="DM-ConfigurationLevel-formcontrol"
                  variant="outlined"
                  className={classes.formControl} size="small">
                  {isEdit ? <TextField
                    value={configLevel}
                    key="ConfigurationLevel1"
                    name="market"
                    id="appConfigurationlevel"
                    label="Configuration Level"
                    disabled={true}
                    variant="outlined"
                    size="small" /> :
                    <><InputLabel
                      key="ConfigurationLevel-input-label"
                      id="ConfigurationLevel-select-label"
                    >
                      Configuration Level
                    </InputLabel><Select
                      key="ConfigurationLevel1"
                      label="App Configuration"
                      value={configLevel || ''}
                      id="appConfigurationlevel"
                      name="appConfigurationlevel"
                      labelId="ConfigurationLevel1-select-label1"
                      onChange={(value) => handleConfigurationLevelChange(value)}
                      autoWidth
                      disabled={isEdit}
                    >
                        {(configLevelDropdownList || []).map((option: any) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.label}
                          </MenuItem>))}
                      </Select></>}
                </FormControl>

                {marketValue !== null && <FormControl
                  key="DM-market-formcontrol"
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  {isEdit ? <TextField
                    value={marketValue}
                    key="DM-market-select"
                    name="market"
                    id="DM-market-select"
                    label="Market"
                    disabled={true}
                    variant="outlined"
                    size="small" /> :
                    <><InputLabel
                      key="DM-market-input-label"
                      id="DM-market-select-label"
                    >Market
                    </InputLabel><Select
                      disabled={!marketEnable}
                      key="DM-market-select"
                      name="market"
                      labelId="DM-market-select-label"
                      id="DM-market-select"
                      value={marketValue}
                      defaultValue=""
                      onChange={val => handleMarketChange(val)}
                      label="Market"
                    >
                        {!marketListLoading &&
                          marketList.map((option: any) => (
                            <MenuItem
                              key={option.marketId}
                              value={option.marketId}
                              aria-selected={true}
                              className={classes.menuItems}
                            >
                              {option.marketName}
                            </MenuItem>
                          ))}
                      </Select></>}
                </FormControl>}

                {customerGroupTypeValue !== null && <FormControl
                  key="DM-CustomerGroupType-formcontrol3"
                  variant="outlined"
                  className={classes.formControl} size="small">
                  {isEdit ? <TextField
                    value={customerGroupTypeValue}
                    key="DM-customerGroupType-select"
                    id="DM-customerGroupType-select"
                    name="customerGroupType"
                    label="Customer Group Type"
                    disabled={true}
                    variant="outlined"
                    size="small" /> :
                    <><InputLabel
                      key="CustomerGroupType-input-label"
                      id="CustomerGroupType-select-label"
                    >
                      Customer Group Type
                    </InputLabel><Select
                      disabled={!customerGroupTypeEnable}
                      key="DM-customerGroupType-select"
                      id="DM-customerGroupType-select"
                      name="customerGroupType"
                      labelId="CustomerGroupType-select-label"
                      value={customerGroupTypeValue}
                      label="Customer Group Type"
                      onChange={(val) => handleCustomerGroupTypeChange(val)}
                      autoWidth
                    >
                        {(customerGroupTypeList || []).map((option: any) => (
                          <MenuItem
                            key={option.customerGroupid}
                            value={option.customerGroupid}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.customerGroupName}
                          </MenuItem>))}
                      </Select></>}
                </FormControl>}

                {dealValue !== null && <FormControl
                  key="DM-dealid-formcontrol4"
                  variant="outlined"
                  className={classes.formControl} size="small">
                  {isEdit ? <TextField
                    value={dealValue}
                    id="value"
                    name="value"
                    label="Deal Id"
                    disabled={true}
                    variant="outlined"
                    size="small" /> :
                    <><InputLabel
                      key="dealid-input-label"
                      id="dealid-select-label"
                    >
                      Deal Id
                    </InputLabel><Select
                      key="DM-dealid-select"
                      id="DM-dealid-select"
                      name="dealid"
                      labelId="dealid-select-label"
                      value={dealValue}
                      label="Deal"
                      onChange={(val) => handleDealChange(val)}
                      autoWidth
                      disabled={!dealEnable}
                    >
                        {(dealList || []).map((option: any) => (
                          <MenuItem
                            key={option.dealId}
                            value={option.dealId}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.dealName}
                          </MenuItem>))}
                      </Select></>}
                </FormControl>}

                {offeringpackageIdValue !== null && <FormControl
                  key="5"
                  variant="outlined"
                  className={classes.formControl} size="small">
                  {isEdit ? <TextField
                    value={offeringpackageIdValue}
                    key="DM-OfferingPackageId-select"
                    id="DM-OfferingPackageId-select"
                    name="offeringPackageId"
                    label="Offering package Id"
                    disabled={true}
                    variant="outlined"
                    size="small" /> :
                    <><InputLabel
                      key="OfferingPackageId-input-label"
                      id="OfferingPackageId-select-label"
                    >
                      Offering package Id
                    </InputLabel><Select
                      key="DM-OfferingPackageId-select"
                      id="DM-OfferingPackageId-select"
                      name="offeringPackageId"
                      labelId="OfferingPackageId-select-label"
                      value={offeringpackageIdValue}
                      label="Offering package"
                      onChange={(val) => handleOfferingPackageChange(val)}
                      autoWidth
                      disabled={!offeringPackageEnable}

                    >
                        {(offeringPackagesList || []).map((option: any) => (
                          <MenuItem
                            key={option.offeringPackageId}
                            value={option.offeringPackageId}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.offeringPackageName}
                          </MenuItem>))}
                      </Select></>}
                </FormControl>}
                <FormControl
                  key="DM-value-formcontrol"
                  variant="outlined"
                  className={classes.formControl}>
                  {configDataType == 'boolean' ? <TextField
                    id="outlined-select-value"
                    variant="outlined"
                    size="small"
                    label="Value"
                    select
                    onChange={(val) => setValue(val.target.value)}
                    value={valueData}
                  >
                    {options.map((data: any) => (
                      <MenuItem
                        key={data.value}
                        value={data.value}
                      >
                        {data.lable}
                      </MenuItem>))}
                  </TextField> :
                    configDataType == 'int' ?
                      <TextField
                        id="outlined-number"
                        label="Value"
                        type="number"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={valueData}
                        onKeyDown={(val) => handlekeydown(val)}
                        onChange={(e) => setValue(e.target.value)}
                      /> :
                      configDataType == 'Email' ?
                        <TextField
                          required
                          id="outlined-email"
                          label="Email"
                          size="small"
                          variant="outlined"
                          value={valueData}
                          onChange={handleEmailChange}
                          error={emailError}
                          helperText={emailError ? "Please enter a valid email" : ""}
                          inputProps={{
                            type: "email",
                          }}
                        /> :
                        <TextField
                          value={valueData}
                          onChange={(val) => setValue(val.target.value)}
                          id="value"
                          name="value"
                          label="Value"
                          variant="outlined"
                          size="small" />
                  }
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions} key={'dialogActions'} >
            {createConfiguartionLoading ?
              (<CircularProgress className={classes.circularProgress} />) :
              (<>
                <Button
                  variant="contained"
                  size="small"
                  className={!submitButtonEnable || emailError ? classes.disabledButton : classes.buttonIcon}
                  title="Submit"
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={!submitButtonEnable || emailError}
                >Submit</Button>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.buttonIcon}
                  title="Cancel"
                  onClick={() => handleCancel()}
                >Cancel</Button>
              </>
              )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    appConfigTypeList: state.appConfigTypeList.appConfigTypeList,
    marketList: state.allMatrketsList.marketList,
    marketListLoading: state.allMatrketsList.pending,

    customerGroupTypeList: state.offeringPackagesList.offeringPackagesList.customerGroupdto,
    dealList: state.offeringPackagesList.offeringPackagesList.dealdto,
    offeringPackagesList: state.offeringPackagesList.offeringPackagesList.offerpackagedto,

    createAppConfiguration: state.createAppConfiguration.CreateAppConfiguration,
    createAppConfigurationErr: state.createAppConfiguration.error,
    updateAppConfigurationErr: state.updateAppConfiguration.error,
  };
};
export default connect(mapStateToProps)(CreateAppConfig);

