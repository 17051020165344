import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  updateCustomerVerifyRequest,
  resetUpdateCustomerVerify,
  fetchCustomerOnfidoRequest,
} from '../../store/user/actions';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import UserPermission from '../../store/UserPermission';
import { Grid } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import SkeletonComponent from './SkeletonComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  collapse: {
    padding: '16px',
    paddingTop: '8px',
    backgroundColor: '#F7F7F7',
  },
  onfidoRow: {
    marginTop: '6px',
  },
  approveRejectBtn: {
    display: 'flex',
    paddingTop: '16px',
  },
}));

interface onfido {
  id: string;
  value: any;
  label: string;
}

function OnfidoDetails(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const {
    customerProfile,
    customerVerify,
    customerVerifyLoading,
    customerOnfido,
    customerOnfidoLoading,
    customerOnfidoError,
  } = props;
  const [loadingApprove, setLoadingApprove] = React.useState(false);
  const [loadingReject, setLoadingReject] = React.useState(false);
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [isVerifyDataFetched, setIsVerifyDataFetched] = React.useState(false);
  const [onfidoMap, setOnfidoMap] = React.useState<onfido[]>([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerOnfido).length === 0) {
      dispatch(
        fetchCustomerOnfidoRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  const handleOnfidoClick = (flag: boolean) => {
    resetUpdateCustomerVerify();
    flag ? setLoadingApprove(true) : setLoadingReject(true);
    dispatch(
      updateCustomerVerifyRequest({
        customerId: customerProfile.customerId,
        canApproveUser: flag,
      })
    );
  };

  if (customerVerifyLoading && isVerifyDataFetched) {
    setIsVerifyDataFetched(false);
  } else if (
    !customerVerifyLoading &&
    Object.keys(customerVerify).length > 0 &&
    !isVerifyDataFetched
  ) {
    setIsVerifyDataFetched(true);
    setLoadingApprove(false);
    setLoadingReject(false);
  }

  if (customerOnfidoLoading) {
    if (isDataFetched) {
      setIsDataFetched(false);
    }
  } else if (!customerOnfidoLoading && !isDataFetched) {
    setIsDataFetched(true);
    if (Object.keys(customerOnfido).length > 0) {
      loadMap();
    } else {
      setOnfidoMap([]);
    }
  }

  function loadMap() {
    const onfidoDataMap: onfido[] = [
      {
        id: 'documentReportStatus',
        value: customerOnfido.documentReportStatus,
        label: 'Document Report Status',
      },
      {
        id: 'faceImageReportStatus',
        value: customerOnfido.faceImageReportStatus,
        label: 'Face Image Report Status',
      },
      {
        id: 'watchlistReportStatus',
        value: customerOnfido.watchlistReportStatus,
        label: 'Watch List Report Status',
      },
      {
        id: 'onfidoDocument',
        value: `https://dashboard.onfido.com/applicant/${customerOnfido.applicantId}/check/${customerOnfido.checkId}/reports`  ,
        label: 'Onfido Document',
      },
    ];
    setOnfidoMap(onfidoDataMap);
  }

  const openInNewTab = (url:any) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.boxTitle} onClick={handleExpandClick}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            Onfido Details
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {isDataFetched ? (
              <>
                {onfidoMap.length > 0 ? (
                  <>
                    {onfidoMap.map(({ label, value, id }) => {
                      return (
                        <Box className={classes.box} key={id}>
                          <Grid container className={classes.onfidoRow}>
                            <Grid item xs={8}>
                              {label}
                            </Grid>
                            <Grid item xs={4}>
                              {id === 'onfidoDocument' ?
                                <OpenInNewIcon
                                  color="action"
                                  fontSize="small"
                                  onClick={() => openInNewTab(value)}
                                ></OpenInNewIcon>
                                : <Box sx={{ color: 'text.secondary' }}>
                                  {value}
                                </Box>}
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                    {customerOnfido.showApprovalRejectionButton ? (
                      <UserPermission
                        component="OnBoardingRequest"
                        permissionFor="update"
                      >
                        <Box className={classes.approveRejectBtn}>
                          <LoadingButton
                            variant="outlined"
                            sx={{ fontSize: '0.7rem', p: 1 }}
                            color="success"
                            size="small"
                            onClick={() => handleOnfidoClick(true)}
                            loading={loadingApprove}
                            loadingPosition="end"
                            endIcon={<DoneIcon />}
                          >
                            Approve
                          </LoadingButton>
                          <LoadingButton
                            variant="outlined"
                            sx={{ fontSize: '0.7rem', p: 1, ml: 1 }}
                            color="error"
                            size="small"
                            onClick={() => handleOnfidoClick(false)}
                            loading={loadingReject}
                            loadingPosition="end"
                            endIcon={<ClearIcon />}
                          >
                            Reject
                          </LoadingButton>
                        </Box>
                      </UserPermission>
                    ) : null}
                  </>
                ) : (
                  <>
                    {customerOnfidoError ? (
                      <Alert severity="error">
                        {`Error loading Onfido Details - [${customerOnfidoError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No Onfido details found!</Alert>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box className={classes.box}>
                <SkeletonComponent></SkeletonComponent>
              </Box>
            )}
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerVerify: state.customerVerify.customerVerify,
    customerVerifyLoading: state.customerVerify.pending,
    customerOnfido: state.customerOnfido.customerOnfido,
    customerOnfidoLoading: state.customerOnfido.pending,
    customerOnfidoError: state.customerOnfido.error,
  };
};

export default connect(mapStateToProps)(OnfidoDetails);
