import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';

import { connect, useDispatch } from 'react-redux';

import { Token } from '../store/token/types';
import { CSVLink } from 'react-csv';
import Spinner from './Spinner';
import { fetchTokenDetailsRequest } from '../store/token/actions';
import { Countries } from '../store/countries/types';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeader: {
    background: '#fed700d1',
    fontFamily: 'Shell-Medium',
    border: '1px solid #aaaaaa',
  },
  tableBodyCell: {
    border: '1px solid #dddddd',
    fontFamily: 'Shell-Book',
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  downloadIcon: {
    '&:hover': {
      background: '#fbcf04d1',
    },
    background: '#fbcf04',
    alignItems: 'center',
    margin: theme.spacing(1, 2, 4, 2),
    width: 110,
    height: 40,
    fontFamily: 'Shell-Book',
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
}));

const headers = [
  { label: 'Token', key: 'code' },
  { label: 'Created On Date ', key: 'created' },
  { label: 'Status', key: 'status' },
  { label: 'Used on', key: 'used' },
  { label: 'Valid To', key: 'validTo' },
  { label: 'Customer', key: 'customer.maskedEmail' },
  { label: 'Customer Status', key: 'customer.isActive' },
  { label: 'Aggregator Name', key: 'deal.description' },
  { label: 'Country', key: 'deal.market.countryCode' },
];

function TokenTable(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    tokenDownload,
    countries,
    token,
    loading,
    page,
    tokenError,
  }: {
    tokenDownload: Token[];
    countries: Countries[];
    token: Token[];
    loading: boolean;
    tokenResponse: any;
    page: any;
    tokenError: string | null;
  } = props;

  if (loading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleChangePage = (_event: any, pageNo: any) => {
    props.searchPayload.pageNumber = pageNo + 1;
    dispatch(fetchTokenDetailsRequest(props.searchPayload));
  };

  return (
    <>
      <div className="search-result">
        <div className="search-result-text">Search Result</div>

        {!loading && tokenDownload.length > 0 ? (
          <CSVLink
            data={tokenDownload}
            filename={`TokenSearch-${new Date().getTime()}.csv`}
            headers={headers}
          >
            <Button
              variant="contained"
              aria-label="settings"
              className={classes.downloadIcon}
              title="Download"
            >
              <span>Download</span>
            </Button>
          </CSVLink>
        ) : null}
      </div>
      <ResponsiveContainer>
        {!loading && token.length > 0 ? (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table" key="table">
                <TableHead className={classes.tableHeader} key={'table-head'}>
                  <TableRow key="table-head-row">
                    <TableCell
                      key="No"
                      className={classes.tableHeader}
                      align="center"
                    >
                      No
                    </TableCell>
                    {headers.map((header: any) => {
                      return (
                        <TableCell
                          key={header.label}
                          className={classes.tableHeader}
                          align="center"
                        >
                          {header.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {token.map((row: any, i: number) => {
                    return (
                      <TableRow key={`table-${i + 1}-row`}>
                        <TableCell
                          key={`table-${i + 1}-cell`}
                          className={classes.tableBodyCell}
                          align="center"
                        >
                          {page.pageNumber === 1
                            ? i + 1
                            : page.pageNumber * page.pageSize -
                              page.pageSize +
                              i +
                              1}
                        </TableCell>
                        {headers.map((header: any) => {
                          return (
                            <TableCell
                              key={`table-${i + 1}-${header.label}-cell`}
                              className={classes.tableBodyCell}
                              align="center"
                              style={
                                header.key === 'customer.maskedEmail'
                                  ? { wordBreak: 'break-all' }
                                  : {}
                              }
                            >
                              {header.key === 'deal.market.countryCode'
                                ? countries.find(
                                    (item: any) =>
                                      item.code === _.get(row, header.key, '-')
                                  )?.name
                                : (header.key === 'created' ||
                                    header.key === 'used' && _.get(row, header.key) !== null) || header.key === 'validTo' &&
                                  _.get(row, header.key) !== null
                                ? new Date(
                                    _.get(row, header.key)
                                  ).toLocaleString()
                                : header.key === 'customer.isActive' &&
                                  _.get(row, 'customer', '-') !== null
                                ? _.get(row, header.key, '-')
                                  ? 'Active'
                                  : 'Inactive'
                                : _.get(row, header.key, '-') || '-'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[page.pageSize]}
              component="div"
              count={
                page.totalNoOfRecords
                  ? page.totalNoOfRecords
                  : page.totalPages * page.pageSize
              }
              rowsPerPage={page.pageSize}
              page={page.pageNumber - 1}
              backIconButtonProps={{
                color: 'secondary',
              }}
              nextIconButtonProps={{ color: 'secondary' }}
              onPageChange={handleChangePage}
            />
          </Paper>
        ) : (
          <Typography style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}>
            {tokenError ? (
              <Alert severity="error">
                {`Error loading Tokens - [${tokenError}]`}
              </Alert>
            ) : (
              'No Tokens found'
            )}
          </Typography>
        )}
      </ResponsiveContainer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    token: state.token.token,
    tokenError: state.token.error,
    page: state.token.page,
    loading: state.token.pending,
    tokenResponse: state.token.response,
  };
};
export default connect(mapStateToProps)(TokenTable);
