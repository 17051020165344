import {
    FETCH_CUSTOMER_GROUP_TYPE_REQUEST,
    FETCH_CUSTOMER_GROUP_TYPE_FAILURE,
    FETCH_CUSTOMER_GROUP_TYPE_SUCCESS,
    FETCH_MAILJET_CONFIGURATION_REQUEST,
    FETCH_MAILJET_CONFIGURATION_FAILURE,
    FETCH_MAILJET_CONFIGURATION_SUCCESS,
    RESET_FETCH_MAILJET_CONFIGURATION_DETAILS,
    DELETE_MAILJET_CONFIGURATION_REQUEST,
    DELETE_MAILJET_CONFIGURATION_FAILURE,
    DELETE_MAILJET_CONFIGURATION_SUCCESS,
    RESET_DELETE_MAILJET_CONFIGURATION_REQUEST,
    CREATE_MAILJET_CONFIGURATION_REQUEST,
    CREATE_MAILJET_CONFIGURATION_FAILURE,
    CREATE_MAILJET_CONFIGURATION_SUCCESS,
    FETCH_APP_CONFIGURATION_REQUEST,
    FETCH_APP_CONFIGURATION_SUCCESS,
    FETCH_APP_CONFIGURATION_FAILURE,
    FETCH_APP_CONFIGURATION_DETAILS_REQUEST,
    FETCH_APP_CONFIGURATION_DETAILS_SUCCESS,
    FETCH_APP_CONFIGURATION_DETAILS_FAILURE,
    RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST,
    CREATE_APP_CONFIGURATION_REQUEST,
    CREATE_APP_CONFIGURATION_SUCCESS,
    CREATE_APP_CONFIGURATION_FAILURE,
    RESET_CREATE_APP_CONFIGURATION_REQUEST,
    UPDATE_APP_CONFIGURATION_REQUEST,
    UPDATE_APP_CONFIGURATION_SUCCESS,
    UPDATE_APP_CONFIGURATION_FAILURE,
    RESET_UPDATE_APP_CONFIGURATION_REQUEST,
    DELETE_APP_CONFIGURATION_REQUEST,
    DELETE_APP_CONFIGURATION_FAILURE,
    DELETE_APP_CONFIGURATION_SUCCESS,
    RESET_DELETE_APP_CONFIGURATION_REQUEST,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS,
    RESET_ALL_OFFERINGPACKAGES_DETAILS,
    FETCH_ALL_MARKETS_REQUEST,
    FETCH_ALL_MARKETS_SUCCESS,
    FETCH_ALL_MARKETS_FAILURE,

  } from './actionTypes';
  import {
    FetchCustomerGroupTypesRequest,
    FetchCustomerGroupTypesSuccess,
    FetchCustomerGroupTypesFailure,
    FetchCustomerGroupTypesSuccessPayload,
    FetchCustomerGroupTypesFailurePayload,

    FetchMailjetConfiguartionRequest,
    FetchMailjetConfiguartionSuccess,
    FetchMailjetConfiguartionFailure,
    FetchMailjetConfiguartionSuccessPayload,
    FetchMailjetConfiguartionFailurePayload,

    DeleteMailjetConfiguartionRequest,
    DeleteMailjetConfiguartionSuccess,
    DeleteMailjetConfiguartionFailure,
    DeleteMailjetConfiguartionSuccessPayload,
    DeleteMailjetConfiguartionFailurePayload,
    DeleteMailjetConfiguartionRequestPayload,
    ResetDeleteMailjetConfiguartion,
    
    CreateMailjetConfiguartionRequest,
    CreateMailjetConfiguartionSuccess,
    CreateMailjetConfiguartionFailure,
    CreateMailjetConfiguartionSuccessPayload,
    CreateMailjetConfiguartionFailurePayload,
    CreateMailjetConfiguartionRequestPayload,
    FetchMailjetConfiguartionRequestPayload,

    FetchAppConfigurationRequest,
    FetchAppConfigurationFailure,
    FetchAppConfigurationSuccess,
    FetchAppConfigurationFailurePayload,
    FetchAppConfigurationSuccessPayload,

    FetchAppConfigurationDetailsRequest,
    FetchAppConfigurationDetailsFailure,
    FetchAppConfigurationDetailsSuccess,
    FetchAppConfigurationDetailsRequestPayload,
    FetchAppConfigurationDetailsFailurePayload,
    FetchAppConfigurationDetailsSuccessPayload,

    CreateAppConfigurationRequest,
    CreateAppConfigurationSuccess,
    CreateAppConfigurationFailure,
    CreateAppConfigurationSuccessPayload,
    CreateAppConfigurationFailurePayload,
    CreateAppConfigurationRequestPayload,


    UpdateAppConfigurationRequest,
    UpdateAppConfigurationSuccess,
    UpdateAppConfigurationFailure,
    UpdateAppConfigurationSuccessPayload,
    UpdateAppConfigurationFailurePayload,
    UpdateAppConfigurationRequestPayload,

    DeleteAppConfiguartionRequest,
    DeleteAppConfiguartionSuccess,
    DeleteAppConfiguartionFailure,
    DeleteAppConfiguartionSuccessPayload,
    DeleteAppConfiguartionFailurePayload,
    DeleteAppConfiguartionRequestPayload,
    ResetDeleteAppConfiguartion,

    FetchAllOfferingPackagesRequest,
    FetchAllOfferingPackagesFailure,
    FetchAllOfferingPackagesSuccess,
    FetchAllOfferingPackagesRequestPayload,
    FetchAllOfferingPackagesSuccessPayload,
    FetchAllOfferingPackagesFailurePayload,

    FetchAllMarketRequest,
    FetchAllMarketFailure,
    FetchAllMarketSuccess,
    FetchAllMarketFailurePayload,
    FetchAllMarketSuccessPayload,

  } from './types';
  
  export const createMailjetConfiguartionRequest = (payload:CreateMailjetConfiguartionRequestPayload): 
  CreateMailjetConfiguartionRequest => ({
    type: CREATE_MAILJET_CONFIGURATION_REQUEST,
    payload
   
  });
  
  export const createMailjetConfiguartionSuccess = (
    payload: CreateMailjetConfiguartionSuccessPayload
    ): CreateMailjetConfiguartionSuccess => ({
    type: CREATE_MAILJET_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const createMailjetConfiguartionFailure = (
    payload: CreateMailjetConfiguartionFailurePayload
  ): CreateMailjetConfiguartionFailure => ({
    type: CREATE_MAILJET_CONFIGURATION_FAILURE,
    payload,
  });

//---
  export const deleteMailjetConfiguartionRequest = (payload:DeleteMailjetConfiguartionRequestPayload): 
  DeleteMailjetConfiguartionRequest => ({
    type: DELETE_MAILJET_CONFIGURATION_REQUEST,
    payload
   
  });
  
  export const deleteMailjetConfiguartionSuccess = (
    payload: DeleteMailjetConfiguartionSuccessPayload
    ): DeleteMailjetConfiguartionSuccess => ({
    type: DELETE_MAILJET_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const deleteMailjetConfiguartionFailure = (
    payload: DeleteMailjetConfiguartionFailurePayload
  ): DeleteMailjetConfiguartionFailure => ({
    type: DELETE_MAILJET_CONFIGURATION_FAILURE,
    payload,
  });

  export const resetDeleteMailjetConfiguartion  = () => ({
    type: RESET_DELETE_MAILJET_CONFIGURATION_REQUEST,
  });

  export const resetFetchMailjetConfigurationDetails = () => ({
    type: RESET_FETCH_MAILJET_CONFIGURATION_DETAILS,
  });

  //---
  export const fetchCustomerGroupTypesRequest = (): FetchCustomerGroupTypesRequest => ({
    type: FETCH_CUSTOMER_GROUP_TYPE_REQUEST,
  });
  
  export const fetchCustomerGroupTypesSuccess = (
    payload: FetchCustomerGroupTypesSuccessPayload
  ): FetchCustomerGroupTypesSuccess => ({
    type: FETCH_CUSTOMER_GROUP_TYPE_SUCCESS,
    payload,
  });
  
  export const fetchCustomerGroupTypesFailure = (
    payload: FetchCustomerGroupTypesFailurePayload
  ): FetchCustomerGroupTypesFailure => ({
    type: FETCH_CUSTOMER_GROUP_TYPE_FAILURE,
    payload,
  });

//---------------
  export const fetchMailjetConfiguartionRequest = (
    payload:FetchMailjetConfiguartionRequestPayload
    ): FetchMailjetConfiguartionRequest => ({
    type: FETCH_MAILJET_CONFIGURATION_REQUEST,
    payload,
  });
  
  export const fetchMailjetConfiguartionSuccess = (
    payload: FetchMailjetConfiguartionSuccessPayload
  ): FetchMailjetConfiguartionSuccess => ({
    type: FETCH_MAILJET_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const fetchMailjetConfiguartionFailure = (
    payload: FetchMailjetConfiguartionFailurePayload
  ): FetchMailjetConfiguartionFailure => ({
    type: FETCH_MAILJET_CONFIGURATION_FAILURE,
    payload,
  });

  //-----

  export const deleteAppConfiguartionRequest = (payload:DeleteAppConfiguartionRequestPayload): 
  DeleteAppConfiguartionRequest => ({
    type: DELETE_APP_CONFIGURATION_REQUEST,
    payload
   
  });
  
  export const deleteAppConfiguartionSuccess = (
    payload: DeleteAppConfiguartionSuccessPayload
    ): DeleteAppConfiguartionSuccess => ({
    type: DELETE_APP_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const deleteAppConfiguartionFailure = (
    payload: DeleteAppConfiguartionFailurePayload
  ): DeleteAppConfiguartionFailure => ({
    type: DELETE_APP_CONFIGURATION_FAILURE,
    payload,
  });

  export const resetDeleteAppConfiguartion  = () => ({
    type: RESET_DELETE_APP_CONFIGURATION_REQUEST,
  });

  //-----
  export const updateAppConfigurationRequest = (payload:UpdateAppConfigurationRequestPayload): 
  UpdateAppConfigurationRequest => ({
    type: UPDATE_APP_CONFIGURATION_REQUEST,
    payload 
  });
  
  export const updateAppConfigurationSuccess = (
    payload: UpdateAppConfigurationSuccessPayload
    ): UpdateAppConfigurationSuccess => ({
    type: UPDATE_APP_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const updateAppConfigurationFailure = (
    payload: UpdateAppConfigurationFailurePayload
  ): UpdateAppConfigurationFailure => ({
    type: UPDATE_APP_CONFIGURATION_FAILURE,
    payload,
  });

  export const resetUpdateAppConfigurationRequest  = () => ({
    type: RESET_UPDATE_APP_CONFIGURATION_REQUEST,
  });
  
  //-----

  export const fetchAppConfigurationRequest = (): FetchAppConfigurationRequest => ({
    type: FETCH_APP_CONFIGURATION_REQUEST,
  });
  
  export const fetchAppConfigurationSuccess = (
    payload: FetchAppConfigurationSuccessPayload
  ): FetchAppConfigurationSuccess => ({
    type: FETCH_APP_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const fetchAppConfigurationFailure = (
    payload: FetchAppConfigurationFailurePayload
  ): FetchAppConfigurationFailure => ({
    type: FETCH_APP_CONFIGURATION_FAILURE,
    payload,
  });

  //-----
  
  export const fetchAppConfigurationDetailsRequest = (
    payload: FetchAppConfigurationDetailsRequestPayload
  ): FetchAppConfigurationDetailsRequest => ({
    type: FETCH_APP_CONFIGURATION_DETAILS_REQUEST,
    payload,
  });
  
  export const fetchAppConfigurationDetailsSuccess = (
    payload: FetchAppConfigurationDetailsSuccessPayload
  ): FetchAppConfigurationDetailsSuccess => ({
    type: FETCH_APP_CONFIGURATION_DETAILS_SUCCESS,
    payload,
  });
  
  export const fetchAppConfigurationDetailsFailure = (
    payload: FetchAppConfigurationDetailsFailurePayload
  ): FetchAppConfigurationDetailsFailure => ({
    type: FETCH_APP_CONFIGURATION_DETAILS_FAILURE,
    payload,
  });

  export const resetFetchAppConfigurationDetailsRequest = () => ({
    type: RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST,
  });

  //---
  
  export const createAppConfigurationRequest = (payload:CreateAppConfigurationRequestPayload): 
  CreateAppConfigurationRequest => ({
    type: CREATE_APP_CONFIGURATION_REQUEST,
    payload 
  });
  
  export const createAppConfigurationSuccess = (
    payload: CreateAppConfigurationSuccessPayload
    ): CreateAppConfigurationSuccess => ({
    type: CREATE_APP_CONFIGURATION_SUCCESS,
    payload,
  });
  
  export const createAppConfigurationFailure = (
    payload: CreateAppConfigurationFailurePayload
  ): CreateAppConfigurationFailure => ({
    type: CREATE_APP_CONFIGURATION_FAILURE,
    payload,
  });

  export const resetCreateAppConfigurationRequest  = () => ({
    type: RESET_CREATE_APP_CONFIGURATION_REQUEST,
  });
  
  //------

  export const fetchAllOfferingPackagesRequest = (
    payload: FetchAllOfferingPackagesRequestPayload
  ): FetchAllOfferingPackagesRequest => ({
    type: FETCH_ALL_OFFERINGPACKAGES_REQUEST,
    payload,
  });
  
  export const fetchAllOfferingPackagesSuccess = (
    payload: FetchAllOfferingPackagesSuccessPayload
  ): FetchAllOfferingPackagesSuccess => ({
    type: FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS,
    payload,
  });
  
  export const fetchAllOfferingPackagesFailure = (
    payload: FetchAllOfferingPackagesFailurePayload
  ): FetchAllOfferingPackagesFailure => ({
    type: FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE,
    payload,
  });

  export const resetAllOfferingPackagesDetails  = () => ({
    type: RESET_ALL_OFFERINGPACKAGES_DETAILS,
  });

    //------

    export const fetchAllmarketsRequest = (): FetchAllMarketRequest => ({
      type: FETCH_ALL_MARKETS_REQUEST,
    });
    
    export const fetchAllMarketSuccess = (
      payload: FetchAllMarketSuccessPayload
    ): FetchAllMarketSuccess => ({
      type: FETCH_ALL_MARKETS_SUCCESS,
      payload,
    });
    
    export const fetchAllMarketFailure = (
      payload: FetchAllMarketFailurePayload
    ): FetchAllMarketFailure => ({
      type: FETCH_ALL_MARKETS_FAILURE,
      payload,
    });
  