import React from 'react';
import { AppBar, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Footer from './Footer';
import Image from '../../public/assets/images/logo.svg';
import backgroundImg from '../../public/assets/images/Background.jpg';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: '7vh',
    minHeight: '85vh',
    background: `url(${backgroundImg}) no-repeat`,
    backgroundSize: '100% 100%',
  },
  appBar: {
    background: '#face00',
    color: '#484848',
    position: 'fixed',
  },
  title: {
    width: '100%',
    margin: '0px 5px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
  },
  toolbar: { paddingLeft: '14px', paddingRight: '26px' },
  container: {
    textAlign: 'center',
    fontFamily: 'Shell-Book',
    border: 0,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  footerParent: {
    borderTop: '1px solid #e0e0e0',
  },
  text: {
    '& div ': { color: '#484848' },
    '& div a': {
      color: '#e1251b',
    },
    margin: 10,
    background: '#facf00ae',
    padding: 10,
  },
}));

const Logout = () => {
  const classes = useStyles();
  const env =
    process.env.NODE_ENV === 'production'
      ? ''
      : process.env.NODE_ENV === 'development'
      ? '- QA'
      : '- UAT';

  return (
    <>
      <div className={classes.container}>
        <AppBar className={classes.appBar} position="absolute">
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbar}></div>
            <Typography
              className={classes.title}
              color="inherit"
              component="div"
              noWrap
              variant="h6"
            >
              <div className="header-shell-logo">
                <img src={Image} alt="SFALogo" />
                <div className="header-logo-text">
                  <span className="text1">Shell </span>
                  <span className="text2">Fleet App</span>
                </div>
              </div>
              <div className="header-text">Operations Portal {env}</div>
              <div style={{ width: 255 }}></div>
            </Typography>
            <div className={classes.toolbar}></div>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Grid item xs={12}>
            <Paper variant="elevation" className={classes.text}>
              <div>Logged out Successfully</div>
              <div>
                <a href="/admin"> Click to Login again </a>
              </div>
            </Paper>
          </Grid>
        </div>
      </div>
      <div className={classes.footerParent}>
        <Footer />
      </div>
    </>
  );
};

export default Logout;
