import {
  CREATE_TOKENS_FAILURE,
  CREATE_TOKENS_REQUEST,
  CREATE_TOKENS_SUCCESS,
  FETCH_TOKEN_DETAILS_FAILURE,
  FETCH_TOKEN_DETAILS_REQUEST,
  FETCH_TOKEN_DETAILS_SUCCESS,
  RESET_CREATE_TOKENS_REQUEST,
  RESET_FETCH_TOKEN_DETAILS,
} from './actionTypes';
import { CreatedTokenState, TokenDetailsActions, TokenState } from './types';

const initialStateToken: TokenState = {
  pending: false,
  token: [],
  error: null,
  response: {},
};

const tokenSearch = (
  state = initialStateToken,
  action: TokenDetailsActions
) => {
  switch (action.type) {
    case FETCH_TOKEN_DETAILS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
        page: {},
      };
    case FETCH_TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        token: action.payload.token,
        error: null,
        response: action.payload.response,
        page: action.payload.page,
      };
    case FETCH_TOKEN_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        token: [],
        response: {},
        page: {},
        error: action.payload.error,
      };
    case RESET_FETCH_TOKEN_DETAILS:
      return {
        ...state,
        pending: false,
        token: [],
        response: {},
        page: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCreatedToken: CreatedTokenState = {
  pending: false,
  createdToken: [],
  error: null,
  response: {},
};

const createdToken = (
  state = initialStateCreatedToken,
  action: TokenDetailsActions
) => {
  switch (action.type) {
    case CREATE_TOKENS_REQUEST:
      return {
        ...state,
        createdToken: [],
        pending: true,
        response: {},
      };
    case CREATE_TOKENS_SUCCESS:
      return {
        ...state,
        pending: false,
        createdToken: action.payload.token,
        error: null,
        response: action.payload.response,
      };
    case CREATE_TOKENS_FAILURE:
      return {
        ...state,
        pending: false,
        createdToken: [],
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_TOKENS_REQUEST:
      return {
        ...state,
        pending: false,
        createdToken: [],
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export { tokenSearch, createdToken };
