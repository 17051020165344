import * as React from 'react';
import {
    TextField, Button, Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { updateUserNameRequest, resetUpdateUserName } from '../../store/user/actions';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    spinnerParent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 120,
    },
    input: {
        color: '#cb3931',
        fontWeight: 600,
        margin: '0px',
    },
    boxStyle: {
        border: '1px solid #bdbdbd',
        width: '100%',
        padding: "5px 5px 0px 5px",
        height: 'fit-content'
    },
    boxStyle1: {
        width: '100%',
        padding: "5px",
        height: 'fit-content'
    },
    editButton: {
        margin: theme.spacing(2, 1, 1, 0),
        color: '#000000cf',
        backgroundColor: '#fbcf04',
        height: '2rem',
        fontFamily: 'Shell-Book',
        '&:hover': { background: '#fbcf04d1' },
        width: '6rem',
    },
    nameBox: {
        display: 'grid',
        width: '15rem',
        paddingLeft: '-5px',
    },
    helperText: {
        fontSize: '12px',
        color: '#b1b1b1',
        textAlign: 'left',
        fontWeight: 500,
    },
    nameText: {
        padding: '5px 0',
    },
}));

const EditUserName = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        customerProfile, userNameDetails
    } = props;

    const name: any = {
        firstName: customerProfile.name.firstName,
        middleName: customerProfile.name.middleName,
        lastName: customerProfile.name.lastName
    };

    const [isEditable, setIsEditable] = React.useState(false);
    const [userName, setUserName] = React.useState(name);
    const [previosUserNameValue, setPreviosUserNameValue] = React.useState(name);

    React.useEffect(() => {
        if(userNameDetails?.error) {
            setUserName(previosUserNameValue);
        }
        if(userNameDetails?.userNameDetails?.success) {
            setPreviosUserNameValue(userName);
        }
      }, [userNameDetails]);

    const handleSaveButton = async () => {
        setIsEditable(false);
        await dispatch(
            updateUserNameRequest({
                id: customerProfile.customerId,
                firstName: userName.firstName,
                middleName: userName.middleName,
                lastName: userName.lastName,
            })
        );
    }

    const handleChange = (e: any) => {
        let obj = {
            ...userName,
            ...{ [e.target.name]: e.target.value }
        };
        setUserName(obj)
    }
    return (
        <>
            {userNameDetails?.pending ?
                <div className={classes.spinnerParent}>
                    <Spinner custom />
                </div> :
                <><Typography
                    sx={{
                        fontSize: 14,
                        display: 'inline-flex',
                        wordBreak: 'break-word',
                        fontWeight: 600,
                        color: '#595959',
                        width: "100%"
                    }}
                    component="div"
                >
                    <div className={customerProfile.status === 'Pending' && isEditable ? classes.boxStyle : classes.boxStyle1}>
                        <div style={{ display: 'flex' }}>
                            <Box className={classes.nameBox}>
                                <span className={classes.helperText}>First Name</span>
                                {isEditable ? <TextField
                                    key={`firstName-textfield`}
                                    name={'firstName'}
                                    variant="outlined"
                                    size="small"
                                    value={userName.firstName}
                                    onChange={handleChange} /> :
                                    <span className={classes.nameText}>
                                        {userName.firstName ? userName.firstName : '-'}
                                    </span>}
                            </Box>
                            <Box className={classes.nameBox} sx={{ ml: 1 }}>
                                <span className={classes.helperText}>Middle Name</span>
                                {isEditable ? <TextField
                                    key={`middleName-textfield`}
                                    name={'middleName'}
                                    variant="outlined"
                                    size="small"
                                    value={userName.middleName}
                                    onChange={handleChange} /> :
                                    <span className={classes.nameText}>
                                        {userName.middleName ? userName.middleName : '-'}
                                    </span>}
                            </Box>
                            <Box className={classes.nameBox} sx={{ ml: 1 }}>
                                <span className={classes.helperText}>Last Name</span>
                                {isEditable ? <TextField
                                    key={`lastName-textfield`}
                                    name={'lastName'}
                                    variant="outlined"
                                    size="small"
                                    value={userName.lastName}
                                    onChange={handleChange} /> :
                                    <span className={classes.nameText}>
                                        {userName.lastName ? userName.lastName : '-'}
                                    </span>}
                            </Box>
                            {!isEditable && !userNameDetails?.error &&
                                <Tooltip title="Edit Customer Name" arrow>
                                    <EditIcon color="action" fontSize="small" style={{ marginRight: '10px' }} onClick={() => setIsEditable(true)} />
                                </Tooltip>
                            }
                        </div>
                        <>
                            {customerProfile.status === 'Pending' &&
                                <>
                                    {isEditable && <>
                                        <Button
                                            key={'MO-Save-button'}
                                            color="default"
                                            onClick={handleSaveButton}
                                            variant="contained"
                                            className={classes.editButton}>
                                            Save
                                        </Button>
                                        <Button
                                            key={'MO-cancel-button'}
                                            color="default"
                                            onClick={() => setIsEditable(false)}
                                            variant="contained"
                                            className={classes.editButton}>
                                            Cancel
                                        </Button>
                                    </>}
                                </>}
                            {Object.keys(userNameDetails?.userNameDetails).length > 0 && <Alert style={{ marginTop: "5px", width: "100%" }} onClose={() => { dispatch(resetUpdateUserName()); }} key={'DMS-handleAlert-success'} severity={"success"}>
                                {'Customer Name Updated Successfully'}
                            </Alert>}

                            {userNameDetails.error && <Alert style={{ marginTop: "5px", width: "100%" }} onClose={() => { dispatch(resetUpdateUserName()); }} key={'DMS-handleAlert-error'} severity={"error"}>
                                {`Error updating Customer Name - [${userNameDetails?.error}]`}
                            </Alert>}
                        </>
                    </div>
                </Typography>
                </>}
        </>
    );
};
export default EditUserName;
