import {
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_SUCCESS,
} from './actionTypes';
import {
  FetchCountriesRequest,
  FetchCountriesSuccess,
  FetchCountriesFailure,
  FetchCountriesSuccessPayload,
  FetchCountriesFailurePayload,
} from './types';

export const fetchCountriesRequest = (): FetchCountriesRequest => ({
  type: FETCH_COUNTRIES_REQUEST,
});

export const fetchCountriesSuccess = (
  payload: FetchCountriesSuccessPayload
): FetchCountriesSuccess => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload,
});

export const fetchCountriesFailure = (
  payload: FetchCountriesFailurePayload
): FetchCountriesFailure => ({
  type: FETCH_COUNTRIES_FAILURE,
  payload,
});
