import { combineReducers } from 'redux';
import { countriesReducer } from './countries/reducer';
import {
  customerGroupTypesReducer,
  mailjetConfiguartionReducer,
  deleteMailjetConfiguartionReducer,
  createMailjetConfiguartionReducer,
  appConfigReducer,
  appConfigDetailsReducer,
  createAppConfiguartionReducer,
  deleteAppConfiguartionReducer,
  updateAppConfiguartionReducer,
  getOfferingPackagesReducer,
  getAllMarketsReducer
} from './configurations/reducer';
import {
  fetchDealsReducer,
  createDealsReducer,
  fetchDealDetailsReducer,
  updateDealsReducer,
  fetchAllDealsReducer,
  createOffersReducer,
  updateOffersReducer
} from './deals/reducer';
import { tokenSearch, createdToken } from './token/reducer';
import {
  userSearch,
  fetchCustomerProfileReducer,
  fetchCustomerOnboardingReducer,
  fetchCustomerGfnaccountReducer,
  fetchCustomerIpaasReducer,
  fetchCustomerPayCardRegReducer,
  fetchCustomercustomerAddressReducer,
  fetchCustomerInvoiceDetailsReducer,
  fetchChargebackInvoiceDetailsReducer,
  updateCustomerVerifyReducer,
  fetchCustomerCardsReducer,
  fetchCustomerBusinessReducer,
  deleteCustomerReducer,
  blockCustomerReducer,
  fetchCustomerH3InvoiceReducer,
  fetchCustomerOnfidoReducer,
  accountCloseReducer,
  fetchSOAReducer,
  fetchChargebackSOADetailsReducer,
  fetchSOADetailsReducer,
  fetchCustomerDetailsReducer,
  updateUserNameReducer
} from './user/reducer';
import {
  offeringPackageReducer,
  createFeeGroupReducer,
  createPriceProfileReducer,
  createTokenTypeReducer,
  createCardTypeReducer,
  fetchFeeGroupReducer,
  fetchPriceProfileReducer,
  updateFeeGroupReducer,
  updatePriceProfileReducer,
  updateTokenTypeReducer,
  fetchTokenTypeReducer,
  updateCardTypeReducer,
  fetchCardTypeReducer,
  createLegalEntityReducer,
  updateLegalEntityReducer,
  fetchLegalEntityReducer,
} from './offeringPackages/reducer';

const rootReducer = combineReducers({
  customerGroupTypeList:customerGroupTypesReducer,
  mailjetConfiguartionList:mailjetConfiguartionReducer,
  deleteMailjetConfiguration:deleteMailjetConfiguartionReducer,
  createMailjetConfiguration:createMailjetConfiguartionReducer,
  customerGroupTypeList:customerGroupTypesReducer,
  mailjetConfiguartionList:mailjetConfiguartionReducer,
  deleteMailjetConfiguration:deleteMailjetConfiguartionReducer,
  createMailjetConfiguration:createMailjetConfiguartionReducer,
  countries: countriesReducer,
  deals: fetchDealsReducer,
  dealDetails: fetchDealDetailsReducer,
  dealCreated: createDealsReducer,
  dealUpdated: updateDealsReducer,
  token: tokenSearch,
  user: userSearch,
  tokensCreated: createdToken,
  offeringPackage: offeringPackageReducer,
  feeGroupCreated: createFeeGroupReducer,
  priceProfileCreated: createPriceProfileReducer,
  tokenTypeCreated: createTokenTypeReducer,
  cardTypeCreated: createCardTypeReducer,
  feeGroups: fetchFeeGroupReducer,
  priceProfiles: fetchPriceProfileReducer,
  feeGroupUpdated: updateFeeGroupReducer,
  priceProfileUpdated: updatePriceProfileReducer,
  tokenTypeUpdated: updateTokenTypeReducer,
  tokenTypes: fetchTokenTypeReducer,
  cardTypeUpdated: updateCardTypeReducer,
  cardTypes: fetchCardTypeReducer,
  legalEntityCreated: createLegalEntityReducer,
  legalEntityUpdated: updateLegalEntityReducer,
  legalEntities: fetchLegalEntityReducer,
  customerProfile: fetchCustomerProfileReducer,
  customerOnboarding: fetchCustomerOnboardingReducer,
  customerGfnaccount: fetchCustomerGfnaccountReducer,
  customerIpaas: fetchCustomerIpaasReducer,
  customerPayCardReg: fetchCustomerPayCardRegReducer,
  customerAddress: fetchCustomercustomerAddressReducer,
  customerInvoiceDetails: fetchCustomerInvoiceDetailsReducer,
  customerVerify: updateCustomerVerifyReducer,
  customerCards: fetchCustomerCardsReducer,
  customerBusiness: fetchCustomerBusinessReducer,
  customerDelete: deleteCustomerReducer,
  customerBlock: blockCustomerReducer,
  customerH3Invoice: fetchCustomerH3InvoiceReducer,
  customerOnfido: fetchCustomerOnfidoReducer,
  chargebacks:fetchChargebackInvoiceDetailsReducer,
  accountClose: accountCloseReducer,
  soaDetails: fetchSOADetailsReducer,
  soaChargeback: fetchChargebackSOADetailsReducer,
  customerDetails: fetchCustomerDetailsReducer,
  allDeals:fetchAllDealsReducer,
  offersCreated: createOffersReducer,
  offersUpdated: updateOffersReducer,
  userUpdated: updateUserNameReducer,
  appConfigTypeList: appConfigReducer,
  appConfigDetailsList: appConfigDetailsReducer,
  createAppConfiguration:createAppConfiguartionReducer,
  deleteAppConfiguration: deleteAppConfiguartionReducer,
  updateAppConfiguration: updateAppConfiguartionReducer,
  offeringPackagesList: getOfferingPackagesReducer,
  allMatrketsList: getAllMarketsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
