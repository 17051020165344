import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import UserAddress from './UserAddress';
import Collapse from '@mui/material/Collapse';
import { connect, useDispatch } from 'react-redux';
import { fetchCustomerAddressRequest } from '../../store/user/actions';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditUserName from './EditUserName';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    width: '100%',
    boxShadow: 'none !important',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px !important',
  },
  box: {
    width: '100%',
    textAlign: 'left',
    paddingBottom: '3px',
    '& .MuiDivider-root': {
      marginTop: '8px',
      marginBottom: '8px',
    },
  },
  profileIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  addressText: {
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline', textDecorationColor: '#3363A0' },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  skeleton: {
    width: '100%',
  },
  onfidoLabel: {
    border: '1px solid #8cbae8',
    backgroundColor: '#8cbae8',
    color: '#fff',
  },
  nameBox: {
    display: 'grid',
    width: 'fit-content',
    minWidth: '90px',
  },
  helperText: {
    fontSize: '12px',
    color: '#b1b1b1',
    textAlign: 'left',
    fontWeight: 500,
  },
  nameText: {
    padding: '5px 0',
  },
  collapse: {
    padding: '8px',
    backgroundColor: '#F7F7F7',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  cardAddress: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
}));

interface userProfileInterface {
  id: string;
  value: any;
  label: string;
  color: string;
}

const colorMap: any = {
  New: '#7F7F7F',
  FailedApplication: '#E44A4D',
  Active: '#339D69',
  Closed: '#E44A4D',
  Pending: '#EF9F37',
  ReadOnlyAccess: '#7F7F7F',
};

function UserProfile(props: any) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const {
    customerProfile,
    customerProfileLoading,
    customerAddress,
    customerAddressLoading,
    customerAddressError,
    userNameDetails
  } = props;
  const [isDataFetched, setIsDataFetched] = React.useState(false);

  const [customerMap, setCustomerMap] = React.useState<userProfileInterface[]>(
    []
  );

  function loadMap() {
    const userProfileDetailsMap: userProfileInterface[] = [
      {
        id: 'token',
        value: customerProfile.token,
        label: 'Token',
        color: '',
      },
      {
        id: 'customerGroup',
        value: customerProfile.customerGroup,
        label: 'Customer Group',
        color: '',
      },
      {
        id: 'deal',
        value: customerProfile.deal,
        label: 'Deal',
        color: '',
      },
      {
        id: 'customerType',
        value: customerProfile.customerType,
        label: 'Customer Type',
        color: '',
      },
      {
        id: 'status',
        value:
          (customerProfile.status || '-') +
          '(' +
          customerProfile.statusId +
          ')',
        label: 'Status',
        color: colorMap[customerProfile.status],
      },
      {
        id: 'subStatus',
        value:
          (customerProfile.subStatus || '-') +
          '(' +
          customerProfile.subStatusId +
          ')',
        label: 'Sub Status',
        color: '',
      },
    ];
    setCustomerMap(userProfileDetailsMap);
  }

  if (customerProfileLoading) {
    if (isDataFetched) {
      setIsDataFetched(false);
    }
  } else if (
    !customerProfileLoading &&
    Object.keys(customerProfile).length > 0 &&
    !isDataFetched
  ) {
    loadMap();
    setIsDataFetched(true);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerAddress).length === 0) {
      dispatch(
        fetchCustomerAddressRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };
  
  return (
    <div className={classes.root}>
      {isDataFetched ? (
        <Card className={classes.card}>
          <CardContent className={classes.cardContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'left' }}>
                  {pathname==='/admin/user-management' && customerProfile.status === 'Pending' ?
                    <EditUserName customerProfile={customerProfile} userNameDetails={userNameDetails}/>
                   :
                      <Typography
                    sx={{
                      fontSize: 14,
                      display: 'inline-flex',
                      wordBreak: 'break-word',
                      fontWeight: 600,
                      color: '#595959',
                    }}
                    component="div"
                  >
                    <Box className={classes.nameBox}>
                      <span className={classes.helperText}>First Name</span>
                      <span className={classes.nameText}>
                        {customerProfile.name.firstName || '-'}
                      </span>
                    </Box>
                    <Box className={classes.nameBox} sx={{ ml: 1 }}>
                      <span className={classes.helperText}>Middle Name</span>
                      <span className={classes.nameText}>
                        {customerProfile.name.middleName || '-'}
                      </span>
                    </Box>
                    <Box className={classes.nameBox} sx={{ ml: 1 }}>
                      <span className={classes.helperText}>Last Name</span>
                      <span className={classes.nameText}>
                        {customerProfile.name.lastName || '-'}
                      </span>
                    </Box>
                  </Typography>}
                  <Grid container style={{ paddingTop: '5px' }}>
                    <Grid item xs={3}>
                      <span> Email</span>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          overflowWrap: 'break-word',
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {customerProfile.emailId || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <span> DOB</span>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {customerProfile.doB || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <span>Mobile</span>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {customerProfile.mobileNumber || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={classes.box}>
                  {customerMap.map(({ label, value, id, color }) => {
                    return (
                      <Box className={classes.box} key={id}>
                        <Grid container>
                          <Grid item xs={5}>
                            {label}
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                color: color || 'text.secondary',
                                wordBreak: 'break-word',
                              }}
                            >
                              {value || '-'}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.cardAddress}>
                  <Box className={classes.boxTitle} onClick={handleExpandClick}>
                    <Typography
                      component="div"
                      sx={{
                        textAlign: 'left',
                        fontFamily: 'Shell-Book',
                        fontWeight: expanded ? '600 !important' : '',
                        color: expanded ? '#595959' : '',
                        fontSize: '.9rem',
                      }}
                    >
                      Address
                    </Typography>
                    {!expanded ? (
                      <ControlPointIcon
                        color="action"
                        fontSize="small"
                      ></ControlPointIcon>
                    ) : (
                      <RemoveCircleOutlineIcon
                        color="action"
                        fontSize="small"
                      ></RemoveCircleOutlineIcon>
                    )}
                  </Box>

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
                    <Box className={classes.collapse}>
                      {!customerAddressLoading ? (
                        Object.keys(customerAddress).length > 0 ? (
                          <UserAddress
                            customerAddressData={customerAddress}
                          ></UserAddress>
                        ) : (
                          <div style={{ padding: '8px' }}>
                            {customerAddressError ? (
                              <Alert severity="error">
                                {`Error loading Customer Address - [${customerAddressError}]`}
                              </Alert>
                            ) : (
                              <Alert severity="info">
                                No customer address found!
                              </Alert>
                            )}
                          </div>
                        )
                      ) : (
                        <div style={{ padding: '8px' }}>
                          <SkeletonComponent></SkeletonComponent>
                        </div>
                      )}
                    </Box>
                  </Collapse>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerProfileLoading: state.customerProfile.pending,
    customerAddress: state.customerAddress.customerAddress,
    customerAddressLoading: state.customerAddress.pending,
    customerAddressError: state.customerAddress.error,
    userNameDetails: state.userUpdated
  };
};

export default connect(mapStateToProps)(UserProfile);
