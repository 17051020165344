import {
  CREATE_TOKENS_FAILURE,
  CREATE_TOKENS_REQUEST,
  CREATE_TOKENS_SUCCESS,
  FETCH_TOKEN_DETAILS_FAILURE,
  FETCH_TOKEN_DETAILS_REQUEST,
  FETCH_TOKEN_DETAILS_SUCCESS,
  RESET_CREATE_TOKENS_REQUEST,
  RESET_FETCH_TOKEN_DETAILS,
} from './actionTypes';
import {
  CreateTokenDetailsFailure,
  CreateTokenDetailsRequest,
  CreateTokenRequestPayload,
  CreateTokenSuccess,
  FetchTokenDetailsFailure,
  FetchTokenDetailsFailurePayload,
  FetchTokenDetailsRequest,
  FetchTokenDetailsRequestPayload,
  FetchTokenDetailsSuccess,
  FetchTokenDetailsSuccessPayload,
} from './types';

export const fetchTokenDetailsRequest = (
  payload: FetchTokenDetailsRequestPayload
): FetchTokenDetailsRequest => ({
  type: FETCH_TOKEN_DETAILS_REQUEST,
  payload,
});

export const fetchTokenDetailsSuccess = (
  payload: FetchTokenDetailsSuccessPayload
): FetchTokenDetailsSuccess => ({
  type: FETCH_TOKEN_DETAILS_SUCCESS,
  payload,
});

export const fetchTokenDetailsFailure = (
  payload: FetchTokenDetailsFailurePayload
): FetchTokenDetailsFailure => ({
  type: FETCH_TOKEN_DETAILS_FAILURE,
  payload,
});

export const createTokenRequest = (
  payload: CreateTokenRequestPayload
): CreateTokenDetailsRequest => ({
  type: CREATE_TOKENS_REQUEST,
  payload,
});

export const createTokenSuccess = (
  payload: FetchTokenDetailsSuccessPayload
): CreateTokenSuccess => ({
  type: CREATE_TOKENS_SUCCESS,
  payload,
});

export const createTokenFailure = (
  payload: FetchTokenDetailsFailurePayload
): CreateTokenDetailsFailure => ({
  type: CREATE_TOKENS_FAILURE,
  payload,
});
export const resetCreateTokenRequest = () => ({
  type: RESET_CREATE_TOKENS_REQUEST,
});

export const resetFetchTokenDetails = () => ({
  type: RESET_FETCH_TOKEN_DETAILS,
});
