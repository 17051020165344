import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { EachItem } from './OfferingPackageForm';
import _ from 'lodash';
import { FormikProps } from 'formik';
import { CreateDealsPayload } from '../store/deals/types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
    //zIndex: 99999,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 0px 28px',
  },
}));

export default function MasterSelectItems(props: {
  item: EachItem;
  formik: FormikProps<CreateDealsPayload>;
  offeringPackage: any;
  countryValue?: string;
  isDealEdit?: boolean;
  isAddOffers?: boolean;
  isEditOffer?: boolean
}) {
  const { item, formik, offeringPackage, isDealEdit, isAddOffers, isEditOffer } = props;
  const classes = useStyles();
  let offeringPackageUpdated = {...offeringPackage};
  offeringPackageUpdated.billingFrequency = offeringPackageUpdated.billingFrequency.sort((a:any, b:any) => a.id - b.id);

  const formikError =
    _.get(formik.touched, item.key) && Boolean(_.get(formik.errors, item.key));
  const errorText =
    _.get(formik.touched, item.key) && _.get(formik.errors, item.key, '');

  return (
    <>
      <FormControl
        key={`DM-${item.key}-formcontrol`}
        variant="outlined"
        className={classes.formControl}
        size="small"
        error={formikError}
      >
        <InputLabel
          key={`DM-${item.key}-input-label`}
          id={`DM-${item.key}-select-label`}
        >
          {item.label}
        </InputLabel>
        {
          <>
            <Select
              key={`DM-${item.key}-select`}
              name={item.key}
              labelId={`DM-${item.key}-select-label`}
              id={`DM-${item.key}-select`}
              multiple={item.type ? true : false}
              value={
                _.get(formik.values, item.key) === 0
                  ? parseInt(_.get(formik.values, item.key))
                  : _.get(formik.values, item.key) || ''
              }
              onChange={formik.handleChange}
              label={item.label}
              onBlur={formik.handleBlur}
              input={<OutlinedInput label={item.label} />}
              renderValue={
                item.type
                  ? (selected: any) => {
                      return selected
                        .map((each: any) =>
                          _.get(offeringPackageUpdated, item.key)
                            .filter((itm: any) => itm.id === each)
                            .map((it: any) => it.name)
                        )
                        .join(', ');
                    }
                  : undefined
              }
              disabled={isDealEdit || isAddOffers || isEditOffer}
            >
              {_.get(offeringPackageUpdated, item.key) &&
                _.get(offeringPackageUpdated, item.key).map(
                  (option: any, ix: number) => {
                    return (
                      <MenuItem
                        key={_.get(option, 'id', `${item.key}-${ix + 1}`)}
                        value={
                          _.get(option, 'id') === 0
                            ? parseInt(_.get(option, 'id'))
                            : _.get(option, 'id', `${item.key}-${ix + 1}`) || ''
                        }
                        aria-selected={false}
                        className={classes.menuItems}
                      >
                        {item.type ? (
                          <>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  _.get(formik.values, item.key).indexOf(
                                    _.get(option, 'id')
                                  ) > -1
                                }
                                color="primary"
                              />
                            </ListItemIcon>
                            <ListItemText primary={_.get(option, 'name')} />
                          </>
                        ) : (
                          _.get(option, 'name')
                        )}
                      </MenuItem>
                    );
                  }
                )}
            </Select>
          </>
        }
        <FormHelperText key={`DM-${item.key}-FHT`}>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}
