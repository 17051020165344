import {
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAILURE,
  RESET_FETCH_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_ONBOARDING_REQUEST,
  FETCH_CUSTOMER_ONBOARDING_SUCCESS,
  FETCH_CUSTOMER_ONBOARDING_FAILURE,
  RESET_FETCH_CUSTOMER_ONBOARDING,
  FETCH_CUSTOMER_GFNACCOUNT_REQUEST,
  FETCH_CUSTOMER_GFNACCOUNT_SUCCESS,
  FETCH_CUSTOMER_GFNACCOUNT_FAILURE,
  RESET_FETCH_CUSTOMER_GFNACCOUNT,
  FETCH_CUSTOMER_IPAAS_REQUEST,
  FETCH_CUSTOMER_IPAAS_SUCCESS,
  FETCH_CUSTOMER_IPAAS_FAILURE,
  RESET_FETCH_CUSTOMER_IPAAS,
  FETCH_CUSTOMER_PAYCARDREG_REQUEST,
  FETCH_CUSTOMER_PAYCARDREG_SUCCESS,
  FETCH_CUSTOMER_PAYCARDREG_FAILURE,
  RESET_FETCH_CUSTOMER_PAYCARDREG,
  FETCH_CUSTOMER_ADDRESS_REQUEST,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_CUSTOMER_ADDRESS_FAILURE,
  RESET_FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST,
  FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS,
  FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE,
  RESET_FETCH_CUSTOMER_INVOICE_DETAILS,
  FETCH_SOA_DETAILS_REQUEST,
  FETCH_SOA_DETAILS_SUCCESS,
  FETCH_SOA_DETAILS_FAILURE,
  RESET_FETCH_SOA_DETAILS,
  UPDATE_CUSTOMER_VERIFY_REQUEST,
  UPDATE_CUSTOMER_VERIFY_SUCCESS,
  UPDATE_CUSTOMER_VERIFY_FAILURE,
  RESET_UPDATE_CUSTOMER_VERIFY,
  FETCH_CUSTOMER_CARDS_REQUEST,
  FETCH_CUSTOMER_CARDS_SUCCESS,
  FETCH_CUSTOMER_CARDS_FAILURE,
  RESET_FETCH_CUSTOMER_CARDS,
  FETCH_CUSTOMER_BUSINESS_REQUEST,
  FETCH_CUSTOMER_BUSINESS_SUCCESS,
  FETCH_CUSTOMER_BUSINESS_FAILURE,
  RESET_FETCH_CUSTOMER_BUSINESS,
  DELETE_CUSTOMER_ACCOUNT_REQUEST,
  DELETE_CUSTOMER_ACCOUNT_SUCCESS,
  DELETE_CUSTOMER_ACCOUNT_FAILURE,
  RESET_DELETE_CUSTOMER_ACCOUNT,
  BLOCK_CUSTOMER_ACCOUNT_REQUEST,
  BLOCK_CUSTOMER_ACCOUNT_SUCCESS,
  BLOCK_CUSTOMER_ACCOUNT_FAILURE,
  RESET_BLOCK_CUSTOMER_ACCOUNT,
  FETCH_CUSTOMER_H3INVOICE_REQUEST,
  FETCH_CUSTOMER_H3INVOICE_SUCCESS,
  FETCH_CUSTOMER_H3INVOICE_FAILURE,
  RESET_FETCH_CUSTOMER_H3INVOICE,
  FETCH_SOA_CHARGEBACK_REQUEST,
  FETCH_SOA_CHARGEBACK_SUCCESS,
  FETCH_SOA_CHARGEBACK_FAILURE,
  FETCH_CUSTOMER_ONFIDO_REQUEST,
  FETCH_CUSTOMER_ONFIDO_SUCCESS,
  FETCH_CUSTOMER_ONFIDO_FAILURE,
  RESET_FETCH_CUSTOMER_ONFIDO,
  FETCH_CUSTOMER_CHARGEBACK_REQUEST,
  FETCH_CUSTOMER_CHARGEBACK_SUCCESS,
  FETCH_CUSTOMER_CHARGEBACK_FAILURE,
  CUSTOMER_ACCOUNT_CLOSER_REQUEST,
  CUSTOMER_ACCOUNT_CLOSER_SUCCESS,
  CUSTOMER_ACCOUNT_CLOSER_FAILURE,
  RESET_CUSTOMER_ACCOUNT_CLOSER,
  FETCH_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_FAILURE,
  RESET_FETCH_CUSTOMER_DETAILS,
  UPDATE_USER_NAME_REQUEST,
  UPDATE_USER_NAME_SUCCESS,
  UPDATE_USER_NAME_FAILURE,
  RESET_UPDATE_USER_NAME
} from './actionTypes';
import {
  
  FetchUserDetailsFailure,
  FetchUserDetailsFailurePayload,
  FetchUserDetailsRequest,
  FetchUserDetailsRequestPayload,
  FetchUserDetailsSuccess,
  FetchUserDetailsSuccessPayload,
  FetchCustomerProfileRequest,
  FetchCustomerProfileSuccess,
  FetchCustomerProfileFailure,
  FetchCustomerProfileRequestPayload,
  FetchCustomerProfileSuccessPayload,
  FetchCustomerProfileFailurePayload,
  FetchCustomerOnboardingRequest,
  FetchCustomerOnboardingSuccess,
  FetchCustomerOnboardingFailure,
  FetchCustomerOnboardingRequestPayload,
  FetchCustomerOnboardingSuccessPayload,
  FetchCustomerOnboardingFailurePayload,
  FetchCustomerGfnaccountRequest,
  FetchCustomerGfnaccountSuccess,
  FetchCustomerGfnaccountFailure,
  FetchCustomerGfnaccountRequestPayload,
  FetchCustomerGfnaccountSuccessPayload,
  FetchCustomerGfnaccountFailurePayload,
  FetchCustomerIpaasRequest,
  FetchCustomerIpaasSuccess,
  FetchCustomerIpaasFailure,
  FetchCustomerIpaasRequestPayload,
  FetchCustomerIpaasSuccessPayload,
  FetchCustomerIpaasFailurePayload,
  FetchCustomerPayCardRegRequest,
  FetchCustomerPayCardRegSuccess,
  FetchCustomerPayCardRegFailure,
  FetchCustomerPayCardRegRequestPayload,
  FetchCustomerPayCardRegSuccessPayload,
  FetchCustomerPayCardRegFailurePayload,
  FetchCustomerAddressRequest,
  FetchCustomerAddressSuccess,
  FetchCustomerAddressFailure,
  FetchCustomerAddressRequestPayload,
  FetchCustomerAddressSuccessPayload,
  FetchCustomerAddressFailurePayload,
  FetchCustomerInvoiceDetailsRequest,
  FetchCustomerInvoiceDetailsSuccess,
  FetchCustomerInvoiceDetailsFailure,
  FetchCustomerInvoiceDetailsRequestPayload,
  FetchCustomerInvoiceDetailsSuccessPayload,
  FetchCustomerInvoiceDetailsFailurePayload,
  FetchSOADetailsRequest,
  FetchSOADetailsSuccess,
  FetchSOADetailsFailure,
  FetchSOADetailsRequestPayload,
  FetchSOADetailsSuccessPayload,
  FetchSOADetailsFailurePayload,
  UpdateCustomerVerifyRequest,
  UpdateCustomerVerifySuccess,
  UpdateCustomerVerifyFailure,
  UpdateCustomerVerifyRequestPayload,
  UpdateCustomerVerifySuccessPayload,
  UpdateCustomerVerifyFailurePayload,
  FetchCustomerCardsRequest,
  FetchCustomerCardsSuccess,
  FetchCustomerCardsFailure,
  FetchCustomerCardsRequestPayload,
  FetchCustomerCardsSuccessPayload,
  FetchCustomerCardsFailurePayload,
  FetchCustomerBusinessRequest,
  FetchCustomerBusinessSuccess,
  FetchCustomerBusinessFailure,
  FetchCustomerBusinessRequestPayload,
  FetchCustomerBusinessSuccessPayload,
  FetchCustomerBusinessFailurePayload,
  DeleteCustomerRequest,
  DeleteCustomerSuccess,
  DeleteCustomerFailure,
  DeleteCustomerRequestPayload,
  DeleteCustomerSuccessPayload,
  DeleteCustomerFailurePayload,
  AccountCloserRequest,
  AccountCloserSuccess,
  AccountCloserFailure,
  AccountCloserRequestPayload,
  AccountCloserSuccessPayload,
  AccountCloserFailurePayload,
  BlockCustomerRequest,
  BlockCustomerSuccess,
  BlockCustomerFailure,
  BlockCustomerRequestPayload,
  BlockCustomerSuccessPayload,
  BlockCustomerFailurePayload,
  FetchChargebackInvoiceRequest,
  FetchChargebackInvoiceSuccess,
  FetchChargebackInvoiceFailure,
  FetchChargebackInvoiceRequestPayload,
  FetchChargebackInvoiceSuccessPayload,
  FetchChargebackInvoiceFailurePayload,
  FetchCustomerH3InvoiceRequest,
  FetchCustomerH3InvoiceSuccess,
  FetchCustomerH3InvoiceFailure,
  FetchCustomerH3InvoiceRequestPayload,
  FetchCustomerH3InvoiceSuccessPayload,
  FetchCustomerH3InvoiceFailurePayload,
  FetchChargebackSOARequestPayload,
  FetchChargebackSOARequest,
  FetchChargebackSOASuccessPayload,
  FetchChargebackSOASuccess,
  FetchChargebackSOAFailurePayload,
  FetchChargebackSOAFailure,
  FetchCustomerOnfidoRequest,
  FetchCustomerOnfidoSuccess,
  FetchCustomerOnfidoFailure,
  FetchCustomerOnfidoRequestPayload,
  FetchCustomerOnfidoSuccessPayload,
  FetchCustomerOnfidoFailurePayload,
  FetchCustomerDetailsRequest,
  FetchCustomerDetailsSuccess,
  FetchCustomerDetailsFailure,
  FetchCustomerDetailsRequestPayload,
  FetchCustomerDetailsSuccessPayload,
  FetchCustomerDetailsFailurePayload,
  UpdateUserNameRequestPayload,
  UpdateUserNameSuccessPayload,
  UpdateUserNameFailurePayload,
  UpdateUserNameRequest,
  UpdateUserNameSuccess,
  UpdateUserNameFailure
} from './types';

export const fetchUserDetailsRequest = (
  payload: FetchUserDetailsRequestPayload
): FetchUserDetailsRequest => ({
  type: FETCH_USER_DETAILS_REQUEST,
  payload,
});

export const fetchUserDetailsSuccess = (
  payload: FetchUserDetailsSuccessPayload
): FetchUserDetailsSuccess => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload,
});

export const fetchUserDetailsFailure = (
  payload: FetchUserDetailsFailurePayload
): FetchUserDetailsFailure => ({
  type: FETCH_USER_DETAILS_FAILURE,
  payload,
});

export const fetchCustomerProfileRequest = (
  payload: FetchCustomerProfileRequestPayload
): FetchCustomerProfileRequest => ({
  type: FETCH_CUSTOMER_PROFILE_REQUEST,
  payload,
});

export const fetchCustomerProfileSuccess = (
  payload: FetchCustomerProfileSuccessPayload
): FetchCustomerProfileSuccess => ({
  type: FETCH_CUSTOMER_PROFILE_SUCCESS,
  payload,
});

export const fetchCustomerProfileFailure = (
  payload: FetchCustomerProfileFailurePayload
): FetchCustomerProfileFailure => ({
  type: FETCH_CUSTOMER_PROFILE_FAILURE,
  payload,
});

export const resetFetchCustomerProfile = () => ({
  type: RESET_FETCH_CUSTOMER_PROFILE,
});

export const fetchCustomerOnboardingRequest = (
  payload: FetchCustomerOnboardingRequestPayload
): FetchCustomerOnboardingRequest => ({
  type: FETCH_CUSTOMER_ONBOARDING_REQUEST,
  payload,
});

export const fetchCustomerOnboardingSuccess = (
  payload: FetchCustomerOnboardingSuccessPayload
): FetchCustomerOnboardingSuccess => ({
  type: FETCH_CUSTOMER_ONBOARDING_SUCCESS,
  payload,
});

export const fetchCustomerOnboardingFailure = (
  payload: FetchCustomerOnboardingFailurePayload
): FetchCustomerOnboardingFailure => ({
  type: FETCH_CUSTOMER_ONBOARDING_FAILURE,
  payload,
});

export const resetFetchCustomerOnboarding = () => ({
  type: RESET_FETCH_CUSTOMER_ONBOARDING,
});

export const fetchCustomerGfnaccountRequest = (
  payload: FetchCustomerGfnaccountRequestPayload
): FetchCustomerGfnaccountRequest => ({
  type: FETCH_CUSTOMER_GFNACCOUNT_REQUEST,
  payload,
});

export const fetchCustomerGfnaccountSuccess = (
  payload: FetchCustomerGfnaccountSuccessPayload
): FetchCustomerGfnaccountSuccess => ({
  type: FETCH_CUSTOMER_GFNACCOUNT_SUCCESS,
  payload,
});

export const fetchCustomerGfnaccountFailure = (
  payload: FetchCustomerGfnaccountFailurePayload
): FetchCustomerGfnaccountFailure => ({
  type: FETCH_CUSTOMER_GFNACCOUNT_FAILURE,
  payload,
});

export const resetFetchCustomerGfnaccount = () => ({
  type: RESET_FETCH_CUSTOMER_GFNACCOUNT,
});

export const fetchCustomerIpaasRequest = (
  payload: FetchCustomerIpaasRequestPayload
): FetchCustomerIpaasRequest => ({
  type: FETCH_CUSTOMER_IPAAS_REQUEST,
  payload,
});

export const fetchCustomerIpaasSuccess = (
  payload: FetchCustomerIpaasSuccessPayload
): FetchCustomerIpaasSuccess => ({
  type: FETCH_CUSTOMER_IPAAS_SUCCESS,
  payload,
});

export const fetchCustomerIpaasFailure = (
  payload: FetchCustomerIpaasFailurePayload
): FetchCustomerIpaasFailure => ({
  type: FETCH_CUSTOMER_IPAAS_FAILURE,
  payload,
});

export const resetFetchCustomerIpaas = () => ({
  type: RESET_FETCH_CUSTOMER_IPAAS,
});

export const fetchCustomerPayCardRegRequest = (
  payload: FetchCustomerPayCardRegRequestPayload
): FetchCustomerPayCardRegRequest => ({
  type: FETCH_CUSTOMER_PAYCARDREG_REQUEST,
  payload,
});

export const fetchCustomerPayCardRegSuccess = (
  payload: FetchCustomerPayCardRegSuccessPayload
): FetchCustomerPayCardRegSuccess => ({
  type: FETCH_CUSTOMER_PAYCARDREG_SUCCESS,
  payload,
});

export const fetchCustomerPayCardRegFailure = (
  payload: FetchCustomerPayCardRegFailurePayload
): FetchCustomerPayCardRegFailure => ({
  type: FETCH_CUSTOMER_PAYCARDREG_FAILURE,
  payload,
});

export const resetFetchCustomerPayCardReg = () => ({
  type: RESET_FETCH_CUSTOMER_PAYCARDREG,
});

export const fetchCustomerAddressRequest = (
  payload: FetchCustomerAddressRequestPayload
): FetchCustomerAddressRequest => ({
  type: FETCH_CUSTOMER_ADDRESS_REQUEST,
  payload,
});

export const fetchCustomerAddressSuccess = (
  payload: FetchCustomerAddressSuccessPayload
): FetchCustomerAddressSuccess => ({
  type: FETCH_CUSTOMER_ADDRESS_SUCCESS,
  payload,
});

export const fetchCustomerAddressFailure = (
  payload: FetchCustomerAddressFailurePayload
): FetchCustomerAddressFailure => ({
  type: FETCH_CUSTOMER_ADDRESS_FAILURE,
  payload,
});

export const resetFetchCustomerAddress = () => ({
  type: RESET_FETCH_CUSTOMER_ADDRESS,
});

export const fetchCustomerInvoiceDetailsRequest = (
  payload: FetchCustomerInvoiceDetailsRequestPayload
): FetchCustomerInvoiceDetailsRequest => ({
  type: FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST,
  payload,
});

export const fetchCustomerInvoiceDetailsSuccess = (
  payload: FetchCustomerInvoiceDetailsSuccessPayload
): FetchCustomerInvoiceDetailsSuccess => ({
  type: FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS,
  payload,
});

export const fetchCustomerInvoiceDetailsFailure = (
  payload: FetchCustomerInvoiceDetailsFailurePayload
): FetchCustomerInvoiceDetailsFailure => ({
  type: FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE,
  payload,
});

export const resetFetchCustomerInvoiceDetails = () => ({
  type: RESET_FETCH_CUSTOMER_INVOICE_DETAILS,
});

export const fetchSOADetailsRequest = (
  payload: FetchSOADetailsRequestPayload
): FetchSOADetailsRequest => ({
  type: FETCH_SOA_DETAILS_REQUEST,
  payload,
});

export const fetchSOADetailsSuccess = (
  payload: FetchSOADetailsSuccessPayload
): FetchSOADetailsSuccess => ({
  type: FETCH_SOA_DETAILS_SUCCESS,
  payload,
});

export const fetchSOADetailsFailure = (
  payload: FetchSOADetailsFailurePayload
): FetchSOADetailsFailure => ({
  type: FETCH_SOA_DETAILS_FAILURE,
  payload,
});

export const resetFetchSOADetails = () => ({
  type: RESET_FETCH_SOA_DETAILS,
});


export const updateCustomerVerifyRequest = (
  payload: UpdateCustomerVerifyRequestPayload
): UpdateCustomerVerifyRequest => ({
  type: UPDATE_CUSTOMER_VERIFY_REQUEST,
  payload,
});

export const updateCustomerVerifySuccess = (
  payload: UpdateCustomerVerifySuccessPayload
): UpdateCustomerVerifySuccess => ({
  type: UPDATE_CUSTOMER_VERIFY_SUCCESS,
  payload,
});

export const updateCustomerVerifyFailure = (
  payload: UpdateCustomerVerifyFailurePayload
): UpdateCustomerVerifyFailure => ({
  type: UPDATE_CUSTOMER_VERIFY_FAILURE,
  payload,
});

export const resetUpdateCustomerVerify = () => ({
  type: RESET_UPDATE_CUSTOMER_VERIFY,
});

export const fetchCustomerCardsRequest = (
  payload: FetchCustomerCardsRequestPayload
): FetchCustomerCardsRequest => ({
  type: FETCH_CUSTOMER_CARDS_REQUEST,
  payload,
});

export const fetchCustomerCardsSuccess = (
  payload: FetchCustomerCardsSuccessPayload
): FetchCustomerCardsSuccess => ({
  type: FETCH_CUSTOMER_CARDS_SUCCESS,
  payload,
});

export const fetchCustomerCardsFailure = (
  payload: FetchCustomerCardsFailurePayload
): FetchCustomerCardsFailure => ({
  type: FETCH_CUSTOMER_CARDS_FAILURE,
  payload,
});

export const resetFetchCustomerCards = () => ({
  type: RESET_FETCH_CUSTOMER_CARDS,
});

export const fetchCustomerBusinessRequest = (
  payload: FetchCustomerBusinessRequestPayload
): FetchCustomerBusinessRequest => ({
  type: FETCH_CUSTOMER_BUSINESS_REQUEST,
  payload,
});

export const fetchCustomerBusinessSuccess = (
  payload: FetchCustomerBusinessSuccessPayload
): FetchCustomerBusinessSuccess => ({
  type: FETCH_CUSTOMER_BUSINESS_SUCCESS,
  payload,
});

export const fetchCustomerBusinessFailure = (
  payload: FetchCustomerBusinessFailurePayload
): FetchCustomerBusinessFailure => ({
  type: FETCH_CUSTOMER_BUSINESS_FAILURE,
  payload,
});

export const resetFetchCustomerBusiness = () => ({
  type: RESET_FETCH_CUSTOMER_BUSINESS,
});

export const deleteCustomerRequest = (
  payload: DeleteCustomerRequestPayload
): DeleteCustomerRequest => ({
  type: DELETE_CUSTOMER_ACCOUNT_REQUEST,
  payload,
});

export const deleteCustomerSuccess = (
  payload: DeleteCustomerSuccessPayload
): DeleteCustomerSuccess => ({
  type: DELETE_CUSTOMER_ACCOUNT_SUCCESS,
  payload,
});

export const deleteCustomerFailure = (
  payload: DeleteCustomerFailurePayload
): DeleteCustomerFailure => ({
  type: DELETE_CUSTOMER_ACCOUNT_FAILURE,
  payload,
});

export const resetDeleteCustomer = () => ({
  type: RESET_DELETE_CUSTOMER_ACCOUNT,
});

export const accountCloserRequest = (
  payload: AccountCloserRequestPayload
): AccountCloserRequest => ({
  type: CUSTOMER_ACCOUNT_CLOSER_REQUEST,
  payload,
});

export const accountCloserSuccess = (
  payload: AccountCloserSuccessPayload
): AccountCloserSuccess => ({
  type: CUSTOMER_ACCOUNT_CLOSER_SUCCESS,
  payload,
});

export const accountCloserFailure = (
  payload: AccountCloserFailurePayload
): AccountCloserFailure => ({
  type: CUSTOMER_ACCOUNT_CLOSER_FAILURE,
  payload,
});

export const resetaccountCloser = () => ({
  type: RESET_CUSTOMER_ACCOUNT_CLOSER,
});

export const blockCustomerRequest = (
  payload: BlockCustomerRequestPayload
): BlockCustomerRequest => ({
  type: BLOCK_CUSTOMER_ACCOUNT_REQUEST,
  payload,
});

export const blockCustomerSuccess = (
  payload: BlockCustomerSuccessPayload
): BlockCustomerSuccess => ({
  type: BLOCK_CUSTOMER_ACCOUNT_SUCCESS,
  payload,
});

export const blockCustomerFailure = (
  payload: BlockCustomerFailurePayload
): BlockCustomerFailure => ({
  type: BLOCK_CUSTOMER_ACCOUNT_FAILURE,
  payload,
});

export const resetBlockCustomer = () => ({
  type: RESET_BLOCK_CUSTOMER_ACCOUNT,
});

export const fetchCustomerH3InvoiceRequest = (
  payload: FetchCustomerH3InvoiceRequestPayload
): FetchCustomerH3InvoiceRequest => ({
  type: FETCH_CUSTOMER_H3INVOICE_REQUEST,
  payload,
});

export const   fetchChargebackInvoiceRequest = (
  payload:   FetchChargebackInvoiceRequestPayload
): FetchChargebackInvoiceRequest => ({
  type: FETCH_CUSTOMER_CHARGEBACK_REQUEST,
  payload,
});

export const fetchChargebackInvoiceSuccess = (
  payload: FetchChargebackInvoiceSuccessPayload
): FetchChargebackInvoiceSuccess => ({
  type: FETCH_CUSTOMER_CHARGEBACK_SUCCESS,
  payload,
});

export const fetchChargebackInvoiceFailure = (
  payload: FetchChargebackInvoiceFailurePayload
): FetchChargebackInvoiceFailure => ({
  type: FETCH_CUSTOMER_CHARGEBACK_FAILURE,
  payload,
});

export const fetchCustomerH3InvoiceSuccess = (
  payload: FetchCustomerH3InvoiceSuccessPayload
): FetchCustomerH3InvoiceSuccess => ({
  type: FETCH_CUSTOMER_H3INVOICE_SUCCESS,
  payload,
});

export const fetchCustomerH3InvoiceFailure = (
  payload: FetchCustomerH3InvoiceFailurePayload
): FetchCustomerH3InvoiceFailure => ({
  type: FETCH_CUSTOMER_H3INVOICE_FAILURE,
  payload,
});

export const resetFetchCustomerH3Invoice = () => ({
  type: RESET_FETCH_CUSTOMER_H3INVOICE,
});

export const fetchChargebackSOARequest = (
  payload: FetchChargebackSOARequestPayload
): FetchChargebackSOARequest => ({
  type: FETCH_SOA_CHARGEBACK_REQUEST,
  payload,
});

export const fetchChargebackSOASuccess = (
  payload: FetchChargebackSOASuccessPayload
): FetchChargebackSOASuccess => ({
  type: FETCH_SOA_CHARGEBACK_SUCCESS,
  payload,
});

export const fetchChargebackSOAFailure = (
  payload: FetchChargebackSOAFailurePayload
): FetchChargebackSOAFailure => ({
  type: FETCH_SOA_CHARGEBACK_FAILURE,
  payload,
});

export const fetchCustomerOnfidoRequest = (
  payload: FetchCustomerOnfidoRequestPayload
): FetchCustomerOnfidoRequest => ({
  type: FETCH_CUSTOMER_ONFIDO_REQUEST,
  payload,
});

export const fetchCustomerOnfidoSuccess = (
  payload: FetchCustomerOnfidoSuccessPayload
): FetchCustomerOnfidoSuccess => ({
  type: FETCH_CUSTOMER_ONFIDO_SUCCESS,
  payload,
});

export const fetchCustomerOnfidoFailure = (
  payload: FetchCustomerOnfidoFailurePayload
): FetchCustomerOnfidoFailure => ({
  type: FETCH_CUSTOMER_ONFIDO_FAILURE,
  payload,
});

export const resetFetchCustomerOnfido = () => ({
  type: RESET_FETCH_CUSTOMER_ONFIDO,
});

export const fetchCustomerDetailsRequest = (
  payload: FetchCustomerDetailsRequestPayload
): FetchCustomerDetailsRequest => ({
  type: FETCH_CUSTOMER_DETAILS,
  payload,
});

export const fetchCustomerDetailsSuccess = (
  payload: FetchCustomerDetailsSuccessPayload
): FetchCustomerDetailsSuccess => ({
  type: FETCH_CUSTOMER_DETAILS_SUCCESS,
  payload,
});

export const fetchCustomerDetailsFailure = (
  payload: FetchCustomerDetailsFailurePayload
): FetchCustomerDetailsFailure => ({
  type: FETCH_CUSTOMER_DETAILS_FAILURE,
  payload,
});

export const resetFetchCustomerDetails = () => ({
  type: RESET_FETCH_CUSTOMER_DETAILS,
});

export const updateUserNameRequest = (
  payload: UpdateUserNameRequestPayload
): UpdateUserNameRequest => ({
  type: UPDATE_USER_NAME_REQUEST,
  payload,
});

export const updateUserNameSuccess = (
  payload: UpdateUserNameSuccessPayload
): UpdateUserNameSuccess => ({
  type: UPDATE_USER_NAME_SUCCESS,
  payload,
});

export const updateUserNameFailure = (
  payload: UpdateUserNameFailurePayload
): UpdateUserNameFailure => ({
  type: UPDATE_USER_NAME_FAILURE,
  payload,
});

export const resetUpdateUserName = () => ({
  type: RESET_UPDATE_USER_NAME,
});