import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,                                                                      
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import React, { useState, forwardRef } from 'react';
import { CSVLink } from 'react-csv';
import { connect, useDispatch } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import { fetchDealsRequest } from '../store/deals/actions';
import moment from 'moment';
import {
  createTokenRequest,
  resetCreateTokenRequest,
} from '../store/token/actions';
import { CreateTokenRequestPayload } from '../store/token/types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    margin: theme.spacing(1),
    minWidth: 250,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '32ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    padding: 10,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  margin: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
    height: 40,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '32ch',
  },
  deleteIcon: {
    alignItems: 'center',
  },
  iconStyle: {
    alignItems: 'center',
    margin: theme.spacing(1, 2, 4, 2),
  },
  title: {
    flexGrow: 1,
  },
  circularProgress: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#fbcf04',
  },
  downloadIcon: {
    '&:hover': {
      background: '#fbcf04d1',
    },
    background: '#fbcf04',
    alignItems: 'center',
    padding: 9,
    fontFamily: 'Shell-Book',
    height: 40,
  },
  csvLink: {
    height: 42,
    marginTop: 8,
  },
}));

const CreateToken = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const {
    dealsLoading,
    deals,
    dealsError,
    createdToken,
    createTokenLoading,
    createdTokenResponse,
    countries,
    countriesLoading,
    countriesError,
    createdTokenError,
  } = props;

  const onlyNumericRegex = /\D/g;

  const tokenGenerationPayloadInt = {
    dealId: '',
    noOfTokens: 0,
    validTo: ''
  };

  const createTokenSelect = {
    dealId: '',
    country: '',
    token: '',
  };

  const createErrors = {
    country: false,
    dealId: false,
    token: false,
  };
  const headers = [
    { label: 'Token', key: 'code' },
    { label: 'Created On Date ', key: 'created' },
    { label: 'Status', key: 'status' },
    { label: 'Used on', key: 'used' },
    { label: 'Customer', key: 'customer.maskedEmail' },
    { label: 'Aggregator Name', key: 'deal.description' },
    { label: 'Country', key: 'deal.market.countryCode' },
  ];
  const [tokenGenerationPayload, setTokenCount] = useState(
    tokenGenerationPayloadInt
  );
  const [advSelect, setAdvSelect] = useState(createTokenSelect);
  const [tokenDownloadPayload, setDownload] = useState(true);
  const [hasCTErrors, setCTErrors] = useState(createErrors);
  const [open, setOpen] = useState(true);
  const [isDownloadClicked, setDownloadClicked] = useState(true);
  const [tokenExpiry, setTokenExpiry] = useState('');
  const [dateAlertMessage, setDateAlertMessage] = useState('');
  const [maxDate,setMaxDate] = useState(false);
  const dispatch = useDispatch();

  const handleCTChange = (event: any) => {
    setDownload(false);
    const { name, value } = event.target;
    if (name === 'country') {
      dispatch(fetchDealsRequest(value));
      setAdvSelect({ ...advSelect, [name]: value });
    }
    if (name === 'noOfTokens') {
      event.target.value = event.target.value.replace(onlyNumericRegex, '');
    }
    setTokenCount({
      ...tokenGenerationPayload,
      [name]: event.target.value,
    });
  };

  const handleButtonClick = () => {
    setDownload(true);
    setDownloadClicked(false);
    const data: CreateTokenRequestPayload = tokenGenerationPayload;
    dispatch(createTokenRequest(data));
  };

  const handleDialogClose = () => {
    if (isDownloadClicked) {
      setOpen(false);
      props.setModalOpen(false);
    }
    dispatch(resetCreateTokenRequest());
  };

  const validateCTErrors = (e: any) => {
    const { name, value } = e.target;
    if (!value) {
      setCTErrors({ ...createErrors, [name]: true });
    } else {
      setCTErrors({ ...createErrors, [name]: false });
    }
  };

  function HandleAlert() {
    if (!createTokenLoading && createdTokenResponse.success) {
      return (
        <Alert severity="success">{`${createdTokenResponse.message}. Please Download the tokens and close.  `}</Alert>
      );
    } else {
      setDownloadClicked(true);
      return (
        <Alert severity="error">
          {`Error creating tokens - [${
            createdTokenError || createdTokenResponse.message
          }]`}
        </Alert>
      );
    }
  }

  const handleDownloadButtonClick = () => {
    setDownloadClicked(true);
    setDownload(false);
    setAdvSelect({ ...advSelect, country: '' });
    setTokenCount(tokenGenerationPayloadInt);
  };

  const ensureDateValid = (dateValue: any)  => {
    var today = moment();
    var todayPlusOneYear = moment().add(1, 'years').calendar();
    var dateMoment = moment(dateValue);
    if (dateMoment.isBefore(today)) {
      setDateAlertMessage('Date must be greater than today.')
      setMaxDate(true)
      return true
    }
    if (dateMoment.isAfter(todayPlusOneYear)) {
      setDateAlertMessage('Date must not be greater than 1 year from today.')
      setMaxDate(true)
      return true
    }
    return false;
}
  const handleDateValue = (e: any) => {
      if(!ensureDateValid(e.target.value) ) {
        setMaxDate(false)
        setTokenExpiry(e.target.value)
        setTokenCount({
          ...tokenGenerationPayload,
          validTo: moment.utc(e.target.value).format()
        });
      }
  }

  return (
    <>
      <Dialog ref={ref} open={open} fullWidth maxWidth="lg">
        <DialogTitle>Create Tokens</DialogTitle>
        <DialogContent>
          <ResponsiveContainer>
            <Card className={classes.root} variant="elevation">
              <Grid container justifyContent="flex-start">
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={hasCTErrors.country || !!countriesError}
                  size="small"
                >
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    name="country"
                    labelId="country-select-label"
                    id="country-select"
                    value={advSelect.country || ''}
                    onChange={handleCTChange}
                    label="Country"
                    onBlur={validateCTErrors}
                  >
                    {!countriesLoading &&
                      countries.map((option: any) => (
                        <MenuItem
                          key={option.code}
                          value={option.code}
                          aria-selected={true}
                          className={classes.menuItems}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {countriesError
                      ? `Error loading countries - [${countriesError}]`
                      : 'Please select country'}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={hasCTErrors.dealId || !!dealsError}
                  size="small"
                >
                  <InputLabel id="deal-select-label">Deal</InputLabel>
                  <Select
                    name="dealId"
                    labelId="deal-select-label"
                    id="deal-select"
                    value={tokenGenerationPayload.dealId || ''}
                    onChange={handleCTChange}
                    label="Deal"
                    onBlur={validateCTErrors}
                    disabled={
                      advSelect.country === '' || deals.length === 0
                        ? true
                        : false
                    }
                  >
                    {deals.map((option: any) => (
                      <MenuItem
                        key={_.get(option, 'id')}
                        value={_.get(option, 'id')}
                        aria-selected={false}
                        className={classes.menuItems}
                      >
                        {_.get(option, 'description')}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {advSelect.country === ''
                      ? 'Please select country to enable deals'
                      : dealsLoading
                      ? 'Loading deals for the country'
                      : dealsError
                      ? `Error loading deals [${dealsError}]`
                      : deals.length === 0
                      ? 'No deals for the country'
                      : 'Please select deal'}
                  </FormHelperText>
                </FormControl>

                <TextField
                  error={
                    hasCTErrors.token &&
                    (tokenGenerationPayload.noOfTokens < 1 ||
                      tokenGenerationPayload.noOfTokens > 15000)
                  }
                  required
                  value={tokenGenerationPayload.noOfTokens}
                  label="Number of Tokens"
                  onChange={handleCTChange}
                  name="noOfTokens"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  helperText="Please enter number of tokens to create. (Min 1 and Max 15000)"
                  margin="normal"
                  variant="outlined"
                  onBlur={validateCTErrors}
                  size="small"
                />
                <TextField
                  id="date"
                  label="Valid To"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText="Please enter expiry date for token"
                  defaultValue={tokenExpiry}
                  variant="outlined"
                  margin="normal"
                  size="small"
                  onChange={(d: any) => handleDateValue(d) }
                />
              
                {createTokenLoading ? (
                  <CircularProgress className={classes.circularProgress} />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className={classes.margin}
                    onClick={handleButtonClick}
                    disabled={
                      !tokenGenerationPayload.dealId ||
                      tokenGenerationPayload.noOfTokens < 1 ||
                      tokenGenerationPayload.noOfTokens > 15000 ||
                      tokenGenerationPayload.validTo === '' ||
                      (createdToken.length > 0 && tokenDownloadPayload)
                        ? true
                        : false
                    }
                  >
                    Generate
                  </Button>
                )}
                {maxDate && (<Alert severity="error">{dateAlertMessage}</Alert>)}

                {createdToken.length > 0 && tokenDownloadPayload ? (
                  <CSVLink
                    className={classes.csvLink}
                    data={createdToken}
                    filename={`Tokens_${createdTokenResponse.data[0].deal.description.replace(
                      / /g,
                      ''
                    )}_${new Date().getTime()}.csv`}
                    headers={headers}
                    onClick={handleDownloadButtonClick}
                  >
                    <Button
                      aria-label="settings"
                      className={classes.downloadIcon}
                      title="Download"
                      onClick={handleDownloadButtonClick}
                    >
                      <span>Download</span>
                    </Button>
                  </CSVLink>
                ) : null}
              </Grid>
            </Card>

          </ResponsiveContainer>
        </DialogContent>
        <DialogActions>
          {(Object.keys(createdTokenResponse).length > 0 ||
            createdTokenError) && <HandleAlert />}
          <Button
            color="secondary"
            onClick={handleDialogClose}
            disabled={isDownloadClicked ? false : true}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesError: state.countries.error,
    countriesLoading: state.countries.pending,
    deals: state.deals.deals,
    dealsLoading: state.deals.pending,
    dealsError: state.deals.error,
    createdToken: state.tokensCreated.createdToken,
    createTokenLoading: state.tokensCreated.pending,
    createdTokenResponse: state.tokensCreated.response,
    createdTokenError: state.tokensCreated.error,
  };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  CreateToken
);
