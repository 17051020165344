import React, { cloneElement } from 'react';

const PERMISSIONS = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
};

const hasPermission = (permission: String, permissionFor: String) => {
  const permissionScope: any = {};
  let permissions = permission.split('');
  let index = 0;
  for (const permission in PERMISSIONS) {
    permissionScope[permission] = permissions[index];
    index++;
  }

  //permissionScope
  //   {
  //     "create": "0",
  //     "read": "1",
  //     "update": "0",
  //     "delete": "0"
  //   }

  for (var key in permissionScope) {
    if (permissionFor === key && permissionScope[key] == 1) {
      return true;
    }
  }
  return false;
};

export default function UserPermission({
  children = <></>,
  disable = false,
  component = '',
  permissionFor = '',
}) {
  const permissionMap = JSON.parse(sessionStorage.permissions);
  const permission = permissionMap[component];

  if (permission) {
    const permissionGranted = hasPermission(permission, permissionFor);

    if (!permissionGranted && disable)
      return cloneElement(children, { disabled: true });

    if (!permissionGranted) return <></>;
  }
  return <>{children}</>;
}
