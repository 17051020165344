export const getTimeDiff = (datetime: string) => {
  return datetime
    ? new Date(datetime)
        .toString()
        .split('GMT')[0]
        .split(' ')
        .splice(1)
        .join(' ')
        .trim()
    : '-';
};
