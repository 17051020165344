import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    border: '1px solid #f4f4f4',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  dnbRow: {
    marginTop: '6px',
  },
}));

function BusinessDnB(props: any) {
  const classes = useStyles();
  const { businessDnb } = props;

  const businessDnbMap = [
    {
      id: 'name',
      value: businessDnb.name,
      label: 'Name',
    },
    {
      id: 'dunsNumber',
      value: businessDnb.dunsNumber,
      label: 'Duns Number',
    },
    {
      id: 'industryCode',
      value: businessDnb.industryCode,
      label: 'Industry Code',
    },
    {
      id: 'industryClassId',
      value: businessDnb.industryClassId,
      label: 'Industry Class ID',
    },
    {
      id: 'registartionNumber',
      value: businessDnb.registartionNumber,
      label: 'Registration Number',
    },
    {
      id: 'registartionNumber2',
      value: businessDnb.registartionNumber2,
      label: 'Registration Number 2',
    },
    {
      id: 'vaTNumber',
      value: businessDnb.vaTNumber,
      label: 'VAT Number',
    },
  ];

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box sx={{ m: 2 }}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontSize: '0.9rem',
              borderBottom: '1px solid #D9D9D9',
              pb: 1,
            }}
          >
            Business DnB
          </Typography>
          {businessDnbMap.map(({ label, value, id }) => {
            return (
              <Box className={classes.box} key={id}>
                <Grid container className={classes.dnbRow}>
                  <Grid item xs={7}>
                    {label}
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ color: 'text.secondary' }}>{value || '-'}</Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Card>
    </div>
  );
}

export default BusinessDnB;
