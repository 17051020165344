import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container, Grid, Paper, Card, FormControl, MenuItem, Select, InputLabel, FormHelperText, CircularProgress } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import UserPermission from '../../store/UserPermission';
import { MailjetConfigurationValidation } from '../../schemas/mailjetConfigurationSchema';
import { fetchCountriesRequest } from '../../store/countries/actions';
import Spinner from '../Spinner';
import _ from 'lodash';
import ButtonElement from '../HtmlElement/Button';
import TableElement from '../HtmlElement/Table';
import ConfirmDialog from '../HtmlElement/Dialogbox';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import {
  fetchCustomerGroupTypesRequest,
  fetchMailjetConfiguartionRequest,
  deleteMailjetConfiguartionRequest,
  createMailjetConfiguartionRequest,
  resetDeleteMailjetConfiguartion,
  resetFetchMailjetConfigurationDetails
} from '../../store/configurations/action';
import { DeleteMailjetConfiguartionRequestPayload } from '../../store/configurations/types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root , .MuiTableCell-root': {
      fontFamily: 'Shell-Book',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 10.5px'
    },
    margin: '10px 0px',
    display: 'flex',
    '& .MuiAlert-root':{
      marginLeft: '34px'
    },
    '& .MuiPaper-root':{
      align:'center'
      
    },
    '& .MuiPaper-root-MuiDialog-paper':{
      maxWidth:'800px !important'
    }
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '80vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
  circularProgress: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#fbcf04',
  },
  createTokenBtn: {
    '& button': {
      color: '#000000cf',
      backgroundColor: '#fbcf04',
      fontFamily: 'Shell-Book',
      '&:hover': { background: '#fbcf04d1' },
    },
    textAlign: 'right',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  // Select
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1,1),
    minWidth: 250,
    width: 300,
  },
  formControlButton: {
    margin: 0,
  }
  ,
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  spinnerRoot: {
    padding: '8px 16px',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    align: 'center'
  },
  buttonIcon: {
    '&:hover': {
      background: '#fbcf04d1 !important',
    },
    background: '#fed700d1 !important',
    color:'#000000 !important',
    margin: theme.spacing(1),
    width: 110,
    height: 40,
    fontFamily: 'Shell-Book',
  },
  disabledButton:{
    width: 110,
    height: 40,
  },
  buttonAlignR:{
    align:'right'
  }
}));


const deleteRowRequest: DeleteMailjetConfiguartionRequestPayload = {};

function ConfigurationMaster(this: any, props: any) {

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      emailType: '',
      mailjetId: '',
      languageCode: '',
      customerGroupType: ''
    },
    validationSchema: MailjetConfigurationValidation,
    onSubmit: (values, { resetForm }) => {
      setCreateMailjetConfigurationSuccess(false);
      setCreateMailjetConfigurationError(false);
      let requestData = {
        emailType: values.emailType,
        mailjetTemplateId: values.mailjetId,
        languageCode: values.languageCode,
        customerGroupTypeId: values.customerGroupType
      };
      dispatch(createMailjetConfiguartionRequest(requestData));
      resetForm();
    },
  });

  const {
    countries, countriesLoading, countriesError,
    customerGroupTypeList, customerGroupTypeLoading, customerGroupTypeError,
    mailjetConfiguartionList, deleteMailjetConfiguration, deleteConfiguartionLoading,
    mailjetConfiguartionLoading, mailjetConfiguartionError,
    mailjetConfiguartionSuccess, deleteConfiguartionSuccess,
    createConfiguartionLoading, createMailjetConfiguration, createConfiguartionError
  
  } = props;

  const dialogTitle = "Delete Mailjet Configuartion";

  const dispatch = useDispatch();
  if (!customerGroupTypeError && !customerGroupTypeLoading && customerGroupTypeList.length === 0) {
    dispatch(fetchCustomerGroupTypesRequest());
  }
  if (!countriesError && !countriesLoading && countries.length === 0) {
    dispatch(fetchCountriesRequest());
  }

  if (!customerGroupTypeError && !customerGroupTypeLoading && customerGroupTypeList.length === 0) {
    dispatch(fetchCustomerGroupTypesRequest());
  }
  const [customerGroupTypeValue, setcustomerGroupTypeValue] = useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContentText, setDialogContentText] = useState('');
  const [openMailjetConfigurationDialog, setOpenMailjetConfigurationDialog] = React.useState(false);
  const [deleteSuccess, setDeleteConfiguartionSuccess] = useState(false);
  const [ConfigurationSuccess, setCreateMailjetConfigurationSuccess] = useState(false);
  const [createMailjetConfigurationError, setCreateMailjetConfigurationError] = useState(false);
  const [countryValue, setCountryValue] = useState('');
  const [hasCountryErrors, setCountryErrors] = useState(false);
  const [buttonDisable, setButtonDisable] = useState({
    country: true,
    customerGroupType: true,
  });

  useEffect(() => {
    if (deleteConfiguartionSuccess) {
      setDialogOpen(false);
      setDeleteConfiguartionSuccess(true);
      dispatch(resetFetchMailjetConfigurationDetails());
    }
    setTimeout(() => {
      setDeleteConfiguartionSuccess(false);
    }, 4000); 
  }, [deleteConfiguartionSuccess]);

  useEffect(()=>{
    dispatch(resetFetchMailjetConfigurationDetails());
  }, []);

  useEffect(() => {
    if (createMailjetConfiguration){
      setCreateMailjetConfigurationSuccess(true);
      setTimeout(() => {
        setCreateMailjetConfigurationSuccess(false);
      }, 4000); 
    }
    else{
      setCreateMailjetConfigurationSuccess(false);
    }
  },[createMailjetConfiguration]);

  useEffect(() => {
    if (createConfiguartionError){
    setCreateMailjetConfigurationError(true)
    setTimeout(() => {
      setCreateMailjetConfigurationError(false);
    }, 4000); }
    else
    setCreateMailjetConfigurationError(false)
  }, [createConfiguartionError]);

  const handleClose = () => {
    setDialogOpen(false);
    setDialogContentText('');
  };

  const handleCountryChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setButtonDisable({ ...buttonDisable, country: false});
      setCountryValue(value);

    } else {
      setCountryErrors(true);
    }
  };

  const validateCountryErrors = (e: any) => {
    const { value } = e.target;
    if (value === '') setCountryErrors(true);
    else setCountryErrors(false);
  };
  

  const handleCustomerGroupTypeChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setButtonDisable({ ...buttonDisable, customerGroupType: false });
      setcustomerGroupTypeValue(value);
    }
  };

  const searchConfiguartion = () => {
    dispatch(fetchMailjetConfiguartionRequest({'Country':countryValue,'CustomerGroupType':customerGroupTypeValue}));
    setDeleteConfiguartionSuccess(false);
    dispatch(resetDeleteMailjetConfiguartion());
  };

  const confirmDelete = () => {
    dispatch(deleteMailjetConfiguartionRequest(deleteRowRequest));
  };

  const handleDeleteMailjet = (row: any) => {
    deleteRowRequest.mailjetTemplateId = row.mailjetTemplateId;
    deleteRowRequest.emailType = row.emailType;
    deleteRowRequest.languageCode = row.languageCode;
    deleteRowRequest.customerGroupTypeId = customerGroupTypeValue;

    setDialogContentText(`Are you sure you want to delete Mailjet Template Id - ${deleteRowRequest.mailjetTemplateId}, Email Type - ${deleteRowRequest.emailType} and  Language Code - ${deleteRowRequest.languageCode}?`)
    setDialogOpen(true);
  };

  const OpenMailjetConfigurationDialog = () => {
    formik.resetForm();
    setCreateMailjetConfigurationSuccess(false);
    setOpenMailjetConfigurationDialog(true);
  };

  interface MailjetConfigurationColumn {
    id: 'mailjetTemplateId' | 'emailType' | 'languageCode' | 'action';
    label: string;
    minWidth?: number;
    align?: string;
  }

  const mailjetConfigurationColumns: readonly MailjetConfigurationColumn[] = [
    { id: 'mailjetTemplateId', label: 'Mailjet Template Id', minWidth: 30, align: "left" },
    { id: 'emailType', label: 'Email Type', minWidth: 60, align: "left" },
    { id: 'languageCode', label: 'Language Code', minWidth: 20, align: "left" },
    { id: 'action', label: 'Delete', minWidth: 10, align: "center" }
  ];

  return (
    <div className={classes.root}>
      <ResponsiveContainer>
        <>
          <Container className={classes.container} maxWidth="lg">
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                className={clsx(classes.paper, classes.fixedHeight)}
              >
                <Card className={classes.root} variant="elevation">
                  <Grid
                    key="configurationMaster-grid-1"
                    container
                    justifyContent="flex-start"
                  >
                    <FormControl
                key="DM-country-formcontrol"
                variant="outlined"
                className={classes.formControl}
                error={hasCountryErrors || !!countriesError}
                size="small"
              >
                <InputLabel
                  key="DM-country-input-label"
                  id="DM-country-select-label"
                >Country
                </InputLabel>
                <Select
                  key="DM-country-select"
                  name="country"
                  labelId="DM-country-select-label"
                  id="DM-country-select"
                  value={countryValue}
                  defaultValue=""
                  onChange={handleCountryChange}
                  label="Country"
                  onBlur={validateCountryErrors}
                >
                  {!countriesLoading &&
                    countries.map((option: any) => (
                      <MenuItem
                        key={option.code}
                        value={option.code}
                        aria-selected={true}
                        className={classes.menuItems}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText key="DM-country-FHT">
                  {countriesLoading
                    ? 'Loading countries'
                    : countriesError
                    ? `Error loading countries - [${countriesError}]`
                    : countryValue
                    ? ''
                    : 'Please select country'}
                </FormHelperText>
              </FormControl>
                    <FormControl
                      key="DM-CustomerGroupType-formcontrol"
                      variant="outlined"
                      className={classes.formControl} size="small">
                      <InputLabel
                        key="CustomerGroupType-input-label"
                        id="CustomerGroupType-select-label"
                      >
                        Customer Group Type
                      </InputLabel>
                      <Select
                        key="customerGroupType1"
                        label="Customer Group Type"
                        value={customerGroupTypeValue}
                        id="customerGroupType1"
                        name="customerGroupType1"
                        labelId="CustomerGroupType-select-label1"
                        onChange={handleCustomerGroupTypeChange}
                        autoWidth
                      >
                        {(customerGroupTypeList || []).map((option: any) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            aria-selected={true}
                            className={classes.menuItems}
                          >
                            {option.name}
                          </MenuItem>))}
                      </Select>
                      <FormHelperText key="DM-customerGroupType-FHT1">
                        {customerGroupTypeLoading
                          ? 'Loading customer group type'
                          : customerGroupTypeError
                            ? `Error in loading customer group type - [${customerGroupTypeError}]`
                            : customerGroupTypeValue
                              ? ''
                              : 'Please select customer group type'}
                      </FormHelperText>
                    </FormControl>
                    <UserPermission component="MailJetConfiguration" permissionFor="read">
                    <FormControl
                      variant="outlined"
                      className={classes.formControlButton}
                      size="small"
                    >
                      <ButtonElement
                        buttonText="Search"
                        onClick={searchConfiguartion}
                        disabled={buttonDisable.country || buttonDisable.customerGroupType}
                      /></FormControl>
                      </UserPermission>
                    <UserPermission component="MailJetConfiguration" permissionFor="create">
                      <FormControl
                        variant="outlined"
                        className={classes.formControlButton}
                        size="small"
                      >
                        <ButtonElement
                          buttonText="Configure"
                          onClick={OpenMailjetConfigurationDialog}
                        />
                      </FormControl>
                    </UserPermission>
                  </Grid></Card>
                {(mailjetConfiguartionLoading) && (mailjetConfiguartionError == null) &&
                  <Card className={classes.spinnerRoot}>
                    <div className={classes.spinnerParent}>
                      <Spinner custom />
                    </div>
                  </Card>
                }
                {mailjetConfiguartionSuccess && (!mailjetConfiguartionLoading) && mailjetConfiguartionList.length==0 && <Alert severity="info"> No mailjet template is configured with this customer group type.</Alert>}
                {deleteSuccess && <Alert severity="success"> MailJet Configuration has been deleted .</Alert>}
                {(!deleteSuccess) && (mailjetConfiguartionSuccess) && mailjetConfiguartionList.length>0 && (!mailjetConfiguartionLoading) &&
                  <TableElement
                    columns={mailjetConfigurationColumns}
                    rows={mailjetConfiguartionList}
                    isDeleteButtonEnable={true}
                    onClick={handleDeleteMailjet}
                  />}
              </Paper>
            </Grid>
          </Container>
          <ConfirmDialog
            deleteConfiguartionLoading={deleteConfiguartionLoading}
            open={dialogOpen}
            onClose={handleClose}
            dialogContentText={dialogContentText}
            dialogTitle={dialogTitle}
            confirmDelete={confirmDelete}
            deleteConfiguartionSuccess={deleteConfiguartionSuccess} />

          <div>
            <Dialog open={openMailjetConfigurationDialog} maxWidth="sm">
            <form onSubmit={formik.handleSubmit}>
              <DialogTitle textAlign="center">Create Mailjet Configuration</DialogTitle>
              { ConfigurationSuccess && <Alert severity="success"> MailJet Configuration has been configured.</Alert>}
              { createMailjetConfigurationError && <Alert severity="error"> {`An error occurred - ${createConfiguartionError}`}</Alert>} 
              <DialogContent>
                  <Grid container justifyContent="center" item xs={11}>
                      <Grid  alignItems="center">
                        <FormControl
                          key="DM-CustomerGroupType-formcontrol"
                          variant="outlined"
                          className={classes.formControl} size="small">
                          <InputLabel
                            key="CustomerGroupType-input-label"
                            id="CustomerGroupType-select-label"
                          >
                            Customer Group Type
                          </InputLabel>
                          <Select
                            error={Boolean(
                              _.get(formik.touched, 'customerGroupType') &&
                                _.get(formik.errors, 'customerGroupType')
                            )}
                            key="DM-customerGroupType-select"
                            id="DM-customerGroupType-select"
                            name="customerGroupType"
                            labelId="CustomerGroupType-select-label"
                            value={formik.values.customerGroupType}
                            label="Customer Group Type"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange("customerGroupType")}
                            autoWidth
                          >
                            {(customerGroupTypeList || []).map((option: any) => (
                              <MenuItem
                                key={option.id}
                                value={option.id}
                                aria-selected={true}
                                className={classes.menuItems}
                              >
                                {option.name}
                              </MenuItem>))}
                          </Select>
                        </FormControl>

                      </Grid>
                      <Grid  alignItems="center">
                        <FormControl
                          key="DM-emailType-formcontrol"
                          variant="outlined"
                          className={classes.formControl}  size="small">
                            <InputLabel
                            key="emailType-input-label"
                            id="emailType-select-label"
                          >
                            Email Type
                          </InputLabel>
                          <Select
                            error={Boolean(
                              _.get(formik.touched, 'emailType') &&
                                _.get(formik.errors, 'emailType')
                            )}
                            key="DM-emailType-select"
                            id="DM-emailType-select"
                            name="emailType"
                            labelId="EmailType-select-label"
                            value={formik.values.emailType}
                            label="Email Type"
                            onChange={formik.handleChange("emailType")}
                            onBlur={formik.handleBlur}
                            autoWidth
                          >
                              <MenuItem
                                key={"ClosedForNonPaymentEmail"}
                                value={"ClosedForNonPaymentEmail"}
                                aria-selected={true}
                                className={classes.menuItems}
                              >
                                ClosedForNonPaymentEmail
                              </MenuItem>
                              <MenuItem
                                key={"BusinessOwnerClosedAccountEmail"}
                                value={"BusinessOwnerClosedAccountEmail"}
                                aria-selected={true}
                                className={classes.menuItems}
                              >
                                BusinessOwnerClosedAccountEmail
                              </MenuItem>
                              <MenuItem
                                key={"VerifyEmailAddressOtpEmail"}
                                value={"VerifyEmailAddressOtpEmail"}
                                aria-selected={true}
                                className={classes.menuItems}
                              >
                                VerifyEmailAddressOtpEmail
                              </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid  alignItems="center">
                        <FormControl
                          key="DM-mailjetId-formcontrol"
                          variant="outlined"
                          className={classes.formControl} >
                          <TextField
                            error={Boolean(
                              _.get(formik.touched, 'mailjetId') &&
                                _.get(formik.errors, 'mailjetId')
                            )}
                            helperText={formik.touched.mailjetId && formik.errors.mailjetId}
                            value={formik.values.mailjetId}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            id="mailjetId"
                            name="mailjetId"
                            label="Mailjet Template Id"
                            variant="outlined"  size="small" />
                        </FormControl>
                      </Grid>
                      <Grid alignItems="center">
                        <FormControl
                          key="DM-languageCode-formcontrol"
                          variant="outlined"
                          className={classes.formControl}>
                          <TextField
                            error={Boolean(
                              _.get(formik.touched, 'languageCode') &&
                                _.get(formik.errors, 'languageCode')
                            )}
                            helperText={formik.touched.languageCode && formik.errors.languageCode}
                            value={_.get(formik.values, 'languageCode')} //{formik.values.languageCode}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            id="languageCode"
                            name="languageCode"
                            label="Language Code"
                            variant="outlined"   
                            size="small" />
                        </FormControl>
                      </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions} key={'dialogActions'} >
              {createConfiguartionLoading?
                  (<CircularProgress className={classes.circularProgress} />): 
                    (<>
                          <Button
                            variant="contained"
                            size="small"
                            className={!(formik.isValid && formik.dirty)?classes.disabledButton:classes.buttonIcon}
                            title="Submit"
                            type="submit"
                            disabled={!(formik.isValid && formik.dirty)}>Submit</Button>   
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.buttonIcon}
                            title="Cancel"
                            onClick={() => {
                              setCreateMailjetConfigurationSuccess(false);
                              setCreateMailjetConfigurationError(false);
                              setOpenMailjetConfigurationDialog(false);
                            }}
                          >Cancel</Button>
                          </>
                          )}
              </DialogActions>
              </form>
            </Dialog>
          </div>
        </>
      </ResponsiveContainer>
    </div>
  );

}
const mapStateToProps = (state: any) => {
  return {
    customerGroupTypeList: state.customerGroupTypeList.customerGroupTypes,
    customerGroupTypeLoading: state.customerGroupTypeList.pending,
    customerGroupTypeError: state.customerGroupTypeList.error,

    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,

    mailjetConfiguartionList: state.mailjetConfiguartionList.mailjetConfiguartionList,
    mailjetConfiguartionLoading: state.mailjetConfiguartionList.pending,
    mailjetConfiguartionError: state.mailjetConfiguartionList.error,
    mailjetConfiguartionSuccess: state.mailjetConfiguartionList.success,

    deleteMailjetConfiguration: state.deleteMailjetConfiguration.DeleteMailjetConfiguartion,
    deleteConfiguartionLoading: state.deleteMailjetConfiguration.pending,
    deleteConfiguartionSuccess: state.deleteMailjetConfiguration.success,
    deleteConfiguartionError: state.deleteMailjetConfiguration.error,

    createMailjetConfiguration: state.createMailjetConfiguration.CreateMailjetConfiguartion,
    createConfiguartionLoading: state.createMailjetConfiguration.pending,
    createConfiguartionError: state.createMailjetConfiguration.error,
  };
};

export default connect(mapStateToProps)(ConfigurationMaster);


