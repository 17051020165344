  import { Card, Typography } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import React from 'react';
  import { connect, useDispatch } from 'react-redux';
  import { useFormik } from 'formik';
  import { createCardTypeRequest, updateCardTypeRequest } from '../../store/offeringPackages/actions';
  import Spinner from '../Spinner';
  import Alert from '@material-ui/lab/Alert';
  import _ from 'lodash';
  import { manageOfferSchema } from '../../schemas/manageOfferSchema';
  import { DealMasterForm } from './DealMasterForm';
  
  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
        fontFamily: 'Shell-Book',
      },
      textAlign: 'left',
      margin: '10px 0px',
    }
  }));
  
  const offerDetailsTextItems: {
    cardType: { key: string; label: string, type: string }[];
  } = {
    cardType: [
        { key: 'cardType.name', label: 'Name', type: 'text' },
        { key: 'cardType.id', label: 'Card Type ID', type: 'number' },
      ]
  };
  
  const initialValues: any = {
    category: '',
    subCategory: 'cardType',
    cardType: { countryCode: '', name: '', id: '' },
  };
  
  const CreateUpdateCardType = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
      offeringPackage,
      countryValue,
      cardTypeCreated,
      cardTypeUpdated,
      offeringPackageError,
      setButtonDisable,
      isDealMasterEdit,
      setEditDealMaster,
      cardTypeInitialValues,
      setBtnClick,
      buttonDisable,
      setCreateUpdateStatus,
      createUpdateStatus
    } = props;

    const currencyId = offeringPackage.currencyId;
    
    const formik = useFormik({
      initialValues: isDealMasterEdit ? cardTypeInitialValues : initialValues,
      validationSchema: manageOfferSchema,
      onSubmit: (values , { setSubmitting, resetForm }) => {
        formatValues(values, countryValue, dispatch,
           setButtonDisable, isDealMasterEdit, buttonDisable);
        setSubmitting(false);
        resetForm();
      },
    });

    _.set(formik.values.cardType, 'name', _.get(formik.values.cardType, 'name').replace(
      `${countryValue} `,
      ''
    ));

    if (
      !formik.isSubmitting &&
      countryValue !== '' &&
      (Object.keys(cardTypeCreated.response).length > 0 ||
      cardTypeCreated.error ||
        Object.keys(cardTypeUpdated.response).length > 0 ||
        cardTypeUpdated.error
    )
    ) {
      setCreateUpdateStatus({...createUpdateStatus, flag:true, screen: isDealMasterEdit? 'update' : 'create'});
      setBtnClick({ createBtn: false });
      setEditDealMaster(false);
      setButtonDisable({ ...buttonDisable, create:false});
    }
  
    if (countryValue === '') {
      return <></>;
    } else if (
      countryValue !== '' &&
      (cardTypeCreated.pending || cardTypeUpdated.pending)
    ) {
      return (
        <Card
          className={classes.root}
          style={{ textAlign: 'center' }}
          variant="elevation"
        >
          <Spinner
            custom
            title={isDealMasterEdit ? 'Updating the Card Type...' : 'Creating the Card Type...'}
          />
        </Card>
      );
    } 
    else if (
      countryValue !== '' &&
      Object.keys(offeringPackage).length > 0 &&
      !offeringPackageError
    ) {
      return (
        <>
          <DealMasterForm
            countryValue={countryValue}
            setButtonDisable={setButtonDisable}
            isDealMasterEdit={isDealMasterEdit}
            setEditDealMaster={setEditDealMaster}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            formik={formik}
            screenName='Card Type'
            screenType='cardType'
            currencyId={currencyId}
            offerDetailsTextItems={offerDetailsTextItems}
          />
        </>
      );
    } else {
      return (
        <Typography>
          <Alert severity="error">{`Error fetching Master Data for the Country - [${offeringPackageError}]`}</Alert>
        </Typography>
      );
    }
  };

  const mapStateToProps = (state: any) => {
    return {
      countries: state.countries.countries,
      countriesLoading: state.countries.pending,
      countriesError: state.countries.error,
      offeringPackage: state.offeringPackage.offeringPackage,
      offeringPackageResp: state.offeringPackage,
      offeringPackageLoading: state.offeringPackage.pending,
      OfferingPackageError: state.offeringPackage.error,
      cardTypeCreated: state.cardTypeCreated,
      cardTypeUpdated: state.cardTypeUpdated,
    };
  };
  
  export default connect(mapStateToProps)(CreateUpdateCardType);
  

  function formatValues(
    values: any,
    countryCode: string,
    dispatch: any,
    setButtonDisable: any,
    isDealMasterEdit: any,
    buttonDisable: any
  ) {
      values.cardType.countryCode = countryCode;
      setButtonDisable({ ...buttonDisable, create: true });
      if (isDealMasterEdit) {
        dispatch(updateCardTypeRequest(values.cardType));
      } else {
        dispatch(createCardTypeRequest(values.cardType));
      }
    };
  
  