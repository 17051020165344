import {
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
} from './actionTypes';
import { CountriesAction, CountriesState } from './types';

const initialState: CountriesState = {
  pending: false,
  countries: [],
  error: null,
};

export const countriesReducer = (
  state = initialState,
  action: CountriesAction
) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        pending: false,
        countries: action.payload.countries,
        error: null,
      };
    case FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        pending: false,
        countries: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
