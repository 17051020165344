import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import { fetchCustomerInvoiceDetailsRequest, resetFetchSOADetails, fetchSOADetailsRequest } from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';
import InvoiceTable from './InvoiceTable';
import SOATable from './SOATable';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 8px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function UserFinanceDetails(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    customerProfile,
    customerInvoiceDetails,
    customerInvoiceDetailsLoading,
    customerInvoiceDetailsError,
    soaDetails,
    soaDetailsLoading,
    soaDetailsError
  } = props;
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    dispatch(resetFetchSOADetails());
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerInvoiceDetails).length === 0) {
      dispatch(
        fetchCustomerInvoiceDetailsRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
    if (Object.keys(soaDetails).length === 0) {
      dispatch(
        fetchSOADetailsRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box sx={{ m: 2 }}>
          <Box className={classes.boxTitle} onClick={handleExpandClick}>
            <Typography
              component="div"
              sx={{
                textAlign: 'left',
                fontFamily: 'Shell-Book',
                fontWeight: expanded ? '600 !important' : '',
                color: expanded ? '#595959' : '',
                fontSize: '.9rem',
              }}
            >
              Financial Details
            </Typography>
            {!expanded ? (
              <ControlPointIcon
                color="action"
                fontSize="small"
              ></ControlPointIcon>
            ) : (
              <RemoveCircleOutlineIcon
                color="action"
                fontSize="small"
              ></RemoveCircleOutlineIcon>
            )}
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <CardContent className={classes.cardContainer}>
            <Box sx={{ width: '100%' }}>
              {!customerInvoiceDetailsLoading ? (
                Object.keys(customerInvoiceDetails).length > 0 ? (
                  <>
                    <InvoiceTable
                      customerInvoiceDetails={customerInvoiceDetails}
                    ></InvoiceTable>
                  </>
                ) : (
                  <>
                    {customerInvoiceDetailsError ? (
                      <Alert severity="error">
                        {`Error loading invoice details - [${customerInvoiceDetailsError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No invoice details found!</Alert>
                    )}
                  </>
                )
              ) : (
                <>
                  <SkeletonComponent></SkeletonComponent>
                </>
              )}
               {!soaDetailsLoading ? (
                Object.keys(soaDetails).length > 0 ? (
                  <>
                    <SOATable
                      soaDetails={soaDetails}
                    ></SOATable>
                  </>
                ) : (
                  <>
                    {soaDetailsError ? (
                      <Alert severity="error">
                        {`Error loading soa details - [${soaDetailsError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No Statement of Account details found!</Alert>
                    )}
                  </>
                )
              ) : (
                <>
                  <SkeletonComponent></SkeletonComponent>
                </>
              )}

            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerInvoiceDetails: state.customerInvoiceDetails.customerInvoiceDetails,
    customerInvoiceDetailsLoading: state.customerInvoiceDetails.pending,
    customerInvoiceDetailsError: state.customerInvoiceDetails.error,
    soaDetails: state.soaDetails.soaDetails,
    soaDetailsLoading: state.soaDetails.pending,
    soaDetailsError: state.soaDetails.error
  };
};

export default connect(mapStateToProps)(UserFinanceDetails);
