import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    '& p': { fontFamily: 'Shell-Book', color: 'rgba(0, 0, 0, 0.87)' },
    padding: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography align="right" variant="body2">
        {'Copyright © '}
        {new Date().getFullYear()}.
      </Typography>
    </div>
  );
};

export default Footer;
