import axios from 'axios';
import * as Session from '../store/session';
import { apiEndpoint } from '../constants/apis';

const AxiosInstance = axios.create({
  baseURL: apiEndpoint(),
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
  timeout: 30 * 1000, // Api request timeout set to 30s
});

AxiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!(await Session.isValidSession())) {
        window.location.reload();
        return error.response;
      }
    } else {
      return error.response;
    }
  }
);

export default AxiosInstance;
