import {
  Paper,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Card,
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../Spinner';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';
import {
  fetchLegalEntityRequest,
  resetCreateLegalEntityRequest,
  resetUpdateLegalEntityRequest,
} from '../../store/offeringPackages/actions';
import { FetchLegalEntityRequestPayload } from '../../store/offeringPackages/types';
import CreateUpdateLegalEntity from './CreateUpdateLegalEntity';
import { DealMasterTable } from './DealMasterTable';
import { handleNumberValidation } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
  },
  helperText: {
    marginLeft: 10,
  },
  chips: {
    margin: 1,
    background: '#aaaaaa50',
  },
  searchIcon: {
    '&:hover': { background: '#fbcf04d1' },
    '&.MuiIconButton-root.Mui-disabled': { background: '#ddddddcf' },
    background: '#fbcf04',
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
  },
}));

let legalEntityInitialValues: any = {
  category: '',
  subCategory: 'legalEntity',
  legalEntity: {
    countryCode: '',
    marketId: '',
    name: '',
    id: '',
    ipaasCode: '',
  },
};

const headers = [
  { label: 'Id', key: 'id' },
  { label: 'Legal Entity Name', key: 'name' },
  { label: 'Action', key: 'id' },
];

function ViewLegalEntity(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const {
    legalEntities,
    countryValue,
    legalEntitiesLoading,
    legalEntityPage,
    legalEntitiesError,
    legalEntityCreated,
    legalEntityUpdated,
    editDealMaster,
    setButtonDisable,
    setEditDealMaster,
    buttonDisable,
    setBtnClick,
    createUpdateStatus,
    setCreateUpdateStatus,
  } = props;

  if (legalEntitiesLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleSearch = () => {
    dispatch(
      fetchLegalEntityRequest({
        countryCode: countryValue,
        legalEntityId: searchText,
      })
    );
  };

  const handleChangePage = (_event: any, page: any) => {
    const payload: FetchLegalEntityRequestPayload = {
      countryCode: countryValue,
      pageNumber: page + 1,
      legalEntityId: searchText,
    };
    dispatch(fetchLegalEntityRequest(payload));
  };

  const handleEdit = (event: any) => {
    const legal = legalEntities.filter(
      (data: any) => data.id == event.currentTarget.id
    );
    legalEntityInitialValues.legalEntity = legal[0];
    resetLegalEntity();
    setButtonDisable({ ...buttonDisable, create: false });
    setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
    setEditDealMaster(true);
  };

  function resetLegalEntity() {
    if (
      Object.keys(legalEntityCreated.response).length > 0 ||
      legalEntityCreated.error ||
      Object.keys(legalEntityUpdated.response).length > 0 ||
      legalEntityUpdated.error
    ) {
      dispatch(resetCreateLegalEntityRequest());
      dispatch(resetUpdateLegalEntityRequest());
    }
  }

  function HandleAlert() {
    if (
      (!legalEntityUpdated.pending && legalEntityUpdated.response.success) ||
      (!legalEntityCreated.pending && legalEntityCreated.response.success)
    ) {
      return (
        <Alert key={'DMS-handleAlert-success'} severity="success">
          {createUpdateStatus.screen === 'update'
            ? ` Legal Entity updated Successfully.`
            : ` Legal Entity created Successfully.  ${JSON.stringify(
                legalEntityCreated.response.data,
                null,
                4
              )}.`}
        </Alert>
      );
    } else {
      return (
        <Alert key={'DMS-handleAlert-error'} severity="error">
          {`Error creating legal entity - [${
            legalEntityCreated.error ||
            legalEntityCreated.response.message ||
            legalEntityUpdated.error ||
            legalEntityUpdated.response.message
          }]`}
        </Alert>
      );
    }
  }

  return (
    <>
      {editDealMaster ? (
        <>
          <CreateUpdateLegalEntity
            isDealMasterEdit={editDealMaster}
            legalEntityInitialValues={legalEntityInitialValues}
            countryValue={countryValue}
            setEditDealMaster={setEditDealMaster}
            setButtonDisable={setButtonDisable}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            createUpdateStatus={createUpdateStatus}
            setCreateUpdateStatus={setCreateUpdateStatus}
          />
        </>
      ) : (
        <>
          {createUpdateStatus.flag ? (
            <Card className={classes.root} variant="elevation">
              <HandleAlert />
            </Card>
          ) : (
            <></>
          )}
          <div className="search-result">
            <div className="search-result-text">Legal Entity Result</div>
            <div>
              <FormControl
                variant="outlined"
                className={classes.inputFormControl}
                error={false}
                size="small"
              >
                <TextField
                  name="searchLegalEntityText"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label="Search Legal Entity Id"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  onKeyPress={handleNumberValidation}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleSearch}
                        className={classes.searchIcon}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
          <ResponsiveContainer>
            {!legalEntitiesLoading && legalEntities.length > 0 ? (
              <Paper className={classes.root}>
                <DealMasterTable
                  headers={headers}
                  data={legalEntities}
                  page={legalEntityPage}
                  handleEdit={handleEdit}
                  handleChangePage={handleChangePage}
                />
              </Paper>
            ) : (
              <Typography
                style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}
              >
                {legalEntitiesError ? (
                  <Alert severity="error">
                    {`Error loading Legal Entities - [${legalEntitiesError}]`}
                  </Alert>
                ) : (
                  'No Legal Entity found'
                )}
              </Typography>
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    legalEntities: state.legalEntities.legalEntities,
    legalEntitiesLoading: state.legalEntities.pending,
    legalEntitiesError: state.legalEntities.error,
    legalEntityPage: state.legalEntities.page,
    legalEntityCreated: state.legalEntityCreated,
    legalEntityUpdated: state.legalEntityUpdated,
  };
};
export default connect(mapStateToProps)(ViewLegalEntity);
