import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Grid } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import { fetchCustomerGfnaccountRequest } from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  gfnRow: {
    marginTop: '6px',
  },
  collapse: {
    padding: '16px',
    paddingTop: '8px',
    backgroundColor: '#F7F7F7',
  },
}));

interface gfn {
  id: string;
  value: any;
  label: string;
}

function GfnAccount(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const {
    customerProfile,
    customerGfnaccount,
    customerGfnaccountLoading,
    customerGfnaccountError,
  } = props;
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [gfnMap, setGfnMap] = React.useState([
    { id: '', value: '', label: '' },
  ]);

  if (customerGfnaccountLoading) {
    if (isDataFetched) {
      setIsDataFetched(false);
    }
  } else if (!customerGfnaccountLoading && !isDataFetched) {
    if (Object.keys(customerGfnaccount).length > 0) {
      loadMap();
    } else {
      setGfnMap([]);
    }
    setIsDataFetched(true);
  }

  function loadMap() {
    const gfnAccountDataMap: gfn[] = [
      {
        id: 'accountId',
        value: customerGfnaccount.accountId,
        label: 'Account ID',
      },
      {
        id: 'accountNumber',
        value: customerGfnaccount.accountNumber,
        label: 'Account Number',
      },
      {
        id: 'payerId',
        value: customerGfnaccount.payerId,
        label: 'Payer ID',
      },
      {
        id: 'payerNumber',
        value: customerGfnaccount.payerNumber,
        label: 'Payer Number',
      },
      {
        id: 'creditLimit',
        value: customerGfnaccount.creditLimit,
        label: 'Credit Limit',
      },
      {
        id: 'billingFrequency',
        value: customerGfnaccount.billingFrequency,
        label: 'Billing Frequency',
      },
    ];
    setGfnMap(gfnAccountDataMap);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerGfnaccount).length === 0) {
      dispatch(
        fetchCustomerGfnaccountRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.boxTitle} onClick={handleExpandClick}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            GFN Account
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {isDataFetched ? (
              <>
                {gfnMap.length > 0 ? (
                  gfnMap.map(({ label, value, id }) => {
                    return (
                      <Box className={classes.box} key={id}>
                        <Grid container className={classes.gfnRow}>
                          <Grid item xs={6}>
                            {label}
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ color: 'text.secondary' }}>{value}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                ) : (
                  <>
                    {customerGfnaccountError ? (
                      <Alert severity="error">
                        {`Error loading GFN Account - [${customerGfnaccountError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">
                        No GFN account details found!
                      </Alert>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box className={classes.box}>
                <SkeletonComponent></SkeletonComponent>
              </Box>
            )}
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerGfnaccount: state.customerGfnaccount.customerGfnaccount,
    customerGfnaccountLoading: state.customerGfnaccount.pending,
    customerGfnaccountError: state.customerGfnaccount.error,
  };
};

export default connect(mapStateToProps)(GfnAccount);
