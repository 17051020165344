export const FETCH_DEALS_REQUEST = 'FETCH_DEALS_REQUEST';
export const FETCH_DEALS_SUCCESS = 'FETCH_DEALS_SUCCESS';
export const FETCH_DEALS_FAILURE = 'FETCH_DEALS_FAILURE';
export const CREATE_DEALS_REQUEST = 'CREATE_DEALS_REQUEST';
export const CREATE_DEALS_SUCCESS = 'CREATE_DEALS_SUCCESS';
export const CREATE_DEALS_FAILURE = 'CREATE_DEALS_FAILURE';
export const RESET_CREATE_DEALS_REQUEST = 'RESET_CREATE_DEALS_REQUEST';
export const FETCH_DEAL_DETAILS_REQUEST = 'FETCH_DEAL_DETAILS_REQUEST';
export const FETCH_DEAL_DETAILS_SUCCESS = 'FETCH_DEAL_DETAILS_SUCCESS';
export const FETCH_DEAL_DETAILS_FAILURE = 'FETCH_DEAL_DETAILS_FAILURE';
export const RESET_FETCH_DEAL_DETAILS = 'RESET_FETCH_DEAL_DETAILS';
export const UPDATE_DEALS_REQUEST = 'UPDATE_DEALS_REQUEST';
export const UPDATE_DEALS_SUCCESS = 'UPDATE_DEALS_SUCCESS';
export const UPDATE_DEALS_FAILURE = 'UPDATE_DEALS_FAILURE';
export const RESET_UPDATE_DEALS_REQUEST = 'RESET_UPDATE_DEALS_REQUEST';
export const FETCH_ALL_DEALS_REQUEST = 'FETCH_ALL_DEALS_REQUEST';
export const FETCH_ALL_DEALS_SUCCESS = 'FETCH_ALL_DEALS_SUCCESS';
export const FETCH_ALL_DEALS_FAILURE = 'FETCH_ALL_DEALS_FAILURE';
export const CREATE_OFFERS_REQUEST = 'CREATE_OFFERS_REQUEST';
export const CREATE_OFFERS_SUCCESS = 'CREATE_OFFERS_SUCCESS';
export const CREATE_OFFERS_FAILURE = 'CREATE_OFFERS_FAILURE';
export const UPDATE_OFFERS_REQUEST = 'UPDATE_OFFERS_REQUEST';
export const UPDATE_OFFERS_SUCCESS = 'UPDATE_OFFERS_SUCCESS';
export const UPDATE_OFFERS_FAILURE = 'UPDATE_OFFERS_FAILURE';
export const RESET_CREATE_OFFERS_REQUEST = 'RESET_CREATE_OFFERS_REQUEST';
export const RESET_UPDATE_OFFERS_REQUEST = 'RESET_UPDATE_OFFERS_REQUEST';
