import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardAction: {
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
    marginTop: '8px',
    paddingLeft: '8px',
    height: '130px',
    overflow: 'auto',
  },
  addressRow: {
    '& .MuiGrid-item': {
      marginTop: '5px',
    },
  },
}));

interface customerAddress {
  i: number;
  addressType: string;
  addresLine1: string;
  addresLine2: string;
  town: string;
  postCode: string;
  countryName: string;
}

function UserAddress(props: any) {
  const classes = useStyles();
  const customerAddressData = props.customerAddressData;

  const customerAddressDataMap: customerAddress[] = JSON.parse(
    JSON.stringify(customerAddressData)
  ).map((address: any, i: number) => {
    return {
      i: i,
      addressType: address.addressType || '-',
      addresLine1: address.addresLine1 || '-',
      addresLine2: address.addresLine2 || '-',
      town: address.town || '-',
      postCode: address.postCode || '-',
      countryName: address.countryName || '-',
    };
  });

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        {customerAddressDataMap.map(
          ({
            i,
            addressType,
            addresLine1,
            addresLine2,
            town,
            postCode,
            countryName,
          }) => {
            return (
              <Box key={i}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                      {addressType}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.addressRow}>
                      <Grid item xs={3}>
                        Address Line 1
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ color: 'text.secondary' }}>
                          {addresLine1}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        Address Line 2
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ color: 'text.secondary' }}>
                          {addresLine2}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        Town
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ color: 'text.secondary' }}>{town}</Box>
                      </Grid>

                      <Grid item xs={3}>
                        Post Code
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ color: 'text.secondary' }}>{postCode}</Box>
                      </Grid>

                      <Grid item xs={3}>
                        Country Name
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ color: 'text.secondary' }}>
                          {countryName}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider variant="middle" sx={{ my: 1, mx: 0 }} />
              </Box>
            );
          }
        )}
      </Box>
    </div>
  );
}

export default UserAddress;
