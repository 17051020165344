import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ReactNode } from 'react';
import React from 'react';

import { DrawerContextProvider } from '../contexts/drawer-context';

import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid #e0e0e0',
  },
  main: {
    flex: 1,
  },
  customToolbar: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

type Props = {
  children: NonNullable<ReactNode>;
};

const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <DrawerContextProvider>
      <div className={classes.root}>
        <Header />
        <Toolbar className={classes.customToolbar} />
        <div className={classes.container}>
          <Drawer />
          <main className={classes.main}>{children}</main>
        </div>
        <Footer />
      </div>
    </DrawerContextProvider>
  );
};

export default Layout;
