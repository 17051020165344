import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { getTimeDiff } from './helper';

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
}));

function PaymentRegistration(props: any) {
  const classes = useStyles();
  const paymentRegistrationRequest = props.paymentRegistrationRequest;

  interface Data {
    registrationDeclined: boolean;
    created: string;
    modified: string;
    error: string;
  }

  function createData(
    registrationDeclined: boolean,
    created: string,
    modified: string,
    error: string
  ): Data {
    return {
      registrationDeclined,
      created,
      modified,
      error,
    };
  }

  const rows = paymentRegistrationRequest.map((pay: any) =>
    createData(
      pay.registrationDeclined,
      getTimeDiff(pay.created),
      getTimeDiff(pay.modified),
      pay.error
    )
  );

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'registrationDeclined',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'created',
      numeric: true,
      disablePadding: false,
      label: 'Created',
    },
    {
      id: 'modified',
      numeric: true,
      disablePadding: false,
      label: 'Modified',
    },
    {
      id: 'error',
      numeric: true,
      disablePadding: false,
      label: 'Error',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FCD839' }}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 1 },
          pr: { xs: 1, sm: 1 },
          textAlign: 'start',
          minHeight: '48px !important',
        }}
      >
        <Typography
          sx={{
            flex: '1 1 100%',
            fontSize: '0.8rem',
            fontWeight: 600,
            fontFamily: 'Shell-Book',
          }}
          id="tableTitle"
          component="div"
        >
          Payment Registration Request
        </Typography>
      </Toolbar>
    );
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2, border: '1px solid #D9D9D9' }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          color: row.registrationDeclined
                            ? '#E44A4D'
                            : '#339D69',
                        }}
                      >
                        {row.registrationDeclined !== null
                          ? row.registrationDeclined
                            ? 'Failure'
                            : 'Success'
                          : '-'}
                      </TableCell>
                      <TableCell>{row.created || '-'}</TableCell>
                      <TableCell>{row.modified || '-'}</TableCell>

                      <TableCell>
                        {(row.error && (
                          <Tooltip title={row.error}>
                            <ErrorIcon
                              sx={{ color: '#E44A4D' }}
                              fontSize="small"
                            ></ErrorIcon>
                          </Tooltip>
                        )) ||
                          '-'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 30 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className={classes.tableFooter}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </Paper>
    </>
  );
}

export default PaymentRegistration;
