import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import { DrawerItem } from '../ts';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { useLocation } from 'react-router-dom';
import UserPermission from '../store/UserPermission';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#000000cf',
      fontSize: '14px',
    },
    button: {
      '&:hover': {
        backgroundColor: '#eeeeee',
        color: '#000000cf',
      },
      '&$selected': {
        backgroundColor: '#dddddd',
        color: '#000000cf',
      },
    },
    selected: {},
    listIcon: {
      minWidth: 'auto',
      paddingRight: theme.spacing(2),
    },
    icon: {
      color: '#484848',
      fontSize: 20,
    },
    listText: {
      '& span': {
        fontFamily: 'Shell-Book',
        fontSize: '0.85rem',
      },
    },
    list: {
      '& .MuiButtonBase-root': {
        paddingLeft: '50px',
      },
    },
  })
);

type Props = DrawerItem & {
  selected?: boolean;
  onClick?: () => void;
};

const MenuItem: React.FC<Props> = ({
  route,
  literal,
  Icon,
  selected,
  onClick,
  items,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const isExpandable = items && items.length > 0;
  const { pathname } = useLocation();

  function handleClick() {
    setOpen(!open);
  }

  const link = (
    <ListItem
      button
      selected={selected}
      classes={{
        root: classes.root,
        selected: classes.selected,
        button: classes.button,
      }}
      onClick={() => {
        onClick;
        handleClick();
      }}
    >
      <ListItemIcon className={classes.listIcon}>
        <Icon className={classes.icon} />
      </ListItemIcon>
      <ListItemText primary={literal} className={classes.listText} />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItem>
  );

  const menuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding className={classes.list}>
        {items.map((item: any) => (
          <UserPermission
            component={item.componentName}
            permissionFor="read"
            key={item.componentName}
          >
            <MenuItem
              Icon={item.Icon}
              literal={item.literal}
              route={item.route}
              key={item.route}
              selected={
                pathname === '/' && item.route === '/admin'
                  ? true
                  : pathname === item.route
              }
              items={item.items}
              componentName={item.componentName}
            />
          </UserPermission>
        ))}
      </List>
    </Collapse>
  ) : null;

  return route ? (
    <>
      <Link style={{ textDecoration: 'none' }} to={route}>
        {link}
      </Link>
      <Link style={{ textDecoration: 'none' }} to={route}>
        {menuItemChildren}
      </Link>
    </>
  ) : (
    link
  );
};

export default MenuItem;
