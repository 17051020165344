import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import DashboardIcon from '@material-ui/icons/AssessmentOutlined';
import PersonIcon from '@material-ui/icons/SupervisorAccountOutlined';
import DealIcon from '@material-ui/icons/LocalOfferOutlined';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { DrawerItem } from '../ts';
import MenuItem from './MenuItem';

import AddIcon from '@material-ui/icons/Add';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ListIcon from '@mui/icons-material/List';
import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined';
import CardType from '@material-ui/icons/CreditCardOutlined';
import LegalEntity from '@material-ui/icons/PolicyOutlined';
import UserPermission from '../store/UserPermission';

const useStyles = makeStyles(() => ({
  padding: {
    padding: 0,
    textDecoration: 'none',
  },
}));

const ROUTES = {
  main: '/admin',
  user: {
    user: '/admin/user-management',
    search: '/admin/user/search-user',
  },
  token: '/admin/token-management',
  deal: '/admin/deal-management',
  configuration: {
    configuration: '/admin/configuration',
    mailjet: '/admin/configuration/mailjet',
    appConfiguration: '/admin/configuration/appConfiguration',
  },
  dealMaster: {
    dealMaster: '/admin/deal-master',
    feeGroup: '/admin/fee-group',
    priceProfile: '/admin/price-profile',
    tokenType: '/admin/token-type',
    cardType: '/admin/card-type',
    legalEntity: '/admin/legal-entity',
  },
};

const DRAWER_LIST: DrawerItem[] = [
  {
    route: ROUTES.main,
    literal: 'Dashboard',
    Icon: DashboardIcon,
    items: [],
    componentName: '',
  },
  {
    route: ROUTES.user.user,
    literal: 'Customer Management',
    Icon: PersonIcon,
    items: [{
      route: ROUTES.user.search,
      literal: 'Customer List',
      Icon: ListIcon,
      componentName: 'CustomerList',
    }],
    componentName: 'Customer',
  },
  {
    route: ROUTES.token,
    literal: 'Token Management',
    Icon: ConfirmationNumberIcon,
    items: [],
    componentName: 'Tokens',
  },
  {
    route: ROUTES.deal,
    literal: 'Deal Management',
    Icon: DealIcon,
    items: [],
    componentName: 'Deals',
  },
  {
    route: ROUTES.configuration.configuration,
    literal: 'Configuration',
    Icon: DealIcon,
    items: [{
      route: ROUTES.configuration.mailjet,
      literal: 'Mailjet',
      Icon: LocalAtmIcon,
      componentName: 'Mailjet',
    },
    {
      route: ROUTES.configuration.appConfiguration,
      literal: 'App Configuration',
      Icon: LocalAtmIcon,
      componentName: 'AppConfiguration',
    }
  ]
  },
  {
    route: ROUTES.dealMaster.dealMaster,
    literal: 'Deal Master',
    Icon: AddIcon,
    items: [
      {
        route: ROUTES.dealMaster.feeGroup,
        literal: 'Fee Group',
        Icon: LocalAtmIcon,
        componentName: 'FeeGroup',
      },
      {
        route: ROUTES.dealMaster.priceProfile,
        literal: 'Price Profile',
        Icon: AssignmentIcon,
        componentName: 'PriceProfile',
      },
      {
        route: ROUTES.dealMaster.tokenType,
        literal: 'Token Type',
        Icon: ConfirmationNumberIcon,
        componentName: 'TokenType',
      },
      {
        route: ROUTES.dealMaster.cardType,
        literal: 'Card Type',
        Icon: CardType,
        componentName: 'CardType',
      },
      {
        route: ROUTES.dealMaster.legalEntity,
        literal: 'Legal Entity',
        Icon: LegalEntity,
        componentName: 'LegalEntity',
      },
    ],
  },
  /* {
    route: ROUTES.user,
    literal: 'User Management',
    Icon: PersonIcon,
  },*/
];

const MenuItemsList = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <Grid>
      <List className={classes.padding}>
        {DRAWER_LIST.map(({ literal, route, Icon, items, componentName }) => (
          <UserPermission
            component={componentName}
            permissionFor="read"
            key={route}
          >
            <MenuItem
              Icon={Icon}
              literal={literal}
              route={route}
              key={route}
              selected={
                pathname === '/' && route === '/admin'
                  ? true
                  : pathname === route
              }
              items={items}
              componentName={componentName}
            />
          </UserPermission>
        ))}
      </List>
    </Grid>
  );
};

export default MenuItemsList;
