import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { getTimeDiff } from './helper';
import Divider from '@mui/material/Divider';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Collapse from '@mui/material/Collapse';
import { fetchCustomerOnboardingRequest } from '../../store/user/actions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkeletonComponent from './SkeletonComponent';
import Alert from '@mui/material/Alert';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 8px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  step: {
    '& .MuiStepConnector-root': {
      marginLeft: '8px !important',
    },
  },
  labelText: {
    overflowWrap: 'break-word',
    fontSize: '0.8rem',
  },
  rootStep: {
    '& .MuiStepIcon-root.Mui-active': { color: '#339D69', height: '20px' },
    '& .MuiStepIcon-root.Mui-completed': {
      color: '#339D69',
      height: '20px',
    },
    '& .MuiStepIcon-root': { color: '#339D69', height: '20px' },
    '& .MuiStepConnector-root': { marginLeft: '8px' },
    marginTop: '0 !important',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  collapse: {
    padding: '16px',
    paddingTop: '8px',
    backgroundColor: '#F7F7F7',
  },
}));

interface customerOnboarding {
  id: string;
  label: string;
  modified: string;
  color: string;
  labelColor: string;
}

function UserOnboarding(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [customerMap, setCustomerMap] = React.useState<customerOnboarding[]>(
    []
  );
  const {
    customerProfile,
    customerOnboarding,
    customerOnboardingLoading,
    customerOnboardingError,
  } = props;

  if (customerOnboardingLoading) {
    if (isDataFetched) {
      setIsDataFetched(false);
    }
  } else if (
    !customerOnboardingLoading &&
    Object.keys(customerOnboarding).length > 0 &&
    !isDataFetched
  ) {
    loadMap();
    setIsDataFetched(true);
  }

  function loadMap() {
    const data: customerOnboarding[] = JSON.parse(
      JSON.stringify(customerOnboarding.customerSaga)
    ).map((label: any) => {
      return {
        id: label.id,
        label: label.description,
        modified: label.modified,
        color: label.isSuccessful ? '#339D69' : '#E44A4D',
        labelColor: label.isSuccessful ? '#595959' : '#E44A4D',
      };
    });
    setCustomerMap(data);
  }

  React.useEffect(() => {
    if ([1, 2].indexOf(props.status) !== -1) handleExpandClick();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (Object.keys(customerOnboarding).length === 0) {
      dispatch(
        fetchCustomerOnboardingRequest({
          customerId: customerProfile.customerId,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.boxTitle} onClick={handleExpandClick}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            Onboarding Status
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {isDataFetched ? (
              <Stepper
                activeStep={1}
                orientation="vertical"
                sx={{ mt: 2 }}
                className={classes.rootStep}
              >
                {customerMap.length > 0 ? (
                  customerMap.map((label: any) => {
                    return (
                      <Step key={label.id} className={classes.step}>
                        <StepLabel
                          StepIconProps={{
                            sx: { color: label.color },
                          }}
                          icon={
                            <CircleIcon
                              sx={{
                                color: label.color,

                                fontSize: 'medium',
                              }}
                            />
                          }
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              <div
                                className={classes.labelText}
                                style={{ color: label.labelColor }}
                              >
                                {label.label}
                              </div>
                            </Grid>

                            <Typography
                              sx={{
                                fontSize: 10,
                                float: 'right',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              color="text.secondary"
                            >
                              {getTimeDiff(label.modified)}
                            </Typography>
                          </Grid>
                        </StepLabel>
                      </Step>
                    );
                  })
                ) : (
                  <>
                    {customerOnboardingError ? (
                      <Alert severity="error">
                        {`Error loading Onboarding Status - [${customerOnboardingError}]`}
                      </Alert>
                    ) : (
                      <Alert severity="info">No Onboarding status</Alert>
                    )}
                  </>
                )}
              </Stepper>
            ) : (
              <SkeletonComponent></SkeletonComponent>
            )}
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerOnboarding: state.customerOnboarding.customerOnboarding,
    customerOnboardingLoading: state.customerOnboarding.pending,
    customerOnboardingError: state.customerOnboarding.error,
  };
};

export default connect(mapStateToProps)(UserOnboarding);
