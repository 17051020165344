import React from 'react';

const Homepage = () => (
  <div
    className="dashboardWelcomeMsg"
    style={{ padding: 50, fontFamily: 'Shell-Medium' }}
  >
    Welcome to Shell Fleet App Operations Portal
  </div>
);

export default Homepage;
