import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { status, subStatus } from './UserStatus';
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import { fetchAllDealsRequest } from '../../store/deals/actions';
import { FetchCustomerDetailsRequestPayload } from '../../store/user/types';
import {
  fetchCustomerDetailsRequest, resetFetchCustomerDetails,
} from '../../store/user/actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '20ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    margin: theme.spacing(0.5),
    minWidth: 80,
  },

  formControl1: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    margin: theme.spacing(0.5),
    minWidth: 200,
  },

  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(0.5, 2, 5, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 42,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: '10ch',
  },
  input: {
    margin: '0px',
  },
  iconButton: {
    padding: 5,
    margin: 5,
    color: '#fed700',
    '&:hover': { color: '#fbcf04', background: '#adadad2e' },
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px 0px',
    fontSize: '1rem',
    textAlign: 'left',
  },
}));

const advancedSearchInit = {
  country: 'all',
  dealId: '',
  status: '',
  subStatus: '0',
  startDate: '',
  endDate: '',
};

function countriesHelperText(
  countriesLoading: boolean,
  countriesError: string
) {
  if (countriesLoading) {
    return 'Loading Countries list';
  } else if (countriesError) {
    return `Error loading countries - [${countriesError}]`;
  } else {
    return 'Please select country';
  }
}

function dealsHelperText(
  dealsLoading: boolean,
  dealsError: string,
  advancedSearch: any,
  dealLength: number
) {
  if (advancedSearch.country === 'all') {
    return 'Please select country to enable deals';
  } else if (dealsLoading) {
    return 'Loading Deals for the country';
  } else if (dealsError) {
    return `Error Loading deals - [${dealsError}]`;
  } else if (dealLength === 0) {
    return 'No deals for the country';
  } else {
    return 'Please select deals';
  }
}

function AdvancedUserSearch(props: any) {
  const classes = useStyles();
  const {
    deals,
    dealsLoading,
    countriesLoading,
    countries,
    searchPayload,
    countriesError,
    dealsError,
  } = props;
  const dispatch = useDispatch();
  const onlyAlphanumericRegex = /[^a-z0-9]/gi;
  const [advancedSearch, setAdvancedSearch] = useState(advancedSearchInit);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    dispatch(resetFetchCustomerDetails());
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'country':
        if (value === 'all') {
          dispatch(resetFetchCustomerDetails());
          setAdvancedSearch(advancedSearchInit);
        } else {
          setAdvancedSearch({
            ...advancedSearch,
            ['country']: value,
            ['dealId']: '',
          });
          dispatch(fetchAllDealsRequest(value));
        }
        break;
      case 'dealId':
        if (event.target.value !== '') {
          event.target.value = value.replace(onlyAlphanumericRegex, '');
          setAdvancedSearch({
            ...advancedSearch,
            ['dealId']: event.target.value,
          });
          setAdvancedSearch({ ...advancedSearch, [name]: value });

        } else {
          setAdvancedSearch({ ...advancedSearch, [name]: value });
        }
        break;
      case 'status':
        if (value === 'all') {
          setAdvancedSearch({
            ...advancedSearch,
            ['status']: '0',
          });
        }
        if (event.target.value !== '') {
          event.target.value = value.replace(onlyAlphanumericRegex, '');
          setAdvancedSearch({
            ...advancedSearch,
            ['status']: event.target.value,
            ['subStatus']: '0',
          });
        } else {
          setAdvancedSearch({ ...advancedSearch, [name]: value });
        }
        break;
      case 'subStatus':
        if (value === 'all') {
          setAdvancedSearch({
            ...advancedSearch,
            ['subStatus']: '0',
          });
        }
        if (event.target.value !== '') {
          event.target.value = value.replace(onlyAlphanumericRegex, '');
          setAdvancedSearch({
            ...advancedSearch,
            ['subStatus']: event.target.value,
          });
        } else {
          setAdvancedSearch({ ...advancedSearch, [name]: value });
        }
        break;
      default:
        setAdvancedSearch({ ...advancedSearch, [name]: value });
        break;
    }
  };

  const handleButtonClick = () => {
    dispatch(resetFetchCustomerDetails());
    const data: FetchCustomerDetailsRequestPayload = {
      countryCode: advancedSearch.country === 'all' ? '' : advancedSearch.country,
      dealId: advancedSearch.dealId,
      status: advancedSearch.status,
      subStatus: advancedSearch.subStatus,
      startDate: startDate,
      endDate: endDate,
      pageNumber: 1,
    };
    dispatch(fetchCustomerDetailsRequest({ ...data, pageSize: 10 }));
    searchPayload(data);
  };

  const handleSubmitDisable = () => {
    if (advancedSearch.dealId !== '' && advancedSearch.status !== '' && advancedSearch.status !== '2') {
      return false;
    }
    else if(advancedSearch.status === '2') {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className={classes.titleMsg}>Search User</div>
      <ResponsiveContainer>
        <Card className={classes.root} variant="elevation">
          <Grid container justifyContent="flex-start">
            <FormControl
              variant="outlined"
              className={classes.formControl1}
              size="small"
            >
              <InputLabel id="TM-country-select-label">Country</InputLabel>
              <Select
                name="country"
                labelId="TM-country-select-label"
                id="TM-country-select"
                value={advancedSearch.country || ''}
                defaultValue=""
                onChange={handleChange}
                label="Country"
              >
                <MenuItem
                  key="all"
                  value="all"
                  aria-selected={true}
                  selected={true}
                  className={classes.menuItems}
                >
                  All
                </MenuItem>
                {!countriesLoading &&
                  countries.map((option: any) => (
                    <MenuItem
                      key={option.code}
                      value={option.code || ''}
                      aria-selected={true}
                      className={classes.menuItems}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {countriesHelperText(countriesLoading, countriesError)}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl1}
              size="small"
            >
              <InputLabel id="TM-deal-select-label">Deal</InputLabel>
              {
                <Select
                  name="dealId"
                  labelId="TM-deal-select-label"
                  id="TM-deal-select"
                  value={advancedSearch.dealId || ''}
                  onChange={handleChange}
                  label="Deal"
                  defaultValue=""
                  disabled={
                    advancedSearch.country === 'all' || deals.length === 0
                      ? true
                      : false
                  }
                >
                  {deals.map((option: any) => {
                    return (
                      <MenuItem
                        key={_.get(option, 'id')}
                        value={_.get(option, 'id', '')}
                        aria-selected={false}
                        className={classes.menuItems}
                      >
                        {_.get(option, 'description')}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              <FormHelperText>
                {dealsHelperText(
                  dealsLoading,
                  dealsError,
                  advancedSearch,
                  deals.length
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              <InputLabel id="TM-status-select-label">Status</InputLabel>
              <Select
                name="status"
                labelId="TM-status-select-label"
                id="TM-status-select"
                value={advancedSearch.status}
                onChange={handleChange}
                label="Status"
              >
                 <MenuItem
                  key="0"
                  value="0"
                  aria-selected={true}
                  selected={true}
                  className={classes.menuItems}
                >
                  All
                </MenuItem>
                {status.map((option: any) => (
                  <MenuItem
                    key={option.value}
                    value={option.value || ''}
                    aria-selected={true}
                    className={classes.menuItems}
                  >
                    {option.label}
                  </MenuItem>
                ))}

              </Select>
              <FormHelperText>Please select status</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              <InputLabel id="TM-substatus-select-label">Sub Status</InputLabel>
              <Select
                name="subStatus"
                labelId="TM-substatus-select-label"
                id="TM-substatus-select"
                value={advancedSearch.subStatus}
                onChange={handleChange}
                label="Sub Status"
                disabled={
                  advancedSearch.status === '2' ||  advancedSearch.status === '4' ||  advancedSearch.status === '5'
                    ? true
                    : false
                }
              >
                <MenuItem
                  key="0"
                  value="0"
                  aria-selected={true}
                  selected={true}
                  className={classes.menuItems}
                >
                  All
                </MenuItem>
                {subStatus.map((option: any) => (
                  <MenuItem
                    key={option.value}
                    value={option.value || ''}
                    aria-selected={true}
                    selected={true}
                    className={classes.menuItems}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Please select sub status</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small">
              <TextField
                id="date"
                label="From Date"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={startDate}
                variant="outlined"
                size="small"
                inputProps={{
                  style: { color: 'gray' }
                }}
                onChange={(d: any) => setStartDate(d.target.value)}
              />
              <FormHelperText>Please select Start Date</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small">
              <TextField
                id="date"
                label="To Date"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={endDate}
                variant="outlined"
                size="small"
                inputProps={{
                  style: { color: 'gray' }
                }}
                onChange={(d: any) => setEndDate(d.target.value)}
              />
              <FormHelperText>Please select End Date</FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              className={classes.formControlButton}
              size="small"
            >
              <Button
                variant="contained"
                size="large"
                className={classes.searchButton}
                onClick={handleButtonClick}
                disabled={handleSubmitDisable()}
              >
                Search
              </Button>
            </FormControl>
          </Grid>
        </Card>
      </ResponsiveContainer>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    deals: state.allDeals.allDeals,
    dealsLoading: state.deals.pendingDeals,
    dealsError: state.deals.allDealsError,
  };
};

export default connect(mapStateToProps)(AdvancedUserSearch);
