import { Dispatch } from 'react';
import { PortalState } from '../App';
import Axios from '../config/Interceptors';

export async function setAccessToken(
  reqType: string,
  code: string,
  setPortalState?: Dispatch<PortalState>
) {
  let reqUrl;
  if (reqType === 'code') {
    reqUrl = `/auth/accessToken/${code}`;
  } else {
    reqUrl = `/auth/refreshToken/${code}`;
  }
  const resp = await Axios({
    url: reqUrl,
    method: 'GET',
    //params: { referer: 'localhost' }, // comment for redirect URL
    headers: {
      'Content-Type': 'application/json',
    },
  });
  switch (resp.status) {
    case 200:
      if (await checkUserAuthentication(resp.data.access_Token)) {
        sessionStorage.setItem('accessToken', resp.data.access_Token);
        sessionStorage.setItem('refreshToken', resp.data.refresh_Token);
        sessionStorage.setItem(
          'tokenExpiry',
          String(
            Math.round(new Date().getTime() / 1000) +
              Number(resp.data.expires_In)
          )
        );
        const permissionResp = await Axios({
          url: '/rolePermissions/getRolePermissions',
          data: { name: localStorage.getItem('role') },
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf8',
            Authorization: `Bearer ${resp.data.access_Token}`,
          },
        });
        if (permissionResp.status === 200) {
          createPermissionMap(permissionResp.data.data);
        }

        if (setPortalState) {
          setPortalState('loggedin');
        }
      } else {
        if (setPortalState) {
          setPortalState('unauthorised');
        }
        window.history.pushState({}, '', '/admin/un-authorised');
      }
      break;
    case 500:
      clearSession();
      alert('Session Expired. Click OK to create a new Session');
      window.location.reload();
      break;
    default:
      if (setPortalState) {
        setPortalState('unauthorised');
      }
      window.history.pushState({}, '', '/admin/un-authorised');
      break;
  }
}

export async function getAccessURI() {
  return Axios({
    url: `/auth/uri`,
    //params: { referer: 'localhost' }, // comment for redirect URL
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function checkUserAuthentication(token: string) {
  const resp = await Axios({
    url: '/appUserRoles/getUserRoles',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.status === 200 && resp.data.data.length > 0) {
    localStorage.setItem('fName', resp.data.data[0].firstName);
    localStorage.setItem('lName', resp.data.data[0].lastName);
    localStorage.setItem('role', resp.data.data[0].roleName);
    localStorage.setItem('sessionSet', 'true');
    return true;
  } else {
    return false;
  }
}

export async function isValidSession() {
  const refreshToken = getRefreshToken();
  const tokenExpiry = Number(getTokenExpiry());
  if (!refreshToken || !tokenExpiry || !localStorage.sessionSet) {
    return false;
  } else if (
    refreshToken &&
    tokenExpiry < Math.round(new Date().getTime() / 1000)
  ) {
    await setAccessToken('refToken', refreshToken);
    return true;
  } else {
    return true;
  }
}

export function getSessionToken() {
  return sessionStorage.getItem('accessToken');
}

export function getRefreshToken() {
  return sessionStorage.getItem('refreshToken');
}

export function getTokenExpiry() {
  return sessionStorage?.getItem('tokenExpiry');
}

export function setRedirect(redirect: string) {
  if (redirect) {
    sessionStorage?.setItem('redirectTo', redirect);
  } else {
    sessionStorage?.removeItem('redirectTo');
  }
}

export function getRedirect() {
  const redirect = sessionStorage?.getItem('redirectTo');

  sessionStorage?.removeItem('redirectTo');

  return redirect;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString('binary')
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function decodeAccessToken() {
  const token = getSessionToken();

  if (!token) {
    return null;
  }

  try {
    return parseJwt(token);
  } catch (e) {
    return null;
  }
}

export function clearSession() {
  sessionStorage.clear();
  localStorage.clear();
}

function createPermissionMap(permissions: any) {
  let permissionMap: any = {};
  const permissionsData = permissions.permissionsData;

  for (let i = 0; i < permissionsData.length; i++) {
    permissionMap[permissionsData[i].componentName] =
      permissionsData[i].permission;
  }
  sessionStorage.setItem('permissions', JSON.stringify(permissionMap));
}
