import {
    FETCH_CUSTOMER_GROUP_TYPE_REQUEST,
    FETCH_CUSTOMER_GROUP_TYPE_SUCCESS,
    FETCH_CUSTOMER_GROUP_TYPE_FAILURE,
    FETCH_MAILJET_CONFIGURATION_REQUEST,
    FETCH_MAILJET_CONFIGURATION_FAILURE,
    FETCH_MAILJET_CONFIGURATION_SUCCESS,
    RESET_FETCH_MAILJET_CONFIGURATION_DETAILS,
    RESET_DELETE_MAILJET_CONFIGURATION_REQUEST,
    DELETE_MAILJET_CONFIGURATION_REQUEST,
    DELETE_MAILJET_CONFIGURATION_FAILURE,
    DELETE_MAILJET_CONFIGURATION_SUCCESS,
    CREATE_MAILJET_CONFIGURATION_REQUEST,
    CREATE_MAILJET_CONFIGURATION_SUCCESS,
    CREATE_MAILJET_CONFIGURATION_FAILURE,
    FETCH_APP_CONFIGURATION_REQUEST,
    FETCH_APP_CONFIGURATION_SUCCESS,
    FETCH_APP_CONFIGURATION_FAILURE,
    FETCH_APP_CONFIGURATION_DETAILS_REQUEST,
    FETCH_APP_CONFIGURATION_DETAILS_SUCCESS,
    FETCH_APP_CONFIGURATION_DETAILS_FAILURE,
    RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST,
    CREATE_APP_CONFIGURATION_REQUEST,
    CREATE_APP_CONFIGURATION_SUCCESS,
    CREATE_APP_CONFIGURATION_FAILURE,
    RESET_CREATE_APP_CONFIGURATION_REQUEST,
    UPDATE_APP_CONFIGURATION_REQUEST,
    UPDATE_APP_CONFIGURATION_SUCCESS,
    UPDATE_APP_CONFIGURATION_FAILURE,
    RESET_UPDATE_APP_CONFIGURATION_REQUEST,
    DELETE_APP_CONFIGURATION_REQUEST,
    DELETE_APP_CONFIGURATION_FAILURE,
    DELETE_APP_CONFIGURATION_SUCCESS,
    RESET_DELETE_APP_CONFIGURATION_REQUEST,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE,
    FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS,
    RESET_ALL_OFFERINGPACKAGES_DETAILS,
    FETCH_ALL_MARKETS_REQUEST,
    FETCH_ALL_MARKETS_FAILURE,
    FETCH_ALL_MARKETS_SUCCESS,
  } from './actionTypes';

import { ConfigurationAction, 
  CustomerGroupTypesState, 
  MailjetConfiguartionState,
  DeleteMailjetConfiguartionState,
  CreateMailjetConfiguartionState,
  AppConfigTypesState,
  AppConfigDetailsState,
  CreateAppConfigurationState,
  DeleteAppConfiguartionState,
  UpdateAppConfigurationState,
  AllOfferingPackagesState,
  AllmarketsState} from './types';
  
  const initialState: CustomerGroupTypesState = {
    pending: false,
    customerGroupTypes: [],
    error: null,
  };

  const mailjetIinitialState: MailjetConfiguartionState = {
    pending: false,
    mailjetConfiguartionList: [],
    error: null,
    success:false
  };

  const deleteMailjetIinitialState: DeleteMailjetConfiguartionState = {
    pending: false,
    error:  null,
    DeleteMailjetConfiguartion: false,
    success:false
  };
  
  const createMailjetIinitialState: CreateMailjetConfiguartionState = {
    pending: false,
    error:  null,
    CreateMailjetConfiguartion: false,
  };

  const deleteAppIinitialState: DeleteAppConfiguartionState = {
    pending: false,
    error:  null,
    DeleteAppConfiguartion: false,
    success:false
  };

  const createAppIinitialState: CreateAppConfigurationState = {
    pending: false,
    error:  null,
    CreateAppConfiguration: false,
  };

  const updateAppIinitialState: UpdateAppConfigurationState = {
    pending: false,
    error:  null,
    UpdateAppConfiguration: false,
  };

  const initialAppConfigState: AppConfigTypesState = {
    pending: false,
    appConfigTypeList: [],
    error: null,
  };

  const initialAppConfigDetailsState: AppConfigDetailsState = {
    pending: false,
    appConfigDetailsList: [],
    error: null,
  };

  const initialAllOfferingPackagesState: AllOfferingPackagesState = {
    pending: false,
    offeringPackagesList: [],
    error: null,
  };

  const initialAllmarketsState: AllmarketsState = {
    pending: false,
    marketList: [],
    error: null,
  };

  export const customerGroupTypesReducer = (
    state = initialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case FETCH_CUSTOMER_GROUP_TYPE_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_CUSTOMER_GROUP_TYPE_SUCCESS:
        return {
          ...state,
          pending: false,
          customerGroupTypes: action.payload.customerGroupTypeList,
          error: null,
        };
      case FETCH_CUSTOMER_GROUP_TYPE_FAILURE:
        return {
          ...state,
          pending: false,
          customerGroupTypes: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const mailjetConfiguartionReducer = (
    state = mailjetIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case FETCH_MAILJET_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_MAILJET_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          mailjetConfiguartionList: action.payload.mailjetConfiguartionList,
          error: null,
          success:true,
        };
      case FETCH_MAILJET_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          mailjetConfiguartionList: [],
          error: action.payload.error,
        };
      case RESET_FETCH_MAILJET_CONFIGURATION_DETAILS:
        return {
          ...state,
          pending: false,
          mailjetConfiguartionList: [],
          error: null,
          success: false,
          };
      default:
        return {
          ...state,
        };
    }
  };

  export const deleteMailjetConfiguartionReducer = (
    state = deleteMailjetIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case DELETE_MAILJET_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case DELETE_MAILJET_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          DeleteMailjetConfiguartion: true,
          error: null,
          success:true,
        };
      case DELETE_MAILJET_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          DeleteMailjetConfiguartion: false,
          error: action.payload.error,
        };
        case RESET_DELETE_MAILJET_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: false,
          DeleteMailjetConfiguartion: false,
          error: null,
          success:false,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const createMailjetConfiguartionReducer = (
    state = createMailjetIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case CREATE_MAILJET_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
          CreateMailjetConfiguartion: false,
        };
      case CREATE_MAILJET_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          CreateMailjetConfiguartion: true,
          error: null,
        };
      case CREATE_MAILJET_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          CreateMailjetConfiguartion: false,
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const getAllMarketsReducer = (
    state = initialAllmarketsState,
    action: ConfigurationAction
  ) => {

    switch (action.type) {
      case FETCH_ALL_MARKETS_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_ALL_MARKETS_SUCCESS:
        return {
          ...state,
          pending: false,
          marketList: action.payload.marketList,
          error: null,
        };
      case FETCH_ALL_MARKETS_FAILURE:
        return {
          ...state,
          pending: false,
          marketList: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const getOfferingPackagesReducer = (
    state = initialAllOfferingPackagesState,
    action: ConfigurationAction
  ) => {

    switch (action.type) {
      case FETCH_ALL_OFFERINGPACKAGES_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_ALL_OFFERINGPACKAGES_REQUEST_SUCCESS:
        return {
          ...state,
          pending: false,
          offeringPackagesList: action.payload.offeringPackagesList,
          error: null,
        };
      case FETCH_ALL_OFFERINGPACKAGES_REQUEST_FAILURE:
        return {
          ...state,
          pending: false,
          offeringPackagesList: [],
          error: action.payload.error,
        };
      case RESET_ALL_OFFERINGPACKAGES_DETAILS:
          return {
            ...state,
            pending: false,
            offeringPackagesList: [],
            error: null,
            success: false,
            };
      default:
        return {
          ...state,
        };
    }
  };

  export const createAppConfiguartionReducer = (
    state = createAppIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case CREATE_APP_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
          CreateAppConfiguration: false,
        };
      case CREATE_APP_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          CreateAppConfiguration: true,
          error: null,
        };
      case CREATE_APP_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          CreateAppConfiguration: false,
          error: action.payload.error,
        };
      case RESET_CREATE_APP_CONFIGURATION_REQUEST:
          return {
            ...state,
            pending: false,
            CreateAppConfiguration: false,
            error: null,
            success: false,
            };
      default:
        return {
          ...state,
        };
    }
  };

  export const updateAppConfiguartionReducer = (
    state = updateAppIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case UPDATE_APP_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
          UpdateAppConfiguration: false,
        };
      case UPDATE_APP_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          UpdateAppConfiguration: true,
          error: null,
        };
      case UPDATE_APP_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          UpdateAppConfiguration: false,
          error: action.payload.error,
        };
      case RESET_UPDATE_APP_CONFIGURATION_REQUEST:
          return {
            ...state,
            pending: false,
            UpdateAppConfiguration: false,
            error: null,
            success: false,
            };
      default:
        return {
          ...state,
        };
    }
  };

  export const appConfigReducer = (
    state = initialAppConfigState,
    action: ConfigurationAction
  ) => {

    switch (action.type) {
      case FETCH_APP_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_APP_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          appConfigTypeList: action.payload.appConfigTypeList,
          error: null,
        };
      case FETCH_APP_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          appConfigTypeList: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const appConfigDetailsReducer = (
    state = initialAppConfigDetailsState,
    action: ConfigurationAction
  ) => {

    switch (action.type) {
      case FETCH_APP_CONFIGURATION_DETAILS_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case FETCH_APP_CONFIGURATION_DETAILS_SUCCESS:
        return {
          ...state,
          pending: false,
          appConfigDetailsList: action.payload.appConfigDetailsList,
          error: null,
        };
      case FETCH_APP_CONFIGURATION_DETAILS_FAILURE:
        return {
          ...state,
          pending: false,
          appConfigDetailsList: [],
          error: action.payload.error,
        };
        case RESET_FETCH_APP_CONFIGURATION_DETAIL_REQUEST:
        return {
          ...state,
          pending: false,
          appConfigDetailsList: [],
          error: null,
          success: false,
        };
      default:
        return {
          ...state,
        };
    }
  };

  export const deleteAppConfiguartionReducer = (
    state = deleteAppIinitialState,
    action: ConfigurationAction
  ) => {
    switch (action.type) {
      case DELETE_APP_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case DELETE_APP_CONFIGURATION_SUCCESS:
        return {
          ...state,
          pending: false,
          DeleteAppConfiguartion: true,
          error: null,
          success:true,
        };
      case DELETE_APP_CONFIGURATION_FAILURE:
        return {
          ...state,
          pending: false,
          DeleteAppConfiguartion: false,
          error: action.payload.error,
        };
        case RESET_DELETE_APP_CONFIGURATION_REQUEST:
        return {
          ...state,
          pending: false,
          DeleteAppConfiguartion: false,
          error: null,
          success:false,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  

  
  

