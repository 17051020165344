import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTimeDiff } from './helper';
import InvoicePaymentRequest from './InvoicePaymentRequest';
import Chargeback from './Chargeback';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { fetchCustomerH3InvoiceRequest, fetchChargebackInvoiceRequest } from '../../store/user/actions';
import { connect, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '32ch',
    },
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    padding: 10,
  },
  tableFooter: {
    float: 'right',
  },
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid #d4cfcf',
      padding: '5px',
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  selected: {
    background: 'rgba(239, 239, 239, 0.93)',
  },
  row:{
    background:'red !important'
  },
}));

interface invoiceHeaderInterface {
  id: string;
  label: string;
}

interface Data {
  checkGfnStatus: string;
  invoiceNumber: string;
  netAmount: number;
  vatAmount: number;
  totalAmount: number;
  fuelQuantity: number;
  fuelTotalAmount: number;
  currencyCode: string;
  issueDate: string;
  dueDate: string;
  gfnDueDate: string;
  gfnStatus: number;
  paymentReceivedDate: string;
  isExported: boolean;
  isChargeBack:boolean;
}

function Row(props: any) {
  const { row, i, payments, fetchGfnStatus, checkGfnStatus, getChargeback } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={i}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          background: isOpen ? 'rgba(239, 239, 239, 0.93)' : '',
          cursor: 'pointer',
        }}
      >
        <TableCell>
            <Button
              size="small"
              onClick={fetchGfnStatus}
              sx={{ textTransform: 'none', fontSize: '0.75rem' }}
              disabled={checkGfnStatus.disable}
            >
            View
          </Button>
          {checkGfnStatus.loading ? (
            <CircularProgress size="1rem" sx={{ color: 'grey.500' }} />
          ) : checkGfnStatus.value !== null ? (
            <div>{checkGfnStatus.value || '-'} </div>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>{row.invoiceNumber || '-'} </TableCell>
        <TableCell>{row.netAmount || '-'}</TableCell>
        <TableCell>{row.vatAmount || '-'}</TableCell>
        <TableCell>{row.totalAmount || '-'}</TableCell>
        <TableCell>{row.fuelQuantity || '-'}</TableCell>
        <TableCell>{row.fuelTotalAmount || '-'}</TableCell>
        <TableCell>{row.currencyCode || '-'}</TableCell>
        <TableCell>{row.issueDate || '-'}</TableCell>
        <TableCell>{row.dueDate || '-'}</TableCell>
        <TableCell>{row.gfnDueDate || '-'}</TableCell>
        <TableCell>{row.gfnStatus || '-'}</TableCell>
        <TableCell>{row.paymentReceivedDate || '-'}</TableCell>

        <TableCell>{row.isExported.toString()}</TableCell>
        <TableCell>
        {row.isChargeBack === true?(<><Button
              size="small"
              onClick={getChargeback}
              sx={{ textTransform: 'none', fontSize: '0.75rem', color:'red', fontWeight: 'bold' }}
            >
            Yes
          </Button></>):'No'}
          </TableCell>
      </TableRow>

      <TableRow
        style={{
          background: 'rgba(239, 239, 239, 0.93)',
        }}
      >
        <TableCell style={{ padding: 0 }} colSpan={15}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {payments.length > 0 ? (
              <InvoicePaymentRequest
                paymentRequests={payments}
              ></InvoicePaymentRequest>
            ) : (
              <div style={{ padding: '8px' }}>
                No payment request found for this invoice
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function InvoiceTable(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    customerProfile,
    customerH3Invoice,
    customerH3InvoiceLoading,
    customerH3InvoiceError,
    chargebacks,
    chargebacksLoading,
    chargebacksError,
  } = props;
  const [isDataFetched, setIsDataFetched] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { invoiceDetails, paymentRequests } = props.customerInvoiceDetails;
  let updatedPaymentRequests: any = [];
  let invoices = invoiceDetails.map((invoice: any) => invoice.invoiceNumber);

  const GfnStatusObj = [];
  for (let i = 0; i < invoices.length; i++) {
    GfnStatusObj[i] = {
      value: null,
      invNumber: invoices[i],
      loading: false,
      disable: false,
    };
  }

  const [checkGfnStatus, setCheckGfnStatus] = React.useState(GfnStatusObj);
  const [invoiceNum, setInvoiceNum] = useState('');

  if (customerH3InvoiceLoading && isDataFetched) {
    setIsDataFetched(false);
  } else if (!customerH3InvoiceLoading && !isDataFetched) {
    if (Object.keys(customerH3Invoice).length > 0) {
      setCheckGfnStatus(
        checkGfnStatus.map((item) =>
          item.invNumber === invoiceNum
            ? {
                ...item,
                value: customerH3Invoice.invoices[0].status,
                loading: false,
              }
            : { ...item, disable: false }
        )
      );
    } else {
      setCheckGfnStatus(
        checkGfnStatus.map((item) => ({
          ...item,
          loading: false,
          disable: false,
        }))
      );
    }

    setIsDataFetched(true);
  }

  const fetchGfnStatus = (e: any, invNum: any) => {
    e.stopPropagation();
    setCheckGfnStatus(
      checkGfnStatus.map((item) =>
        item.invNumber === invNum
          ? { ...item, loading: true }
          : { ...item, disable: true }
      )
    );
    dispatch(
      fetchCustomerH3InvoiceRequest({
        customerId: customerProfile.customerId,
        invoiceNumber: invNum,
      })
    );
    setInvoiceNum(invNum);
  };

  const handleClose=()=>{
    setOpen(false);
  };

  const getChargeback = (e: any, invNum: any) => {
    e.stopPropagation();

    dispatch(
      fetchChargebackInvoiceRequest({
        invoiceNumber: invNum,
      })
    );

    setOpen(true);
  };

  for (let inv of invoices) {
    updatedPaymentRequests[inv] = paymentRequests.filter(
      (pay: any) => pay.invoiceNumber === inv
    );
  }

  function createData(
    checkGfnStatus: string,
    invoiceNumber: string,
    netAmount: number,
    vatAmount: number,
    totalAmount: number,
    fuelQuantity: number,
    fuelTotalAmount: number,
    currencyCode: string,
    issueDate: string,
    dueDate: string,
    gfnDueDate: string,
    gfnStatus: number,
    paymentReceivedDate: string,
    isExported: boolean,
    isChargeBack:boolean
  ): Data {
    return {
      checkGfnStatus,
      invoiceNumber,
      netAmount,
      vatAmount,
      totalAmount,
      fuelQuantity,
      fuelTotalAmount,
      currencyCode,
      issueDate,
      dueDate,
      gfnDueDate,
      gfnStatus,
      paymentReceivedDate,
      isExported,
      isChargeBack
    };
  }

  const rows = invoiceDetails.map((invoice: any) =>
    createData(
      '',
      invoice.invoiceNumber,
      invoice.netAmount,
      invoice.vatAmount,
      invoice.totalAmount,
      invoice.fuelQuantity,
      invoice.fuelTotalAmount,
      invoice.currencyCode,
      getTimeDiff(invoice.issueDate),
      getTimeDiff(invoice.dueDate),
      getTimeDiff(invoice.gfnDueDate),
      invoice.gfnStatus,
      getTimeDiff(invoice.paymentReceivedDate),
      invoice.isExported,
      invoice.isChargeBack
    )
  );

  const invoiceHeaderMap: readonly invoiceHeaderInterface[] = [
    {
      id: 'checkGfnStatus',
      label: 'Check GFN Status',
    },
    {
      id: 'invoiceNumber',
      label: 'Invoice Number',
    },
    {
      id: 'netAmount',
      label: 'Net Amount',
    },
    {
      id: 'vatAmount',
      label: 'Vat Amount',
    },
    {
      id: 'totalAmount',
      label: 'Total Amount',
    },
    {
      id: 'fuelQuantity',
      label: 'Fuel Quantity',
    },
    {
      id: 'fuelTotalAmount',
      label: 'Fuel Total Amount',
    },
    {
      id: 'currencyCode',
      label: 'Currency Code',
    },
    {
      id: 'issueDate',
      label: 'Issue Date',
    },
    {
      id: 'dueDate',
      label: 'Due Date',
    },
    {
      id: 'gfnDueDate',
      label: 'GFN Due Date',
    },
    {
      id: 'gfnStatus',
      label: 'GFN Status',
    },
    {
      id: 'paymentReceivedDate',
      label: 'Payment Received Date',
    },
    {
      id: 'isExported',
      label: 'Exported',
    },
    {
      id: 'isChargeBack',
      label: 'Chargeback',
    },
  ];

  interface EnhancedTableProps {
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead sx={{ background: '#FCD839' }}>
        <TableRow>
          {invoiceHeaderMap.map((header) => (
            <TableCell
              key={header.id}
              sx={{
                fontFamily: 'Shell-Book',
                color: '#484848',
                fontWeight: 600,
              }}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 1 },
          pr: { xs: 1, sm: 1 },
          textAlign: 'start',
          minHeight: '48px !important',
        }}
      >
        <Typography
          sx={{
            flex: '1 1 100%',
            fontWeight: 600,
            fontSize: '0.8rem',
            fontFamily: 'Shell-Book',
          }}
          id="tableTitle"
          component="div"
        >
          Invoice
        </Typography>
      </Toolbar>
    );
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            className={classes.table}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  return (
                    <Row
                      row={row}
                      key={index}
                      i={index}
                      payments={updatedPaymentRequests[row.invoiceNumber]}
                      fetchGfnStatus={(e: any) =>
                        fetchGfnStatus(e, row.invoiceNumber)
                      }
                      getChargeback={(e: any) =>
                        getChargeback(e, row.invoiceNumber)
                      }
                      checkGfnStatus={checkGfnStatus[index]}
                    ></Row>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 30 * emptyRows,
                  }}
                >
                  <TableCell colSpan={14} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className={classes.tableFooter}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </Paper>
      <>
       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Chargeback Details</DialogTitle>
        <DialogContent>
          <ResponsiveContainer>
              <Grid container justifyContent="flex-start">
              {chargebacksLoading ? (
            <CircularProgress size="1rem" sx={{ color: 'grey.500' }} />
          ) : <Chargeback chargebacks={chargebacks} />}
            
              </Grid>
          </ResponsiveContainer>
        </DialogContent>
        <DialogActions>
         
          
        </DialogActions>
      </Dialog>
      </>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile,
    customerH3Invoice: state.customerH3Invoice.customerH3Invoice,
    customerH3InvoiceLoading: state.customerH3Invoice.pending,
    customerH3InvoiceError: state.customerH3Invoice.error,
    chargebacks: state.chargebacks.chargebacks,
    chargebacksLoading: state.chargebacks.pending,
    chargebacksError: state.chargebacks.error,
  };
};

export default connect(mapStateToProps)(InvoiceTable);
