import classNames from 'classnames';
import React from 'react';

export default function Spinner(props: {
  title?: string | undefined;
  inline?: boolean | undefined;
  overlay?: boolean | undefined;
  custom?: boolean | undefined;
}) {
  const {
    title = 'Loading...',
    inline = false,
    overlay = false,
    custom = false,
  } = props;

  return (
    <div className={classNames('spinner_wrapper', { overlay, inline, custom })}>
      <div>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
          enableBackground="new 0 0 500 500"
          xmlSpace="preserve"
        >
          <g id="layer1" transform="translate(-14.828488,-233.63904)">
            <g
              id="g2384"
              transform="matrix(0.2326949,0,0,0.2326949,0.4836773,190.36074)"
            >
              <path
                id="Red_part"
                style={{ fill: '#ED1C24' }}
                d="M1136,1956.6c-53.8,0-81.4-35.1-81.4-35.1c-44.2-1.9-389.7-1-389.7-1l-33.6-283.9
			c0,0-212.8-150-230.1-167.3C298.9,1005.9,668.2,564.1,1136,564.1c467.9,0,837.1,441.8,734.9,905.2
			c-17.3,17.3-230.1,167.3-230.1,167.3l-33.6,283.9c0,0-345.5-1-389.7,1C1217.5,1921.5,1189.8,1956.6,1136,1956.6z"
              />
              <path
                id="Yellow_part"
                style={{ fill: '#FFD500' }}
                d="M1136.5,1524.8l-32.6-819.6c-39.4-8-139.8,1.6-193.7,41.8l156.7,781L853.1,779.1
			c-51.2-0.6-154.6,86-162.3,111.7l311.8,664.5L652.3,947.8c-34.6,12.9-110.1,151.9-98,168l390.5,478L543,1185.7
			c0,0-52.2,79.6-18.2,214.3l226.9,163.9l28.4,223.9h278.2c0,0,45.5,43.1,77.8,43.1c32.3,0,77.8-43.1,77.8-43.1h278.2l28.4-223.9
			l226.9-163.9c34.1-134.7-18.2-214.3-18.2-214.3l-401.8,408.2l390.5-478c12.1-16.2-63.5-155.2-98-168l-350.3,607.5l311.8-664.5
			c-7.7-25.7-111.1-112.3-162.3-111.7L1205.5,1528l156.7-781c-53.8-40.2-154.3-49.8-193.7-41.8L1136.5,1524.8z"
              />
            </g>
          </g>
        </svg>
      </div>
      {title && <span>{title}</span>}
    </div>
  );
}
