export const FETCH_OFFERINGPACKAGE_REQUEST = 'FETCH_OFFERINGPACKAGE_REQUEST';
export const FETCH_OFFERINGPACKAGE_SUCCESS = 'FETCH_OFFERINGPACKAGE_SUCCESS';
export const FETCH_OFFERINGPACKAGE_FAILURE = 'FETCH_OFFERINGPACKAGE_FAILURE';

export const CREATE_FEEGROUP_REQUEST = 'CREATE_FEEGROUP_REQUEST ';
export const CREATE_FEEGROUP_SUCCESS = 'CREATE_FEEGROUP_SUCCESS';
export const CREATE_FEEGROUP_FAILURE = 'CREATE_FEEGROUP_FAILURE';
export const RESET_CREATE_FEEGROUP = 'RESET_CREATE_FEEGROUP';

export const UPDATE_FEEGROUP_REQUEST = 'UPDATE_FEEGROUP_REQUEST ';
export const UPDATE_FEEGROUP_SUCCESS = 'UPDATE_FEEGROUP_SUCCESS';
export const UPDATE_FEEGROUP_FAILURE = 'UPDATE_FEEGROUP_FAILURE';
export const RESET_UPDATE_FEEGROUP = 'RESET_UPDATE_FEEGROUP';


export const CREATE_PRICEPROFILE_REQUEST = 'CREATE_PRICEPROFILE_REQUEST ';
export const CREATE_PRICEPROFILE_SUCCESS = 'CREATE_PRICEPROFILE_SUCCESS';
export const CREATE_PRICEPROFILE_FAILURE = 'CREATE_PRICEPROFILE_FAILURE';
export const RESET_CREATE_PRICEPROFILE = 'RESET_CREATE_PRICEPROFILE';

export const UPDATE_PRICEPROFILE_REQUEST = 'UPDATE_PRICEPROFILE_REQUEST ';
export const UPDATE_PRICEPROFILE_SUCCESS = 'UPDATE_PRICEPROFILE_SUCCESS';
export const UPDATE_PRICEPROFILE_FAILURE = 'UPDATE_PRICEPROFILE_FAILURE';
export const RESET_UPDATE_PRICEPROFILE = 'RESET_UPDATE_PRICEPROFILE';

export const CREATE_TOKENTYPE_REQUEST = 'CREATE_TOKENTYPE_REQUEST ';
export const CREATE_TOKENTYPE_SUCCESS = 'CREATE_TOKENTYPE_SUCCESS';
export const CREATE_TOKENTYPE_FAILURE = 'CREATE_TOKENTYPE_FAILURE';
export const RESET_CREATE_TOKENTYPE = 'RESET_CREATE_TOKENTYPE';

export const UPDATE_TOKENTYPE_REQUEST = 'UPDATE_TOKENTYPE_REQUEST ';
export const UPDATE_TOKENTYPE_SUCCESS = 'UPDATE_TOKENTYPE_SUCCESS';
export const UPDATE_TOKENTYPE_FAILURE = 'UPDATE_TOKENTYPE_FAILURE';
export const RESET_UPDATE_TOKENTYPE = 'RESET_UPDATE_TOKENTYPE';

export const CREATE_CARDTYPE_REQUEST = 'CREATE_CARDTYPE_REQUEST ';
export const CREATE_CARDTYPE_SUCCESS = 'CREATE_CARDTYPE_SUCCESS';
export const CREATE_CARDTYPE_FAILURE = 'CREATE_CARDTYPE_FAILURE';
export const RESET_CREATE_CARDTYPE = 'RESET_CREATE_CARDTYPE';


export const UPDATE_CARDTYPE_REQUEST = 'UPDATE_CARDTYPE_REQUEST ';
export const UPDATE_CARDTYPE_SUCCESS = 'UPDATE_CARDTYPE_SUCCESS';
export const UPDATE_CARDTYPE_FAILURE = 'UPDATE_CARDTYPE_FAILURE';
export const RESET_UPDATE_CARDTYPE = 'RESET_UPDATE_CARDTYPE';


export const FETCH_FEE_GROUP_REQUEST = 'FETCH_FEE_GROUP_REQUEST';
export const FETCH_FEE_GROUP_SUCCESS = 'FETCH_FEE_GROUP_SUCCESS';
export const FETCH_FEE_GROUP_FAILURE = 'FETCH_FEE_GROUP_FAILURE';
export const RESET_FETCH_FEE_GROUP = 'RESET_FETCH_FEE_GROUP';


export const FETCH_PRICEPROFILE_REQUEST = 'FETCH_PRICEPROFILE_REQUEST';
export const FETCH_PRICEPROFILE_SUCCESS = 'FETCH_PRICEPROFILE_SUCCESS';
export const FETCH_PRICEPROFILE_FAILURE = 'FETCH_PRICEPROFILE_FAILURE';
export const RESET_FETCH_PRICEPROFILE = 'RESET_FETCH_PRICEPROFILE';

export const FETCH_TOKENTYPE_REQUEST = 'FETCH_TOKENTYPE_REQUEST ';
export const FETCH_TOKENTYPE_SUCCESS = 'FETCH_TOKENTYPE_SUCCESS';
export const FETCH_TOKENTYPE_FAILURE = 'FETCH_TOKENTYPE_FAILURE';
export const RESET_FETCH_TOKENTYPE = 'RESET_FETCH_TOKENTYPE';

export const FETCH_CARDTYPE_REQUEST = 'FETCH_CARDTYPE_REQUEST ';
export const FETCH_CARDTYPE_SUCCESS = 'FETCH_CARDTYPE_SUCCESS';
export const FETCH_CARDTYPE_FAILURE = 'FETCH_CARDTYPE_FAILURE';
export const RESET_FETCH_CARDTYPE = 'RESET_FETCH_CARDTYPE';


export const CREATE_LEGALENTITY_REQUEST = 'CREATE_LEGALENTITY_REQUEST ';
export const CREATE_LEGALENTITY_SUCCESS = 'CREATE_LEGALENTITY_SUCCESS';
export const CREATE_LEGALENTITY_FAILURE = 'CREATE_LEGALENTITY_FAILURE';
export const RESET_CREATE_LEGALENTITY = 'RESET_CREATE_LEGALENTITY';

export const UPDATE_LEGALENTITY_REQUEST = 'UPDATE_LEGALENTITY_REQUEST ';
export const UPDATE_LEGALENTITY_SUCCESS = 'UPDATE_LEGALENTITY_SUCCESS';
export const UPDATE_LEGALENTITY_FAILURE = 'UPDATE_LEGALENTITY_FAILURE';
export const RESET_UPDATE_LEGALENTITY = 'RESET_UPDATE_LEGALENTITY';

export const FETCH_LEGALENTITY_REQUEST = 'FETCH_LEGALENTITY_REQUEST ';
export const FETCH_LEGALENTITY_SUCCESS = 'FETCH_LEGALENTITY_SUCCESS';
export const FETCH_LEGALENTITY_FAILURE = 'FETCH_LEGALENTITY_FAILURE';
export const RESET_FETCH_LEGALENTITY = 'RESET_FETCH_LEGALENTITY';
