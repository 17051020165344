import { all, fork } from 'redux-saga/effects';

import dealsSaga from './deals/sagas';
import countriesSaga from './countries/sagas';
import tokenDetailsSaga from './token/sagas';
import userDetailsSaga from './user/sagas';
import offeringPackageSaga from './offeringPackages/sagas';
import customerGroupTypesSaga from './configurations/sagas';

export function* rootSaga() {
  yield all([fork(countriesSaga)]);
  yield all([fork(dealsSaga)]);
  yield all([fork(tokenDetailsSaga)]);
  yield all([fork(offeringPackageSaga)]);
  yield all([fork(userDetailsSaga)]);
  yield all([fork(customerGroupTypesSaga)]);
}
