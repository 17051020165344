import {
  CREATE_CARDTYPE_FAILURE,
  CREATE_CARDTYPE_REQUEST,
  CREATE_CARDTYPE_SUCCESS,
  CREATE_FEEGROUP_FAILURE,
  CREATE_FEEGROUP_REQUEST,
  CREATE_FEEGROUP_SUCCESS,
  CREATE_PRICEPROFILE_FAILURE,
  CREATE_PRICEPROFILE_REQUEST,
  CREATE_PRICEPROFILE_SUCCESS,
  CREATE_TOKENTYPE_FAILURE,
  CREATE_TOKENTYPE_REQUEST,
  CREATE_TOKENTYPE_SUCCESS,
  FETCH_OFFERINGPACKAGE_FAILURE,
  FETCH_OFFERINGPACKAGE_REQUEST,
  FETCH_OFFERINGPACKAGE_SUCCESS,
  RESET_CREATE_CARDTYPE,
  RESET_CREATE_FEEGROUP,
  RESET_CREATE_PRICEPROFILE,
  RESET_CREATE_TOKENTYPE,
  FETCH_FEE_GROUP_REQUEST,
  FETCH_FEE_GROUP_SUCCESS,
  FETCH_FEE_GROUP_FAILURE,
  RESET_FETCH_FEE_GROUP,
  FETCH_PRICEPROFILE_REQUEST,
  FETCH_PRICEPROFILE_SUCCESS,
  FETCH_PRICEPROFILE_FAILURE,
  RESET_FETCH_PRICEPROFILE,
  UPDATE_FEEGROUP_REQUEST,
  UPDATE_FEEGROUP_SUCCESS,
  UPDATE_FEEGROUP_FAILURE,
  RESET_UPDATE_FEEGROUP,
  UPDATE_PRICEPROFILE_REQUEST,
  UPDATE_PRICEPROFILE_SUCCESS,
  UPDATE_PRICEPROFILE_FAILURE,
  RESET_UPDATE_PRICEPROFILE,
  FETCH_TOKENTYPE_REQUEST,
  FETCH_TOKENTYPE_SUCCESS,
  FETCH_TOKENTYPE_FAILURE,
  RESET_FETCH_TOKENTYPE,
  UPDATE_TOKENTYPE_REQUEST,
  UPDATE_TOKENTYPE_SUCCESS,
  UPDATE_TOKENTYPE_FAILURE,
  RESET_UPDATE_TOKENTYPE,
  FETCH_CARDTYPE_REQUEST,
  FETCH_CARDTYPE_SUCCESS,
  FETCH_CARDTYPE_FAILURE,
  RESET_FETCH_CARDTYPE,
  UPDATE_CARDTYPE_REQUEST,
  UPDATE_CARDTYPE_SUCCESS,
  UPDATE_CARDTYPE_FAILURE,
  RESET_UPDATE_CARDTYPE,
  CREATE_LEGALENTITY_REQUEST,
  CREATE_LEGALENTITY_SUCCESS,
  CREATE_LEGALENTITY_FAILURE,
  RESET_CREATE_LEGALENTITY,
  UPDATE_LEGALENTITY_REQUEST,
  UPDATE_LEGALENTITY_SUCCESS,
  UPDATE_LEGALENTITY_FAILURE,
  RESET_UPDATE_LEGALENTITY,
  FETCH_LEGALENTITY_REQUEST,
  FETCH_LEGALENTITY_SUCCESS,
  FETCH_LEGALENTITY_FAILURE,
  RESET_FETCH_LEGALENTITY
} from './actionTypes';
import {
  CreateOPState,
  OfferingPackagesActions,
  OfferingPackageState,
  FeeGroupState,
  PriceProfileState,
  TokenTypeState,
  CardTypeState,
  LegalEntityState
} from './types';

const initialStateOP: OfferingPackageState = {
  pending: false,
  offeringPackage: [],
  error: null,
};

const initialState: FeeGroupState = {
  pending: false,
  error: null,
  response: {},
  feeGroups: [],
  page: {},
};

const initialStatePP: PriceProfileState = {
  pending: false,
  error: null,
  response: {},
  priceProfiles: [],
  page: {},
};

const initialStateTT: TokenTypeState={
  pending: false,
  error: null,
  response: {},
  tokenTypes: [],
  page: {},
}

const initialStateCT: CardTypeState={
  pending: false,
  error: null,
  response: {},
  cardTypes: [],
  page: {},
}

const initialStateLE: LegalEntityState ={
  pending: false,
  error: null,
  response: {},
  legalEntities: [],
  page: {},
}

export const offeringPackageReducer = (
  state = initialStateOP,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_OFFERINGPACKAGE_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
        offeringPackage: [],
      };
    case FETCH_OFFERINGPACKAGE_SUCCESS:
      return {
        ...state,
        pending: false,
        offeringPackage: action.payload.offeringPackage,
        error: null,
      };
    case FETCH_OFFERINGPACKAGE_FAILURE:
      return {
        ...state,
        pending: false,
        offeringPackage: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialCreateState: CreateOPState = {
  pending: false,
  error: null,
  response: {},
};

export const createFeeGroupReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case CREATE_FEEGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_FEEGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_FEEGROUP_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_FEEGROUP:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const createPriceProfileReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case CREATE_PRICEPROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_PRICEPROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_PRICEPROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_PRICEPROFILE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const createTokenTypeReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case CREATE_TOKENTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_TOKENTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_TOKENTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_TOKENTYPE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const createCardTypeReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case CREATE_CARDTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_CARDTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_CARDTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_CARDTYPE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const fetchFeeGroupReducer = (
  state = initialState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_FEE_GROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_FEE_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        feeGroups: action.payload.feeGroups,
        error: null,
        page: action.payload.page,
      };
    case FETCH_FEE_GROUP_FAILURE:
      return {
        ...state,
        pending: false,
        feeGroups: [],
        error: action.payload.error,
      };
    case RESET_FETCH_FEE_GROUP:
      return {
        ...state,
        pending: false,
        feeGroups: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export const fetchPriceProfileReducer = (
  state = initialStatePP,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_PRICEPROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PRICEPROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        priceProfiles: action.payload.priceProfiles,
        error: null,
        page: action.payload.page,
      };
    case FETCH_PRICEPROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        priceProfiles: [],
        error: action.payload.error,
      };
    case RESET_FETCH_PRICEPROFILE:
      return {
        ...state,
        pending: false,
        priceProfiles: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export const updateFeeGroupReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case UPDATE_FEEGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_FEEGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_FEEGROUP_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_FEEGROUP:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export const updatePriceProfileReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case UPDATE_PRICEPROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_PRICEPROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_PRICEPROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_PRICEPROFILE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const fetchTokenTypeReducer = (
  state = initialStateTT,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_TOKENTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TOKENTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        tokenTypes: action.payload.tokenTypes,
        error: null,
        page: action.payload.page,
      };
    case FETCH_TOKENTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        tokenTypes: [],
        error: action.payload.error,
      };
    case RESET_FETCH_TOKENTYPE:
      return {
        ...state,
        pending: false,
        tokenTypes: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};


export const updateTokenTypeReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case UPDATE_TOKENTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_TOKENTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_TOKENTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_TOKENTYPE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};


export const fetchCardTypeReducer = (
  state = initialStateCT,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_CARDTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CARDTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        cardTypes: action.payload.cardTypes,
        error: null,
        page: action.payload.page,
      };
    case FETCH_CARDTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        cardTypes: [],
        error: action.payload.error,
      };
    case RESET_FETCH_CARDTYPE:
      return {
        ...state,
        pending: false,
        cardTypes: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};


export const updateCardTypeReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case UPDATE_CARDTYPE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_CARDTYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_CARDTYPE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_CARDTYPE:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const createLegalEntityReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case CREATE_LEGALENTITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_LEGALENTITY_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case CREATE_LEGALENTITY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_CREATE_LEGALENTITY:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export const updateLegalEntityReducer = (
  state = initialCreateState,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case UPDATE_LEGALENTITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_LEGALENTITY_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        response: action.payload.response,
      };
    case UPDATE_LEGALENTITY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        response: {},
      };
    case RESET_UPDATE_LEGALENTITY:
      return {
        ...state,
        pending: false,
        error: null,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export const fetchLegalEntityReducer = (
  state = initialStateLE,
  action: OfferingPackagesActions
) => {
  switch (action.type) {
    case FETCH_LEGALENTITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LEGALENTITY_SUCCESS:
      return {
        ...state,
        pending: false,
        legalEntities: action.payload.legalEntities,
        error: null,
        page: action.payload.page,
      };
    case FETCH_LEGALENTITY_FAILURE:
      return {
        ...state,
        pending: false,
        legalEntities: [],
        error: action.payload.error,
      };
    case RESET_FETCH_LEGALENTITY:
      return {
        ...state,
        pending: false,
        legalEntities: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};





