import { all, call, put, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '../../config/Interceptors';
import { fetchCountriesFailure, fetchCountriesSuccess } from './actions';
import { FETCH_COUNTRIES_REQUEST } from './actionTypes';
import { Countries } from './types';
import * as Session from '../session';
import { AxiosResponse } from 'axios';

const getCountries = async () => {
  if (await Session.isValidSession()) {
    const token = Session.getSessionToken();
    return AxiosInstance.get<Countries[]>('/countries', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    window.location.reload();
    return false;
  }
};

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchCountriesSaga() {
  try {
    const response: AxiosResponse = yield call(getCountries);
    if (response.status === 200) {
      yield put(
        fetchCountriesSuccess({
          countries: response.data.data || [],
        })
      );
    } else {
      yield put(
        fetchCountriesFailure({
          error: response.statusText,
        })
      );
    }
  } catch (e: any) {
    yield put(
      fetchCountriesFailure({
        error: e.message,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* countryDetailsSaga() {
  yield all([takeLatest(FETCH_COUNTRIES_REQUEST, fetchCountriesSaga)]);
}

export default countryDetailsSaga;
