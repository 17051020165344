import { Button, Container, Grid, Modal, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchCountriesRequest } from '../store/countries/actions';
import { Token } from '../store/token/types';
import AdvancedSearch from './AdvancedSearch';
import CreateToken from './CreateToken';
import TokenTable from './TokenTable';
import UserPermission from '../store/UserPermission';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '80vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
  createTokenBtn: {
    '& button': {
      color: '#000000cf',
      backgroundColor: '#fbcf04',
      fontFamily: 'Shell-Book',
      '&:hover': { background: '#fbcf04d1' },
    },
    textAlign: 'right',
  },
}));

function TokenManagement(props: any) {
  const [tokenDownload, setTokenDownload] = useState<Token[]>([]);
  const { countries, countriesLoading, countriesError } = props;
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const [open, setModalOpen] = useState(false);
  const inputRef = useRef();

  const dispatch = useDispatch();
  if (!countriesError && !countriesLoading && countries.length === 0) {
    dispatch(fetchCountriesRequest());
  }

  const showSearchResultEvent = (e: any) => {
    setSearchResults(e);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const handleCreateToken = () => {
    setModalOpen(true);
  };

  const handleTokenDownload = (token: any) => {
    const tempTokenDownload: any = [];

    if (token.length > 0)
      token.forEach((eachtoken: Token) => {
        tempTokenDownload.push(eachtoken);
      });

    setTokenDownload(tempTokenDownload);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.createTokenBtn}>
            <UserPermission component="Tokens" permissionFor="create">
              <Button
                variant="contained"
                size="large"
                onClick={handleCreateToken}
              >
                Create Token
              </Button>
            </UserPermission>
          </Grid>

          <Grid item xs={12}>
            <Paper variant="outlined" className={fixedHeightPaper}>
              <AdvancedSearch
                searchPayload={(e: any) => setSearchPayload(e)}
                showSearchResult={setSearchResults}
                isModalOpen={open}
                handleTokenDownload={handleTokenDownload}
              />
            </Paper>
          </Grid>
          {searchResults ? (
            <Grid item xs={12}>
              <Paper variant="outlined" className={fixedHeightPaper}>
                <TokenTable
                  searchPayload={searchPayload}
                  tokenDownload={tokenDownload}
                  hideSearchResult={showSearchResultEvent}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        <Modal open={open}>
          <CreateToken ref={inputRef} setModalOpen={setModalOpen} />
        </Modal>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
  };
};
export default connect(mapStateToProps)(TokenManagement);
