import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import { fetchOfferingPackageRequest } from '../store/offeringPackages/actions';

import Spinner from './Spinner';
import OfferingPackageForm from './OfferingPackageForm';
import {
  fetchDealDetailsRequest,
  resetCreateDealsRequest,
  resetUpdateDealsRequest,
  resetCreateOffersRequest,
  resetUpdateOffersRequest,
} from '../store/deals/actions';
import ViewDeals from './ViewDeals';
import UserPermission from '../store/UserPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
    //zIndex: 99999,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  textOR: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '20px 0px 20px 0px',
    fontFamily: 'Shell-Book',
    border: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
  },
}));

function AggregatorDetails(props: any) {
  const classes = useStyles();
  const {
    offeringPackageLoading,
    dealsLoading,
    countriesLoading,
    countries,
    countriesError,
    dealCreated,
    dealUpdated,
  } = props;

  const dispatch = useDispatch();

  const [countryValue, setCountryValue] = useState('');
  const [hasCountryErrors, setCountryErrors] = useState(false);
  const [buttonDisable, setButtonDisable] = useState({
    create: true,
    view: true,
  });
  const [btnClick, setBtnClick] = useState({
    viewBtn: false,
    createBtn: false,
  });
  const [editDeals, setEditDeal] = useState(false);
  const [addOffers, setAddOffers] = useState(false);
  const [isEditOffer, setEditOffer] = useState(false);
  useEffect(() => {
    if (countryValue) {
      dispatch(fetchOfferingPackageRequest(countryValue));
    }
  }, [countryValue]);

  const handleCountryChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      if (
        Object.keys(dealCreated.response).length > 0 ||
        dealCreated.error ||
        Object.keys(dealUpdated.response).length > 0 ||
        dealUpdated.error
      ) {
        dispatch(resetCreateDealsRequest());
        dispatch(resetUpdateDealsRequest());
      }
      setBtnClick({ viewBtn: false, createBtn: false });
      setButtonDisable({ ...buttonDisable, create: false, view: false });
      setCountryValue(value);
    } else {
      setCountryErrors(true);
    }
  };
  const validateCountryErrors = (e: any) => {
    const { value } = e.target;
    if (value === '') setCountryErrors(true);
    else setCountryErrors(false);
  };

  const handleViewButtonClick = () => {
    setAddOffers(false);
    setEditOffer(false);
    setBtnClick({ ...btnClick, viewBtn: true, createBtn: false });
    setEditDeal(false);
    setButtonDisable({ ...buttonDisable, create: false, view: true });
    dispatch(fetchDealDetailsRequest({ country: countryValue }));
    if (
      Object.keys(dealCreated.response).length > 0 ||
      dealCreated.error ||
      Object.keys(dealUpdated.response).length > 0 ||
      dealUpdated.error
    ) {
      dispatch(resetCreateDealsRequest());
      dispatch(resetUpdateDealsRequest());
    }
    if (
      Object.keys(props.offersCreated.response).length > 0 ||
      props.offersCreated.error ||
      Object.keys(props.offersUpdated.response).length > 0 ||
      props.offersUpdated.error
    ) {
      dispatch(resetCreateOffersRequest());
      dispatch(resetUpdateOffersRequest());
    }

  };
  const handleCreateButtonClick = () => {
    setEditDeal(false);
    setAddOffers(false);
    setBtnClick({ ...btnClick, viewBtn: false, createBtn: true });
    setButtonDisable({ ...buttonDisable, create: true, view: false });
    if (
      Object.keys(dealCreated.response).length > 0 ||
      dealCreated.error ||
      Object.keys(dealUpdated.response).length > 0 ||
      dealUpdated.error
    ) {
      dispatch(resetCreateDealsRequest());
      dispatch(resetUpdateDealsRequest());
    }
    if (
      Object.keys(props.offersCreated.response).length > 0 ||
      props.offersCreated.error ||
      Object.keys(props.offersUpdated.response).length > 0 ||
      props.offersUpdated.error
    ) {
      dispatch(resetCreateOffersRequest());
      dispatch(resetUpdateOffersRequest());
    }
  };
  return (
    <>
      <ResponsiveContainer>
        <>
          <Card className={classes.root} variant="elevation">
            <Grid
              key="aggregatorDetails-grid-1"
              container
              justifyContent="flex-start"
            >
              <FormControl
                key="DM-country-formcontrol"
                variant="outlined"
                className={classes.formControl}
                error={hasCountryErrors || !!countriesError}
                size="small"
              >
                <InputLabel
                  key="DM-country-input-label"
                  id="DM-country-select-label"
                >
                  Country
                </InputLabel>
                <Select
                  key="DM-country-select"
                  name="country"
                  labelId="DM-country-select-label"
                  id="DM-country-select"
                  value={countryValue}
                  defaultValue=""
                  onChange={handleCountryChange}
                  label="Country"
                  onBlur={validateCountryErrors}
                >
                  {!countriesLoading &&
                    countries.map((option: any) => (
                      <MenuItem
                        key={option.code}
                        value={option.code}
                        aria-selected={true}
                        className={classes.menuItems}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText key="DM-country-FHT">
                  {countriesLoading
                    ? 'Loading countries'
                    : countriesError
                    ? `Error loading countries - [${countriesError}]`
                    : countryValue
                    ? ''
                    : 'Please select country'}
                </FormHelperText>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControlButton}
                size="small"
              >
                <Button
                  variant="contained"
                  size="large"
                  className={classes.searchButton}
                  onClick={handleViewButtonClick}
                  disabled={buttonDisable.view}
                >
                  View Deals
                </Button>
              </FormControl>
              <UserPermission component="Deals" permissionFor="create">
                <>
                  <div className={classes.textOR}>OR</div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControlButton}
                    size="small"
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.searchButton}
                      onClick={handleCreateButtonClick}
                      disabled={buttonDisable.create}
                    >
                      Create Deal
                    </Button>
                  </FormControl>
                </>
              </UserPermission>
            </Grid>
          </Card>

          {(btnClick.viewBtn && dealsLoading) ||
          (btnClick.createBtn && offeringPackageLoading) ? (
            <Card style={{ height: 120 }}>
              <Grid>
                <Spinner custom title={`Loading ...`} />
              </Grid>
            </Card>
          ) : btnClick.createBtn ? (
            <OfferingPackageForm
              key={'offeringPackageForm'}
              countryValue={countryValue}
              setButtonDisable={setButtonDisable}
            />
          ) : btnClick.viewBtn ? (
            <ViewDeals
              key={'viewDeals'}
              countryValue={countryValue}
              setButtonDisable={setButtonDisable}
              setEditDeal={setEditDeal}
              editDeals={editDeals}
              setAddOffers={setAddOffers}
              addOffers={addOffers}
              setEditOffer={setEditOffer}
              isEditOffer={isEditOffer}
            />
          ) : null}
        </>
      </ResponsiveContainer>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    dealsLoading: state.deals.pending,
    offeringPackage: state.offeringPackage.offeringPackage,
    offeringPackageLoading: state.offeringPackage.pending,
    offeringPackageError: state.offeringPackage.error,
    dealCreated: state.dealCreated,
    dealUpdated: state.dealUpdated,
    dealDetails: state.dealDetails.dealDetails,
    dealDetailsLoading: state.dealDetails.pending,
    offersUpdated: state.offersUpdated,
    offersCreated: state.offersCreated,
  };
};

export default connect(mapStateToProps)(AggregatorDetails);
