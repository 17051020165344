import {
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAILURE,
  RESET_FETCH_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_ONBOARDING_REQUEST,
  FETCH_CUSTOMER_ONBOARDING_SUCCESS,
  FETCH_CUSTOMER_ONBOARDING_FAILURE,
  RESET_FETCH_CUSTOMER_ONBOARDING,
  FETCH_CUSTOMER_GFNACCOUNT_REQUEST,
  FETCH_CUSTOMER_GFNACCOUNT_SUCCESS,
  FETCH_CUSTOMER_GFNACCOUNT_FAILURE,
  RESET_FETCH_CUSTOMER_GFNACCOUNT,
  FETCH_CUSTOMER_IPAAS_REQUEST,
  FETCH_CUSTOMER_IPAAS_SUCCESS,
  FETCH_CUSTOMER_IPAAS_FAILURE,
  RESET_FETCH_CUSTOMER_IPAAS,
  FETCH_CUSTOMER_PAYCARDREG_REQUEST,
  FETCH_CUSTOMER_PAYCARDREG_SUCCESS,
  FETCH_CUSTOMER_PAYCARDREG_FAILURE,
  RESET_FETCH_CUSTOMER_PAYCARDREG,
  FETCH_CUSTOMER_ADDRESS_REQUEST,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_CUSTOMER_ADDRESS_FAILURE,
  RESET_FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST,
  FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS,
  FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE,
  RESET_FETCH_CUSTOMER_INVOICE_DETAILS,
  UPDATE_CUSTOMER_VERIFY_REQUEST,
  UPDATE_CUSTOMER_VERIFY_SUCCESS,
  UPDATE_CUSTOMER_VERIFY_FAILURE,
  RESET_UPDATE_CUSTOMER_VERIFY,
  FETCH_CUSTOMER_CARDS_REQUEST,
  FETCH_CUSTOMER_CARDS_SUCCESS,
  FETCH_CUSTOMER_CARDS_FAILURE,
  RESET_FETCH_CUSTOMER_CARDS,
  FETCH_CUSTOMER_BUSINESS_REQUEST,
  FETCH_CUSTOMER_BUSINESS_SUCCESS,
  FETCH_CUSTOMER_BUSINESS_FAILURE,
  RESET_FETCH_CUSTOMER_BUSINESS,
  DELETE_CUSTOMER_ACCOUNT_REQUEST,
  DELETE_CUSTOMER_ACCOUNT_SUCCESS,
  DELETE_CUSTOMER_ACCOUNT_FAILURE,
  RESET_DELETE_CUSTOMER_ACCOUNT,
  BLOCK_CUSTOMER_ACCOUNT_REQUEST,
  BLOCK_CUSTOMER_ACCOUNT_SUCCESS,
  BLOCK_CUSTOMER_ACCOUNT_FAILURE,
  RESET_BLOCK_CUSTOMER_ACCOUNT,
  FETCH_CUSTOMER_H3INVOICE_REQUEST,
  FETCH_CUSTOMER_H3INVOICE_SUCCESS,
  FETCH_CUSTOMER_H3INVOICE_FAILURE,
  RESET_FETCH_CUSTOMER_H3INVOICE,
  FETCH_CUSTOMER_ONFIDO_REQUEST,
  FETCH_CUSTOMER_ONFIDO_SUCCESS,
  FETCH_CUSTOMER_ONFIDO_FAILURE,
  RESET_FETCH_CUSTOMER_ONFIDO,
  FETCH_CUSTOMER_CHARGEBACK_REQUEST,
  FETCH_CUSTOMER_CHARGEBACK_SUCCESS,
  FETCH_CUSTOMER_CHARGEBACK_FAILURE,
  CUSTOMER_ACCOUNT_CLOSER_REQUEST,
  CUSTOMER_ACCOUNT_CLOSER_SUCCESS,
  CUSTOMER_ACCOUNT_CLOSER_FAILURE,
  RESET_CUSTOMER_ACCOUNT_CLOSER,
  FETCH_SOA_DETAILS_REQUEST,
  FETCH_SOA_DETAILS_SUCCESS,
  FETCH_SOA_DETAILS_FAILURE,
  RESET_FETCH_SOA_DETAILS,
  FETCH_SOA_CHARGEBACK_REQUEST,
  FETCH_SOA_CHARGEBACK_SUCCESS,
  FETCH_SOA_CHARGEBACK_FAILURE,
  FETCH_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_FAILURE,
  RESET_FETCH_CUSTOMER_DETAILS,
  UPDATE_USER_NAME_REQUEST,
  UPDATE_USER_NAME_SUCCESS,
  UPDATE_USER_NAME_FAILURE,
  RESET_UPDATE_USER_NAME
} from './actionTypes';
import {
  CreatedUserState,
  UserDetailsActions,
  UserState,
  CustomerProfileState,
  CustomerOnboardingState,
  CustomerGfnaccountState,
  CustomerIpaasState,
  CustomerPayCardRegState,
  CustomerAddressState,
  CustomerInvoiceDetailsState,
  CustomerVerifyState,
  CustomerCardsState,
  CustomerBusinessState,
  CustomerDeleteState,
  AccountCloseState,
  CustomerBlockState,
  CustomerH3InvoiceState,
  CustomerOnfidoState,
  ChargebackInvoiceState,
  SOADetailsState,
  ChargebackSOAState,
  CustomerDetailsState,
  UpdateUserNameState
} from './types';

const initialStateUser: UserState = {
  pending: false,
  user: [],
  error: null,
  response: {},
};

const userSearch = (state = initialStateUser, action: UserDetailsActions) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        user: action.payload.user,
        error: null,
        response: action.payload.response,
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        user: [],
        response: {},
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCreatedUser: CreatedUserState = {
  pending: false,
  createdUser: [],
  error: null,
  response: {},
};

const createdUser = (
  state = initialStateCreatedUser,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        createdUser: [],
        pending: true,
        response: {},
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        createdUser: action.payload.user,
        error: null,
        response: action.payload.response,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        pending: false,
        createdUser: [],
        error: action.payload.error,
        response: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerProfile: CustomerProfileState = {
  pending: false,
  customerProfile: [],
  error: null,
  response: {},
  success: null,
};

const fetchCustomerProfileReducer = (
  state = initialStateCustomerProfile,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        customerProfile: action.payload.customerProfile,
        error: null,
        success: action.payload.success,
      };
    case FETCH_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        customerProfile: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_PROFILE:
      return {
        ...state,
        pending: false,
        customerProfile: [],
        response: {},
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerOnboarding: CustomerOnboardingState = {
  pending: false,
  customerOnboarding: [],
  error: null,
  response: {},
};

const fetchCustomerOnboardingReducer = (
  state = initialStateCustomerOnboarding,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_ONBOARDING_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_ONBOARDING_SUCCESS:
      return {
        ...state,
        pending: false,
        customerOnboarding: action.payload.customerOnboarding,
        error: null,
      };
    case FETCH_CUSTOMER_ONBOARDING_FAILURE:
      return {
        ...state,
        pending: false,
        customerOnboarding: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_ONBOARDING:
      return {
        ...state,
        pending: false,
        customerOnboarding: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerGfnaccount: CustomerGfnaccountState = {
  pending: false,
  customerGfnaccount: [],
  error: null,
  response: {},
};

const fetchCustomerGfnaccountReducer = (
  state = initialStateCustomerGfnaccount,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_GFNACCOUNT_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_GFNACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        customerGfnaccount: action.payload.customerGfnaccount,
        error: null,
      };
    case FETCH_CUSTOMER_GFNACCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        customerGfnaccount: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_GFNACCOUNT:
      return {
        ...state,
        pending: false,
        customerGfnaccount: [],
        response: {},
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerIpaas: CustomerIpaasState = {
  pending: false,
  customerIpaas: [],
  error: null,
  response: {},
};

const fetchCustomerIpaasReducer = (
  state = initialStateCustomerIpaas,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_IPAAS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_IPAAS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerIpaas: action.payload.customerIpaas,
        error: null,
      };
    case FETCH_CUSTOMER_IPAAS_FAILURE:
      return {
        ...state,
        pending: false,
        customerIpaas: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_IPAAS:
      return {
        ...state,
        pending: false,
        customerIpaas: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerPayCardReg: CustomerPayCardRegState = {
  pending: false,
  customerPayCardReg: [],
  error: null,
  response: {},
};

const fetchCustomerPayCardRegReducer = (
  state = initialStateCustomerPayCardReg,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_PAYCARDREG_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_PAYCARDREG_SUCCESS:
      return {
        ...state,
        pending: false,
        customerPayCardReg: action.payload.customerPayCardReg,
        error: null,
      };
    case FETCH_CUSTOMER_PAYCARDREG_FAILURE:
      return {
        ...state,
        pending: false,
        customerPayCardReg: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_PAYCARDREG:
      return {
        ...state,
        pending: false,
        customerPayCardReg: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerAddress: CustomerAddressState = {
  pending: false,
  customerAddress: [],
  error: null,
  response: {},
};

const fetchCustomercustomerAddressReducer = (
  state = initialStateCustomerAddress,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerAddress: action.payload.customerAddress,
        error: null,
      };
    case FETCH_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        pending: false,
        customerAddress: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_ADDRESS:
      return {
        ...state,
        pending: false,
        customerAddress: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerInvoiceDetails: CustomerInvoiceDetailsState = {
  pending: false,
  customerInvoiceDetails: [],
  error: null,
  response: {},
};

const initialStateChargebackInvoice: ChargebackInvoiceState = {
  pending: false,
  chargebacks: [],
  error: null,
};

const initialStateSOADetails: SOADetailsState = {
  pending: false,
  soaDetails: [],
  error: null,
  response: {},
};

const initialStateChargebackSOA: ChargebackSOAState = {
  pending: false,
  soaChargebacks: [],
  error: null,
};

const fetchSOADetailsReducer = (
  state = initialStateSOADetails,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_SOA_DETAILS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_SOA_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        soaDetails: action.payload.soaDetails,
        error: null,
      };
    case FETCH_SOA_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        soaDetails: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_SOA_DETAILS:
      return {
        ...state,
        pending: false,
        soaDetails: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const fetchChargebackSOADetailsReducer = (
  state = initialStateChargebackSOA,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_SOA_CHARGEBACK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SOA_CHARGEBACK_SUCCESS:
      return {
        ...state,
        pending: false,
        soaChargebacks: action.payload.soaChargebacks,
        error: null,
      };
    case FETCH_SOA_CHARGEBACK_FAILURE:
      return {
        ...state,
        pending: false,
        soaChargebacks: [],
        error: action.payload.error,
      };
    case RESET_FETCH_SOA_DETAILS:
      return {
        ...state,
        pending: false,
        soaChargebacks: [],
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const fetchCustomerInvoiceDetailsReducer = (
  state = initialStateCustomerInvoiceDetails,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_INVOICE_DETAILS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerInvoiceDetails: action.payload.customerInvoiceDetails,
        error: null,
      };
    case FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        customerInvoiceDetails: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_INVOICE_DETAILS:
      return {
        ...state,
        pending: false,
        customerInvoiceDetails: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const fetchChargebackInvoiceDetailsReducer = (
  state = initialStateChargebackInvoice,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_CHARGEBACK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CUSTOMER_CHARGEBACK_SUCCESS:
      return {
        ...state,
        pending: false,
        chargebacks: action.payload.chargebacks,
        error: null,
      };
    case FETCH_CUSTOMER_CHARGEBACK_FAILURE:
      return {
        ...state,
        pending: false,
        chargebacks: [],
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_INVOICE_DETAILS:
      return {
        ...state,
        pending: false,
        chargebacks: [],
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerVerify: CustomerVerifyState = {
  pending: false,
  customerVerify: {},
  error: null,
};

const updateCustomerVerifyReducer = (
  state = initialStateCustomerVerify,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_VERIFY_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case UPDATE_CUSTOMER_VERIFY_SUCCESS:
      return {
        ...state,
        pending: false,
        customerVerify: action.payload.customerVerify,
        error: null,
      };
    case UPDATE_CUSTOMER_VERIFY_FAILURE:
      return {
        ...state,
        pending: false,
        customerVerify: {},
        error: action.payload.error,
      };
    case RESET_UPDATE_CUSTOMER_VERIFY:
      return {
        ...state,
        pending: false,
        customerVerify: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerCards: CustomerCardsState = {
  pending: false,
  customerCards: [],
  error: null,
  response: {},
};

const fetchCustomerCardsReducer = (
  state = initialStateCustomerCards,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_CARDS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_CARDS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerCards: action.payload.customerCards,
        error: null,
      };
    case FETCH_CUSTOMER_CARDS_FAILURE:
      return {
        ...state,
        pending: false,
        customerCards: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_CARDS:
      return {
        ...state,
        pending: false,
        customerCards: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerBusiness: CustomerBusinessState = {
  pending: false,
  customerBusiness: [],
  error: null,
  response: {},
};

const fetchCustomerBusinessReducer = (
  state = initialStateCustomerBusiness,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_BUSINESS_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case FETCH_CUSTOMER_BUSINESS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerBusiness: action.payload.customerBusiness,
        error: null,
      };
    case FETCH_CUSTOMER_BUSINESS_FAILURE:
      return {
        ...state,
        pending: false,
        customerBusiness: [],
        response: {},
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_BUSINESS:
      return {
        ...state,
        pending: false,
        customerBusiness: [],
        response: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerDelete: CustomerDeleteState = {
  pending: false,
  customerDelete: {},
  error: null,
};

const deleteCustomerReducer = (
  state = initialStateCustomerDelete,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case DELETE_CUSTOMER_ACCOUNT_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case DELETE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        customerDelete: action.payload.customerDelete,
        error: null,
      };
    case DELETE_CUSTOMER_ACCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        customerDelete: {},
        error: action.payload.error,
      };
    case RESET_DELETE_CUSTOMER_ACCOUNT:
      return {
        ...state,
        pending: false,
        customerDelete: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};


const initialStateCustomerAccountClose: AccountCloseState = {
  pending: false,
  accountClose: false,
  error: null,
};

const accountCloseReducer = (
  state = initialStateCustomerAccountClose,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case CUSTOMER_ACCOUNT_CLOSER_REQUEST:
      return {
        ...state,
        pending:true,
      };
    case CUSTOMER_ACCOUNT_CLOSER_SUCCESS:
      return {
        ...state,
        accountClose:true,
        pending:false,
        error: null,
      };
    case CUSTOMER_ACCOUNT_CLOSER_FAILURE:
      return {
        ...state,
        pending: false,
        accountClose: false,
        error: action.payload.error,
      };
    case RESET_CUSTOMER_ACCOUNT_CLOSER:
      return {
        ...state,
        pending: false,
        accountClose: false,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerBlock: CustomerBlockState = {
  pending: false,
  customerBlock: false,
  error: null,
};

const blockCustomerReducer = (
  state = initialStateCustomerBlock,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case BLOCK_CUSTOMER_ACCOUNT_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case BLOCK_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        customerBlock: action.payload.customerBlock,
        error: null,
      };
    case BLOCK_CUSTOMER_ACCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        customerBlock: false,
        error: action.payload.error,
      };
    case RESET_BLOCK_CUSTOMER_ACCOUNT:
      return {
        ...state,
        pending: false,
        customerBlock: false,
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerH3Invoice: CustomerH3InvoiceState = {
  pending: false,
  customerH3Invoice: [],
  error: null,
};

const fetchCustomerH3InvoiceReducer = (
  state = initialStateCustomerH3Invoice,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_H3INVOICE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CUSTOMER_H3INVOICE_SUCCESS:
      return {
        ...state,
        pending: false,
        customerH3Invoice: action.payload.customerH3Invoice,
        error: null,
      };
    case FETCH_CUSTOMER_H3INVOICE_FAILURE:
      return {
        ...state,
        pending: false,
        customerH3Invoice: [],
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_H3INVOICE:
      return {
        ...state,
        pending: false,
        customerH3Invoice: [],
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerOnfido: CustomerOnfidoState = {
  pending: false,
  customerOnfido: [],
  error: null,
};

const fetchCustomerOnfidoReducer = (
  state = initialStateCustomerOnfido,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_ONFIDO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CUSTOMER_ONFIDO_SUCCESS:
      return {
        ...state,
        pending: false,
        customerOnfido: action.payload.customerOnfido,
        error: null,
      };
    case FETCH_CUSTOMER_ONFIDO_FAILURE:
      return {
        ...state,
        pending: false,
        customerOnfido: [],
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_ONFIDO:
      return {
        ...state,
        pending: false,
        customerOnfido: [],
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateCustomerDetails: CustomerDetailsState = {
  pending: false,
  customerDetails: [],
  error: null,
  page: ''
};

const fetchCustomerDetailsReducer = (
  state = initialStateCustomerDetails,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        customerDetails: action.payload.customerDetails,
        error: null,
        page: action.payload.page,
      };
    case FETCH_CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        customerDetails: [],
        error: action.payload.error,
      };
    case RESET_FETCH_CUSTOMER_DETAILS:
      return {
        ...state,
        pending: false,
        customerDetails: [],
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

const initialStateUpdateUserName: UpdateUserNameState = {
  pending: false,
  userNameDetails: {},
  error: null,
};

const updateUserNameReducer = (
  state = initialStateUpdateUserName,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case UPDATE_USER_NAME_REQUEST:
      return {
        ...state,
        response: {},
        pending: true,
      };
    case UPDATE_USER_NAME_SUCCESS:
      return {
        ...state,
        pending: false,
        userNameDetails: action.payload.userNameDetails,
        error: null,
      };
    case UPDATE_USER_NAME_FAILURE:
      return {
        ...state,
        pending: false,
        userNameDetails: {},
        error: action.payload.error,
      };
    case RESET_UPDATE_USER_NAME:
      return {
        ...state,
        pending: false,
        userNameDetails: {},
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export {
  userSearch,
  createdUser,
  fetchCustomerProfileReducer,
  fetchCustomerOnboardingReducer,
  fetchCustomerGfnaccountReducer,
  fetchCustomerIpaasReducer,
  fetchCustomerPayCardRegReducer,
  fetchCustomercustomerAddressReducer,
  fetchCustomerInvoiceDetailsReducer,
  fetchChargebackInvoiceDetailsReducer,
  updateCustomerVerifyReducer,
  fetchCustomerCardsReducer,
  fetchCustomerBusinessReducer,
  deleteCustomerReducer,
  blockCustomerReducer,
  fetchCustomerH3InvoiceReducer,
  fetchCustomerOnfidoReducer,
  accountCloseReducer,
  fetchChargebackSOADetailsReducer,
  fetchSOADetailsReducer,
  fetchCustomerDetailsReducer,
  updateUserNameReducer
};
