import * as React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    '&:hover': {
      background: '#fbcf04d1 !important',
    },
    background: '#fed700d1 !important',
    color:'#000000 !important',
    alignItems: 'center',
    margin: theme.spacing(1, 2, 4, 2),
    width: 110,
    height: 40,
    fontFamily: 'Shell-Book',
  },  circularProgress: {
    margin: theme.spacing(1, 2, 4, 2),
    color: '#fbcf04',
  },
}));

export default function ConfirmDialog(props: any) {
  const classes = useStyles();
  return (
    <div>

      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.deleteConfiguartionLoading?
        (<CircularProgress className={classes.circularProgress} />
        ): (<React.Fragment>
           <Button
            variant="contained"
            aria-label="settings"
            className={classes.buttonIcon}
            title="Cancel"
            onClick={props.onClose}>
            <span>Cancel</span>
          </Button>
          <Button
            variant="contained"
            aria-label="settings"
            className={classes.buttonIcon}
            title="Ok"
          onClick={props.confirmDelete} autoFocus >
           <span>Ok</span>
          </Button>
          </React.Fragment>
         )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
