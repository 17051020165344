export const status = [
    {
      value: '1',
      label: 'New',
    },
    {
      value: '2',
      label: 'Pending',
    },
    {
      value: '3',
      label: 'Active',
    },
    {
      value: '4',
      label: 'Closed',
    },
    {
      value: '5',
      label: 'FailedApplication',
    },
    {
      value: '100',
      label: 'ReadOnlyAccess',
    }];
    
    export const subStatus = [
    {
      value: '10',
      label: 'UserIdCreated',
    },
    {
      value: '11',
      label: 'IdentityVerification',
    },
    {
      value: '12',
      label: 'BusinessVerification',
    },
    {
      value: '13',
      label: 'PhoneVerification',
    },
    {
      value: '14',
      label: 'PaymentVerification',
    },
    {
      value: '15',
      label: 'TermsAccepted',
    },
    {
      value: '16',
      label: 'AddressVerification',
    },
    {
      value: '17',
      label: 'FleetSetUp',
    },
    {
      value: '18',
      label: 'ClosedByManager',
    },
    {
      value: '19',
      label: 'PaymentCardRegistration',
    }, {
      value: '20',
      label: 'OfferingPackageSelection',
    },
    {
      value: '21',
      label: 'BusinessRegistrationDocumentUpload',
    },
    {
      value: '22',
      label: 'BusinessRegistrationOrSecretaryCertificationUpload',
    },
    {
      value: '23',
      label: 'BIRCertificationUpload',
    },
    {
      value: '24',
      label: 'BusinessVerificationPH',
    },
    {
      value: '25',
      label: 'MY_SSMFormDEBRD_DocumentUploaded',
    },
    {
      value: '26',
      label: 'MY_AllBusinessDocumentsUploaded',
    },
    {
      value: '27',
      label: 'BusinessVerificationMY',
    },
    {
      value: '28',
      label: 'AwaitingAccountClosure',
    }];