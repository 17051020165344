import { Card, Typography } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import React from 'react';
  import { connect, useDispatch } from 'react-redux';
  import { useFormik } from 'formik';
  import { createFeeGroupRequest, updateFeeGroupRequest } from '../../store/offeringPackages/actions';
  import Spinner from '../Spinner';
  import Alert from '@material-ui/lab/Alert';
  import _ from 'lodash';
  import { manageOfferSchema } from '../../schemas/manageOfferSchema';
  import { DealMasterForm } from './DealMasterForm';
  
  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
        fontFamily: 'Shell-Book',
      },
      textAlign: 'left',
      margin: '10px 0px',
    }
  }));
  
  const offerDetailsTextItems: {
    feeGroup: { key: string; label: string; type: string; cur?: boolean; }[];
  } = {
    feeGroup: [
      { key: 'feeGroup.name', label: 'Name', type: 'text' },
      { key: 'feeGroup.id', label: 'ID', type: 'number' },
      { key: 'feeGroup.setUpFee', label: 'Setup Fee', cur: true, type: 'number' },
      { key: 'feeGroup.monthlyFee', label: 'Monthly Fee', type: 'number', cur: true },
      { key: 'feeGroup.cardMonthlyFee', label: 'Card Monthly Fee', type: 'number', cur: true },
      { key: 'feeGroup.transactionFee', label: 'Transaction Fee', type: 'number', cur: true },
      { key: 'feeGroup.annualFee', label: 'Annual Fee', type: 'number', cur: true },
      {
        key: 'feeGroup.cardReplacementFee',
        label: 'Card Replacement Fee',
        type: 'number',
        cur: true,
      },
      { key: 'feeGroup.oneOffFee', label: 'One Off Fee', type: 'number', cur: true },
      { key: 'feeGroup.ipaasCode', label: 'IPaas Code', type: 'text' },
    ]
  };
  
  const initialValues: any = {
    category: '',
    subCategory: 'feeGroup',
    feeGroup: {
      countryCode: '',
      marketId: '',
      name: '',
      id: '',
      setUpFee: 0.0,
      monthlyFee: 0.0,
      cardMonthlyFee: 0.0,
      transactionFee: 0.0,
      annualFee: 0.0,
      cardReplacementFee: 0.0,
      oneOffFee: 0.0,
      ipaasCode: '',
    }
  };
  
  const CreateUpdateFeeGroup = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
      offeringPackage,
      countryValue,
      feeGroupCreated,
      feeGroupUpdated,
      offeringPackageError,
      setButtonDisable,
      isDealMasterEdit,
      setEditDealMaster,
      feeGroupInitialValues,
      setBtnClick,
      buttonDisable,
      setCreateUpdateStatus,
      createUpdateStatus
    } = props;

    const currencyId = offeringPackage.currencyId;
    
    const formik = useFormik({
      initialValues: isDealMasterEdit ? feeGroupInitialValues : initialValues,
      validationSchema: manageOfferSchema,
      onSubmit: (values , { setSubmitting, resetForm }) => {
        formatValues(values, offeringPackage.marketId, countryValue, dispatch,
           setButtonDisable, isDealMasterEdit, buttonDisable);
        setSubmitting(false);
        resetForm();
      },
    });

    _.set(formik.values.feeGroup, 'name', _.get(formik.values.feeGroup, 'name').replace(
      `${countryValue} `,
      ''
    ));

    if (
      !formik.isSubmitting &&
      countryValue !== '' &&
      (Object.keys(feeGroupCreated.response).length > 0 ||
      feeGroupCreated.error ||
        Object.keys(feeGroupUpdated.response).length > 0 ||
        feeGroupUpdated.error)
    ) {
      setCreateUpdateStatus({...createUpdateStatus, flag:true, screen: isDealMasterEdit? 'update' : 'create'});
      setBtnClick({ createBtn: false });
      setEditDealMaster(false);
      setButtonDisable({ ...buttonDisable, create:false});
    }
  
    if (countryValue === '') {
      return <></>;
    } else if (
      countryValue !== '' &&
      (feeGroupCreated.pending || feeGroupUpdated.pending)
    ) {
      return (
        <Card
          className={classes.root}
          style={{ textAlign: 'center' }}
          variant="elevation"
        >
          <Spinner
            custom
            title={isDealMasterEdit ? 'Updating the Fee Group...' : 'Creating the Fee Group...'}
          />
        </Card>
      );
    } 
    else if (
      countryValue !== '' &&
      Object.keys(offeringPackage).length > 0 &&
      !offeringPackageError
    ) {
      return (
        <>
          <DealMasterForm
            countryValue={countryValue}
            setButtonDisable={setButtonDisable}
            isDealMasterEdit={isDealMasterEdit}
            setEditDealMaster={setEditDealMaster}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            formik={formik}
            screenName='Fee Group'
            screenType='feeGroup'
            currencyId={currencyId}
            offerDetailsTextItems={offerDetailsTextItems}
          />
        </>
      );
    } else {
      return (
        <Typography>
          <Alert severity="error">{`Error fetching Master Data for the Country - [${offeringPackageError}]`}</Alert>
        </Typography>
      );
    }
  };

  const mapStateToProps = (state: any) => {
    return {
      countries: state.countries.countries,
      countriesLoading: state.countries.pending,
      countriesError: state.countries.error,
      offeringPackage: state.offeringPackage.offeringPackage,
      offeringPackageResp: state.offeringPackage,
      offeringPackageLoading: state.offeringPackage.pending,
      OfferingPackageError: state.offeringPackage.error,
      feeGroupCreated: state.feeGroupCreated,
      feeGroupUpdated: state.feeGroupUpdated
    };
  };
  
  export default connect(mapStateToProps)(CreateUpdateFeeGroup);

  function formatValues(
    values: any,
    marketId: string,
    countryCode: string,
    dispatch: any,
    setButtonDisable: any,
    isDealMasterEdit: any,
    buttonDisable: any
  ) {
      values.feeGroup.countryCode = countryCode;
      values.feeGroup.marketId = marketId;
      setButtonDisable({ ...buttonDisable, create: true });
      if (isDealMasterEdit) {
        dispatch(updateFeeGroupRequest(values.feeGroup));
      } else {
        dispatch(createFeeGroupRequest(values.feeGroup));
      }
    };
  
  