import {
  FETCH_DEALS_FAILURE,
  FETCH_DEALS_REQUEST,
  FETCH_DEALS_SUCCESS,
  CREATE_DEALS_SUCCESS,
  CREATE_DEALS_REQUEST,
  CREATE_DEALS_FAILURE,
  RESET_CREATE_DEALS_REQUEST,
  FETCH_DEAL_DETAILS_REQUEST,
  FETCH_DEAL_DETAILS_SUCCESS,
  FETCH_DEAL_DETAILS_FAILURE,
  RESET_FETCH_DEAL_DETAILS,
  UPDATE_DEALS_SUCCESS,
  UPDATE_DEALS_REQUEST,
  UPDATE_DEALS_FAILURE,
  RESET_UPDATE_DEALS_REQUEST,
  FETCH_ALL_DEALS_REQUEST,
  FETCH_ALL_DEALS_FAILURE,
  FETCH_ALL_DEALS_SUCCESS,
  CREATE_OFFERS_REQUEST,
  CREATE_OFFERS_SUCCESS,
  CREATE_OFFERS_FAILURE,
  UPDATE_OFFERS_REQUEST,
  UPDATE_OFFERS_SUCCESS,
  UPDATE_OFFERS_FAILURE,
  RESET_CREATE_OFFERS_REQUEST,
  RESET_UPDATE_OFFERS_REQUEST
} from './actionTypes';
import {
  FetchDealsFailure,
  FetchDealsFailurePayload,
  FetchDealsRequest,
  FetchDealsSuccess,
  FetchDealsSuccessPayload,
  CreateDealsRequest,
  CreateDealsPayload,
  CreateDealsSuccess,
  CreateDealsSuccessPayload,
  // UpdateOffersPayload,
  UpdateOffersSuccessPayload,
  UpdateOffersFailurePayload,
  UpdateOffersSuccess,
  UpdateOffersRequest,
  UpdateOffersFailure,
  CreateDealsFailure,
  CreateDealsFailurePayload,
  FetchDealDetailsRequest,
  FetchDealDetailsSuccess,
  FetchDealDetailsFailure,
  FetchDealDetailsRequestPayload,
  FetchDealDetailsSuccessPayload,
  FetchDealDetailsFailurePayload,
  UpdateDealsRequest,
  UpdateDealsSuccess,
  UpdateDealsFailure,
  FetchAllDealsRequest,
  FetchAllDealsFailure,
  FetchAllDealsSuccess,
  FetchAllDealsSuccessPayload,
  FetchAllDealsFailurePayload,
  CreateOffersPayload,
  CreateOffersSuccessPayload,
  CreateOffersFailurePayload,
  CreateOffersRequest,
  CreateOffersSuccess,
  CreateOffersFailure,
} from './types';

export const fetchDealsRequest = (country: string): FetchDealsRequest => ({
  type: FETCH_DEALS_REQUEST,
  country,
});

export const fetchDealsSuccess = (
  payload: FetchDealsSuccessPayload
): FetchDealsSuccess => ({
  type: FETCH_DEALS_SUCCESS,
  payload,
});

export const fetchDealsFailure = (
  payload: FetchDealsFailurePayload
): FetchDealsFailure => ({
  type: FETCH_DEALS_FAILURE,
  payload,
});

export const fetchDealDetailsRequest = (
  payload: FetchDealDetailsRequestPayload
): FetchDealDetailsRequest => ({
  type: FETCH_DEAL_DETAILS_REQUEST,
  payload,
});

export const fetchDealDetailsSuccess = (
  payload: FetchDealDetailsSuccessPayload
): FetchDealDetailsSuccess => ({
  type: FETCH_DEAL_DETAILS_SUCCESS,
  payload,
});

export const fetchDealDetailsFailure = (
  payload: FetchDealDetailsFailurePayload
): FetchDealDetailsFailure => ({
  type: FETCH_DEAL_DETAILS_FAILURE,
  payload,
});

export const resetFetchDealDetails = () => ({
  type: RESET_FETCH_DEAL_DETAILS,
});

export const createDealsRequest = (
  payload: CreateDealsPayload
): CreateDealsRequest => ({
  type: CREATE_DEALS_REQUEST,
  payload,
});

export const createDealsSuccess = (
  payload: CreateDealsSuccessPayload
): CreateDealsSuccess => ({
  type: CREATE_DEALS_SUCCESS,
  payload,
});

export const createDealsFailure = (
  payload: CreateDealsFailurePayload
): CreateDealsFailure => ({
  type: CREATE_DEALS_FAILURE,
  payload,
});

export const updateDealsRequest = (
  payload: CreateDealsPayload
): UpdateDealsRequest => ({
  type: UPDATE_DEALS_REQUEST,
  payload,
});

export const updateDealsSuccess = (
  payload: CreateDealsSuccessPayload
): UpdateDealsSuccess => ({
  type: UPDATE_DEALS_SUCCESS,
  payload,
});

export const updateDealsFailure = (
  payload: CreateDealsFailurePayload
): UpdateDealsFailure => ({
  type: UPDATE_DEALS_FAILURE,
  payload,
});

export const resetCreateDealsRequest = () => ({
  type: RESET_CREATE_DEALS_REQUEST,
});
export const resetUpdateDealsRequest = () => ({
  type: RESET_UPDATE_DEALS_REQUEST,
});

export const fetchAllDealsRequest = (country: string): FetchAllDealsRequest => ({
  type: FETCH_ALL_DEALS_REQUEST,
  country,
});

export const fetchAllDealsSuccess = (
  payload: FetchAllDealsSuccessPayload
): FetchAllDealsSuccess => ({
  type: FETCH_ALL_DEALS_SUCCESS,
  payload,
});

export const fetchAllDealsFailure = (
  payload: FetchAllDealsFailurePayload
): FetchAllDealsFailure => ({
  type: FETCH_ALL_DEALS_FAILURE,
  payload,
});

export const resetCreateOffersRequest = () => ({
  type: RESET_CREATE_OFFERS_REQUEST,
});
export const resetUpdateOffersRequest = () => ({
  type: RESET_UPDATE_OFFERS_REQUEST,
});

export const createOffersRequest = (
  payload: CreateOffersPayload
): CreateOffersRequest => ({
  type: CREATE_OFFERS_REQUEST,
  payload,
});

export const createOffersSuccess = (
  payload: CreateOffersSuccessPayload
): CreateOffersSuccess => ({
  type: CREATE_OFFERS_SUCCESS,
  payload,
});

export const createOffersFailure = (
  payload: CreateOffersFailurePayload
): CreateOffersFailure => ({
  type: CREATE_OFFERS_FAILURE,
  payload,
});

export const updateOffersRequest = (
  payload: CreateOffersPayload
): UpdateOffersRequest => ({
  type: UPDATE_OFFERS_REQUEST,
  payload,
});

export const updateOffersSuccess = (
  payload: UpdateOffersSuccessPayload
): UpdateOffersSuccess => ({
  type: UPDATE_OFFERS_SUCCESS,
  payload,
});

export const updateOffersFailure = (
  payload: UpdateOffersFailurePayload
): UpdateOffersFailure => ({
  type: UPDATE_OFFERS_FAILURE,
  payload,
});
