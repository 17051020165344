import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { getTimeDiff } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    border: '1px solid #f4f4f4',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  dnbRow: {
    marginTop: '6px',
  },
}));

function BusinessManual(props: any) {
  const classes = useStyles();
  const { businessManual } = props;

  const businessManualMap = [
    {
      id: 'name',
      value: businessManual.name,
      label: 'Name',
    },
    {
      id: 'registartionNumber',
      value: businessManual.registartionNumber,
      label: 'Registartion Number',
    },
    {
      id: 'tin',
      value: businessManual.tin,
      label: 'TIN',
    },
    {
      id: 'dateOfIncorporation',
      value: businessManual.dateOfIncorporation
        ? getTimeDiff(businessManual.dateOfIncorporation)
        : '-',
      label: 'Incorporation Date',
    },
  ];

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box sx={{ m: 2 }}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontSize: '0.9rem',
              borderBottom: '1px solid #D9D9D9',
              pb: 1,
            }}
          >
            Business Manual
          </Typography>
          {businessManualMap.map(({ label, value, id }) => {
            return (
              <Box className={classes.box} key={id}>
                <Grid container className={classes.dnbRow}>
                  <Grid item xs={7}>
                    {label}
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ color: 'text.secondary' }}>{value || '-'}</Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Card>
    </div>
  );
}

export default BusinessManual;
