export const apiEndpoint = () => {
  console.log('Environment == ', process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'https://app-shfaadminapi-qa.azurewebsites.net';
      //return 'https://localhost:44317';
    case 'uat':
      return 'https://fleetapp-uat.shell.com/adminapi';
    case 'production':
      return 'https://fleetapp.shell.com/adminapi';
    default:
      return 'https://app-shfaadminapi-qa.azurewebsites.net';
      //return 'https://localhost:44317';

  }
};
export const apiVersion = '1.0';

export const mockServer = {
  endpoint: 'http://private-b1e68-motix4.apiary-mock.com/motix4',
};
