import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import {
  fetchOfferingPackageRequest,
  fetchFeeGroupRequest,
  fetchPriceProfileRequest,
  fetchTokenTypeRequest,
  fetchCardTypeRequest,
  fetchLegalEntityRequest,
  resetCreateFeeGroupRequest,
  resetUpdateFeeGroupRequest,
  resetCreatePriceProfileRequest,
  resetUpdatePriceProfileRequest,
  resetCreateTokenTypeRequest,
  resetUpdateTokenTypeRequest,
  resetCreateCardTypeRequest,
  resetUpdateCardTypeRequest,
  resetCreateLegalEntityRequest,
  resetUpdateLegalEntityRequest,
} from '../../store/offeringPackages/actions';
import Spinner from '../Spinner';
import ViewFeeGroup from './ViewFeeGroup';
import ViewPriceProfile from './ViewPriceProfile';
import ViewTokenType from './ViewTokenType';
import ViewCardType from './ViewCardType';
import ViewLegalEntity from './ViewLegalEntity';
import { useLocation } from 'react-router-dom';
import CreateUpdateFeeGroup from './CreateUpdateFeeGroup';
import CreateUpdatePriceProfile from './CreateUpdatePriceProfile';
import CreateUpdateTokenType from './CreateUpdateTokenType';
import CreateUpdateCardType from './CreateUpdateCardType';
import CreateUpdateLegalEntity from './CreateUpdateLegalEntity';
import UserPermission from '../../store/UserPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root, .MuiFormLabel-root, .MuiFormHelperText-root': {
      fontFamily: 'Shell-Book',
    },
    textAlign: 'left',
    margin: '10px 0px',
  },
  formControl: {
    '& .MuiSelect-select.Mui-disabled': { background: '#eee' },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    margin: theme.spacing(1, 5),
    minWidth: 250,
    width: 325,
  },
  formControlButton: {
    margin: 0,
  },
  menuItems: {
    fontFamily: 'Shell-Book',
  },
  searchButton: {
    margin: theme.spacing(1, 2, 1, 2),
    color: '#000000cf',
    backgroundColor: '#fbcf04',
    height: 40,
    fontFamily: 'Shell-Book',
    '&:hover': { background: '#fbcf04d1' },
  },
  input: {
    margin: '0px',
  },
  customInput: {
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    paddingRight: 39,
    //zIndex: 99999,
  },
  inputFormControl: {
    margin: theme.spacing(1, 5),
    width: 325,
  },
  helperText: {
    marginLeft: 10,
  },
  titleMsg: {
    fontFamily: 'Shell-Medium',
    padding: '10px',
    fontSize: '1rem',
    textAlign: 'left',
  },
  divider: {
    margin: '20px 0px 10px 0px',
  },
  textOR: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '20px 0px 20px 0px',
    fontFamily: 'Shell-Book',
    border: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
  },
}));

function CountryAggregator(props: any) {
  const classes = useStyles();
  const {
    offeringPackageLoading,
    dealsLoading,
    countriesLoading,
    countries,
    countriesError,
    name,
    feeGroupCreated,
    feeGroupUpdated,
    priceProfileCreated,
    priceProfileUpdated,
    tokenTypeCreated,
    tokenTypeUpdated,
    cardTypeCreated,
    cardTypeUpdated,
    legalEntityCreated,
    legalEntityUpdated,
  } = props;
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const [countryValue, setCountryValue] = useState('');
  const [hasCountryErrors, setCountryErrors] = useState(false);
  const [buttonDisable, setButtonDisable] = useState({ create: true });
  const [btnClick, setBtnClick] = useState({ createBtn: false });
  const [editDealMaster, setEditDealMaster] = useState(false);
  const [viewComponentName, setViewComponentName] = useState('');
  const [createUpdateStatus, setCreateUpdateStatus] = useState({
    flag: false,
    screen: '',
  });

  const screenName = name
    .replace(/\w+/g, function (w: any) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    })
    .split('-')
    .join('');

  const createUpdateComponentMap: any = {
    FeeGroup: CreateUpdateFeeGroup,
    PriceProfile: CreateUpdatePriceProfile,
    TokenType: CreateUpdateTokenType,
    CardType: CreateUpdateCardType,
    LegalEntity: CreateUpdateLegalEntity,
  };

  const viewComponentMap: any = {
    FeeGroup: ViewFeeGroup,
    PriceProfile: ViewPriceProfile,
    TokenType: ViewTokenType,
    CardType: ViewCardType,
    LegalEntity: ViewLegalEntity,
  };

  useEffect(() => {
    handlePathNameChange();
  }, [pathname]);

  useEffect(() => {
    fetchData(countryValue);
  }, [createUpdateStatus]);

  function handlePathNameChange() {
    let arr = name.split('-');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    setViewComponentName(arr.join(''));
    setCountryValue('');
    setButtonDisable({ ...buttonDisable, create: true });
  }

  const loadViewComponent = () => {
    let ViewComponent = viewComponentMap[viewComponentName];
    return (
      <ViewComponent
        countryValue={countryValue}
        editDealMaster={editDealMaster}
        setEditDealMaster={setEditDealMaster}
        setButtonDisable={setButtonDisable}
        buttonDisable={buttonDisable}
        setBtnClick={setBtnClick}
        createUpdateStatus={createUpdateStatus}
        setCreateUpdateStatus={setCreateUpdateStatus}
      />
    );
  };

  const loadCrtUptComponent = () => {
    let LoadComponent = createUpdateComponentMap[viewComponentName];
    return (
      <LoadComponent
        countryValue={countryValue}
        setButtonDisable={setButtonDisable}
        setEditDealMaster={setEditDealMaster}
        setBtnClick={setBtnClick}
        buttonDisable={buttonDisable}
        createUpdateStatus={createUpdateStatus}
        setCreateUpdateStatus={setCreateUpdateStatus}
      />
    );
  };

  useEffect(() => {
    if (countryValue) {
      dispatch(fetchOfferingPackageRequest(countryValue));
    }
  }, [countryValue]);

  const handleCountryChange = (event: any) => {
    const { value } = event.target;
    if (value !== '') {
      setBtnClick({ createBtn: false });
      setButtonDisable({ ...buttonDisable, create: false });
      setEditDealMaster(false);
      setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
      setCountryValue(value);
      fetchData(value);
      resetRequest();
    } else {
      setCountryErrors(true);
    }
  };

  const resetRequest = () => {
    switch (viewComponentName) {
      case 'FeeGroup':
        if (
          Object.keys(feeGroupCreated.response).length > 0 ||
          feeGroupCreated.error ||
          Object.keys(feeGroupUpdated.response).length > 0 ||
          feeGroupUpdated.error
        ) {
          dispatch(resetCreateFeeGroupRequest());
          dispatch(resetUpdateFeeGroupRequest());
        }
        return;
      case 'PriceProfile':
        if (
          Object.keys(priceProfileCreated.response).length > 0 ||
          priceProfileCreated.error ||
          Object.keys(priceProfileUpdated.response).length > 0 ||
          priceProfileUpdated.error
        ) {
          dispatch(resetCreatePriceProfileRequest());
          dispatch(resetUpdatePriceProfileRequest());
        }
        return;
      case 'TokenType':
        if (
          Object.keys(tokenTypeCreated.response).length > 0 ||
          tokenTypeCreated.error ||
          Object.keys(tokenTypeUpdated.response).length > 0 ||
          tokenTypeUpdated.error
        ) {
          dispatch(resetCreateTokenTypeRequest());
          dispatch(resetUpdateTokenTypeRequest());
        }
        return;
      case 'CardType':
        if (
          Object.keys(cardTypeCreated.response).length > 0 ||
          cardTypeCreated.error ||
          Object.keys(cardTypeUpdated.response).length > 0 ||
          cardTypeUpdated.error
        ) {
          dispatch(resetCreateCardTypeRequest());
          dispatch(resetUpdateCardTypeRequest());
        }
        return;
      case 'LegalEntity':
        if (
          Object.keys(legalEntityCreated.response).length > 0 ||
          legalEntityCreated.error ||
          Object.keys(legalEntityUpdated.response).length > 0 ||
          legalEntityUpdated.error
        ) {
          dispatch(resetCreateLegalEntityRequest());
          dispatch(resetUpdateLegalEntityRequest());
        }
        return;
    }
  };

  const fetchData = (value: string) => {
    switch (viewComponentName) {
      case 'FeeGroup':
        dispatch(fetchFeeGroupRequest({ country: value }));
        return;
      case 'PriceProfile':
        dispatch(fetchPriceProfileRequest({ countryCode: value }));
        return;
      case 'TokenType':
        dispatch(fetchTokenTypeRequest({ country: value }));
        return;
      case 'CardType':
        dispatch(fetchCardTypeRequest({ country: value }));
        return;
      case 'LegalEntity':
        dispatch(fetchLegalEntityRequest({ countryCode: value }));
        return;
      default:
        return;
    }
  };

  const validateCountryErrors = (e: any) => {
    const { value } = e.target;
    if (value === '') setCountryErrors(true);
    else setCountryErrors(false);
  };

  const handleCreateButtonClick = () => {
    setEditDealMaster(false);
    setBtnClick({ createBtn: true });
    setButtonDisable({ ...buttonDisable, create: true });
    setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
    resetRequest();
  };

  return (
    <>
      <ResponsiveContainer>
        <>
          <Card className={classes.root} variant="elevation">
            <Grid
              key="aggregatorDetails-grid-1"
              container
              justifyContent="flex-start"
            >
              <FormControl
                key="DM-country-formcontrol"
                variant="outlined"
                className={classes.formControl}
                error={hasCountryErrors || !!countriesError}
                size="small"
              >
                <InputLabel
                  key="DM-country-input-label"
                  id="DM-country-select-label"
                >
                  Country
                </InputLabel>
                <Select
                  key="DM-country-select"
                  name="country"
                  labelId="DM-country-select-label"
                  id="DM-country-select"
                  value={countryValue}
                  defaultValue=""
                  onChange={handleCountryChange}
                  label="Country"
                  onBlur={validateCountryErrors}
                >
                  {!countriesLoading &&
                    countries.map((option: any) => (
                      <MenuItem
                        key={option.code}
                        value={option.code}
                        aria-selected={true}
                        className={classes.menuItems}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText key="DM-country-FHT">
                  {countriesLoading
                    ? 'Loading countries'
                    : countriesError
                    ? `Error loading countries - [${countriesError}]`
                    : countryValue
                    ? ''
                    : 'Please select country'}
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControlButton}
                size="small"
              >
                <UserPermission component={screenName} permissionFor="create">
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.searchButton}
                    onClick={handleCreateButtonClick}
                    disabled={buttonDisable.create}
                  >
                    Create {name.split('-').join(' ')}
                  </Button>
                </UserPermission>
              </FormControl>
            </Grid>
          </Card>
          {dealsLoading || (btnClick.createBtn && offeringPackageLoading) ? (
            <Card style={{ height: 120 }}>
              <Grid>
                <Spinner custom title={`Loading ...`} />
              </Grid>
            </Card>
          ) : btnClick.createBtn ? (
            loadCrtUptComponent()
          ) : countryValue !== '' ? (
            loadViewComponent()
          ) : null}
        </>
      </ResponsiveContainer>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    countriesLoading: state.countries.pending,
    countriesError: state.countries.error,
    dealsLoading: state.deals.pending,
    offeringPackage: state.offeringPackage.offeringPackage,
    offeringPackageLoading: state.offeringPackage.pending,
    offeringPackageError: state.offeringPackage.error,
    feeGroupCreated: state.feeGroupCreated,
    feeGroupUpdated: state.feeGroupUpdated,
    priceProfileCreated: state.priceProfileCreated,
    priceProfileUpdated: state.priceProfileUpdated,
    tokenTypeCreated: state.tokenTypeCreated,
    tokenTypeUpdated: state.tokenTypeUpdated,
    cardTypeCreated: state.cardTypeCreated,
    cardTypeUpdated: state.cardTypeUpdated,
    legalEntityCreated: state.legalEntityCreated,
    legalEntityUpdated: state.legalEntityUpdated,
  };
};

export default connect(mapStateToProps)(CountryAggregator);
