import {
  Paper,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Card,
} from '@material-ui/core';

import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../Spinner';
import { ResponsiveContainer } from 'recharts';
import Alert from '@material-ui/lab/Alert';
import {
  fetchTokenTypeRequest,
  resetCreateTokenTypeRequest,
  resetUpdateTokenTypeRequest,
} from '../../store/offeringPackages/actions';
import { FetchTokenTypeRequestPayload } from '../../store/offeringPackages/types';
import CreateUpdateTokenType from './CreateUpdateTokenType';
import { DealMasterTable } from './DealMasterTable';
import { handleNumberValidation } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  spinnerParent: {
    padding: '10px 10px 10px 20px',
    height: 120,
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    margin: '0px',
  },
  inputFormControl: {
    margin: '8px 0px 0px 8px',
  },
  helperText: {
    marginLeft: 10,
  },
  chips: {
    margin: 1,
    background: '#aaaaaa50',
  },
  searchIcon: {
    '&:hover': { background: '#fbcf04d1' },
    '&.MuiIconButton-root.Mui-disabled': { background: '#ddddddcf' },
    background: '#fbcf04',
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
  },
}));

const headers = [
  { label: 'Id', key: 'id' },
  { label: 'Token Type Name', key: 'name' },
  { label: 'Action', key: 'id' },
];

const tokenTypeInitialValues: any = {
  category: '',
  subCategory: 'tokenType',
  tokenType: { countryCode: '', name: '', id: '' },
};

function ViewTokenType(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const {
    tokenTypes,
    countryValue,
    tokenTypesLoading,
    tokenTypePage,
    tokenTypesError,
    tokenTypeCreated,
    tokenTypeCreatedError,
    tokenTypeUpdated,
    tokenTypeUpdatedError,
    editDealMaster,
    setButtonDisable,
    setEditDealMaster,
    buttonDisable,
    setBtnClick,
    createUpdateStatus,
    setCreateUpdateStatus,
  } = props;

  if (tokenTypesLoading) {
    return (
      <div className={classes.spinnerParent}>
        <Spinner custom />
      </div>
    );
  }

  const handleSearch = () => {
    dispatch(
      fetchTokenTypeRequest({
        country: countryValue,
        tokenTypeId: searchText,
      })
    );
  };

  const handleChangePage = (_event: any, page: any) => {
    const payload: FetchTokenTypeRequestPayload = {
      country: countryValue,
      pageNumber: page + 1,
      tokenTypeId: searchText,
    };
    dispatch(fetchTokenTypeRequest(payload));
  };

  const handleEdit = (event: any) => {
    const tokenData = tokenTypes.filter(
      (p: any) => p.id == event.currentTarget.id
    );
    tokenTypeInitialValues.tokenType = tokenData[0];

    resetTokenType();
    setButtonDisable({ ...buttonDisable, create: false });
    setCreateUpdateStatus({ ...createUpdateStatus, flag: false });
    setEditDealMaster(true);
  };

  function resetTokenType() {
    if (
      Object.keys(tokenTypeCreated.response).length > 0 ||
      tokenTypeCreated.error ||
      Object.keys(tokenTypeUpdated.response).length > 0 ||
      tokenTypeUpdated.error
    ) {
      dispatch(resetCreateTokenTypeRequest());
      dispatch(resetUpdateTokenTypeRequest());
    }
  }

  function HandleAlert() {
    if (
      (!tokenTypeUpdated.pending && tokenTypeUpdated.response.success) ||
      (!tokenTypeCreated.pending && tokenTypeCreated.response.success)
    ) {
      return (
        <Alert key={'DMS-handleAlert-success'} severity="success">
          {createUpdateStatus.screen === 'update'
            ? ` Token Type updated Successfully.`
            : ` Token Type created Successfully.  ${JSON.stringify(
                tokenTypeCreated.response.data,
                null,
                4
              )}.`}
        </Alert>
      );
    } else {
      return (
        <Alert key={'DMS-handleAlert-error'} severity="error">
          {`Error creating Token Type - [${
            tokenTypeCreatedError ||
            tokenTypeCreated.response.message ||
            tokenTypeUpdatedError ||
            tokenTypeUpdated.response.message
          }]`}
        </Alert>
      );
    }
  }

  return (
    <>
      {editDealMaster ? (
        <>
          <CreateUpdateTokenType
            isDealMasterEdit={editDealMaster}
            tokenTypeInitialValues={tokenTypeInitialValues}
            countryValue={countryValue}
            setEditDealMaster={setEditDealMaster}
            setButtonDisable={setButtonDisable}
            setBtnClick={setBtnClick}
            buttonDisable={buttonDisable}
            createUpdateStatus={createUpdateStatus}
            setCreateUpdateStatus={setCreateUpdateStatus}
          />
        </>
      ) : (
        <>
          {createUpdateStatus.flag ? (
            <Card className={classes.root} variant="elevation">
              <HandleAlert />
            </Card>
          ) : (
            <></>
          )}
          <div className="search-result">
            <div className="search-result-text">Token Type Result</div>
            <div>
              <FormControl
                variant="outlined"
                className={classes.inputFormControl}
                error={false}
                size="small"
              >
                <TextField
                  name="searchPPText"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  label="Search Token Type Id"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  onKeyPress={handleNumberValidation}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleSearch}
                        className={classes.searchIcon}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
          <ResponsiveContainer>
            {!tokenTypesLoading && tokenTypes.length > 0 ? (
              <Paper className={classes.root}>
                <DealMasterTable
                  headers={headers}
                  data={tokenTypes}
                  page={tokenTypePage}
                  handleEdit={handleEdit}
                  handleChangePage={handleChangePage}
                />
              </Paper>
            ) : (
              <Typography
                style={{ fontFamily: 'Shell-Book', textAlign: 'center' }}
              >
                {tokenTypesError ? (
                  <Alert severity="error">
                    {`Error loading Token Types - [${tokenTypesError}]`}
                  </Alert>
                ) : (
                  'No Token Type found'
                )}
              </Typography>
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countries: state.countries.countries,
    tokenTypes: state.tokenTypes.tokenTypes,
    tokenTypesLoading: state.tokenTypes.pending,
    tokenTypesError: state.tokenTypes.error,
    tokenTypePage: state.tokenTypes.page,
    tokenTypeCreated: state.tokenTypeCreated,
    tokenTypeCreatedError: state.tokenTypeCreated.error,
    tokenTypeUpdated: state.tokenTypeUpdated,
    tokenTypeUpdatedError: state.tokenTypeUpdated.error,
  };
};
export default connect(mapStateToProps)(ViewTokenType);
