import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

function StatusMessage(props: any) {
  function TransitionLeft(props: any) {
    return <Slide {...props} direction="left" />;
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={props.open}
      action={action}
      message={props.message}
      TransitionComponent={TransitionLeft}
    />
  );
}

export default StatusMessage;
