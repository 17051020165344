import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    float: 'left',
    transition: '0.3s',
    boxShadow: '0 18px 4px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
    width: '100%',
  },
  cardAction: {
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    textAlign: 'left',
  },
  boxTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  gfnRow: {
    marginTop: '6px',
  },
  collapse: {
    padding: '16px',
    paddingTop: '8px',
    backgroundColor: '#F7F7F7',
  },
}));

interface gfn {
  id: string;
  value: any;
  label: string;
}

function CustomerDetails(props: any) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const {
    customerProfile,
  } = props;
  const [gfnMap, setGfnMap] = React.useState([
    { id: '', value: '', label: '' },
  ]);

  React.useEffect(() => {
    if (customerProfile)  loadMap();
  }, []);

  function loadMap() {
    const gfnAccountDataMap: gfn[] = [
      {
        id: 'customerId',
        value: customerProfile.customerId ? customerProfile.customerId : '-',
        label: 'Customer ID',
      },
      {
        id: 'gfnAccountId',
        value: customerProfile.gfnAccountId ? customerProfile.gfnAccountId : '-',
        label: 'GFN Account ID',
      },
      {
        id: 'userId',
        value: customerProfile.userId ? customerProfile.userId : '-',
        label: 'User ID',
      },
    ];
    setGfnMap(gfnAccountDataMap);
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
    {localStorage.getItem('role') === 'superadmin' &&
        <Box className={classes.boxTitle} onClick={() => setExpanded(!expanded)}>
          <Typography
            component="div"
            sx={{
              textAlign: 'left',
              fontFamily: 'Shell-Book',
              fontWeight: expanded ? '600 !important' : '',
              color: expanded ? '#595959' : '',
              fontSize: '.9rem',
            }}
          >
            Customer Account Details
          </Typography>
          {!expanded ? (
            <ControlPointIcon
              color="action"
              fontSize="small"
            ></ControlPointIcon>
          ) : (
            <RemoveCircleOutlineIcon
              color="action"
              fontSize="small"
            ></RemoveCircleOutlineIcon>
          )}
        </Box>}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider variant="middle" sx={{ my: 0, mx: 0 }} />
          <Box className={classes.collapse}>
            {gfnMap && gfnMap.map(({ label, value, id }) => {
                    return (
                      <Box className={classes.box} key={id}>
                        <Grid container className={classes.gfnRow}>
                          <Grid item xs={6}>
                            {label}
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ color: 'text.secondary' }}>{value}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                }
          </Box>
        </Collapse>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    customerProfile: state.customerProfile.customerProfile
  };
};

export default connect(mapStateToProps)(CustomerDetails);